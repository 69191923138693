import React, { Component } from 'react';
import { EDIT_BASIC_PROFILE, GET_SINGLE_USER } from '../../queries/UserQueries';

import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions'

import { Dropdown, Grid, Button, Radio, Form, Icon } from 'semantic-ui-react';

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

//Spinner
import Loader from 'react-loader-spinner'


import {
    GET_ALL_PROVINCES, GET_DISTRICTS_FOR_PROVINCES, GET_DIVISIONS_FOR_MOHAREA, GET_MOHAREAS_FOR_DISTRICT, GET_ALL_DISTRICTS,
    GET_AREA_DETAILS
} from '../../queries/CommonQueries'
import { tsImportEqualsDeclaration } from '@babel/types';


const apiKey = "AIzaSyAa4iktQ8XNg1TJDk_CPptIgzZWmBQm7bM"

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class UserProfile extends Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        }
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        this.state = {
            loading: false,
            userId: id,
            user: [],
            name: "",
            tel: '',
            nic: '',
            email: '',
            gender: '',
            isMale: false,
            isFemale: false,
            province: "",
            district: "",
            moharea: "",
            gndivision: "",
            provinceId: "",
            districtId: "",
            mohareaId: "",
            gndivisionId: "",
            lat: "",
            lon: "",
            provinces: [],
            districts: [],
            mohareas: [],
            divisions: [],
            marker:
            {
                title: "The marker`s title will appear as a tooltip.",
                name: "COLOMBO",
                position: { lat: 6.93194, lng: 79.84778 }
            },

        }
        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });


        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ loading: true });

        this._editUserProfile().then(result => {

            this.props.setSuccessStatus({
                status: true,
                title: "Oops!",
                message:
                    "Updated Successfully!"
            });
            this.setState({ loading: false });

        }).catch(error => {

            console.log(error);
            this.setState({ loading: false });
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                    "There was an error while trying to Update, Please try again later!"
            });

        });
    };


    componentDidMount() {
        this.getSingleUser(this.state.userId);

        // read provinces
        this.loadProvinces().then(result => {
            var provinces = result.data.getAllProvinces.map(province => {
                console.log(province)
                return { key: province.id, text: province.province, value: province.id, id: 'provinceId' }
            });
            provinces.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            provinces.unshift({ key: null, id: "provinceId", text: "Select-Province", value: "Select-Province", disabled: true });

            this.setState({ provinces: provinces });
        })

        this.loadAllDistricts().then(result => {
            var districts = result.data.getAllDistricts.map(district => {
                return { key: district.id, text: district.district, value: district.id, id: 'districtId' }
            });
            districts.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            districts.unshift({ key: null, id: "districtId", text: "Select-District", value: "Select-District", disabled: true });

            this.setState({ districts: districts });
        })
    }

    getSingleUser(userId) {
        this.setState({ loading: true });
        this._getSingleUser(userId).then(result => {
            console.log(result.data.getSingleUser.name)

            if (result.data.getSingleUser.districtId != undefined && result.data.getSingleUser.districtId != "" && result.data.getSingleUser.districtId != null) {

                this.loadMOHAreas(result.data.getSingleUser.districtId).then(result => {
                    console.log(result.data.getDivisionsForMOHArea)
                    var mohareas = result.data.getMOHAreasForDistrict.map(moharea => {
                        return { key: moharea.id, text: moharea.mohArea, value: moharea.id, id: 'mohareaId' }
                    });
                    mohareas.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
                    mohareas.unshift({ key: null, id: "mohareaId", text: "Select-MOHArea", value: "Select-MOHArea", disabled: true });
                    this.setState({ mohareas: mohareas });
                })
            }

            if (result.data.getSingleUser.mohareaId != undefined && result.data.getSingleUser.mohareaId != "" && result.data.getSingleUser.mohareaId != null) {

                this.getDivisions(result.data.getSingleUser.mohareaId);
            }

            var isMale = false;
            var isFemale = false;

            if (result.data.getSingleUser.gender == "MALE") {
                isMale = true;
                isFemale = false;
            } else if (result.data.getSingleUser.gender == "FEMALE") {
                isMale = false;
                isFemale = true;
            }


            console.log(result.data.getSingleUser)
            this.setState({
                name: result.data.getSingleUser.name,
                tel: result.data.getSingleUser.tel,
                nic: result.data.getSingleUser.nic,
                email: result.data.getSingleUser.email,
                gender: result.data.getSingleUser.gender,
                isMale: isMale,
                isFemale: isFemale,
                province: result.data.getSingleUser.province,
                district: result.data.getSingleUser.district,
                moharea: result.data.getSingleUser.moharea,
                gndivision: result.data.getSingleUser.gndivision,
                provinceId: result.data.getSingleUser.provinceId,
                districtId: result.data.getSingleUser.districtId,
                mohareaId: result.data.getSingleUser.mohareaId,
                gndivisionId: result.data.getSingleUser.gndivisionId,
                lat: result.data.getSingleUser.location.latitude,
                lon: result.data.getSingleUser.location.longitude,
                loading: false,
                marker:
                {
                    title: "The marker`s title will appear as a tooltip.",
                    name: "COLOMBO",
                    position: { lat: result.data.getSingleUser.location.latitude, lng: result.data.getSingleUser.location.longitude }
                },
                location: {},
                allowClick: true
            })

        });
    }

    getDivisions(key) {
        this.loadDivisions(key).then(result => {
            var divisions = result.data.getDivisionsForMOHArea.map(division => {
                return { key: division.id, text: division.gndivision, value: division.id, id: 'gndivisionId' }
            });
            divisions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            divisions.unshift({ key: null, id: "gndivisionId", text: "Select-Division", value: "Select-Division", disabled: true });
            this.setState({ divisions: divisions });
        })
    }


    // load divisions from api
    loadDivisions = async (mohId) => {
        const result = await this.props.client.query({
            query: GET_DIVISIONS_FOR_MOHAREA,
            variables: { mohId },
            fetchPolicy: "network-only"
        });
        return result;
    };

    _getSingleUser = async (userId) => {
        const result = await this.props.client.query({
            query: GET_SINGLE_USER,
            variables: { userId },
            //consider above
            fetchPolicy: 'cache-first'
        });
        console.log(result)
        return result;
    };

    _editUserProfile = async () => {
        const { userId, name, tel, province, provinceId, district, districtId, moharea, mohareaId, division, gndivision, gndivisionId, email, isActive } = this.state;

        var lat = "";
        var lon = "";

        if (this.state.lat == "" || this.state.lon == "" || this.state.lat == undefined || this.state.lon == undefined) {
            lat = "";
            lon = "";
        } else {
            lat = this.state.lat.toString();
            lon = this.state.lon.toString();
        }

        const result = await this.props.client.mutate({
            mutation: EDIT_BASIC_PROFILE,
            variables: { userId, name, tel, province, provinceId, district, districtId, moharea, mohareaId, division, gndivision, gndivisionId, lat, lon, email, isActive }
        });

        return result.data.editBasicProfile;
    }

    // load provinces from api
    loadProvinces = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_PROVINCES,
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load all districts from api
    loadAllDistricts = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_DISTRICTS,
            fetchPolicy: "network-only"
        });
        return result;
    };


    // load districts from api
    loadDistricts = async (provinceId) => {
        const result = await this.props.client.query({
            query: GET_DISTRICTS_FOR_PROVINCES,
            variables: { provinceId },
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load mohareas from api
    loadMOHAreas = async (districtId) => {
        const result = await this.props.client.query({
            query: GET_MOHAREAS_FOR_DISTRICT,
            variables: { districtId },
            fetchPolicy: "network-only"
        });
        return result;
    };


    _editProfile() {
        this.props.history.push('/editProfile')
    }

    handleOnCheck = (e, data) => {
        this.setState({ ['gender']: data.value });
    }

    handleProvinceChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "province": text });

        this.loadDistricts(key).then(result => {
            var districts = result.data.getDistrictsForProvince.map(district => {
                return { key: district.id, text: district.district, value: district.id, id: 'districtId' }
            });
            districts.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            districts.unshift({ key: null, id: "districtId", text: "Select-District", value: "Select-District", disabled: true });
            this.setState({ districts: districts });
        })
    }

    handleDistrictChange = (e, data) => {

        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "district": text });

        this.loadMOHAreas(key).then(result => {
            console.log(result.data.getDivisionsForMOHArea)
            var mohareas = result.data.getMOHAreasForDistrict.map(moharea => {
                return { key: moharea.id, text: moharea.mohArea, value: moharea.id, id: 'mohareaId' }
            });
            mohareas.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            mohareas.unshift({ key: null, id: "mohareaId", text: "Select-MOHArea", value: "Select-MOHArea", disabled: true });
            this.setState({ mohareas: mohareas });
        })
    }

    handleMOHChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "moharea": text });
        this.getDivisions(key);
    }

    handleDivisionChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        this.setState({ [data.id]: key, "gndivision": text });

    }

    onMapClick(t, map, coord) {
        const { latLng } = coord;
        const lat = latLng.lat().toString();
        const lng = latLng.lng().toString();
        const location = { latitude: lat, longitude: lng };
        this.setState({ lat: lat, lon: lng, allowClick: false });

        this.setState({ marker: { title: "", name: "", position: { lat, lng } } });

        this.getAreaDetails(lat, lng).then(result => {
            var mohareas = result.mohareas.map(moharea => {
                return { key: moharea.id, text: moharea.mohArea, value: moharea.id, id: 'mohareaId' }
            });
            mohareas.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            mohareas.unshift({ key: null, id: "mohareaId", text: "Select-MOHArea", value: "Select-MOHArea", disabled: true });

            var divisions = result.divisions.map(division => {
                return { key: division.id, text: division.gndivision, value: division.id, id: 'gndivisionId' }
            });
            divisions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            divisions.unshift({ key: null, id: "gndivisionId", text: "Select-Division", value: "Select-Division", disabled: true });

            this.setState({
                provinceId: result.province.provinceId,
                province: result.province.province,
                districtId: result.district.districtId,
                district: result.district.district,
                divisions: divisions,
                mohareas: mohareas,
                allowClick: true
            });
        }).catch(error => {
            if (error) {
                console.log(error);
                console.log('There is an error');
                this.props.setFormStatus({ status: true, title: 'Oops!', message: 'Error in ' });
            }
            this.setState({ loading: false, allowClick: true });

        });


    }

    getAreaDetails = async (lat, lng) => {
        const lon = lng;

        const result = await this.props.client.query({
            query: GET_AREA_DETAILS,
            variables: { lat, lon },
            fetchPolicy: "network-only"
        });
        return result.data.getAreaDetails;
    };

    goBack = () => {
        this.props.history.goBack();
    };


    render() {
        const { user } = this.state;
        const { marker, name, loading, tel, gndivision, email, nic, password, confirmpassword, lon, lat, provinces, districts, mohareas, divisions } = this.state;
        const { formErrorStatus, formSuccessState } = this.props;
        console.log(this.state.lon);
        console.log(this.state.gender)
        // console.log
        return (
            <div className="right_col" role="main">
                <div className="row">
                    <div >
                        <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                    </div>

                    <div id="" class="col-md-12 col-sm-12 ">
                        <section class="login_content">
                            <form>
                                <Grid columns='two'>
                                    <Grid.Row>
                                        <Grid.Column className="login_leftcontent" width={6}>
                                            <h1>User Profile</h1>
                                            <div>
                                                <label className="floatLeft">Name </label><br />
                                                <input value={this.state.name} type="text" id="name" name="name" className="form-control" placeholder="Full Name" onChange={this.handleChange} />
                                            </div>
                                            <div>
                                                <label className="floatLeft"> Telephone </label><br />
                                                <input value={this.state.tel} type="text" id="tel" name='tel' className="form-control" placeholder="Telephone" onChange={this.handleChange} />
                                            </div>
                                            <div style={{ float: "left", textAlign: "left" }}>
                                                <label className="floatLeft"> Gender </label><br />

                                                <Form.Group >
                                                    <Form.Radio
                                                        id="male"
                                                        label="Male"
                                                        name="gender"
                                                        value={this.state.gender}
                                                        checked={this.state.isMale}
                                                        onChange={this.handleOnCheck}
                                                    />
                                                    <Form.Radio
                                                        id="female"
                                                        label="Female"
                                                        name="gender"
                                                        value={this.state.gender}
                                                        checked={this.state.isFemale}
                                                        onChange={this.handleOnCheck}
                                                    />
                                                </Form.Group>

                                                <br />
                                            </div>
                                            <div>
                                                {/* <label className="floatLeft"> NIC </label><br /> */}
                                                <input value={this.state.nic} type="text" id="nic" name='nic' className="form-control" placeholder="NIC" onChange={this.handleChange} />
                                            </div>
                                            <div>
                                                <label className="floatLeft"> Email </label><br />
                                                <input value={this.state.email} type="email" id="email" name='email' className="form-control" placeholder="Email" onChange={this.handleChange} />
                                            </div>
                                            <div>
                                                <label className="floatLeft"> Province </label><br />
                                                <Dropdown
                                                    placeholder='Select Province'
                                                    fluid
                                                    selection
                                                    id='provinceId'
                                                    name='provinceId'
                                                    options={provinces}
                                                    value={this.state.provinceId}
                                                    onChange={this.handleProvinceChange}
                                                />
                                            </div><br />

                                            <div>
                                                <label className="floatLeft"> District </label><br />
                                                <Dropdown
                                                    placeholder='Select District'
                                                    fluid
                                                    selection
                                                    id='districtId'
                                                    name='districtId'
                                                    options={districts}
                                                    value={this.state.districtId}
                                                    onChange={this.handleDistrictChange}
                                                />
                                            </div><br />

                                            <div>
                                                <label className="floatLeft"> MOH Area </label><br />
                                                <Dropdown
                                                    placeholder='Select MOH Area'
                                                    fluid
                                                    selection
                                                    search
                                                    id='mohareaId'
                                                    name='mohareaId'
                                                    options={mohareas}
                                                    value={this.state.mohareaId}
                                                    onChange={this.handleMOHChange}
                                                />
                                            </div><br />

                                            <div>
                                                <label className="floatLeft"> GN Division </label><br />
                                                <Dropdown
                                                    placeholder='Select Division'
                                                    fluid
                                                    selection
                                                    search
                                                    id='gndivisionId'
                                                    name='gndivisionId'
                                                    options={divisions}
                                                    value={this.state.gndivisionId}
                                                    onChange={this.handleDivisionChange}
                                                />
                                            </div><br />

                                            {(formErrorStatus.status) ? (
                                                <div class="ui negative message">

                                                    <div class="header">
                                                        Not Submitted.
                                                </div>
                                                    <p>{formErrorStatus.message}</p>
                                                </div>

                                            ) : ((formSuccessState.status) ? (
                                                <div class="ui success message">
                                                    <div class="header">
                                                        Submitted successfully.
                                                            </div>
                                                    <p>{formSuccessState.message}</p>
                                                </div>
                                            ) : (''))}

                                            <div className="ln_solid" />
                                            <div className="form-group">
                                                <div className="col-md-12 col-sm-12" style={{ textAlign: "right" }}>
                                                    <button onClick={this.handleSubmit} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                        {loading ? (
                                                            <Loader
                                                                type="Oval"
                                                                color="#2A3F54"
                                                                height={15}
                                                                width={40}
                                                            />
                                                        ) : ("Submit")}
                                                    </button>
                                                </div>
                                            </div>

                                        </Grid.Column>

                                        <Grid.Column >
                                            <div className="map_container" style={{ "paddingTop": "55px" }}>
                                                <Map
                                                    google={this.props.google}
                                                    style={{ width: "80%", margin: "auto", height: "78%" }}
                                                    initialCenter={{ lat: 7.2906, lng: 80.6337 }}
                                                    zoom={8.7}
                                                    onClick={
                                                        this.state.allowClick ? this.onMapClick : false
                                                    }
                                                >
                                                    <Marker
                                                        title={marker.title}
                                                        name={marker.name}
                                                        position={marker.position}
                                                        onChange={this.handleChange}
                                                    />
                                                </Map>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>


                            </form>
                        </section>
                    </div>
                </div>
            </div>

        )
    }
}
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: apiKey
})
    (UserProfile))));
