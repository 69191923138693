import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import '@firebase/storage';

const settings = {timestampsInSnapshots: true};

// const config = {
//     apiKey: "AIzaSyAT-ehZWASiI2J6tvHy3xEOgvMOr4XLsCI",
//     authDomain: "ippf-7131f.firebaseapp.com",
//     databaseURL: "https://ippf-7131f.firebaseio.com",
//     projectId: "ippf-7131f",
//     storageBucket: "gs://ippf-7131f.appspot.com",
//     messagingSenderId: "393204276878",
//     appId: "1:393204276878:web:38fd163398a79dd3bc33df",
//     measurementId: "G-KYRS7W6S37"
// };

// var config = {
//     apiKey: "AIzaSyAe22zW4rYWfkDjtVovr0sWUIU7qy0VCHo",
//     authDomain: "unfpa-web.firebaseapp.com",
//     databaseURL: "https://unfpa-web.firebaseio.com",
//     projectId: "unfpa-web",
//     storageBucket: "unfpa-web.appspot.com",
//     messagingSenderId: "769758831960",
//     appId: "1:769758831960:web:1aec055a9f81569c92312d"
// };

const config = {
    apiKey: "AIzaSyD8VqWeQ6Pzbm7vLKkOMPwnfKON5XddruU",
    authDomain: "unfpa-547e9.firebaseapp.com",
    databaseURL: "https://unfpa-547e9.firebaseio.com",
    projectId: "unfpa-547e9",
    storageBucket: "unfpa-547e9.appspot.com",
    messagingSenderId: "1034980656278",
    appId: "1:1034980656278:web:27cc02d6f7618138342eb7",
    measurementId: "G-YBM2W6C12Y"
  };

firebase.initializeApp(config);

//firebase.firestore().settings(settings);

export default firebase;