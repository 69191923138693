import ManageUsers from "../screens/users/manageUsers";
import ManageSettings from "../screens/settings/manageSettings";
import UserProfile from "../screens/users/userProfile";
import HighlevelUsers from "../screens/highlevelusers/highlevelUsers";
import Dashboard from "../screens/reports/dashboard/dashboard";
import AddNewPreSchoolSetting from "../screens/settings/preSchool/addNewPreSchoolSetting";
import AddNewHighLevelUser from "../screens/highlevelusers/addNewHighLevelUser";
import AddNewMotherSupportSetting from "../screens/settings/motherSupportGroup/addNewMotherSupportSetting";
import ViewPreSchoolSubmission from '../screens/settings/preSchool/viewPreSchoolSubmission';
import ViewMotherSupportSubmission from '../screens/settings/motherSupportGroup/viewMotherSupportSubmission';
import AddNewMotherSupportEvent from '../screens/events/motherSupportEvent/addNewMotherSupportEvent';
import ViewMotherSupportEvents from '../screens/events/motherSupportEvent/viewMotherSupportEvents';
import Viewworkplacesubmission from '../screens/settings/workPlace/viewWorkPlaceSubmission';
import Addworkplacesubmission from '../screens/settings/workPlace/addNewWorkPlaceSetting';
import AddNewWorkPlaceEvent from '../screens/events/workPlaceEvent/addNewWorkPlaceEvent';
import AddNewHospitalSetting from '../screens/settings/hospital/addNewHospitalSetting';
import ViewHospitalSubmission from '../screens/settings/hospital/viewHospitalSubmission';
import ViewHospitalEvents from '../screens/events/hospitalEvent/viewHospitalEvents';
import AddNewHospitalEvent from '../screens/events/hospitalEvent/addNewHospitalEvent';
import ViewWorkPlaceEvents from '../screens/events/workPlaceEvent/viewWorkPlaceEvents';
import AddNewPreschoolEvent from '../screens/events/preSchoolEvent/addNewPreschoolEvent';
import NewsList from '../screens/news/newsList';
import ViewNews from '../screens/news/viewNews';
import AddNews from '../screens/news/addNews';
import ViewPreschoolEvents from "../screens/events/preSchoolEvent/viewPreschoolEvents";
import AddNewSchoolSetting from '../screens/settings/school/addNewSchoolSetting';
import ViewSchoolSubmission from '../screens/settings/school/viewSchoolSubmission';
import AddNewSchoolEvent from '../screens/events/schoolEvent/addNewSchoolEvent';
import ViewSchoolEvents from '../screens/events/schoolEvent/viewSchoolEvents';

import AddNewVillageSetting from '../screens/settings/village/addNewVillageSetting';
import ViewVillageSubmission from '../screens/settings/village/viewVillageSubmission';
import AddNewVillageEvent from '../screens/events/villageEvent/addNewVillageEvent';
import ViewVillageEvents from '../screens/events/villageEvent/viewVillageEvents';
import ChangePassword from '../screens/users/changePassword';
import ViewNotifications from '../screens/notifications/viewNotifications';
import ForgetPassword from '../screens/users/forgetPassword';
import SubmissionReportByDistrict from '../screens/reports/submissionReportByDistrict';
import UserReportByDistrict from '../screens/reports/userReportByDistrict';

export const reportRoutes = [
    {
        path: "/submission-report-by-district",
        name: "Setting Reports",
        icon: "fa fa-file-text-o",
        component: SubmissionReportByDistrict,
        isVisibleInMenu:true
    },
    {
        path: "/user-report",
        name: "Users Reports",
        icon: "fa fa-users",
        component: UserReportByDistrict,
        isVisibleInMenu:true
    }
]


export const userRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "fa fa-tachometer",
        component: Dashboard,
        isVisibleInMenu:false
    },
    {
        path: "/settings",
        name: "Manage Settings",
        icon: "fa fa-desktop",
        component: ManageSettings,
        isVisibleInMenu:true
    },
    {
        path: "/addpreschoolsubmission",
        name: "Add Pre School Submission",
        icon: "fa fa-calendar",
        component: AddNewPreSchoolSetting,
        isVisibleInMenu:true
    },
    {
        path:'/viewpreschoolsubmission',
        name: "View Pre School Submission",
        component: ViewPreSchoolSubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/addmothersupportsubmission",
        name: "Add Mother Support Submission",
        icon: "fa fa-calendar",
        component: AddNewMotherSupportSetting,
        isVisibleInMenu:true
    },
    {
        path:'/viewmothersupportsubmission',
        name: "View Mother Support Submission",
        component: ViewMotherSupportSubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/addmothersupportevent",
        name: "Add Mother Support Event",
        icon: "fa fa-user-plus",
        component: AddNewMotherSupportEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewmothersupportevents",
        name: "Mother Support Events",
        icon: "fa fa-user-plus",
        component: ViewMotherSupportEvents,
        isVisibleInMenu:false
    },
    {
        path: "/viewmothersupportevent",
        name: "Mother Support Event",
        icon: "fa fa-user-plus",
        component: AddNewMotherSupportEvent,
        isVisibleInMenu:false
    },
    {
        path: "/editmothersupportsubmission",
        name: "Edit Mother Support",
        icon: "fa fa-user-plus",
        component: AddNewMotherSupportSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewworkplacesubmission',
        name: "View Work Place Submission",
        component: Viewworkplacesubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/addworkplacesubmission",
        name: "Add Work Place Submission",
        icon: "fa fa-user-plus",
        component: Addworkplacesubmission,
        isVisibleInMenu:false
    },
    {
        path: "/editworkplacesubmission",
        name: "Edit Work Place",
        icon: "fa fa-user-plus",
        component: Addworkplacesubmission,
        isVisibleInMenu:false
    },
    {
        path: "/viewworkplaceevents",
        name: "Work Place Events",
        icon: "fa fa-user-plus",
        component: ViewWorkPlaceEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addworkplaceevent",
        name: "Add Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewWorkPlaceEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewworkplaceevent",
        name: "View Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewWorkPlaceEvent,
        isVisibleInMenu:false
    },
    {
        path: "/addhospitalsubmission",
        name: "Add Hospital",
        icon: "fa fa-user-plus",
        component: AddNewHospitalSetting,
        isVisibleInMenu:false
    },
    {
        path: "/edithospitalsubmission",
        name: "Edit Hospital",
        icon: "fa fa-user-plus",
        component: AddNewHospitalSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewhospitalsubmission',
        name: "View Hospital Submission",
        component: ViewHospitalSubmission,
        isVisibleInMenu:false,
    },
    {
        path: "/viewhospitalevents",
        name: "Hospital Events",
        icon: "fa fa-user-plus",
        component: ViewHospitalEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addhospitalevent",
        name: "Add Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewHospitalEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewhospitalevent",
        name: "View Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewHospitalEvent,
        isVisibleInMenu:false
    },
    {
        path: "/editpreschoolsubmission",
        name: "Edit Pre School",
        icon: "fa fa-user-plus",
        component: AddNewPreSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path: "/viewpreschoolevents",
        name: "Pre School Events",
        icon: "fa fa-user-plus",
        component: ViewPreschoolEvents,
        isVisibleInMenu:false
    },
    {
        path: "/viewpreschoolevent",
        name: "View Pre School Event",
        icon: "fa fa-user-plus",
        component: AddNewPreschoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/addpreschoolevent",
        name: "Add Pre School Event",
        icon: "fa fa-user-plus",
        component: AddNewPreschoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/addschoolsubmission",
        name: "Add School",
        icon: "fa fa-user-plus",
        component: AddNewSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editSchoolsubmission",
        name: "Edit School",
        icon: "fa fa-user-plus",
        component: AddNewSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewschoolsubmission',
        name: "View School Submission",
        component: ViewSchoolSubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/viewschoolevents",
        name: " School Events",
        icon: "fa fa-user-plus",
        component: ViewSchoolEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addschoolevent",
        name: "Add School Event",
        icon: "fa fa-user-plus",
        component: AddNewSchoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewschoolevent",
        name: "View School Event",
        icon: "fa fa-user-plus",
        component: AddNewSchoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/addvillagesubmission",
        name: "Add Village",
        icon: "fa fa-user-plus",
        component: AddNewVillageSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editvillagesubmission",
        name: "Edit Village",
        icon: "fa fa-user-plus",
        component: AddNewVillageSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewvillagesubmission',
        name: "View Village Submission",
        component: ViewVillageSubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/viewvillageevents",
        name: " Village Events",
        icon: "fa fa-user-plus",
        component: ViewVillageEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addvillageevent",
        name: "Add Village Event",
        icon: "fa fa-user-plus",
        component: AddNewVillageEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewvillageevent",
        name: "View Village Event",
        icon: "fa fa-user-plus",
        component: AddNewVillageEvent,
        isVisibleInMenu:false
    },
    {
        path: "/userProfile",
        name: "User Profile",
        icon: "fa fa-user",
        component: UserProfile,
        isVisibleInMenu:true
    },
    {
        path: "/changePassword",
        name: "Change Password",
        icon: "fa fa-user-plus",
        component: ChangePassword,
        isVisibleInMenu:false
    },
    {
        path: "/viewNotifications",
        name: "View Notifications",
        icon: "fa fa-user-plus",
        component: ViewNotifications,
        isVisibleInMenu:false
    }
    

    

    
];

export const adminRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "fa fa-tachometer",
        component: Dashboard,
        isVisibleInMenu:true
    },
    {
        path: "/users",
        name: "Manage Users",
        icon: "fa fa-user",
        component: ManageUsers,
        isVisibleInMenu:true
    },
    {
        path: "/highlevelusers",
        name: "Manage High Level Users",
        icon: "fa fa-user-plus",
        component: HighlevelUsers,
        isVisibleInMenu:true
    },
    {
        path: "/settings",
        name: "Manage Settings",
        icon: "fa fa-desktop",
        component: ManageSettings,
        isVisibleInMenu:true
    },
    {
        path: "/news",
        name: "Manage News",
        icon: "fa fa-newspaper-o",
        component: NewsList,
        isVisibleInMenu:true
    },
    {
        path: "/reports",
        name: "Reports",
        icon: "fa fa-line-chart",
        component: Dashboard,
        isVisibleInMenu:false
    },
    {
        path: "/addnewhighleveluser",
        name: "Add High Level User",
        icon: "fa fa-user-plus",
        component: AddNewHighLevelUser,
        isVisibleInMenu:false
    },
    {
        path:'/viewpreschoolsubmission',
        name: "View Pre School Submission",
        component: ViewPreSchoolSubmission,
        isVisibleInMenu:false,
    },
    {
        path:'/viewmothersupportsubmission',
        name: "View Mother Support Submission",
        component: ViewMotherSupportSubmission,
        isVisibleInMenu:false,
    },
    {
        path: "/viewmothersupportevents",
        name: "Mother Support Events",
        icon: "fa fa-user-plus",
        component: ViewMotherSupportEvents,
        isVisibleInMenu:false
    },
    {
        path: "/viewmothersupportevent",
        name: "Mother Support Event",
        icon: "fa fa-user-plus",
        component: AddNewMotherSupportEvent,
        isVisibleInMenu:false
    },
    {
        path: "/userProfile",
        name: "User Profile",
        icon: "fa fa-user",
        component: UserProfile,
        isVisibleInMenu:false
    },
    {
        path:'/viewworkplacesubmission',
        name: "View Work Place Submission",
        component: Viewworkplacesubmission,
        isVisibleInMenu:false,
    },
    {
        path: "/viewworkplaceevents",
        name: "Work Place Events",
        icon: "fa fa-user-plus",
        component: ViewWorkPlaceEvents,
        isVisibleInMenu:false
    },
    {
        path: "/viewworkplaceevent",
        name: "View Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewWorkPlaceEvent,
        isVisibleInMenu:false
    },
    {
        path:'/viewhospitalsubmission',
        name: "View Hospital Submission",
        component: ViewHospitalSubmission,
        isVisibleInMenu:false,
    },
    {
        path: "/viewhospitalevents",
        name: "Hospital Events",
        icon: "fa fa-user-plus",
        component: ViewHospitalEvents,
        isVisibleInMenu:false
    },
    {
        path: "/viewhospitalevent",
        name: "View Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewHospitalEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewpreschoolevents",
        name: "Pre School Events",
        icon: "fa fa-user-plus",
        component: ViewPreschoolEvents,
        isVisibleInMenu:false
    },
    {
        path: "/viewpreschoolevent",
        name: "View Pre School Event",
        icon: "fa fa-user-plus",
        component: AddNewPreschoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/editmothersupportsubmission",
        name: "Edit Mother Support",
        icon: "fa fa-user-plus",
        component: AddNewMotherSupportSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editpreschoolsubmission",
        name: "Edit Pre School",
        icon: "fa fa-user-plus",
        component: AddNewPreSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editworkplacesubmission",
        name: "Edit Work Place",
        icon: "fa fa-user-plus",
        component: Addworkplacesubmission,
        isVisibleInMenu:false
    },
    {
        path: "/edithospitalsubmission",
        name: "Edit Hospital",
        icon: "fa fa-user-plus",
        component: AddNewHospitalSetting,
        isVisibleInMenu:false
    },
    {
        path: "/addnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: AddNews,
        isVisibleInMenu:false
    },
    {
        path: "/editnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: AddNews,
        isVisibleInMenu:false
    },
    {
        path: "/viewnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: ViewNews,
        isVisibleInMenu:false
    },
    {
        path: "/addschoolsubmission",
        name: "Add School",
        icon: "fa fa-user-plus",
        component: AddNewSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editSchoolsubmission",
        name: "Edit School",
        icon: "fa fa-user-plus",
        component: AddNewSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewschoolsubmission',
        name: "View School Submission",
        component: ViewSchoolSubmission,
        isVisibleInMenu:false,
    },
    {
        path: "/viewschoolevents",
        name: " School Events",
        icon: "fa fa-user-plus",
        component: ViewSchoolEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addschoolevent",
        name: "Add School Event",
        icon: "fa fa-user-plus",
        component: AddNewSchoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewschoolevent",
        name: "View School Event",
        icon: "fa fa-user-plus",
        component: AddNewSchoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/addvillagesubmission",
        name: "Add Village",
        icon: "fa fa-user-plus",
        component: AddNewVillageSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editvillagesubmission",
        name: "Edit Village",
        icon: "fa fa-user-plus",
        component: AddNewVillageSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewvillagesubmission',
        name: "View Village Submission",
        component: ViewVillageSubmission,
        isVisibleInMenu:false,
    },
    {
        path: "/viewvillageevents",
        name: " Village Events",
        icon: "fa fa-user-plus",
        component: ViewVillageEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addvillageevent",
        name: "Add Village Event",
        icon: "fa fa-user-plus",
        component: AddNewVillageEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewvillageevent",
        name: "View Village Event",
        icon: "fa fa-user-plus",
        component: AddNewVillageEvent,
        isVisibleInMenu:false
    },
    {
        path: "/changePassword",
        name: "Change Password",
        icon: "fa fa-user-plus",
        component: ChangePassword,
        isVisibleInMenu:false
    },
    {
        path: "/viewNotifications",
        name: "View Notifications",
        icon: "fa fa-user-plus",
        component: ViewNotifications,
        isVisibleInMenu:false
    }
    
    
];

export const consultantRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "fa fa-tachometer",
        component: Dashboard,
        isVisibleInMenu:false
    },
    {
        path: "/users",
        name: "Manage Users",
        icon: "fa fa-user",
        component: ManageUsers,
        isVisibleInMenu:true
    },
    {
        path: "/settings",
        name: "Manage Settings",
        icon: "fa fa-desktop",
        component: ManageSettings,
        isVisibleInMenu:true
    },
    {
        path: "/news",
        name: "Manage News",
        icon: "fa fa-newspaper-o",
        component: NewsList,
        isVisibleInMenu:true
    },
    {
        path:'/viewpreschoolsubmission',
        name: "View Pre School Submission",
        component: ViewPreSchoolSubmission,
        isVisibleInMenu:true,
    },
    {
        path:'/viewmothersupportsubmission',
        name: "View Mother Support Submission",
        component: ViewMotherSupportSubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/viewmothersupportevents",
        name: "Mother Support Events",
        icon: "fa fa-user-plus",
        component: ViewMotherSupportEvents,
        isVisibleInMenu:false
    },
    {
        path: "/viewmothersupportevent",
        name: "Mother Support Event",
        icon: "fa fa-user-plus",
        component: AddNewMotherSupportEvent,
        isVisibleInMenu:false
    },
    {
        path: "/userProfile",
        name: "User Profile",
        icon: "fa fa-user",
        component: UserProfile,
        isVisibleInMenu:true
    },
    {
        path:'/viewworkplacesubmission',
        name: "View Work Place Submission",
        component: Viewworkplacesubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/addworkplacesubmission",
        name: "Add Work Place Submission",
        icon: "fa fa-user-plus",
        component: Addworkplacesubmission,
        isVisibleInMenu:false
    },
    {
        path: "/viewworkplaceevents",
        name: "Work Place Events",
        icon: "fa fa-user-plus",
        component: ViewWorkPlaceEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addworkplaceevent",
        name: "Add Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewWorkPlaceEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewworkplaceevent",
        name: "View Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewWorkPlaceEvent,
        isVisibleInMenu:false
    },
    {
        path: "/addhospitalsubmission",
        name: "Add Hospital",
        icon: "fa fa-user-plus",
        component: AddNewHospitalSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewhospitalsubmission',
        name: "View Hospital Submission",
        component: ViewHospitalSubmission,
        isVisibleInMenu:false,
    },
    {
        path: "/viewhospitalevents",
        name: "Hospital Events",
        icon: "fa fa-user-plus",
        component: ViewHospitalEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addhospitalevent",
        name: "Add Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewHospitalEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewhospitalevent",
        name: "View Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewHospitalEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewpreschoolevents",
        name: "Pre School Events",
        icon: "fa fa-user-plus",
        component: ViewPreschoolEvents,
        isVisibleInMenu:false
    },
    {
        path: "/viewpreschoolevent",
        name: "View Pre School Event",
        icon: "fa fa-user-plus",
        component: AddNewPreschoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/editmothersupportsubmission",
        name: "Edit Mother Support",
        icon: "fa fa-user-plus",
        component: AddNewMotherSupportSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editpreschoolsubmission",
        name: "Edit Pre School",
        icon: "fa fa-user-plus",
        component: AddNewPreSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editworkplacesubmission",
        name: "Edit Work Place",
        icon: "fa fa-user-plus",
        component: Addworkplacesubmission,
        isVisibleInMenu:false
    },
    {
        path: "/edithospitalsubmission",
        name: "Edit Hospital",
        icon: "fa fa-user-plus",
        component: AddNewHospitalSetting,
        isVisibleInMenu:false
    },
    {
        path: "/addnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: AddNews,
        isVisibleInMenu:false
    },
    {
        path: "/editnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: AddNews,
        isVisibleInMenu:false
    },
    {
        path: "/viewnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: ViewNews,
        isVisibleInMenu:false
    },
    {
        path: "/addschoolsubmission",
        name: "Add School",
        icon: "fa fa-user-plus",
        component: AddNewSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editSchoolsubmission",
        name: "Edit School",
        icon: "fa fa-user-plus",
        component: AddNewSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewschoolsubmission',
        name: "View School Submission",
        component: ViewSchoolSubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/viewschoolevents",
        name: " School Events",
        icon: "fa fa-user-plus",
        component: ViewSchoolEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addschoolevent",
        name: "Add School Event",
        icon: "fa fa-user-plus",
        component: AddNewSchoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewschoolevent",
        name: "View School Event",
        icon: "fa fa-user-plus",
        component: AddNewSchoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/addvillagesubmission",
        name: "Add Village",
        icon: "fa fa-user-plus",
        component: AddNewVillageSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editvillagesubmission",
        name: "Edit Village",
        icon: "fa fa-user-plus",
        component: AddNewVillageSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewvillagesubmission',
        name: "View Village Submission",
        component: ViewVillageSubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/viewvillageevents",
        name: " Village Events",
        icon: "fa fa-user-plus",
        component: ViewVillageEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addvillageevent",
        name: "Add Village Event",
        icon: "fa fa-user-plus",
        component: AddNewVillageEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewvillageevent",
        name: "View Village Event",
        icon: "fa fa-user-plus",
        component: AddNewVillageEvent,
        isVisibleInMenu:false
    },
    {
        path: "/userProfile",
        name: "User Profile",
        icon: "fa fa-user",
        component: UserProfile,
        isVisibleInMenu:true
    },
    {
        path: "/changePassword",
        name: "Change Password",
        icon: "fa fa-user-plus",
        component: ChangePassword,
        isVisibleInMenu:false
    },
    {
        path: "/viewNotifications",
        name: "View Notifications",
        icon: "fa fa-user-plus",
        component: ViewNotifications,
        isVisibleInMenu:false
    },
    {
        path: "/forget-password",
        name: "Forget Password",
        icon: "fa fa-user-plus",
        component: ForgetPassword,
        isVisibleInMenu:false
    }
    
];

export const hgoRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "fa fa-tachometer",
        component: Dashboard,
        isVisibleInMenu:false
    },
    {
        path: "/users",
        name: "Manage Users",
        icon: "fa fa-user",
        component: ManageUsers,
        isVisibleInMenu:true
    },
    {
        path: "/settings",
        name: "Manage Settings",
        icon: "fa fa-desktop",
        component: ManageSettings,
        isVisibleInMenu:true
    },
    {
        path: "/news",
        name: "Manage News",
        icon: "fa fa-newspaper-o",
        component: NewsList,
        isVisibleInMenu:true
    },
    {
        path:'/viewpreschoolsubmission',
        name: "View Pre School Submission",
        component: ViewPreSchoolSubmission,
        isVisibleInMenu:true,
    },
    {
        path:'/viewmothersupportsubmission',
        name: "View Mother Support Submission",
        component: ViewMotherSupportSubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/viewmothersupportevents",
        name: "Mother Support Events",
        icon: "fa fa-user-plus",
        component: ViewMotherSupportEvents,
        isVisibleInMenu:false
    },
    {
        path: "/viewmothersupportevent",
        name: "Mother Support Event",
        icon: "fa fa-user-plus",
        component: AddNewMotherSupportEvent,
        isVisibleInMenu:false
    },
    {
        path: "/userProfile",
        name: "User Profile",
        icon: "fa fa-user",
        component: UserProfile,
        isVisibleInMenu:true
    },
    {
        path:'/viewworkplacesubmission',
        name: "View Work Place Submission",
        component: Viewworkplacesubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/addworkplacesubmission",
        name: "Add Work Place Submission",
        icon: "fa fa-user-plus",
        component: Addworkplacesubmission,
        isVisibleInMenu:false
    },
    {
        path: "/viewworkplaceevents",
        name: "Work Place Events",
        icon: "fa fa-user-plus",
        component: ViewWorkPlaceEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addworkplaceevent",
        name: "Add Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewWorkPlaceEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewworkplaceevent",
        name: "View Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewWorkPlaceEvent,
        isVisibleInMenu:false
    },
    {
        path: "/addhospitalsubmission",
        name: "Add Hospital",
        icon: "fa fa-user-plus",
        component: AddNewHospitalSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewhospitalsubmission',
        name: "View Hospital Submission",
        component: ViewHospitalSubmission,
        isVisibleInMenu:false,
    },
    {
        path: "/viewhospitalevents",
        name: "Hospital Events",
        icon: "fa fa-user-plus",
        component: ViewHospitalEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addhospitalevent",
        name: "Add Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewHospitalEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewhospitalevent",
        name: "View Work Place Event",
        icon: "fa fa-user-plus",
        component: AddNewHospitalEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewpreschoolevents",
        name: "Pre School Events",
        icon: "fa fa-user-plus",
        component: ViewPreschoolEvents,
        isVisibleInMenu:false
    },
    {
        path: "/viewpreschoolevent",
        name: "View Pre School Event",
        icon: "fa fa-user-plus",
        component: AddNewPreschoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/editmothersupportsubmission",
        name: "Edit Mother Support",
        icon: "fa fa-user-plus",
        component: AddNewMotherSupportSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editpreschoolsubmission",
        name: "Edit Pre School",
        icon: "fa fa-user-plus",
        component: AddNewPreSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editworkplacesubmission",
        name: "Edit Work Place",
        icon: "fa fa-user-plus",
        component: Addworkplacesubmission,
        isVisibleInMenu:false
    },
    {
        path: "/edithospitalsubmission",
        name: "Edit Hospital",
        icon: "fa fa-user-plus",
        component: AddNewHospitalSetting,
        isVisibleInMenu:false
    },
    {
        path: "/addnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: AddNews,
        isVisibleInMenu:false
    },
    {
        path: "/editnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: AddNews,
        isVisibleInMenu:false
    },
    {
        path: "/viewnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: ViewNews,
        isVisibleInMenu:false
    },
    {
        path: "/addschoolsubmission",
        name: "Add School",
        icon: "fa fa-user-plus",
        component: AddNewSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editSchoolsubmission",
        name: "Edit School",
        icon: "fa fa-user-plus",
        component: AddNewSchoolSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewschoolsubmission',
        name: "View School Submission",
        component: ViewSchoolSubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/viewschoolevents",
        name: " School Events",
        icon: "fa fa-user-plus",
        component: ViewSchoolEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addschoolevent",
        name: "Add School Event",
        icon: "fa fa-user-plus",
        component: AddNewSchoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewschoolevent",
        name: "View School Event",
        icon: "fa fa-user-plus",
        component: AddNewSchoolEvent,
        isVisibleInMenu:false
    },
    {
        path: "/addvillagesubmission",
        name: "Add Village",
        icon: "fa fa-user-plus",
        component: AddNewVillageSetting,
        isVisibleInMenu:false
    },
    {
        path: "/editvillagesubmission",
        name: "Edit Village",
        icon: "fa fa-user-plus",
        component: AddNewVillageSetting,
        isVisibleInMenu:false
    },
    {
        path:'/viewvillagesubmission',
        name: "View Village Submission",
        component: ViewVillageSubmission,
        isVisibleInMenu:true,
    },
    {
        path: "/viewvillageevents",
        name: " Village Events",
        icon: "fa fa-user-plus",
        component: ViewVillageEvents,
        isVisibleInMenu:false
    },
    {
        path: "/addvillageevent",
        name: "Add Village Event",
        icon: "fa fa-user-plus",
        component: AddNewVillageEvent,
        isVisibleInMenu:false
    },
    {
        path: "/viewvillageevent",
        name: "View Village Event",
        icon: "fa fa-user-plus",
        component: AddNewVillageEvent,
        isVisibleInMenu:false
    },
    {
        path: "/userProfile",
        name: "User Profile",
        icon: "fa fa-user",
        component: UserProfile,
        isVisibleInMenu:true
    },
    {
        path: "/changePassword",
        name: "Change Password",
        icon: "fa fa-user-plus",
        component: ChangePassword,
        isVisibleInMenu:false
    },
    {
        path: "/viewNotifications",
        name: "View Notifications",
        icon: "fa fa-user-plus",
        component: ViewNotifications,
        isVisibleInMenu:false
    },
    {
        path: "/forget-password",
        name: "Forget Password",
        icon: "fa fa-user-plus",
        component: ForgetPassword,
        isVisibleInMenu:false
    }
    
];

