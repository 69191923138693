import React, { Component } from 'react'
import { EDIT_BASIC_PROFILE, GET_All_USERS, SEARCH_HIGHLEVEL_USERS } from '../../queries/UserQueries'

import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions'
import ScrollContainer from 'react-indiana-drag-scroll';

import Loader from 'react-loader-spinner'

//ui
import { Button, Icon, Grid,Dropdown } from 'semantic-ui-react';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}


class HighLevelUsers extends Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        }
        this.state = {
            loading: false,
            pagination: {
                skip: 0,
                first: 2000
            },
            users: [],
            items: [],
            userTypes: [
                { key: "EM", text: "Select-UserType", value: "EM", id: 'userType', disabled: true },
                { key: "ALL", text: "ALL", value: "ALL", id: 'userType' },
                { key: "DIRECTOR", text: "DIRECTOR", value: "DIRECTOR", id: 'userType' },
                { key: "CONSULTANT", text: "CONSULTANT", value: "CONSULTANT", id: 'userType' },
                { key: "HEO", text: "HEO", value: "HEO", id: 'userType' },
                { key: "RDHS", text: "RDHS", value: "RDHS", id: 'userType' },
                { key: "CCP", text: "CCP", value: "CCP", id: 'userType' },
            ],
            userType: "",
            searchTerm: "",
            searchLoading: false,
        }

    }

    componentDidMount() {
        console.log(this.state.firstName)
        this.getAllUsers(this.state.pagination);
    }

    getAllUsers(pagination) {
        this.setState({ loading: true });
        this.get_all_users(pagination).then(result => {
            console.log(result.users)
            this.setState({ users: result.users, count: result.count, loading: false })
        });
    }

    get_all_users = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_All_USERS,
            variables: pagination,
            fetchPolicy: 'network-only'
        });
        console.log(result);
        return result.data.getAllUsers;
    };


    changeUserStatus(id, status) {
        var data = {};
        if (status) {
            data = { userId: id, isActive: false };
        } else {
            data = { userId: id, isActive: true };
        }

        this.setState({ loading: true });

        this.saveUserStatus(data).then(result => {
            this.setState({ loading: false });
            this.getAllUsers(this.state.pagination);
        }).catch(error => {
            console.log(error);
        });
    }

    saveUserStatus = async (data) => {
        const result = await this.props.client.mutate({
            mutation: EDIT_BASIC_PROFILE,
            variables: data
        });
        return result.data.editBasicProfile
    }

    newHighLevelUser() {
        this.props.history.push("/addnewhighleveluser");
    }

    goBack = () => {
        this.props.history.goBack();
    };

    handleUserTypeChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({
            [data.id]: key, users: [],
        });
        var pagination = {};
        if (key == "ALL") {
            pagination = {
                skip: 0,
                first: 2000,
            };
        } else {
            pagination = {
                skip: 0,
                first: 2000,
                userType: key
            };


        }
        this.getAllUsers(pagination);


    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    search() {
        var searchTerm = this.state.searchTerm;
        if (searchTerm == "" || searchTerm == null) {
            return false;
        } else {
            this.setState({ searchLoading: true });
            this.findUsers(searchTerm).then(result => {

                if (result.count > 0) {
                    console.log(result)

                    this.setState({
                        users: result.users,
                        loading: false,
                        searchLoading: false
                    })
                } else {
                    this.setState({
                        users: [],
                        loading: false,
                        searchLoading: false
                    })
                }


            })
        }
    }

    findUsers = async (searchTerm) => {
        const result = await this.props.client.query({
            query: SEARCH_HIGHLEVEL_USERS,
            variables: { searchTerm },
            fetchPolicy: 'network-only'
        });
        return result.data.searchHighLevelUsers;
    }

    render() {
        const { users, loading } = this.state;
        console.log(users)
        return (
            <div>
                <div className="right_col" role="main">
                    <div className="">
                        <div >
                            <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <br />

                    <div className="row">
                        <div className="col-md-12 col-sm-12  ">
                            <Button style={{ float: "right" }} type="button" className="btn btn-success" onClick={() => this.newHighLevelUser()}>  Add New High Level User </Button> <br />
                            <br /><br />
                            <div className="x_panel">
                                <div className="">
                                    <h2>High Level User List </h2>

                                    <div className="clearfix"></div>
                                </div>
                                <div>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={4}>

                                                <Dropdown
                                                    placeholder='Filter by UserType'
                                                    fluid
                                                    selection
                                                    id='userType'
                                                    name='userType'
                                                    options={this.state.userTypes}
                                                    value={this.state.userType}
                                                    onChange={this.handleUserTypeChange}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                                <div class="form-group row ">
                                                    <input style={{ float: "right" }} type="text" id="searchTerm" name="searchTerm" value={this.state.searchTerm} placeholder="Search" onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <button style={{ float: "left" }} onClick={() => this.search()} type="button" className="btn btn-info" disabled={this.state.searchLoading ? (true) : (false)}>
                                                    {this.state.searchLoading ? (
                                                        <Loader
                                                            type="Oval"
                                                            color="#2A3F54"
                                                            height={15}
                                                            width={40}
                                                        />
                                                    ) : ("Search")}
                                                </button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>

                                <div className="x_content">

                                    {loading ? (
                                        <div className="ListLoader">
                                            <Loader
                                                type="ThreeDots"
                                                color="#5A738E"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                    ) : (
                                        <ScrollContainer>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        {/* <th> # </th> */}
                                                        <th> Name </th>
                                                        <th> Telephone </th>
                                                        <th> Province </th>
                                                        <th> District </th>
                                                        <th> GN Division </th>
                                                        <th> Gender </th>
                                                        <th> User Type </th>
                                                        <th> Setting Type </th>
                                                        <th>  </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        users.map((user, index) => (
                                                            user.userType == "USER" ? (null) : (
                                                                <tr key={index}>
                                                                    {/* <th scope="row"> {index + 1}</th> */}
                                                                    <td> {user.name} </td>
                                                                    <td> {user.tel} </td>
                                                                    <td> {user.province} </td>
                                                                    <td> {user.district} </td>
                                                                    <td>  {user.district} </td>
                                                                    <td> {user.gender == "MALE" ? (
                                                                        "Male"
                                                                    ) : (
                                                                        user.gender == "FEMALE" ? (
                                                                            "Female"
                                                                        ) : (
                                                                            null
                                                                        )
                                                                    )} </td>
                                                                    <td> {user.userType} </td>
                                                                    <td>
                                                                        {user.settingType}
                                                                    </td>
                                                                    <td>
                                                                        {user.isActive ? (
                                                                            <button type="button" className="btn btn-danger" onClick={() => this.changeUserStatus(user.id, user.isActive)}>
                                                                                Deactivate </button>
                                                                        ) : (
                                                                            <button type="button" className="btn btn-success" onClick={() => this.changeUserStatus(user.id, user.isActive)}>  Activate </button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )

                                                        ))
                                                    }

                                                </tbody>
                                                {/* {user.isActive ? (
                                                <button disabled={loading ? (true) : (false)} className="uk-button uk-button-danger admin-table-btn" onClick={() => this.changeUserStatus(user.id, user.isActive)}>
                                                    {loading ? (
                                                        <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                                    ) : ("Deactivate")}
                                                </button>
                                            ) : (
                                                    <button disabled={loading ? (true) : (false)} className="uk-button uk-button-success admin-table-btn" onClick={() => this.changeUserStatus(user.id, user.isActive)}>
                                                        {loading ? (
                                                            <span data-uk-spinner="" style={{ color: "#o6a7e3" }} />
                                                        ) : ("Activate")}
                                                    </button>
                                                )} */}
                                            </table>
                                        </ScrollContainer>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(HighLevelUsers)));

