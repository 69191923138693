import gql from 'graphql-tag';

export const GET_ALL_PROVINCES = gql`
 query {
    getAllProvinces{
      id
      province
      machineName
  }
 }
`;

export const GET_ALL_DISTRICTS = gql`
 query {
    getAllDistricts{
        id
        district
        machineName
        provinceId
  }
 }
`;


export const GET_DISTRICTS_FOR_PROVINCES = gql`
query GetDistrictsForProvince( $provinceId: String!){
    getDistrictsForProvince( provinceId:$provinceId){
        id
        district
        machineName
        provinceId
    }
}
`;

export const GET_DIVISIONS_FOR_DISTRICT = gql`
query GetDivisionsForMOHArea( $districtId: String!){
    getDivisionsForMOHArea( districtId:$districtId){
        id
        gndivision
        machineName
        provinceId
        districtId
    }
}
`;

export const GET_DIVISIONS_FOR_MOHAREA = gql`
query GetDivisionsForMOHArea( $mohId: String!){
    getDivisionsForMOHArea( mohId:$mohId){
        id
        gndivision
        machineName
        provinceId
        districtId
        mohId
    }
}
`;

export const GET_PROVINCE_BY_MACHINENAME = gql`
query GetProvinceByMachinename( $machineName: String){
    getProvinceByMachinename( machineName:$machineName){
        id
        province
        machineName
    }
}
`;

export const GET_DISTRICT_BY_MACHINENAME = gql`
query GetDistrictByMachinename( $machineName: String){
    getDistrictByMachinename( machineName:$machineName){
        id
        district
        machineName
        provinceId
    }
}
`;

export const GET_AREA_DETAILS = gql`
query GetAreaDetails( $lat: String!,$lon: String!){
    getAreaDetails( lat:$lat,lon:$lon){
        district{
            districtId
            district
            machineName
        }
        province{
            provinceId
            province
            machineName
        }
        divisions{
            id
            gndivision
            machineName
            provinceId
            districtId
        }
        mohareas{
            id
            mohArea
            machineName
            provinceId
            districtId
        }
    }
}
`;

export const GET_ALL_MOHAREAS = gql`
 query {
    getAllMOHAreas{
        id
        mohArea
        machineName
        provinceId
        districtId
    }
 }
`;

export const GET_MOHAREAS_FOR_DISTRICT = gql`
query GetMOHAreasForDistrict( $districtId: String!){
    getMOHAreasForDistrict( districtId:$districtId){
        id
        mohArea
        machineName
        provinceId
        districtId
    }
}
`;

export const EDIT_PASSWORD = gql`
mutation EditPassword( $currentPassword: String!,$newPassword: String!){
    editPassword( currentPassword:$currentPassword,newPassword:$newPassword)
}
`;


export const RESET_PASSWORD_REQUEST = gql`
mutation ResetPasswordRequest( $nic: String!){
    resetPasswordRequest( nic:$nic){
        status
        reason
        userId
        userEmail
        userName
        passcode
    }
}
`;





