import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

//query
import { LOGIN } from '../../../queries/UserQueries';

//ui
import { Button, Grid, Dropdown, TextArea, Input, Message, Icon } from 'semantic-ui-react';

//Google map
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

//Spinner
import Loader from 'react-loader-spinner'

//firebase
import { uploadFile, deleteFile } from "../../../firebase/FileServices";

//queries
import {
    GET_ALL_PROVINCES, GET_DISTRICTS_FOR_PROVINCES, GET_DIVISIONS_FOR_MOHAREA, GET_ALL_DISTRICTS, GET_AREA_DETAILS, GET_MOHAREAS_FOR_DISTRICT
} from '../../../queries/CommonQueries'
import {
    GET_ALL_SETTINGS, ADD_SCHOOL_SETTING_SUBMISSION, GET_SINGLE_SCHOOL_SETTING_SUBMISSION, EDIT_SCHOOL_SETTING_SUBMISSION
} from '../../../queries/SettingQueries'


//DatePicker
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

//middleware
import { formatDate } from '../../../middleware/index';

//location
import { geolocated } from "react-geolocated";

import ImageZoom from 'react-medium-image-zoom'

const apiKey = "AIzaSyAa4iktQ8XNg1TJDk_CPptIgzZWmBQm7bM"

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class AddNewSchoolSetting extends Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        var currentLocation = window.location.href;
        var url = currentLocation.split("?")
        url = url[0];
        url = url.split("/");
        var page = url[url.length - 1];

        var pageFormat = "";
        var settingSubmissionId = "";

        if (page == "editschoolsubmission") {
            pageFormat = "EDIT"
            settingSubmissionId = id
        } else {
            pageFormat = "ADD"
        }

        this.state = {
            marker:
            {
                title: "The marker`s title will appear as a tooltip.",
                name: "COLOMBO",
                position: { lat: 6.93194, lng: 79.84778 }
            },
            location: {},
            loading: false,
            form_state: 'default',
            title: "",
            message: "",
            province: "",
            district: "",
            gndivision: "",
            mohArea: "",
            provinceId: "",
            districtId: "",
            gndivisionId: "",
            mohAreaId: "",
            phmAreaId: "",
            provinceMachineName: "",
            districtMachineName: "",
            gndivisionMachineName: "",
            provinces: [],
            districts: [],
            divisions: [],
            mohareas: [],
            phmArea: "",

            nameOfSchool: "",
            nameOfFacilitatingPHM: "",
            nameOfFacilitatingPHI: "",
            schoolPrincipalName: "",
            schoolPrincipalTel: "",
            dateOfEstablishmentOfSchool: "",
            numOfStudents: "",
            meetingFrequency: "ONCE_A_MONTH",
            numOfMeetingsInyear: 0,
            numOfProgramsHealthAuthority: 0,
            conductedActivities: [],
            supervisionVisit: "PHM",
            comments: "",

            supervisionVisits: [
                { key: 'PHM', text: 'PHM', value: 'PHM' },
                { key: 'PHI', text: 'PHI', value: 'PHI' },
                { key: 'HEO', text: 'HEO', value: 'HEO' },
                { key: 'Other', text: 'Other', value: 'Other' }
            ],
            status: 'PENDING',
            settingId: "",
            allowClick: true,
            imageUrl: null,
            tempFile: null,
            isUploading: false,
            fileRef: null,
            uploads: [],
            images: [],
            loading: false,
            formData: [],
            readFiles: [],
            initialize: false,
            pageFormat: pageFormat,
            settingSubmissionId: settingSubmissionId,

            conducted_activity_list: [
                { text: 'Nutrition promotion', value: 'Nutrition promotion' },
                { text: 'Budget management and promote local income generation', value: 'Budget management and promote local income generation' },
                { text: 'Happy families living in alcohol and violence free environments', value: 'Happy families living in alcohol and violence free environments' },
                { text: 'Improve early childcare &amp; development practices', value: 'Improve early childcare &amp; development practices' },
                { text: 'Environmental health', value: 'Environmental health' },
                { text: 'Exercise and sports programs', value: 'Exercise and sports programs' },
                { text: 'Health screening for NCD', value: 'Health screening for NCD' },
                { text: 'Preventing school dropping out', value: 'Preventing school dropping out' },
                { text: 'Promoting the development of healthy settings', value: 'Promoting the development of healthy settings' },
                { text: 'Developing safe and hygienic kitchens using the 5S concept', value: 'Developing safe and hygienic kitchens using the 5S concept' },
                { text: 'Promoting good parenting skills', value: 'Promoting good parenting skills' },
            ],
            meetingFrequencies: [
                { key: "ONCE_A_MONTH", text: "Once a month", value: "ONCE_A_MONTH", id: 'meetingFrequency' },
                { key: "ONCE_IN_EVERY_TWO_MONTHS", text: "Once in every two months", value: "ONCE_IN_EVERY_TWO_MONTHS", id: 'meetingFrequency' },
                { key: "ONCE_IN_EVERY_THREE_MONTHS", text: "Once in every three months", value: "ONCE_IN_EVERY_THREE_MONTHS", id: 'meetingFrequency' },
                { key: "OTHER", text: "Other", value: "OTHER", id: 'meetingFrequency' }
            ]

        };

        props.setFormStatus({ status: false, title: '', message: '' });

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMapClick = this.onMapClick.bind(this);

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });

        if (this.state.pageFormat == "ADD") {
            this.getLocation();
        }

    }

    getLocation() {
        var msg;

        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(success);

            var self = this;

            function success(pos) {
                var lng = pos.coords.longitude;
                var lat = pos.coords.latitude;

                console.log(lng)
                console.log(lat)

                self.setState({
                    marker:
                    {
                        title: "The marker`s title will appear as a tooltip.",
                        name: "COLOMBO",
                        position: { lat: lat, lng: lng }
                    },
                    lat: lat,
                    lon: lng
                });

            }

        } else {
            msg = "Sorry, looks like your browser doesn't support geolocation";
            alert(msg)
        }

    }

    componentDidMount() {



        // read provinces
        this.loadProvinces().then(result => {
            var provinces = result.data.getAllProvinces.map(province => {
                return { key: province.id, text: province.province, value: province.id, id: 'provinceId' }
            });
            provinces.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            provinces.unshift({ key: null, id: "provinceId", text: "Select-Province", value: "Select-Province", disabled: true });

            this.setState({ provinces: provinces });
        })

        this.loadAllDistricts().then(result => {
            var districts = result.data.getAllDistricts.map(district => {
                return { key: district.id, text: district.district, value: district.id, id: 'districtId' }
            });
            districts.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            districts.unshift({ key: null, id: "districtId", text: "Select-District", value: "Select-District", disabled: true });

            this.setState({ districts: districts });
        })

        this.loadSettings().then(result => {
            var settingId = "";
            result.data.getAllSettings.map(setting => {
                if (setting.type == "SCHOOL") {
                    settingId = setting.id;
                }
            });

            this.setState({ settingId: settingId });
        })

        if (this.state.pageFormat == "EDIT") {
            this.getSingleSubmission();
        }
    }

    getSingleSubmission() {
        this.setState({ initialize: true });
        this.getSingleSchoolSettingSubmission().then(result => {
            var images = [];
            if (result.images == undefined || result.images == "" || result.images == null) {
                images = [];
            } else {
                images = result.images;
            }

            if (result.districtId != undefined && result.districtId != "" && result.districtId != null) {
                this.loadMOHAreas(result.districtId).then(result => {
                    console.log(result.data.getDivisionsForMOHArea)
                    var mohareas = result.data.getMOHAreasForDistrict.map(moharea => {
                        return { key: moharea.id, text: moharea.mohArea, value: moharea.id, id: 'mohAreaId' }
                    });
                    mohareas.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
                    mohareas.unshift({ key: null, id: "mohAreaId", text: "Select-MOHArea", value: "Select-MOHArea", disabled: true });
                    this.setState({ mohareas: mohareas });
                })
            }

            if (result.mohAreaId != undefined && result.mohAreaId != "" && result.mohAreaId != null) {
                this.getDivisions(result.mohAreaId);
            }
            this.setState({
                location: result.location,
                province: result.province,
                district: result.district,
                gndivision: result.gndivision,
                mohArea: result.mohArea,
                provinceId: result.provinceId,
                districtId: result.districtId,
                gndivisionId: result.gndivisionId,
                mohAreaId: result.mohAreaId,
                phmAreaId: result.phmAreaId,
                phmArea: result.phmArea,
                lat: result.location.latitude,
                lon: result.location.longitude,

                nameOfSchool: result.nameOfSchool,
                nameOfFacilitatingPHM: result.nameOfFacilitatingPHM,
                nameOfFacilitatingPHI: result.nameOfFacilitatingPHI,
                schoolPrincipalName: result.schoolPrincipalName,
                schoolPrincipalTel: result.schoolPrincipalTel,
                dateOfEstablishmentOfSchool: result.dateOfEstablishmentOfSchool,
                numOfStudents: result.numOfStudents,
                meetingFrequency: result.meetingFrequency,
                numOfMeetingsInyear: result.numOfMeetingsInyear,
                numOfProgramsHealthAuthority: result.numOfProgramsHealthAuthority,
                conductedActivities: result.conductedActivities,
                supervisionVisit: result.supervisionVisit,
                comments: result.comments,

                images: images,
                initialize: false,
                marker:
                {
                    title: "The marker`s title will appear as a tooltip.",
                    name: "COLOMBO",
                    position: { lat: result.location.latitude, lng: result.location.longitude }
                }
            });


        })
    }

    getSingleSchoolSettingSubmission = async () => {
        const { settingSubmissionId } = this.state;

        console.log(settingSubmissionId)
        const result = await this.props.client.query({
            query: GET_SINGLE_SCHOOL_SETTING_SUBMISSION,
            variables: { settingSubmissionId },
            fetchPolicy: 'network-only'
        });
        return result.data.getSingleSchoolSettingSubmission;
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    handleSubmit(event) {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        event.preventDefault();
        const { provinceId, districtId, mohAreaId, lat, lon, pageFormat } = this.state;

        if (provinceId == "") {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Province Required!"
            });
            return;
        } else if (districtId == "") {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "District Required!"
            });
            return;
        } else {

            if (mohAreaId == "") {
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message: "MOH Area Required!"
                });
                return;
            } else {

                this.setState({ loading: true });
                this.props.setFormStatus({ status: false, title: '', message: '' });
                this.props.setSuccessStatus({ status: false, title: '', message: '' });

                if (pageFormat == "ADD") {
                    this.addSchoolSettingSubmission().then(result => {
                        console.log(result);
                        this.setState({
                            location: {},
                            loading: false,
                            form_state: 'default',
                            title: "",
                            message: "",
                            province: "",
                            district: "",
                            gndivision: "",
                            mohArea: "",
                            provinceId: "",
                            districtId: "",
                            gndivisionId: "",
                            mohAreaId: "",
                            phmAreaId: "",
                            lat: "",
                            lon: "",
                            nameOfSchool: "",
                            nameOfFacilitatingPHM: "",
                            nameOfFacilitatingPHI: "",
                            schoolPrincipalName: "",
                            schoolPrincipalTel: "",
                            dateOfEstablishmentOfSchool: "",
                            numOfStudents: "",
                            meetingFrequency: "",
                            numOfMeetingsInyear: 0,
                            numOfProgramsHealthAuthority: 0,
                            conductedActivities: [],
                            supervisionVisit: "",
                            comments: "",
                            status: "PENDING",
                        });

                        this.props.setSuccessStatus({
                            status: true,
                            title: "",
                            message:
                                "Setting Submitted Successfully!. Please wait for till get admin approve."
                        });
                    }).catch(error => {
                        console.log(error);
                        if (error) {
                            this.setState({ loading: false });
                            this.props.setFormStatus({
                                status: true,
                                title: "Oops!",
                                message:
                                    "There was an error while trying to submit setting."

                            });
                        }
                    });
                } else if (pageFormat == "EDIT") {
                    this.editSchoolSettingSubmission().then(result => {
                        console.log(result);
                        this.setState({ loading: false });
                        this.getSingleSubmission();

                        this.props.setSuccessStatus({
                            status: true,
                            title: "",
                            message:
                                "Setting edited Successfully!.."
                        });

                    }).catch(error => {
                        console.log(error);
                        if (error) {
                            this.setState({ loading: false });
                            this.props.setFormStatus({
                                status: true,
                                title: "Oops!",
                                message:
                                    "There was an error while trying to edit setting."

                            });
                        }
                    });
                }

            }

        }

    }

    addSchoolSettingSubmission = async () => {
        const {
            images,
            provinceId,
            province,
            districtId,
            district,
            mohArea,
            mohAreaId,
            phmArea,
            gndivision,
            gndivisionId,
            nameOfSchool,
            nameOfFacilitatingPHM,
            nameOfFacilitatingPHI,
            schoolPrincipalName,
            schoolPrincipalTel,
            dateOfEstablishmentOfSchool,
            meetingFrequency,
            conductedActivities,
            supervisionVisit,
            comments,
            status,
            settingId,
        } = this.state;

        var lat = "";
        var lon = "";

        if (this.state.lat == "" || this.state.lon == "" || this.state.lat == undefined || this.state.lon == undefined) {
            lat = "";
            lon = "";
        } else {
            lat = this.state.lat.toString();
            lon = this.state.lon.toString();
        }

        var numOfStudents = parseInt(this.state.numOfStudents);
        var numOfMeetingsInyear = parseInt(this.state.numOfMeetingsInyear);
        var numOfProgramsHealthAuthority = parseInt(this.state.numOfProgramsHealthAuthority);

        const result = await this.props.client.mutate({
            mutation: ADD_SCHOOL_SETTING_SUBMISSION,
            variables: {
                images, provinceId, province, districtId, district, mohArea, mohAreaId, phmArea, gndivision, gndivisionId, lat, lon,
                nameOfSchool,
                nameOfFacilitatingPHM,
                nameOfFacilitatingPHI,
                schoolPrincipalName,
                schoolPrincipalTel,
                dateOfEstablishmentOfSchool,
                numOfStudents,
                meetingFrequency,
                numOfMeetingsInyear,
                numOfProgramsHealthAuthority,
                supervisionVisit,
                conductedActivities, comments, status, settingId
            }
        });
        return result.data.addSchoolSettingSubmission;
    };


    editSchoolSettingSubmission = async () => {
        const {
            settingSubmissionId,
            images,
            provinceId,
            province,
            districtId,
            district,
            mohArea,
            mohAreaId,
            phmArea,
            gndivision,
            gndivisionId,
            nameOfSchool,
            nameOfFacilitatingPHM,
            nameOfFacilitatingPHI,
            schoolPrincipalName,
            schoolPrincipalTel,
            dateOfEstablishmentOfSchool,
            meetingFrequency,
            supervisionVisit,
            conductedActivities,
            comments,
            status,
            settingId,
        } = this.state;

        var lat = "";
        var lon = "";

        if (this.state.lat == "" || this.state.lon == "" || this.state.lat == undefined || this.state.lon == undefined) {
            lat = "";
            lon = "";
        } else {
            lat = this.state.lat.toString();
            lon = this.state.lon.toString();
        }

        console.log(dateOfEstablishmentOfSchool)
        console.log(images)


        var numOfStudents = parseInt(this.state.numOfStudents);
        var numOfMeetingsInyear = parseInt(this.state.numOfMeetingsInyear);
        var numOfProgramsHealthAuthority = parseInt(this.state.numOfProgramsHealthAuthority);


        const result = await this.props.client.mutate({
            mutation: EDIT_SCHOOL_SETTING_SUBMISSION,
            variables: {
                settingSubmissionId, images, provinceId, province, districtId, district, mohArea, mohAreaId, phmArea, gndivision, gndivisionId, lat, lon,
                nameOfSchool,
                nameOfFacilitatingPHM,
                nameOfFacilitatingPHI,
                schoolPrincipalName,
                schoolPrincipalTel,
                dateOfEstablishmentOfSchool,
                numOfStudents,
                meetingFrequency,
                numOfMeetingsInyear,
                numOfProgramsHealthAuthority,
                supervisionVisit,
                conductedActivities, comments, status, settingId
            }
        });
        return result.data.editSchoolSettingSubmission;
    };

    // load provinces from api
    loadProvinces = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_PROVINCES,
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load all districts from api
    loadAllDistricts = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_DISTRICTS,
            fetchPolicy: "network-only"
        });
        return result;
    };


    // load districts from api
    loadDistricts = async (provinceId) => {
        const result = await this.props.client.query({
            query: GET_DISTRICTS_FOR_PROVINCES,
            variables: { provinceId },
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load divisions from api
    loadDivisions = async (mohId) => {
        const result = await this.props.client.query({
            query: GET_DIVISIONS_FOR_MOHAREA,
            variables: { mohId },
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load mohareas from api
    loadMOHAreas = async (districtId) => {
        const result = await this.props.client.query({
            query: GET_MOHAREAS_FOR_DISTRICT,
            variables: { districtId },
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load settings from api
    loadSettings = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_SETTINGS,
            fetchPolicy: "network-only"
        });
        return result;
    };

    handleProvinceChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "province": text });

        this.loadDistricts(key).then(result => {
            var districts = result.data.getDistrictsForProvince.map(district => {
                return { key: district.id, text: district.district, value: district.id, id: 'districtId' }
            });
            districts.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            districts.unshift({ key: null, id: "districtId", text: "Select-District", value: "Select-District", disabled: true });
            this.setState({ districts: districts });
        })
    }

    handleDistrictChange = (e, data) => {

        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "district": text });

        this.loadMOHAreas(key).then(result => {
            console.log(result.data.getDivisionsForMOHArea)
            var mohareas = result.data.getMOHAreasForDistrict.map(moharea => {
                return { key: moharea.id, text: moharea.mohArea, value: moharea.id, id: 'mohAreaId' }
            });
            mohareas.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            mohareas.unshift({ key: null, id: "mohAreaId", text: "Select-MOHArea", value: "Select-MOHArea", disabled: true });
            this.setState({ mohareas: mohareas });
        })

    }

    handleMOHChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "mohArea": text });
        this.getDivisions(key);
    }

    getDivisions(key) {
        this.loadDivisions(key).then(result => {
            var divisions = result.data.getDivisionsForMOHArea.map(division => {
                return { key: division.id, text: division.gndivision, value: division.id, id: 'gndivisionId' }
            });
            divisions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            divisions.unshift({ key: null, id: "gndivisionId", text: "Select-Division", value: "Select-Division", disabled: true });
            this.setState({ divisions: divisions });
        })
    }

    handleDivisionChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        this.setState({ [data.id]: key, "gndivision": text });

    }

    handleMeetingChange = (e, data) => {
        this.setState({ [data.id]: data.value });
    }

    handleOnChange = (e, data) => {
        this.setState({ [data.id]: data.value });
    }

    handleTimeChange = (event, { name, value }) => {
        this.setState({ dateOfEstablishmentOfSchool: value });
    }

    handleActivityChange = (e, data) => {
        this.setState({ [data.id]: data.value });
    }

    onMapClick(t, map, coord) {
        const { latLng } = coord;
        const lat = latLng.lat().toString();
        const lng = latLng.lng().toString();
        const location = { latitude: lat, longitude: lng };
        this.setState({ lat: lat, lon: lng, allowClick: false });

        this.setState({ marker: { title: "", name: "", position: { lat, lng } } });

        this.getAreaDetails(lat, lng).then(result => {
            var mohareas = result.mohareas.map(moharea => {
                return { key: moharea.id, text: moharea.mohArea, value: moharea.id, id: 'mohAreaId' }
            });
            mohareas.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            mohareas.unshift({ key: null, id: "mohAreaId", text: "Select-MOHArea", value: "Select-MOHArea", disabled: true });

            var divisions = result.divisions.map(division => {
                return { key: division.id, text: division.gndivision, value: division.id, id: 'gndivisionId' }
            });
            divisions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            divisions.unshift({ key: null, id: "gndivisionId", text: "Select-Division", value: "Select-Division", disabled: true });

            this.setState({
                provinceId: result.province.provinceId,
                province: result.province.province,
                districtId: result.district.districtId,
                district: result.district.district,
                divisions: divisions,
                mohareas: mohareas,
                allowClick: true
            });
        }).catch(error => {
            if (error) {
                console.log(error);
                console.log('There is an error');
                // this.props.setFormStatus({ status: true, title: 'Oops!', message: 'Error in ' });
            }
            this.setState({ loading: false, allowClick: true });

        });


    }

    getAreaDetails = async (lat, lng) => {
        const lon = lng;

        const result = await this.props.client.query({
            query: GET_AREA_DETAILS,
            variables: { lat, lon },
            fetchPolicy: "network-only"
        });
        return result.data.getAreaDetails;
    };

    handleFileAddChange = event => {
        var formData = this.state.formData;
        var readFiles = this.state.readFiles;

        formData.push(event.target.files[0]);
        readFiles.push(URL.createObjectURL(event.target.files[0]));

        this.setState({
            formData: formData,
            readFiles: readFiles
        });

    }

    handleFileRemoveChange = (i) => {
        var formData = this.state.formData;
        var readFiles = this.state.readFiles;

        formData.splice(i, 1);
        readFiles.splice(i, 1);

        this.setState({
            formData: formData,
            readFiles: readFiles
        });

    }

    fileUploadOnClick = event => {
        this.setState({
            isUploading: true,
        });

        if (this.state.formData.length > 0) {
            this.state.formData.map((file, key) => {
                this._startUpload(file);
            })
        }

    };

    _startUpload = async file => {
        var intDate = Number(new Date());
        var images = this.state.images;

        const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
        if (result.status) {
            images.push(result.url);
            this.setState({
                isUploading: false,
                fileRef: 'uploads/' + localStorage.FIRST_NAME + intDate + '/' + file.name,
                images: images,
                readFiles: []
            });
        }
    };

    _deleteFile = async (path, i) => {
        var images = this.state.images;
        const deleteRef = await deleteFile(path);
        images.splice(i, 1);
        this.setState({
            images: images
        });
        return deleteRef;
    }

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        const { loading, marker, provinces, districts, divisions, mohareas } = this.state;
        const { formErrorStatus, formSuccessState } = this.props;

        console.log(this.state.settingId);

        return (
            <div className="right_col" role="main">
                <div >
                    <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                </div>
                <div class="page-title">
                    <div class="title_left">
                        <h3> Add Setting </h3>
                    </div>
                </div>
                <div class="clearfix"></div>

                <div className="row">
                    <div className="col-md-12 ">
                        <div className="x_panel">
                            <div className="x_title">
                                <h2> School Submission </h2>

                                <div className="clearfix" />
                            </div>
                            <div className="x_content">
                                <br />

                                {this.state.initialize ? (
                                    null
                                ) : (

                                        <div className="row">
                                            <div className="col-md-6 ">
                                                <form className="form-horizontal form-label-left">

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 ">Select Province </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <Dropdown
                                                                placeholder='Select Province'
                                                                fluid
                                                                selection
                                                                id='provinceId'
                                                                name='provinceId'
                                                                options={provinces}
                                                                value={this.state.provinceId}
                                                                onChange={this.handleProvinceChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 ">District </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <Dropdown
                                                                placeholder='Select District'
                                                                fluid
                                                                selection
                                                                id='districtId'
                                                                name='districtId'
                                                                options={districts}
                                                                value={this.state.districtId}
                                                                onChange={this.handleDistrictChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> MOHArea </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <Dropdown
                                                                placeholder='Select MOHArea'
                                                                fluid
                                                                selection
                                                                id='mohAreaId'
                                                                name='mohAreaId'
                                                                options={mohareas}
                                                                value={this.state.mohAreaId}
                                                                onChange={this.handleMOHChange}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Gndivision </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <Dropdown
                                                                placeholder='Select Division'
                                                                fluid
                                                                search
                                                                selection
                                                                id='gndivisionId'
                                                                name='gndivisionId'
                                                                options={divisions}
                                                                value={this.state.gndivisionId}
                                                                onChange={this.handleDivisionChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> PHMArea </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input type="text" id="phmArea" name="phmArea" value={this.state.phmArea} onChange={this.handleChange} className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Name of School </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input type="text" id="nameOfSchool" name="nameOfSchool" value={this.state.nameOfSchool} onChange={this.handleChange} className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Name of Facilitating PHM </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input type="text" id="nameOfFacilitatingPHM" name="nameOfFacilitatingPHM" value={this.state.nameOfFacilitatingPHM} onChange={this.handleChange} className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Name of Facilitating PHI </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input type="text" id="nameOfFacilitatingPHI" name="nameOfFacilitatingPHI" value={this.state.nameOfFacilitatingPHI} onChange={this.handleChange} className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Name of School Principal </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input type="text" id="schoolPrincipalName" name="schoolPrincipalName" value={this.state.schoolPrincipalName} onChange={this.handleChange} className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Phone No. of School Principal </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input type="number" id="schoolPrincipalTel" name="schoolPrincipalTel" value={this.state.schoolPrincipalTel} onChange={this.handleChange} className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Date of establishment of school </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <SemanticDatepicker
                                                                maxDate={new Date()}
                                                                style={{ width: "100%" }}
                                                                label=""
                                                                id="dateOfEstablishmentOfSchool"
                                                                name="dateOfEstablishmentOfSchool"
                                                                placeholder="Date of establishment of the School"
                                                                dateFormat="YYYY-MM-DD"
                                                                value={this.state.dateOfEstablishmentOfSchool}
                                                                iconPosition="left"
                                                                onChange={this.handleTimeChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Number Of Students </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input type="number" id="numOfStudents" name="numOfStudents" value={this.state.numOfStudents} onChange={this.handleChange} className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Meating Frequency </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <Dropdown
                                                                placeholder='Select Meating Frequency'
                                                                fluid
                                                                selection
                                                                id='meetingFrequency'
                                                                name='meetingFrequency'
                                                                options={this.state.meetingFrequencies}
                                                                value={this.state.meetingFrequency}
                                                                onChange={this.handleMeetingChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Num Of Meetings In year </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input type="number" id="numOfMeetingsInyear" name="numOfMeetingsInyear" value={this.state.numOfMeetingsInyear} onChange={this.handleChange} className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Number of meetings/programs conducted for school health authorities: </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input type="number" id="numOfProgramsHealthAuthority" name="numOfProgramsHealthAuthority" value={this.state.numOfProgramsHealthAuthority} onChange={this.handleChange} className="form-control" />
                                                        </div>
                                                    </div>


                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Select conducted activities </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <Dropdown
                                                                placeholder='Select conducted activities'
                                                                fluid
                                                                multiple
                                                                selection
                                                                id='conductedActivities'
                                                                name='conductedActivities'
                                                                options={this.state.conducted_activity_list}
                                                                value={this.state.conductedActivities}
                                                                onChange={this.handleActivityChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Last supervision visit </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <Dropdown
                                                                placeholder='Select Last supervision visit'
                                                                fluid
                                                                selection
                                                                id='supervisionVisit'
                                                                name='supervisionVisit'
                                                                options={this.state.supervisionVisits}
                                                                value={this.state.supervisionVisit}
                                                                onChange={this.handleOnChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div class="form-group row ">
                                                        <label class="control-label col-md-3 col-sm-3 " for="number" >Comments </label>
                                                        <div class="col-md-9 col-sm-9">
                                                            <TextArea style={{ width: "100%" }} id="comments" name="comments" value={this.state.comments} onChange={this.handleChange} placeholder='Comments' rows={6} />
                                                        </div>
                                                    </div>

                                                    <div class="form-group row ">
                                                        <label class="control-label col-md-3 col-sm-3 " for="number" >Upload Images </label>
                                                        <div class="col-md-9 col-sm-9">
                                                            <div>
                                                                <Grid columns={2}>
                                                                    <Grid.Column textAlign="left">
                                                                        <Input
                                                                            onChange={this.handleFileAddChange}
                                                                            name="upload"
                                                                            id="upload"
                                                                            style={{ width: "250px" }}
                                                                            value={this.state.uploads}
                                                                            placeholder="Product Image"
                                                                            type="file"
                                                                            required
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column textAlign="right">
                                                                        {localStorage.USER_TYPE == "USER" ? (
                                                                            <button onClick={() => this.fileUploadOnClick()} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                                                {loading ? (
                                                                                    <Loader
                                                                                        type="Oval"
                                                                                        color="#2A3F54"
                                                                                        height={15}
                                                                                        width={40}
                                                                                    />
                                                                                ) : ("Click here to Upload")}
                                                                            </button>
                                                                        ) : (
                                                                                null
                                                                            )}

                                                                    </Grid.Column>
                                                                </Grid>
                                                            </div>

                                                            <div>
                                                                {this.state.isUploading ? (
                                                                    <Message icon>
                                                                        <Icon name="circle notched" loading />
                                                                        <Message.Content>
                                                                            <Message.Header>Just one second</Message.Header>
                                                                            We are uploading your document
                                                                </Message.Content>
                                                                    </Message>
                                                                ) : (
                                                                        ""
                                                                    )}
                                                            </div>

                                                            <div>
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column>
                                                                            {this.state.images.map((image, key) => {
                                                                                return (
                                                                                    <div class="img_wrp">
                                                                                        <ImageZoom
                                                                                            image={{
                                                                                                src: image,
                                                                                                className: 'img',
                                                                                                style: { width: "150px", height: "150px", "padding": "15px" }
                                                                                            }}
                                                                                            zoomImage={{
                                                                                                src: image,
                                                                                            }}
                                                                                        />

                                                                                        <span onClick={() => this._deleteFile(image, key)} class="close">&times;</span>
                                                                                    </div>

                                                                                );
                                                                            })}

                                                                            {this.state.readFiles.map((file, key) => {
                                                                                return (
                                                                                    <div class="img_wrp">
                                                                                        <ImageZoom
                                                                                            image={{
                                                                                                src: file,
                                                                                                className: 'img',
                                                                                                style: { width: "150px", height: "150px", "padding": "15px" }
                                                                                            }}
                                                                                            zoomImage={{
                                                                                                src: file,
                                                                                            }}
                                                                                        />

                                                                                        <span onClick={() => this.handleFileRemoveChange(key)} class="close">&times;</span>
                                                                                    </div>

                                                                                );
                                                                            })}

                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {(formErrorStatus.status) ? (
                                                        <div class="ui negative message">

                                                            <div class="header">
                                                                Not Submitted.
                                                </div>
                                                            <p>{formErrorStatus.message}</p>
                                                        </div>

                                                    ) : ((formSuccessState.status) ? (
                                                        <div class="ui success message">
                                                            <div class="header">
                                                                Submitted successfully.
                                                            </div>
                                                            <p>{formSuccessState.message}</p>
                                                        </div>
                                                    ) : (''))}

                                                    <div className="ln_solid" />
                                                    <div className="form-group">
                                                        <div className="col-md-9 col-sm-9  offset-md-3">
                                                            <button onClick={this.handleSubmit} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                                {loading ? (
                                                                    <Loader
                                                                        type="Oval"
                                                                        color="#2A3F54"
                                                                        height={15}
                                                                        width={40}
                                                                    />
                                                                ) : ("Submit")}
                                                            </button>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                            <div className="col-md-6 ">
                                                <div className="map_container">
                                                    <Map
                                                        google={this.props.google}
                                                        style={{ width: "80%", margin: "auto", height: "78%" }}
                                                        initialCenter={{ lat: 7.2906, lng: 80.6337 }}
                                                        zoom={8.7}
                                                        onClick={
                                                            this.state.allowClick ? this.onMapClick : false
                                                        }
                                                    >
                                                        <Marker
                                                            title={marker.title}
                                                            name={marker.name}
                                                            position={marker.position}
                                                        />

                                                    </Map>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                            </div>
                        </div>

                    </div>


                </div>
            </div>

        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: apiKey
})(AddNewSchoolSetting))));
