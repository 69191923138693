import React, { Component } from 'react';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, withRouter, Redirect } from "react-router-dom";
import './App.css';
import indexRoutes from "./routes/indexRoutes";



const hist = createBrowserHistory({ basename: '/' });
// basename: '/production/healthpromotionweb'

class App extends Component {

  render() {
    let routes = (
      <Switch>

        {indexRoutes.map((prop, key) => {
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        })}

        <Redirect to="/login" />

      </Switch>
    );

    return (
      <Router history={hist}>
        {routes}
      </Router>
    );
  }
}

export default withRouter(App);

