import gql from 'graphql-tag';

export const ADD_MOTHERSUPPORT_EVENT = gql`
mutation AddMotherSupportEvent($title: String,$description: String,$date: String,$images: JSON,$motherSupportSettingSubmissionId: String!){
    addMotherSupportEvent( title: $title,description: $description,date: $date,images: $images,motherSupportSettingSubmissionId: $motherSupportSettingSubmissionId)
        {
            id
            title
            description
            date
            images
            motherSupportSettingSubmissionId
        }
}
`

export const ADD_PRESCHOOL_EVENT = gql`
mutation AddPreSchoolEvent($title: String,$description: String,$date: String,$images: JSON,$preschoolSettingSubmissionId: String!){
    addPreSchoolEvent( title: $title,description: $description,date: $date,images: $images,preschoolSettingSubmissionId: $preschoolSettingSubmissionId)
        {
            id
            title
            description
            date
            images
            preschoolSettingSubmissionId
        }
}
`

export const ADD_SCHOOL_EVENT = gql`
mutation AddSchoolEvent($title: String,$description: String,$date: String,$images: JSON,$schoolSettingSubmissionId: String!){
    addSchoolEvent( title: $title,description: $description,date: $date,images: $images,schoolSettingSubmissionId: $schoolSettingSubmissionId)
        {
            id
            title
            description
            date
            images
            schoolSettingSubmissionId
        }
}
`

export const ADD_WORKPLACE_EVENT = gql`
mutation AddWorkPlaceEvent($title: String,$description: String,$date: String,$images: JSON,$workPlaceSettingSubmissionId: String!){
    addWorkPlaceEvent( title: $title,description: $description,date: $date,images: $images,workPlaceSettingSubmissionId: $workPlaceSettingSubmissionId)
        {
            id
            title
            description
            date
            images
            workPlaceSettingSubmissionId
        }
}
`

export const ADD_HOSPITAL_EVENT = gql`
mutation AddHospitalEvent($title: String,$description: String,$date: String,$images: JSON,$hospitalSettingSubmissionId: String!){
    addHospitalEvent( title: $title,description: $description,date: $date,images: $images,hospitalSettingSubmissionId: $hospitalSettingSubmissionId)
        {
            id
            title
            description
            date
            images
            hospitalSettingSubmissionId
        }
}
`

export const ADD_VILLAGE_EVENT = gql`
mutation AddVillageEvent($title: String,$description: String,$date: String,$images: JSON,$villageSettingSubmissionId: String!){
    addVillageEvent( title: $title,description: $description,date: $date,images: $images,villageSettingSubmissionId: $villageSettingSubmissionId)
        {
            id
            title
            description
            date
            images
            villageSettingSubmissionId
        }
}
`

export const EDIT_MOTHERSUPPORT_EVENT = gql`
mutation EditMotherSupportEvent($eventId: String!,$title: String,$description: String,$date: String,$images: JSON){
    editMotherSupportEvent( eventId: $eventId,title: $title,description: $description,date: $date,images: $images)
        {
            id
            title
            description
            date
            images
            motherSupportSettingSubmissionId
        }
}
`

export const EDIT_PRESCHOOL_EVENT = gql`
mutation EditPreSchoolEvent($eventId: String!,$title: String,$description: String,$date: String,$images: JSON){
    editPreSchoolEvent( eventId: $eventId,title: $title,description: $description,date: $date,images: $images)
        {
            id
            title
            description
            date
            images
            preschoolSettingSubmissionId
        }
}
`

export const EDIT_SCHOOL_EVENT = gql`
mutation EditSchoolEvent($eventId: String!,$title: String,$description: String,$date: String,$images: JSON){
    editSchoolEvent( eventId: $eventId,title: $title,description: $description,date: $date,images: $images)
        {
            id
            title
            description
            date
            images
            schoolSettingSubmissionId
        }
}
`

export const EDIT_WORKPLACE_EVENT = gql`
mutation EditWorkPlaceEvent($eventId: String!,$title: String,$description: String,$date: String,$images: JSON){
    editWorkPlaceEvent( eventId: $eventId,title: $title,description: $description,date: $date,images: $images)
        {
            id
            title
            description
            date
            images
            workPlaceSettingSubmissionId
        }
}
`

export const EDIT_HOSPITAL_EVENT = gql`
mutation EditHospitalEvent($eventId: String!,$title: String,$description: String,$date: String,$images: JSON){
    editHospitalEvent( eventId: $eventId,title: $title,description: $description,date: $date,images: $images)
        {
            id
            title
            description
            date
            images
            hospitalSettingSubmissionId
        }
}
`

export const EDIT_VILLAGE_EVENT = gql`
mutation EditVillageEvent($eventId: String!,$title: String,$description: String,$date: String,$images: JSON){
    editVillageEvent( eventId: $eventId,title: $title,description: $description,date: $date,images: $images)
        {
            id
            title
            description
            date
            images
            villageSettingSubmissionId
        }
}
`

export const GET_MOTHER_SUPPORT_EVENTS = gql`
  query GetMotherSupportEvents($skip: Int,$first: Int, $motherSupportSettingSubmissionId: String!){
    getMotherSupportEvents(skip:$skip,first:$first,motherSupportSettingSubmissionId:$motherSupportSettingSubmissionId){
            motherSupportEvents{
                id
                title
                description
                date
                images
                motherSupportSettingSubmissionId
                createdAt
            }
            count
        }
    }
`;

export const GET_PRE_SCHOOL_EVENTS = gql`
  query GetPreSchoolEvents($skip: Int,$first: Int,$preschoolSettingSubmissionId: String!){
    getPreSchoolEvents(skip:$skip,first:$first,preschoolSettingSubmissionId:$preschoolSettingSubmissionId){
        preSchoolEvents{
                id
                title
                description
                date
                images
                preschoolSettingSubmissionId
                createdAt
            }
            count
        }
    }
`;

export const GET_SCHOOL_EVENTS = gql`
  query GetSchoolEvents($skip: Int,$first: Int,$schoolSettingSubmissionId: String!){
    getSchoolEvents(skip:$skip,first:$first,schoolSettingSubmissionId:$schoolSettingSubmissionId){
            schoolEvents{
                id
                title
                description
                date
                images
                schoolSettingSubmissionId
                createdAt
            }
            count
        }
    }
`;

export const GET_WORKPLACE_EVENTS = gql`
  query GetWorkPlaceEvents($skip: Int,$first: Int,$workPlaceSettingSubmissionId: String!){
    getWorkPlaceEvents(skip:$skip,first:$first,workPlaceSettingSubmissionId:$workPlaceSettingSubmissionId){
            workPlaceEvents{
                id
                title
                description
                date
                images
                workPlaceSettingSubmissionId
                createdAt
            }
            count
        }
    }
`;

export const GET_HOSPITAL_EVENTS = gql`
  query GetHospitalEvents($skip: Int,$first: Int,$hospitalSettingSubmissionId: String!){
    getHospitalEvents(skip:$skip,first:$first,hospitalSettingSubmissionId:$hospitalSettingSubmissionId){
        hospitalEvents{
                id
                title
                description
                date
                images
                hospitalSettingSubmissionId
                createdAt
            }
            count
        }
    }
`;

export const GET_VILLAGE_EVENTS = gql`
  query GetVillageEvents($skip: Int,$first: Int,$villageSettingSubmissionId: String!){
    getVillageEvents(skip:$skip,first:$first,villageSettingSubmissionId:$villageSettingSubmissionId){
        villageEvents{
                id
                title
                description
                date
                images
                villageSettingSubmissionId
                createdAt
            }
            count
        }
    }
`;

export const GET_SINGLE_MOTHERSUPPORT_EVENT = gql`
  query GetSingleMotherSupportEvent($eventId: String!){
    getSingleMotherSupportEvent(eventId:$eventId){
        id
        title
        description
        date
        images
        motherSupportSettingSubmissionId
    }
  }
`;

export const GET_SINGLE_PRESCHOOL_EVENT = gql`
  query GetSinglePreSchoolEvent($eventId: String!){
    getSinglePreSchoolEvent(eventId:$eventId){
        id
        title
        description
        date
        images
        preschoolSettingSubmissionId
    }
  }
`;

export const GET_SINGLE_SCHOOL_EVENT = gql`
  query GetSingleSchoolEvent($eventId: String!){
    getSingleSchoolEvent(eventId:$eventId){
        id
        title
        description
        date
        images
        schoolSettingSubmissionId
    }
  }
`;

export const GET_SINGLE_WORKPLACE_EVENT = gql`
  query GetSingleWorkPlaceEvent($eventId: String!){
    getSingleWorkPlaceEvent(eventId:$eventId){
        id
        title
        description
        date
        images
        workPlaceSettingSubmissionId
    }
  }
`;

export const GET_SINGLE_HOSPITAL_EVENT = gql`
  query GetSingleHospitalEvent($eventId: String!){
    getSingleHospitalEvent(eventId:$eventId){
        id
        title
        description
        date
        images
        hospitalSettingSubmissionId
    }
  }
`;

export const GET_SINGLE_VILLAGE_EVENT = gql`
  query GetSingleVillageEvent($eventId: String!){
    getSingleVillageEvent(eventId:$eventId){
        id
        title
        description
        date
        images
        villageSettingSubmissionId
    }
  }
`;

export const DELETE_MOTHERSUPPORT_EVENT = gql`
mutation DeleteMotherSupportEvent($id:String!){
    deleteMotherSupportEvent(id:$id)
        {
            id
        }
}
`

export const DELETE_PRESCHOOL_EVENT = gql`
mutation DeletePreschoolEvent($id:String!){
    deletePreschoolEvent(id:$id)
        {
            id
        }
}
`

export const DELETE_SCHOOL_EVENT = gql`
mutation DeleteSchoolEvent($id:String!){
    deleteSchoolEvent(id:$id)
        {
            id
        }
}
`

export const DELETE_WORKPLACE_EVENT = gql`
mutation DeleteWorkPlaceEvent($id:String!){
    deleteWorkPlaceEvent(id:$id)
        {
            id
        }
}
`

export const DELETE_HOSPITAL_EVENT = gql`
mutation DeleteHospitalEvent($id:String!){
    deleteHospitalEvent(id:$id)
        {
            id
        }
}
`
export const DELETE_VILLAGE_EVENT = gql`
mutation DeleteVillageEvent($id:String!){
    deleteVillageEvent(id:$id)
        {
            id
        }
}
`

export const SEARCH_MOTHERSUPPORT_EVENTS =gql`
  query searchMotherSupportEvents($searchTerm: String,$skip: Int,$first: Int){
    searchMotherSupportEvents(searchTerm:$searchTerm,skip: $skip, first:$first)
    }
`;

export const SEARCH_PRESCHOOL_EVENTS =gql`
  query searchPreSchoolEvents($searchTerm: String,$skip: Int,$first: Int){
    searchPreSchoolEvents(searchTerm:$searchTerm,skip: $skip, first:$first)
    }
`;

export const SEARCH_SCHOOL_EVENTS =gql`
  query searchSchoolEvents($searchTerm: String,$skip: Int,$first: Int){
    searchSchoolEvents(searchTerm:$searchTerm,skip: $skip, first:$first)
    }
`;

export const SEARCH_WORKPLACE_EVENTS =gql`
  query searchWorkPlaceEvents($searchTerm: String,$skip: Int,$first: Int){
    searchWorkPlaceEvents(searchTerm:$searchTerm,skip: $skip, first:$first)
    }
`;

export const SEARCH_HOSPITAL_EVENTS =gql`
  query searchHospitalEvents($searchTerm: String,$skip: Int,$first: Int){
    searchHospitalEvents(searchTerm:$searchTerm,skip: $skip, first:$first)
    }
`;

export const SEARCH_VILLAGE_EVENTS =gql`
  query searchVillageEvents($searchTerm: String,$skip: Int,$first: Int){
    searchVillageEvents(searchTerm:$searchTerm,skip: $skip, first:$first)
    }
`;




