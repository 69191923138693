import React , {Component } from 'react'

class AdminFooter extends Component{
  

    render(){
        return(
            <div>
                <footer>
                    <div className="pull-right">
                        UNFPA-GIS - © Copyright UNFPA | Privacy | Powered by Encyte
                    </div>
                    <div className="clearfix"></div>
                </footer>
            </div>

        );
    }
}

export default AdminFooter;