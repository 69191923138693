import {
    LOGGING_USER,
    LOGIN_SUCCESS,
    FORM_STATUS,
    SUCCESS_STATUS
  } from "../constants/index";

  const initialState = {
    loginFormState: {
      form_state: "default",
      title: "",
      message: ""
    },
    formErrorStatus: {
      status: false,
      message: '',
      'title':'',
    },
    formSuccessState: {
      status: false,
      message: '',
      'title':'',
    }
  };
  
  export const rootReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGGING_USER:
        return loginUserReducer(state, action);
      case LOGIN_SUCCESS:
        return loginSuccess(state, action);
      case SUCCESS_STATUS:
        return requestSuccess(state, action);
      case FORM_STATUS:
        return updateFormStatus(state, action);
      default:
        return state;
    }
  }
  
  
  function updateFormStatus(state, action) {
    return {
      ...state,
      formErrorStatus: {
        status: action.status.status,
        message: action.status.message,
        'title':action.status.title,
      }
    }
  }
 
  function requestSuccess(state, action) {
    return {
      ...state,
      formSuccessState: {
        status: action.status.status,
        message: action.status.message,
        'title':action.status.title,
      }
    };
  }
  
  function loginSuccess(state, action) {
    return {
      ...state,
      loginFormState: {
        form_state: "success",
        title: "",
        message: ""
      }
    };
  }
  
  function loginUserReducer(state, action) {
    return {
      ...state,
      loginFormState: {
        form_state: "error",
        title: "",
        message: ""
      }
    };
  }
  
  export default rootReducer;
  