import React, { Component } from 'react'
import { EDIT_BASIC_PROFILE, GET_All_USERS, DELETE_USER } from '../../queries/UserQueries'
import { GET_PENDING_SUBMISSIONS } from '../../queries/SettingQueries'
import { GET_NEWS } from '../../queries/NewsQueries'

import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions'
import moment from 'moment';
//ui
import { Button, Grid, Modal, Dropdown, Icon } from 'semantic-ui-react';

import Loader from 'react-loader-spinner'

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}


class ViewNotifications extends Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        }
        this.state = {
            loading: false,
            pagination: {
                skip: 0,
                first: 10
            },
            settings: [],
            currPage: 0,
            totalPages: 0,
            count: 0,
            items: [],

            newsLoading: false,
            newsPagination: {
                skip: 0,
                first: 10
            },
            news: [],
            newsCurrPage: 0,
            newsTotalPages: 0,
            newsCount: 0,
            newsItems: [],
        }
    }

    componentDidMount() {
        this.loadPendingSubmissions();
        this.loadNews();
    }

    loadPendingSubmissions = () => {
        this.setState({ loading: true });
        this.getPendingSubmissions(this.state.pagination).then(result => {

            console.log(result)

            const pages = Math.ceil(result.count / 10);

            if (pages === 0) return null;

            var items = [];

            for (var index = 0; index < pages; index++) {
                items[index] = index - 1;
            }

            console.log("///////////////////////")

            this.setState({
                settings: result.collaborateSubmissions,
                count: result.count,
                loading: false,
                totalPages: parseInt(result.count / 10) + 1,
                items: items,
            });
        })
    }

    loadNews = () => {
        this.setState({ newsLoading: true });
        this.getNews(this.state.newsPagination).then(result => {
            const pages = Math.ceil(result.count / 10);

            if (pages === 0) return null;

            var items = [];

            for (var index = 0; index < pages; index++) {
                items[index] = index - 1;
            }

            this.setState({
                news: result.news,
                newsCount: result.count,
                newsLoading: false,
                newsTotalPages: parseInt(result.count / 10) + 1,
                newsItems: items,
            });
        })
    }


    handlePagination = async (pageNo) => {

        if (pageNo < 0) {
            return false;
        } else if (pageNo == 0) {

            this.setState({ currPage: pageNo })

            this.loadPendingSubmissions(this.state.pagination);

        } else if (pageNo > 0) {

            if (this.state.totalPages < pageNo + 1) {
                return false;
            } else {
                this.setState({ currPage: pageNo })

                var pagination = {
                    skip: pageNo * 10,
                    first: 10
                };

                this.loadPendingSubmissions(pagination);
            }
        }

    }

    handleNewsPagination = async (pageNo) => {

        if (pageNo < 0) {
            return false;
        } else if (pageNo == 0) {

            this.setState({ newsCurrPage: pageNo })

            this.loadNews(this.state.newsPagination);

        } else if (pageNo > 0) {

            if (this.state.newsTotalPages < pageNo + 1) {
                return false;
            } else {
                this.setState({ newsCurrPage: pageNo })

                var newsPagination = {
                    skip: pageNo * 10,
                    first: 10,
                };

                this.loadNews(newsPagination);
            }
        }

    }


    getNews = async (newsPagination) => {
        const result = await this.props.client.query({
            query: GET_NEWS,
            fetchPolicy: 'network-only',
            variables: newsPagination,
        });
        return result.data.getNews;
    }

    getPendingSubmissions = async (settingPagination) => {
        const result = await this.props.client.query({
            query: GET_PENDING_SUBMISSIONS,
            fetchPolicy: 'network-only',
            variables: settingPagination,
        });
        console.log(result)
        return result.data.getPendingSubmissions;
    }

    settings = (type) => {
        this.props.history.push("/settings?type="+type);
    }

    news = () => {
        this.props.history.push("/news");
    }

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        const { settings, news, loading, newsLoading } = this.state;

        console.log(settings)
        console.log(news)
        return (
            <div>
                <div className="right_col" role="main">
                    <div className="page-title">
                        <div >
                            <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                        </div>

                    </div>
                    <div className="clearfix"></div>
                    <br />
                    <div className="row">
                        <div className="col-md-6 col-sm-6  ">
                            <div className="x_panel">
                                <div className="x_title">
                                    <h2>Setting Alerts </h2>
                                    <div className="clearfix"></div>
                                </div>

                                <div className="x_content">


                                    {loading ? (
                                        <div className="ListLoader">
                                            <Loader
                                                type="ThreeDots"
                                                color="#5A738E"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                    ) : (

                                            <div>
                                                <ul class="list-unstyled timeline">
                                                    {
                                                        settings != undefined ?
                                                            settings.length > 0 ?
                                                                settings.map((setting, index) => (

                                                                    <li>
                                                                        <div class="block">
                                                                            <div class="tags">
                                                                                <a href="" class="tag">
                                                                                    <span>{setting.type}</span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="block_content">

                                                                                <div class="byline">
                                                                                    <span> Added On {moment(setting.createdAt).format("YYYY-MM-DD")}</span>
                                                                                    <Button floated="right" onClick={() => this.settings(setting.type)} basic color='blue'>View Details</Button>

                                                                                </div>
                                                                                <p class="excerpt">
                                                                                    Province : {setting.province} <br />
                                                                                        District : {setting.district} <br />
                                                                                        GN Division : {setting.gndivision != undefined ? setting.gndivision : null} <br />
                                                                                        MOH Area : {setting.gndivision != undefined ? setting.mohArea : null} <br />
                                                                                </p>
                                                                            </div>

                                                                        </div>
                                                                    </li>

                                                                ))
                                                                : null : null
                                                    }

                                                </ul>

                                                {
                                                    this.state.count > 10 ? (
                                                        <div style={{ textAlign: "center" }}>
                                                            <div aria-label="Pagination Navigation" role="navigation" class="ui pagination menu">

                                                                <a
                                                                    onClick={() => this.handlePagination(0)}
                                                                    aria-current="false"
                                                                    aria-disabled="false"
                                                                    tabindex="0"
                                                                    value="1"
                                                                    type="firstItem"
                                                                    class="icon item"
                                                                >
                                                                    <i aria-hidden="true" class="angle double left icon"></i>
                                                                </a>
                                                                <a
                                                                    onClick={() => this.handlePagination(this.state.currPage - 1)}
                                                                    aria-current="false"
                                                                    aria-disabled="false"
                                                                    tabindex="0"
                                                                    value="1"
                                                                    aria-label="Previous item"
                                                                    type="prevItem"
                                                                    class="item">
                                                                    <i aria-hidden="true" class="angle left icon"></i>
                                                                </a>

                                                                {
                                                                    this.state.items.map((item, i) =>
                                                                        <a
                                                                            onClick={() => this.handlePagination(item + 1)}
                                                                            aria-current="true"
                                                                            aria-disabled="false"
                                                                            tabindex="0"
                                                                            value="1"
                                                                            type="pageItem"
                                                                            class="active item"
                                                                        >{i + 1}</a>
                                                                    )
                                                                }
                                                                <a onClick={() => this.handlePagination(this.state.currPage + 1)} aria-current="false" aria-disabled="false" tabindex="0" value="2" type="pageItem" class="item"> <i aria-hidden="true" class="angle right icon"></i> </a>


                                                                <a
                                                                    onClick={() => this.handlePagination(this.state.totalPages - 1)}
                                                                    aria-current="false"
                                                                    aria-disabled="false"
                                                                    tabindex="0"
                                                                    value="10"
                                                                    type="lastItem"
                                                                    class="icon item"
                                                                >
                                                                    <i aria-hidden="true" class="angle double right icon"></i>
                                                                </a>

                                                            </div>
                                                            <br />
                                                        </div>
                                                    ) : (
                                                            null
                                                        )
                                                }

                                            </div>

                                        )}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-6  ">
                            <div className="x_panel">
                                <div className="x_title">
                                    <h2>News Alerts </h2>
                                    <div className="clearfix"></div>
                                </div>


                                <div className="x_content">

                                    {loading ? (
                                        <div className="ListLoader">
                                            <Loader
                                                type="ThreeDots"
                                                color="#5A738E"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                    ) : (

                                            <div>
                                                <ul class="list-unstyled msg_list">
                                                    {
                                                        news != undefined ?
                                                            news.length > 0 ?
                                                                news.map((newsDetail, index) => (

                                                                    <li>
                                                                        <a>
                                                                            <span>
                                                                                <span class="time">Added On {moment(newsDetail.createdAt).format("YYYY-MM-DD")}</span>
                                                                                <h3>{newsDetail.title} <small></small></h3>
                                                                            </span>
                                                                            <br />
                                                                            <p>
                                                                                {newsDetail.description}
                                                                            </p>
                                                                            <span>Expire On {moment(newsDetail.expireAt).format("YYYY-MM-DD")}</span>
                                                                            <br />
                                                                        </a>
                                                                    </li>

                                                                ))
                                                                : null : null
                                                    }
                                                </ul>

                                                {
                                                    this.state.newsCount > 10 ? (
                                                        <div style={{ textAlign: "center" }}>
                                                            <div aria-label="Pagination Navigation" role="navigation" class="ui pagination menu">

                                                                <a
                                                                    onClick={() => this.handleNewsPagination(0)}
                                                                    aria-current="false"
                                                                    aria-disabled="false"
                                                                    tabindex="0"
                                                                    value="1"
                                                                    type="firstItem"
                                                                    class="icon item"
                                                                >
                                                                    <i aria-hidden="true" class="angle double left icon"></i>
                                                                </a>
                                                                <a
                                                                    onClick={() => this.handleNewsPagination(this.state.newsCurrPage - 1)}
                                                                    aria-current="false"
                                                                    aria-disabled="false"
                                                                    tabindex="0"
                                                                    value="1"
                                                                    aria-label="Previous item"
                                                                    type="prevItem"
                                                                    class="item">
                                                                    <i aria-hidden="true" class="angle left icon"></i>
                                                                </a>

                                                                {
                                                                    this.state.newsItems.map((item, i) =>
                                                                        <a
                                                                            onClick={() => this.handleNewsPagination(item + 1)}
                                                                            aria-current="true"
                                                                            aria-disabled="false"
                                                                            tabindex="0"
                                                                            value="1"
                                                                            type="pageItem"
                                                                            class="active item"
                                                                        >{i + 1}</a>
                                                                    )
                                                                }
                                                                <a onClick={() => this.handleNewsPagination(this.state.newsCurrPage + 1)} aria-current="false" aria-disabled="false" tabindex="0" value="2" type="pageItem" class="item"> <i aria-hidden="true" class="angle right icon"></i> </a>


                                                                <a
                                                                    onClick={() => this.handleNewsPagination(this.state.newsTotalPages - 1)}
                                                                    aria-current="false"
                                                                    aria-disabled="false"
                                                                    tabindex="0"
                                                                    value="10"
                                                                    type="lastItem"
                                                                    class="icon item"
                                                                >
                                                                    <i aria-hidden="true" class="angle double right icon"></i>
                                                                </a>

                                                            </div>
                                                            <br />
                                                        </div>
                                                    ) : (
                                                            null
                                                        )
                                                }

                                            </div>

                                        )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ViewNotifications)));

