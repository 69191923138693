import Login from '../screens/login/login';
import Registration from '../screens/registration/registration';
import Home from '../screens/home/home';
import ForgetPassword from '../screens/users/forgetPassword';

const indexRoutes = [
    {
        path: "/login",
        name: "Login",
        icon: "",
        component: Login,
        isVisibleInMenu:false
    },
    {
        path: "/registration",
        name: "Registration",
        icon: "",
        component: Registration,
        isVisibleInMenu:false
    },
    {
        path: "/home",
        name: "Home",
        icon: "",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/users",
        name: "Manage Users",
        icon: "fa fa-desktop",
        component: Home,
        isVisibleInMenu:true
    },
    {
        path: "/settings",
        name: "Manage Settings",
        icon: "fa fa-desktop",
        component: Home,
        isVisibleInMenu:true
    },
    {
        path: "/addpreschoolsubmission",
        name: "Add Pre School Submission",
        icon: "fa fa-desktop",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/editpreschoolsubmission",
        name: "Edit Pre School",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/userprofile",
        name: "User Profile",
        icon: "fa fa-user",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/reports",
        name: "Reports",
        icon: "fa fa-line-chart",
        component: Home,
        isVisibleInMenu:true
    },
    {
        path: "/highlevelusers",
        name: "Manage High Level Users",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/addnewhighleveluser",
        name: "Add High Level User",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/addmothersupportsubmission",
        name: "Add Mother Support Submission",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path:'/viewpreschoolsubmission',
        name: "View Pre School Submission",
        component: Home,
        isVisibleInMenu:true,
    },
    {
        path:'/viewmothersupportsubmission',
        name: "View Mother Support Submission",
        component: Home,
        isVisibleInMenu:true,
    },
    {
        path: "/addmothersupportevent",
        name: "Add Mother Support Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/viewmothersupportevents",
        name: "Mother Support Events",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/viewmothersupportevent",
        name: "Mother Support Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/editmothersupportsubmission",
        name: "Edit Mother Support",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "fa fa-tachometer",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path:'/viewworkplacesubmission',
        name: "View Work Place Submission",
        component: Home,
        isVisibleInMenu:true,
    },
    {
        path: "/addworkplacesubmission",
        name: "Add Work Place Submission",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/editworkplacesubmission",
        name: "Edit Work Place",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/viewworkplaceevents",
        name: "Work Place Events",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/addworkplaceevent",
        name: "Add Work Place Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/viewworkplaceevent",
        name: "View Work Place Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/addhospitalsubmission",
        name: "Add Hospital",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/edithospitalsubmission",
        name: "Edit Hospital",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path:'/viewhospitalsubmission',
        name: "View Hospital Submission",
        component: Home,
        isVisibleInMenu:false,
    },
    {
        path: "/viewhospitalevents",
        name: "Hospital Events",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/addhospitalevent",
        name: "Add Work Place Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/viewhospitalevent",
        name: "View Work Place Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/viewpreschoolevents",
        name: "Pre School Events",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/addpreschoolevent",
        name: "Add Pre School Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/viewpreschoolevent",
        name: "View Pre School Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/news",
        name: "View News",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/addnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/editnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/viewnews",
        name: "View News",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/addschoolsubmission",
        name: "Add School",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/editSchoolsubmission",
        name: "Edit School",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path:'/viewschoolsubmission',
        name: "View School Submission",
        component: Home,
        isVisibleInMenu:true,
    },
    {
        path: "/viewschoolevents",
        name: " School Events",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/addschoolevent",
        name: "Add School Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/viewschoolevent",
        name: "View School Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/addvillagesubmission",
        name: "Add Village",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/editvillagesubmission",
        name: "Edit Village",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path:'/viewvillagesubmission',
        name: "View Village Submission",
        component: Home,
        isVisibleInMenu:true,
    },
    {
        path: "/viewvillageevents",
        name: " Village Events",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/addvillageevent",
        name: "Add Village Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/viewvillageevent",
        name: "View Village Event",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/changePassword",
        name: "Change Password",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/viewNotifications",
        name: "View Notifications",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/forget-password",
        name: "Forget Password",
        icon: "fa fa-user-plus",
        component: ForgetPassword,
        isVisibleInMenu:false
    },
    {
        path: "/submission-report-by-district",
        name: "Submissions By District",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    },
    {
        path: "/user-report",
        name: "Users Report",
        icon: "fa fa-user-plus",
        component: Home,
        isVisibleInMenu:false
    }
    

    
];

export default indexRoutes;
