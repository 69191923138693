import gql from 'graphql-tag';

export const GET_EVENT_COUNT_BY_MONTH = gql`
  query getEventCountByMonth{
    getEventCountByMonth
  }
`

export const GET_ALL_SUBMISSION_LOCATIONS = gql`
  query getAllSubmissionLocations{
    getAllSubmissionLocations
  }
`

export const GET_ALL_SUBMISSION_COUNTS = gql`
  query getAllSubmissionCounts{
    getAllSubmissionCounts
  }
`

export const GET_ALL_EVENT_COUNTS = gql`
  query getAllEventCounts{
    getAllEventCounts
  }
`

export const GET_SUBMISSION_BY_DISTRICT = gql`
query GetSubmissionsByDistrict($skip: Int,$first: Int) {
  getSubmissionsByDistrict(skip:$skip,first:$first)
  }
`;

export const GET_RECENT_EVENTS= gql`
  query getRecentEvents{
    getRecentEvents
  }
`

export const GET_COUNTERS_FOR_DASHBOARD= gql`
  query getCountersForDashboard{
    getCountersForDashboard
  }
`

export const GET_SUBMISSION_BY_TYPEAND_DISTRICT = gql`
query GetSubmissionsByTypeAndDistrict($settingType: String!,$districtId: String,$start: String, $end: String) {
  getSubmissionsByTypeAndDistrict(settingType:$settingType,districtId:$districtId,start: $start, end: $end)
  }
`;

export const GET_USERS_BY_DISTRICT =gql`
  query GetUsersByDistrict($districtId: String, $start: String, $end: String, $userLevel: String){
        getUsersByDistrict(districtId:$districtId, start: $start, end: $end, userLevel: $userLevel){
            users{
                id
                name
                tel
                provinceId
                province
                districtId
                district
                mohareaId
                moharea
                gndivisionId
                gndivision
                location
                email
                gender
                nic
                password
                userType
                settingType
                isActive
                createdAt
            }
            count
            stats
        }
    }
`;

