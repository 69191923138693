import React, { Component } from 'react'
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { unfpagisauthtoken, USER_ID, NIC, NAME, USER_TYPE, CONSULTANT_SETTING, NOTIFICATIONS, REG_NOTIFICATIONS } from '../../constants/genarics';

//middleware
import { formatDate } from '../../middleware/index';

import {
  GET_PENDING_SUBMISSIONS
} from '../../queries/SettingQueries'

import {
  UPDATE_USER_VIEW_NOTIFICATIONS
} from '../../queries/NewsQueries'


import {
  GET_NEWS
} from '../../queries/NewsQueries'

import {
  UPDATE_USER_VIEW_REG_NOTIFICATIONS, GET_USER_REG_NOTIFICATIONS, GET_USER_REG_LATEST_NOTIFICATIONS, UPDATE_REG_NOTIFICATIONS
} from '../../queries/UserQueries'

import moment from 'moment';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      submissions: [],
      count: 0,
      news: [],
      newsCount: 0,
      loadingNews: true,
      regView: 0,
      pagination: {
        skip: 0,
        first: 500
      },
      userId: localStorage.USER_ID,
      notifications: [],
      notificationsAll: [],
      notifications_count: 0,
      click_count: 0,
      notifyLoading: true,
    }


  }

  componentDidMount() {
    this.loadStuff();
    this.get_notifications().then(result => {
      this.setState({
        notificationsAll: result,
        notifications_count: result.count
      });
    });
    this.get_latest_notifications().then(result => {
      console.log(result)
      this.setState({
        notifications: result,
        notifyLoading: false,
        // notifications_count: result.length
      });
    });
  }

  loadStuff() {
    this.setState({ loading: true, loadingNews: true });
    this.getPendingSubmissions().then(result => {
      this.setState({
        submissions: result.collaborateSubmissions,
        count: result.count,
        loading: false
      });
    })

    this.getNews().then(result => {
      this.setState({
        news: result.news,
        newsCount: result.count,
        loadingNews: false
      });
    })

  }

  getNews = async () => {
    const result = await this.props.client.query({
      query: GET_NEWS,
      fetchPolicy: 'network-only',
    });
    return result.data.getNews;
  }

  getPendingSubmissions = async () => {
    const result = await this.props.client.query({
      query: GET_PENDING_SUBMISSIONS,
      fetchPolicy: 'network-only',
    });
    return result.data.getPendingSubmissions;
  }

  logout = () => {
    setTimeout(() => {
      localStorage.removeItem(unfpagisauthtoken);
      localStorage.removeItem(USER_ID);
      localStorage.removeItem(NIC);
      localStorage.removeItem(NAME);
      localStorage.removeItem(USER_TYPE);
      localStorage.removeItem(CONSULTANT_SETTING);
      localStorage.removeItem(NOTIFICATIONS);

      this.props.history.push("/");
    }, 500)

  };

  settings = (type) => {
    this.props.history.push("/settings?type=" + type);
  }

  viewNotifications = () => {
    this.props.history.push("/viewNotifications");
  }

  viewProfile = () => {
    this.props.history.push("/userProfile?id=" + localStorage.USER_ID);
  }

  changePassword = () => {
    this.props.history.push("/changePassword");
  }

  onClickNotifications = () => {
    this.updateUserViewNotifications().then(result => {
      localStorage.NOTIFICATIONS = result.notifications;
    })
  }

  onClickRegNotifications = () => {
    const regView = this.state.regView;
    if (regView == 0) {
      this.setState({ regView: 1 })
    }
    else if(regView == 1){
      this.updateUserViewRegNotifications().then(result => {
        localStorage.REG_NOTIFICATIONS = result.regUserNotifications;
      })
      this.setState({regView:2})
    }

  }

  updateUserViewNotifications = async () => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_USER_VIEW_NOTIFICATIONS,
    });
    return result.data.updateUserViewNotifications;
  };

  get_notifications = async () => {
    const result = await this.props.client.query({
      query: GET_USER_REG_NOTIFICATIONS,
      fetchPolicy: 'network-only'
    });
    return result.data.getUserRegNotifications;
  };

  get_latest_notifications = async () => {
    const result = await this.props.client.query({
      query: GET_USER_REG_LATEST_NOTIFICATIONS,
      fetchPolicy: 'network-only'
    });
    return result.data.getUserRegLatestNotifications;
  };

  read_notification = () => {
    const { click_count, notificationsAll } = this.state;

    console.log(notificationsAll)

    // const temp_click_count = click_count === 0 ? click_count + 1 : 0;
    // this.setState({ notifications_count: 0, click_count: temp_click_count, notifications: click_count === notifications ? [] : notifications });

    this.setState({ notifications_count: 0 });
    if (notificationsAll != undefined) {
      if (notificationsAll.result.length > 0) {
        console.log(notificationsAll.result)

        notificationsAll.result.map((notification,i) => {
          console.log("==========================="+i);
          this.update_notification(notification.id).then(result => {
            console.log(result);
          })
        })
      }
    }
  }

  update_notification = async (id) => {
    console.log(id)
    const result = await this.props.client.mutate({
      mutation: UPDATE_REG_NOTIFICATIONS,
      variables: { id },
    });
    return result.data.updateRegNotifications;
  };


  render() {
    const { loading, submissions, count, news, newsCount, loadingNews, notifications, notifications_count } = this.state;
    console.log(notifications)
    return (
      <div>
        {/* top navigation */}
        <div className="top_nav">
          <div className="nav_menu" style={{ paddingTop: "5px" }}>
            <div className="nav toggle">
              {/* <a id="menu_toggle"><i className="fa fa-bars" /></a> */}
            </div>
            <nav className="nav navbar-nav">
              <ul className=" navbar-right">

                <li className="nav-item dropdown open" style={{ paddingLeft: 15 }}>
                  <a href="javascript:;" className="user-profile dropdown-toggle" aria-haspopup="true" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false">
                    {localStorage.NAME}
                  </a>
                  <div className="dropdown-menu dropdown-usermenu pull-right" aria-labelledby="navbarDropdown">
                    <a className="dropdown-item" href="javascript:;" onClick={() => this.viewProfile()}> Profile</a>
                    {/* <a className="dropdown-item" href="javascript:;" onClick={()=>this.changePassword()}>Change Password</a> */}
                    <a className="dropdown-item" onClick={() => this.logout()}><i className="fa fa-sign-out pull-right" /> Log Out</a>
                  </div>
                </li>

                {
                  localStorage.USER_TYPE != "USER" ? (
                    loading && loadingNews ? (

                      <li role="presentation" className="nav-item dropdown open" style={{ "margin-right": "10px" }}>
                        <a href="javascript:;" className="dropdown-toggle info-number" id="navbarDropdown1" data-toggle="dropdown" aria-expanded="false">
                          <i className="fa fa-calendar" />
                          <span className="badge bg-green">0</span>
                        </a>
                        <ul className="dropdown-menu list-unstyled msg_list" role="menu" aria-labelledby="navbarDropdown1">

                        </ul>
                      </li>
                    ) : (
                      [<li role="presentation" className="nav-item dropdown open" style={{ "margin-right": "10px" }}>
                        <a href="javascript:;" className="dropdown-toggle info-number" id="navbarDropdown1" data-toggle="dropdown" aria-expanded="false">

                          <i className="fa fa-calendar" />
                          <span className="badge bg-green">{count}</span>
                        </a>
                        <ul className="dropdown-menu list-unstyled msg_list " role="menu" aria-labelledby="navbarDropdown1">
                          <div className="notificationPanel">
                            <li className="nav-item" style={{ "color": "#4183c4" }}>
                              Settings
                            </li>
                            {submissions.map((submission, key) => {
                              return (

                                <li className="nav-item" onClick={() => this.settings(submission.type)}>
                                  <a className="dropdown-item">
                                    <span>
                                      <span>{submission.type}</span>
                                      <span className="time">{formatDate(submission.createdAt)}</span>
                                    </span>
                                    <span className="message">
                                      <strong>Province</strong> : {submission.province} <br />
                                      <strong>District</strong> : {submission.district} <br />

                                    </span>
                                  </a>
                                </li>

                              );
                            })}
                            <li className="nav-item" style={{ "color": "#4183c4" }}>
                              News
                            </li>
                            {news.map((singleNews, key) => {
                              return (
                                <li className="nav-item">
                                  <a className="dropdown-item">
                                    <span>
                                      <span>{singleNews.title}</span>
                                      <span className="time">{formatDate(singleNews.createdAt)}</span><br /><br />
                                    </span>
                                    <span className="message">
                                      <strong>Description</strong> <br />
                                      {singleNews.description}
                                    </span>
                                  </a>
                                </li>
                              );
                            })}
                          </div>
                          <li className="text-center nav-item">
                            <a onClick={() => this.viewNotifications()}>
                              <strong>View more</strong> {' '}
                              <i class="fa fa-angle-right"></i>
                            </a>
                          </li>
                        </ul>

                      </li>,

                      <li role="presentation" className="nav-item dropdown open" style={{ "margin-right": "10px" }}>
                        <a href="javascript:;"
                          onClick={() => this.read_notification()}
                          className="dropdown-toggle info-number" id="navbarDropdown2" data-toggle="dropdown" aria-expanded="false">
                          <i className="fa fa-bell" />
                          <span className="badge bg-green">
                            {notifications_count}

                          </span>
                        </a>
                        <ul className="dropdown-menu list-unstyled msg_list" role="menu" aria-labelledby="navbarDropdown2">
                          <div className="">
                            <li className="nav-item" style={{ "color": "#4183c4" }}>
                              Users
          </li>
                            {
                              this.state.notifyLoading != true ?
                                notifications != undefined ?
                                  notifications.notifications != undefined ?
                                    notifications.notifications.length > 0 ?
                                      notifications.notifications.map((notification, i) => {
                                        return <li className="nav-item" >
                                          <a className="dropdown-item">
                                            <span>
                                              <span><b>{notification.name}</b></span> <br />

                                            </span>
                                            <span className="message">
                                              <div style={{ float: "left" }}>
                                                <strong>Province</strong> : {notification.province} <br />
                                                <strong>District</strong> : {notification.district} <br />
                                              </div>
                                              <div style={{ float: "right" }}>
                                                <strong>NIC</strong> : {notification.nic} <br />
                                                <strong>User Type</strong> : {notification.userType} <br />
                                              </div>
                                            </span>

                                            <br /><br />

                                            <span style={{ float: "right", fontSize: "10px", "color": "#5A738E", paddingTop: "10px" }}>Reg Date: {moment(notification.createdAt).format("YYYY-MM-DD HH:mm")}</span>

                                          </a>
                                        </li>
                                      })

                                      : <ul className="dropdown-menu list-unstyled msg_list" role="menu" aria-labelledby="navbarDropdown1"></ul>
                                    : <ul className="dropdown-menu list-unstyled msg_list" role="menu" aria-labelledby="navbarDropdown1"></ul>
                                  : <ul className="dropdown-menu list-unstyled msg_list" role="menu" aria-labelledby="navbarDropdown1"></ul>
                                : <ul className="dropdown-menu list-unstyled msg_list" role="menu" aria-labelledby="navbarDropdown1"></ul>
                            }
                          </div>

                        </ul>
                      </li>]

                    )
                  ) : (
                    null
                  )

                }



              </ul>
            </nav>
          </div>
        </div>
        {/* /top navigation */}

      </div>
    );
  }
}

export default withRouter(withApollo(Header));