import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions';

import { GET_ALL_NEWS, DELETE_NEWS } from '../../queries/NewsQueries'

//sementic ui
import { Grid, Button, Modal, Icon } from 'semantic-ui-react';
import Loader from 'react-loader-spinner'
import { formatDate } from '../../middleware/index';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}


class NewsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            news: [],
            loading: true,
            deleteId: "",
            openDelete: false
        }
    }

    componentDidMount() {
        this.loadNews();
    }

    loadNews() {
        this.setState({ loading: true });
        this.getNews().then(result => {
            this.setState({ news: result.news, loading: false });
        })
    }

    getNews = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_NEWS,
            fetchPolicy: 'network-only'
        });
        return result.data.getAllNews;
    }

    newNews = () => {
        this.props.history.push("/addnews");
    }

    editNews = (id) => {
        this.props.history.push("/editnews?id=" + id);
    }

    viewNews = (id) => {
        this.props.history.push("/viewnews?id=" + id);
    }

    onDelete = (id) => {
        this.setState({ deleteId: id, openDelete: true });
    }

    deleteNews = () => {
        this.removeNews().then(result => {
            this.closeDelete();
            this.loadNews();
        })
    }

    removeNews = async () => {
        const id = this.state.deleteId;
        const result = await this.props.client.mutate({
            mutation: DELETE_NEWS,
            variables: { id }
        });
        return result.data.deleteNews;
    };

    closeDelete = () => this.setState({ openDelete: false })

    goBack = () => {
        this.props.history.goBack();
    };


    render() {

        const { news, loading, } = this.state;

        console.log(news)

        return (

            <div className="right_col" role="main">
                <div className="page-title">

                </div>
                <div className="clearfix"></div>
                <div className="col-md-12 col-sm-12">
                    <div >
                        <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                    </div><br /><br />
                    <div className="x_panel">
                        <div className="x_title">
                            <h2> News </h2>
                            <div className="clearfix"></div>
                        </div>
                        <div class="x_content">

                            <Grid>
                                <Grid.Row>
                                    <Grid.Column > <br />
                                        <Button basic color='blue' style={{ float: "right" }} type="button" onClick={() => this.newNews()}>  Add News </Button>
                                        <br />
                                    </Grid.Column>

                                </Grid.Row>

                                <Modal className="middleModal" size="mini" open={this.state.openDelete} onClose={this.closeDelete}>
                                    <Modal.Header> Delete News</Modal.Header>
                                    <Modal.Content>
                                        <p>Do you want to delete this news ?</p>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button negative onClick={() => this.closeDelete()}>No</Button>
                                        <Button
                                            positive
                                            onClick={() => this.deleteNews()}
                                        >Yes</Button>
                                    </Modal.Actions>
                                </Modal>


                                <Grid.Row>
                                    <Grid.Column>
                                        {loading ? (
                                            <div className="ListLoader">
                                                <Loader
                                                    type="ThreeDots"
                                                    color="#5A738E"
                                                    height={100}
                                                    width={100}
                                                />
                                            </div>
                                        ) : (
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th> Title </th>
                                                            <th> Description </th>
                                                            <th> Added User </th>
                                                            <th> Added UserType </th>
                                                            <th> Expire Date </th>
                                                            <th> Status </th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                          news != undefined ?  
                                                          news.length > 0 ?
                                                            news.map((singleNews, index) => (
                                                                <tr key={index} >
                                                                    <td> {singleNews.title} </td>
                                                                    <td> {singleNews.description} </td>
                                                                    <td> {singleNews.userName} </td>
                                                                    <td> {singleNews.userType} </td>
                                                                    <td> {
                                                                        singleNews.expireAt != undefined && singleNews.expireAt != null && singleNews.expireAt != "" ? (
                                                                            formatDate(singleNews.expireAt)
                                                                        ) : ('')
                                                                    } </td>
                                                                    <td> {
                                                                        singleNews.expireAt != undefined && singleNews.expireAt != null && singleNews.expireAt != "" ? (
                                                                            formatDate(singleNews.expireAt) >= formatDate(new Date()) ?
                                                                                (
                                                                                    <label style={{ color: "green" }}>Active</label>
                                                                                ) : (
                                                                                    <label style={{ color: "red" }}>Expired</label>
                                                                                )
                                                                        ) : ('')} </td>
                                                                    <td> <button type="button" className="btn btn-info" onClick={() => this.viewNews(singleNews.id)} > View </button></td>
                                                                    <td>
                                                                        {localStorage.USER_ID == singleNews.userId ? (
                                                                            <button type="button" className="btn btn-primary" onClick={() => this.editNews(singleNews.id)} >Edit </button>
                                                                        ) : (
                                                                                <button disabled type="button" className="btn btn-success" > Edit </button>
                                                                            )}

                                                                    </td>

                                                                    <td> <button type="button" className="btn btn-warning" onClick={() => this.onDelete(singleNews.id)} > Delete </button></td>

                                                                </tr>
                                                            ))
                                                        : null : null}
                                                    </tbody>
                                                </table>
                                            )}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(NewsList))); 
