import React, { Component } from 'react';
import { Route } from "react-router-dom";

import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { un_icon } from '../../images/log2.jpg';

class Sidemenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: [],
            reportRoutes: this.props.reportRoutes,
            urlLastPart: "/" + window.location.pathname.split("/").pop()
        }

    }

    componentDidMount() {
        var route_list = this.props.sideRoutes;
        var routes = [];
        var routes = route_list.map((prop, i) => {
            if (
                prop.path == "/dashboard" ||
                prop.path == "/users" ||
                prop.path == "/highlevelusers" ||
                prop.path == "/settings" ||
                prop.path == "/news"

            ) {
                return { path: prop.path, icon: prop.icon, name: prop.name, isVisibleInMenu: prop.isVisibleInMenu }

            } else {
                return false;
            }
        });
        this.setState({ routes: routes });
    }

    RedirectToScreen = (url) => {
        this.props.getClickedUrl(url);
    }

    render() {
        const { sideRoutes } = this.props;

        console.log(this.state.routes)
        const activeStyle = { "background-color": '#5A738E', 'box-shadow': 'rgba(0,0,0,0.25) 0 1px 0, inset rgba(255,255,255,0.16) 0 1px 0' };
        return (
            <div>
                <div className="col-md-3 left_col" style={{ height: "100%", "overflow-y": "scroll" }}>
                    <div className="left_col scroll-view" style={{ height: "100%", "overflow-y": "scroll" }}>
                        <br />
                        <div style={{ textAlign: "center" }}>
                            <img src={require('../../images/log2.jpg')} width="100" />
                        </div>
                        <div className="navbar nav_title" style={{ border: 0 }}>
                            <a href="" className="mainHeaderTitle"> <span>Health Promotion Dashboard</span></a>
                        </div>
                        <div className="clearfix" />
                        {/* menu profile quick info */}
                        <div className="profile clearfix">
                            <div className="profile_pic">

                            </div>
                            <div className="profile_info sideMenuTop">
                                <span>Welcome</span>
                                <h2>{localStorage.NAME}</h2>
                                <h5 style={{ color: "#73879C" }}>
                                    {localStorage.USER_TYPE}
                                </h5>
                            </div>
                        </div>
                        {/* /menu profile quick info */}
                        <br />
                        {/* sidebar menu */}
                        <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
                            <div className="menu_section">
                                {/* <h3>General</h3> */}

                                <ul className="nav side-menu">

                                    {this.state.routes.map((route, key) => (
                                        route.isVisibleInMenu == true ?

                                            <li style={this.state.urlLastPart == route.path ? activeStyle : {}}>
                                                <a onClick={() => this.RedirectToScreen(route.path, route.activeIndex)}><i className={route.icon} /> {route.name} </a>
                                            </li>

                                            : null
                                    ))}


                                    {
                                        localStorage.USER_TYPE == "ADMIN" || localStorage.USER_TYPE == "HEO" || localStorage.USER_TYPE == "DIRECTOR" || localStorage.USER_TYPE == "CONSULTANT" || localStorage.USER_TYPE == "RDHS" ?
                                            <div>
                                                <br />
                                                <p style={{ marginLeft: "15px", color: "#73879c" }}><b>Reports</b></p>
                                            </div>
                                            : null
                                    }


                                    {
                                        localStorage.USER_TYPE == "ADMIN" || localStorage.USER_TYPE == "HEO" || localStorage.USER_TYPE == "DIRECTOR" || localStorage.USER_TYPE == "CONSULTANT" || localStorage.USER_TYPE == "RDHS" ?
                                            this.state.reportRoutes != undefined ?
                                                this.state.reportRoutes.length > 0 ?
                                                    this.state.reportRoutes.map((route, key) => (
                                                        <li style={this.state.urlLastPart == route.path ? activeStyle : {}}>
                                                            <a onClick={() => this.RedirectToScreen(route.path, route.activeIndex)}><i className={route.icon} /> {route.name} </a>
                                                        </li>
                                                    ))
                                                    : null : null : null
                                    }

                                </ul>

                            </div>

                        </div>



                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withApollo(Sidemenu));