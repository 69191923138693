import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus } from '../../actions/user-actions';

//query


//ui
import { Button, Icon } from 'semantic-ui-react';

//components
import PreSchool from './preSchool/preSchool';
import School from './school/school';
import WorkPlace from './workPlace/workPlace';
import Village from './village/village';
import Hospital from './hospital/hospital';
import MotherSupportGroup from './motherSupportGroup/motherSupportGroup';
import ScrollContainer from 'react-indiana-drag-scroll';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        }
    }
}

class ManageSettings extends Component {
    constructor(props) {
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        const type = query.get('type');
        var settingType = "";
        if (type != null && type != "" && type != undefined) {
            settingType = type;
        }

        this.state = {
            settingType: settingType,
            status: {
                form_state: 'default',
                title: '',
                message: '',
            },
            settingTag: 'MOTHERSUPPORT',
            loading: false
        };

        props.setFormStatus({ status: false, title: '', message: '' });

    }

    loadSettingTab = (type) => {
        this.setState({ settingTag: type });
    }

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <div className="right_col" role="main">
                <div >
                    <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                </div>
                <div className="page-title">
                    <div className="title_left">
                        <h3> Setting List </h3>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="col-md-12 col-sm-12">
                    <div className="x_panel">
                        <div className="x_title">
                            <h2> Community Engadgement Groups / Settings </h2>
                            <div className="clearfix"></div>
                        </div>
                        <div class="x_content">
                            
                                <div>

                                    <ul class="nav nav-tabs bar_tabs" id="myTab" role="tablist">

                                        {localStorage.USER_TYPE == "CONSULTANT" ? (

                                            localStorage.CONSULTANT_SETTING == "MOTHERSUPPORTGROUP" ? (
                                                <li class="nav-item">
                                                    <a class="nav-link active" id="mother-support-tab" data-toggle="tab" href="#mother-support" role="tab" aria-controls="mother-support" aria-selected="true" >Mother Support Group</a>
                                                </li>
                                            ) : (
                                                    localStorage.CONSULTANT_SETTING == "PRESCHOOL" ? (
                                                        <li class="nav-item">
                                                            <a class="nav-link" id="pre-school-tab" data-toggle="tab" href="#pre-school" role="tab" aria-controls="pre-school" aria-selected="true" >Pre- School</a>
                                                        </li>
                                                    ) : (
                                                            localStorage.CONSULTANT_SETTING == "SCHOOL" ? (
                                                                <li className="nav-item">
                                                                    <a className="nav-link" id="school-tab" data-toggle="tab" href="#school" role="tab" aria-controls="school" aria-selected="false">School</a>
                                                                </li>
                                                            ) : (
                                                                    localStorage.CONSULTANT_SETTING == "WORKPLACE" ? (
                                                                        <li className="nav-item">
                                                                            <a className="nav-link" id="workPlace-tab" data-toggle="tab" href="#workPlace" role="tab" aria-controls="workPlace" aria-selected="false">Work Place</a>
                                                                        </li>
                                                                    ) : (
                                                                            localStorage.CONSULTANT_SETTING == "HOSPITAL" ? (
                                                                                <li className="nav-item">
                                                                                    <a className="nav-link" id="hospital-tab" data-toggle="tab" href="#hospital" role="tab" aria-controls="hospital" aria-selected="false"> Hospital </a>
                                                                                </li>
                                                                            ) : (
                                                                                    localStorage.CONSULTANT_SETTING == "VILLAGE" ? (
                                                                                        <li className="nav-item">
                                                                                            <a className="nav-link" id="village-tab" data-toggle="tab" href="#village" role="tab" aria-controls="village" aria-selected="false">Village</a>
                                                                                        </li>
                                                                                    ) : (
                                                                                            null
                                                                                        )
                                                                                )
                                                                        )
                                                                )
                                                        )
                                                )
                                        ) : (
                                                [
                                                    <li class="nav-item" onClick={() => this.loadSettingTab("MOTHERSUPPORT")}>
                                                        <a class="nav-link active" id="mother-support-tab" data-toggle="tab" href="#mother-support" role="tab" aria-controls="mother-support" aria-selected="true" >Mother Support Group</a>
                                                    </li>,
                                                    <li class="nav-item" onClick={() => this.loadSettingTab("PRESCHOOL")}>
                                                        <a class="nav-link" id="pre-school-tab" data-toggle="tab" href="#pre-school" role="tab" aria-controls="pre-school" aria-selected="true" >Pre- School</a>
                                                    </li>,
                                                    <li className="nav-item" onClick={() => this.loadSettingTab("SCHOOL")}>
                                                        <a className="nav-link" id="school-tab" data-toggle="tab" href="#school" role="tab" aria-controls="school" aria-selected="false">School</a>
                                                    </li>,
                                                    <li className="nav-item" onClick={() => this.loadSettingTab("WORKPLACE")}>
                                                        <a className="nav-link" id="workPlace-tab" data-toggle="tab" href="#workPlace" role="tab" aria-controls="workPlace" aria-selected="false">Work Place</a>
                                                    </li>,
                                                    <li className="nav-item" onClick={() => this.loadSettingTab("HOSPITAL")}>
                                                        <a className="nav-link" id="hospital-tab" data-toggle="tab" href="#hospital" role="tab" aria-controls="hospital" aria-selected="false"> Hospital </a>
                                                    </li>,
                                                    <li className="nav-item" onClick={() => this.loadSettingTab("VILLAGE")}>
                                                        <a className="nav-link" id="village-tab" data-toggle="tab" href="#village" role="tab" aria-controls="village" aria-selected="false">Village</a>
                                                    </li>
                                                ]

                                            )}

                                    </ul>

                                    <div class="tab-content" id="myTabContent">
                                        {localStorage.USER_TYPE == "CONSULTANT" ? (

                                            localStorage.CONSULTANT_SETTING == "MOTHERSUPPORTGROUP" ? (
                                                <div class="tab-pane fade show active" id="mother-support" role="tabpanel" aria-labelledby="mother-support-tab">
                                                    <MotherSupportGroup />
                                                </div>
                                            ) : (
                                                    localStorage.CONSULTANT_SETTING == "PRESCHOOL" ? (
                                                        <div class="tab-pane fade show active" id="pre-school" role="tabpanel" aria-labelledby="pre-school-tab">
                                                            <PreSchool />
                                                        </div>
                                                    ) : (
                                                            localStorage.CONSULTANT_SETTING == "SCHOOL" ? (
                                                                <div className="tab-pane fade show active" id="school" role="tabpanel" aria-labelledby="school-tab">
                                                                    <School />
                                                                </div>
                                                            ) : (
                                                                    localStorage.CONSULTANT_SETTING == "WORKPLACE" ? (
                                                                        <div className="tab-pane fade show active" id="workPlace" role="tabpanel" aria-labelledby="workPlace-tab">
                                                                            <WorkPlace />
                                                                        </div>
                                                                    ) : (
                                                                            localStorage.CONSULTANT_SETTING == "HOSPITAL" ? (
                                                                                <div className="tab-pane fade show active" id="village" role="tabpanel" aria-labelledby="village-tab">
                                                                                    <Village />
                                                                                </div>
                                                                            ) : (
                                                                                    localStorage.CONSULTANT_SETTING == "VILLAGE" ? (
                                                                                        <div className="tab-pane fade show active" id="hospital" role="tabpanel" aria-labelledby="hospital-tab">
                                                                                            <Hospital />
                                                                                        </div>
                                                                                    ) : (
                                                                                            null
                                                                                        )
                                                                                )
                                                                        )
                                                                )
                                                        )
                                                )
                                        ) : (
                                                [


                                                    <div class="tab-pane fade show active" id="mother-support" role="tabpanel" aria-labelledby="mother-support-tab">
                                                        {this.state.settingTag == "MOTHERSUPPORT" ? (
                                                            <MotherSupportGroup />
                                                        ) : (null)}
                                                    </div>,

                                                    <div class="tab-pane fade" id="pre-school" role="tabpanel" aria-labelledby="pre-school-tab">
                                                        {this.state.settingTag == "PRESCHOOL" ? (
                                                            <PreSchool />
                                                        ) : (null)}

                                                    </div>,

                                                    <div className="tab-pane fade" id="school" role="tabpanel" aria-labelledby="school-tab">
                                                        {this.state.settingTag == "SCHOOL" ? (
                                                            <School />
                                                        ) : (null)}

                                                    </div>,
                                                    <div className="tab-pane fade" id="workPlace" role="tabpanel" aria-labelledby="workPlace-tab">
                                                        {this.state.settingTag == "WORKPLACE" ? (
                                                            <WorkPlace />
                                                        ) : (null)}

                                                    </div>,

                                                    <div className="tab-pane fade" id="village" role="tabpanel" aria-labelledby="village-tab">
                                                        {this.state.settingTag == "VILLAGE" ? (
                                                            <Village />
                                                        ) : (null)}

                                                    </div>,

                                                    <div className="tab-pane fade" id="hospital" role="tabpanel" aria-labelledby="hospital-tab">
                                                        {this.state.settingTag == "HOSPITAL" ? (
                                                            <Hospital />
                                                        ) : (null)}

                                                    </div>]


                                            )}

                                    </div>

                                </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ManageSettings)));


