import React, { Component } from 'react';
import { UPDATE_PASSWORD } from '../../queries/UserQueries';

import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions'

import { Dropdown, Grid, Button, Form } from 'semantic-ui-react';


//Spinner
import Loader from 'react-loader-spinner'


import {
    RESET_PASSWORD_REQUEST
} from '../../queries/CommonQueries'

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class ForgetPassword extends Component {
    constructor(props) {
        super(props);


        this.state = {
            loading: false,
            nic: '',
            resetFirstScreenVisible: true,
            resetSecondScreenVisible: false,
            passcode: "",
            responsePasscode: "",
            userId: "",
            newPasswordScreen: false,
            password: "",
            confirmPassword: "",
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });

        this.setState({ loading: true });

        const { nic, resetFirstScreenVisible, resetSecondScreenVisible } = this.state;

        if (nic == "") {
            this.setState({ loading: false });
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "NIC Required!"
            });
            return;
        } else {
            this.forgetPassword().then(result => {

                console.log(result)

                if (result.status == true) {

                    this.props.setSuccessStatus({
                        status: true,
                        title: "Check your Mailbox and enter here your passcode that you recieved.",
                        message:
                            result.reason
                    });

                    this.setState({
                        loading: false,
                        resetFirstScreenVisible: false,
                        resetSecondScreenVisible: true,
                        responsePasscode: result.passcode,
                        userId: result.userId,
                    });



                } else {
                    this.props.setFormStatus({
                        status: true,
                        title: "Oops!",
                        message:
                            "Wrong NIC. User does not exist."
                    });
                    this.setState({ loading: false });
                }

            }).catch(error => {

                console.log(error);
                this.setState({ loading: false });
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message:
                        "There was an error while trying to Update, Please try again later!"
                });

            });


        }


    };

    forgetPassword = async () => {
        const { nic } = this.state;

        const result = await this.props.client.mutate({
            mutation: RESET_PASSWORD_REQUEST,
            variables: { nic }
        });

        return result.data.resetPasswordRequest;
    }

    handleSubmitPasscode = (event) => {
        event.preventDefault();
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });

        this.setState({ loading: true });

        const { nic, passcode, responsePasscode, userId } = this.state;

        if (passcode == "") {
            this.setState({ loading: false });
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Passcode Required!"
            });
            return;
        } else {

            if (passcode == responsePasscode) {
                this.setState({
                    newPasswordScreen: true,
                    resetFirstScreenVisible: false,
                    resetSecondScreenVisible: false,
                    loading: false,
                });
            } else {
                this.setState({
                    newPasswordScreen: false,
                    loading: false,
                });
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message: "Invalid Passcode. Please try again!"
                });
                return;
            }

        }

    }

    handleSubmitNewPassword = (event) => {
        event.preventDefault();
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });

        this.setState({ loading: true });

        const { userId, password, confirmPassword } = this.state;

        if (password == "" || confirmPassword == "") {
            this.setState({ loading: false });
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Required Password & password confirmation!"
            });
            return;
        } else {
            if (password == confirmPassword) {


                this.updatePassword().then(result => {

                    console.log(result)

                    if (result.token != "" && result.token != undefined && result.token != null) {

                        this.props.setSuccessStatus({
                            status: true,
                            title: "Update password.",
                            message:
                                "New Password updated successfully."
                        });

                        this.setState({
                            loading: false,
                        });



                    } else {
                        this.props.setFormStatus({
                            status: true,
                            title: "Oops!",
                            message:
                                "Password not updated."
                        });
                        this.setState({ loading: false });
                    }

                }).catch(error => {

                    console.log(error);
                    this.setState({ loading: false });
                    this.props.setFormStatus({
                        status: true,
                        title: "Oops!",
                        message:
                            "There was an error while trying to Update, Please try again later!"
                    });

                });

            } else {
                this.setState({ loading: false });
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message: "Passwords do not match. Please check again!"
                });
                return;
            }

        }
    }

    updatePassword = async () => {
        const { userId, password } = this.state;

        const result = await this.props.client.mutate({
            mutation: UPDATE_PASSWORD,
            variables: { userId, password }
        });

        return result.data.updatePassword;
    }

    onLogin = () => {
        this.props.history.push('/');
    }


    render() {
        const { loading, resetFirstScreenVisible, resetSecondScreenVisible, newPasswordScreen } = this.state;

        const { formErrorStatus, formSuccessState } = this.props;

        return (
            <div className="right_col" role="main">
                <div className="row">
                    <div id="" class="col-md-12 col-sm-12 ">
                        <section class="login_content">

                            <Grid columns='two'>
                                <Grid.Row>
                                    <Grid.Column className="login_leftcontent" width={6}>
                                        <h1>Forget Password</h1>

                                        {
                                            resetFirstScreenVisible == true ?
                                                <div>
                                                    <div>
                                                        <label className="floatLeft">Enter your NIC </label><br />
                                                        <input value={this.state.nic} type="text" id="nic" name="nic" className="form-control" placeholder="NIC" onChange={this.handleChange} />
                                                    </div>
                                                    <br />
                                                    {(formErrorStatus.status) ? (
                                                        <div class="ui negative message">

                                                            <div class="header">
                                                                Invalid NIC.
                                                </div>
                                                            <p>{formErrorStatus.message}</p>
                                                        </div>

                                                    ) : ((formSuccessState.status) ? (
                                                        <div class="ui success message">
                                                            <div class="header">
                                                                Check Your Mailbox
                                                            </div>
                                                            <p>{formSuccessState.message}</p>
                                                        </div>
                                                    ) : (''))}
                                                    <div className="ln_solid" />
                                                    <div className="form-group">
                                                        <div className="col-md-12 col-sm-12" style={{ textAlign: "right" }}>
                                                            <button onClick={this.handleSubmit} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                                {loading ? (
                                                                    <Loader
                                                                        type="Oval"
                                                                        color="#2A3F54"
                                                                        height={15}
                                                                        width={40}
                                                                    />
                                                                ) : ("Submit")}
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                                : null
                                        }


                                        {
                                            resetSecondScreenVisible == true ?
                                                <div>
                                                    <div class="ui warning message">
                                                    <div class="header">
                                                                Check the Mailbox
                                                            </div>
                                                            <p>Check your Mailbox and enter here your passcode that you recieved.</p>
                                                        </div>
                                                    <div>
                                                        <label className="floatLeft">Enter the Passcode </label><br />
                                                        <input value={this.state.passcode} type="text" id="passcode" name="passcode" className="form-control" placeholder="passcode" onChange={this.handleChange} />
                                                    </div>
                                                    <br />
                                                    <div className="ln_solid" />
                                                    <br />
                                                    {(formErrorStatus.status) ? (
                                                        <div class="ui negative message">

                                                            <div class="header">
                                                                Invalid Passcode.
                                                </div>
                                                            <p>{formErrorStatus.message}</p>
                                                        </div>

                                                    ) : (null)}
                                                    <div className="form-group">
                                                        <div className="col-md-12 col-sm-12" style={{ textAlign: "right" }}>
                                                            <button onClick={this.handleSubmitPasscode} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                                {loading ? (
                                                                    <Loader
                                                                        type="Oval"
                                                                        color="#2A3F54"
                                                                        height={15}
                                                                        width={40}
                                                                    />
                                                                ) : ("Submit")}
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                                : null
                                        }



                                        {
                                            newPasswordScreen == true ?
                                                <div>
                                                    <div>
                                                        <label className="floatLeft">Enter new password </label><br />
                                                        <input value={this.state.password} type="text" id="password" name="password" className="form-control" placeholder="password" onChange={this.handleChange} />
                                                    </div>
                                                    <br />
                                                    <div>
                                                        <label className="floatLeft">Confirm password </label><br />
                                                        <input value={this.state.confirmPassword} type="text" id="confirmPassword" name="confirmPassword" className="form-control" placeholder="confirm password" onChange={this.handleChange} />
                                                    </div>
                                                    <div className="ln_solid" />
                                                    <br />
                                                    {(formErrorStatus.status) ? (
                                                        <div class="ui negative message">

                                                            <div class="header">
                                                                Password not updated.
                                                </div>
                                                            <p>{formErrorStatus.message}</p>
                                                        </div>

                                                    ) : ((formSuccessState.status) ? (
                                                        <div class="ui success message">
                                                            <div class="header">
                                                                Update Password.
                                                            </div>
                                                            <p>{formSuccessState.message}</p>
                                                            <a style={{ color: "#0077cc" }} onClick={this.onLogin} className="to_register link">Click here to login with new password. </a>
                                                        </div>
                                                    ) : (''))}
                                                    <div className="form-group">
                                                        <div className="col-md-12 col-sm-12" style={{ textAlign: "right" }}>
                                                            <button onClick={this.handleSubmitNewPassword} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                                {loading ? (
                                                                    <Loader
                                                                        type="Oval"
                                                                        color="#2A3F54"
                                                                        height={15}
                                                                        width={40}
                                                                    />
                                                                ) : ("Submit")}
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                                : null
                                        }





                                    </Grid.Column>


                                </Grid.Row>
                            </Grid>



                        </section>
                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)));