import React, { Component } from 'react'
import { EDIT_BASIC_PROFILE, GET_All_USERS, DELETE_USER, SEARCH_USERS } from '../../queries/UserQueries'

import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions'
import moment from 'moment';
//ui
import { Button, Grid, Modal, Dropdown, Icon } from 'semantic-ui-react';
import ScrollContainer from 'react-indiana-drag-scroll';

import Loader from 'react-loader-spinner'

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}


class ManageUsers extends Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        }
        this.state = {
            loading: false,
            pagination: {
                userType: 'USER',
                skip: 0,
                first: 50
            },
            users: [],
            deleteId: "",
            openDelete: false,
            currPage: 0,
            totalPages: 0,
            count: 0,
            items: [],
            searchTerm: "",
        }
    }

    componentDidMount() {
        console.log(this.state.firstName)
        this.getAllUsers();
    }

    getAllUsers(pagination) {
        this.setState({ loading: true });
        this.get_all_users(pagination).then(result => {
            console.log(result.users)

            const pages = Math.ceil(result.count / 50);

            if (pages === 0) return null;

            var items = [];

            for (var index = 0; index < pages; index++) {
                items[index] = index - 1;
            }

            this.setState({
                users: result.users, count: result.count, loading: false, totalPages: parseInt(result.count / 50) + 1,
                items: items,
            })
        });
    }



    handlePagination = async (pageNo) => {

        console.log(pageNo)

        if (pageNo < 0) {
            console.log(1)
            return false;
        } else if (pageNo == 0) {
            console.log(2)
            this.setState({ currPage: pageNo })

            this.getAllUsers(this.state.pagination);

        } else if (pageNo > 0) {

            if (this.state.totalPages < pageNo + 1) {
                console.log(3)
                return false;
            } else {
                console.log(4)
                this.setState({ currPage: pageNo })

                var pagination = {
                    skip: pageNo * 50,
                    first: 50,
                    userType: 'USER',
                };

                console.log(pagination)


                this.getAllUsers(pagination);
            }
        }

    }


    get_all_users = async (pagination) => {
        console.log(pagination)
        const result = await this.props.client.query({
            query: GET_All_USERS,
            variables: pagination,
            fetchPolicy: 'network-only'
        });
        console.log(result);
        return result.data.getAllUsers;
    };


    changeUserStatus(id, status) {
        var data = {};
        if (status) {
            data = { userId: id, isActive: false };
        } else {
            data = { userId: id, isActive: true };
        }

        this.setState({ loading: true });

        this.saveUserStatus(data).then(result => {
            this.setState({ loading: false });
            this.getAllUsers();
        }).catch(error => {
            console.log(error);
        });
    }

    saveUserStatus = async (data) => {
        const result = await this.props.client.mutate({
            mutation: EDIT_BASIC_PROFILE,
            variables: data
        });
        return result.data.editBasicProfile
    }

    viewUser = (id) => {
        this.props.history.push("/userProfile?id=" + id);
    }

    onDelete = (id) => {
        this.setState({ deleteId: id, openDelete: true });
    }

    setDeleteUser = () => {
        this.deleteUser().then(result => {
            this.closeDelete();
            this.getAllUsers();
        })
    }

    deleteUser = async () => {
        const id = this.state.deleteId;
        const result = await this.props.client.mutate({
            mutation: DELETE_USER,
            variables: { id }
        });
        return result.data.deleteUser;
    };


    closeDelete = () => this.setState({ openDelete: false })

    goBack = () => {
        this.props.history.goBack();
    };

    searchUsers = async (searchTerm) => {
        console.log(searchTerm)
        const result = await this.props.client.query({
            query: SEARCH_USERS,
            variables: { "searchTerm": searchTerm },
            fetchPolicy: 'network-only'
        });
        console.log(result);
        return result.data.searchUsers;
    };

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    searchUserSUbmit = () => {
        if (this.state.searchTerm != "") {
            this.setState({
                loading: true
            })
            this.searchUsers(this.state.searchTerm).then(result => {
                console.log(result.users)


                const pages = Math.ceil(result.count / 50);

                if (pages === 0) return null;

                var items = [];

                for (var index = 0; index < pages; index++) {
                    items[index] = index - 1;
                }

                this.setState({
                    users: result.users, count: result.count, loading: false, totalPages: parseInt(result.count / 50) + 1,
                    items: items,
                })
            });
        }

    }

    render() {
        const { users, loading } = this.state;
        console.log(users)

        console.log(this.state.totalPages)
        console.log(this.state.items)
        return (
            <div>
                <div className="right_col" role="main">
                    <div className="page-title">
                        <div >
                            <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                        </div>

                    </div>
                    <div className="clearfix"></div>
                    <br />
                    <div className="row">
                        <div className="col-md-12 col-sm-12  ">
                            <div className="x_panel">
                                <div className="">
                                    <h2>Users </h2>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="">
                                    <div className="col-md-4 col-sm-4  ">
                                        <input value={this.state.searchTerm} type="text" id="searchTerm" name="searchTerm" class="form-control" placeholder="Search by Keyword" onChange={this.handleChange} />
                                    </div>
                                    <div className="col-md-4 col-sm-4  ">
                                        <Button type="button" onClick={() => this.searchUserSUbmit()} className="btn btn-primary">
                                            SEARCH
                                    </Button>
                                    </div>



                                </div>

                                <Modal className="middleModal" size="mini" open={this.state.openDelete} onClose={this.closeDelete}>
                                    <Modal.Header> Delete Submission</Modal.Header>
                                    <Modal.Content>
                                        <p>Do you want to delete this user ?</p>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button negative onClick={() => this.closeDelete()}>No</Button>
                                        <Button
                                            positive
                                            onClick={() => this.setDeleteUser()}
                                        >Yes</Button>
                                    </Modal.Actions>
                                </Modal>

                                <div className="x_content">

                                    {loading ? (
                                        <div className="ListLoader">
                                            <Loader
                                                type="ThreeDots"
                                                color="#5A738E"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                    ) : (

                                            <div>
                                                <ScrollContainer>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                {/* <th> # </th> */}
                                                                <th style={{ width: "120px" }}> Name </th>
                                                                <th> NIC </th>
                                                                <th> Telephone </th>
                                                                <th> District </th>
                                                                <th> GN Division </th>
                                                                <th> Gender </th>
                                                                <th> Reg Date </th>
                                                                <th> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                users.map((user, index) => (
                                                                    <tr key={index}>
                                                                        {/* <th scope="row"> {index + 1}</th> */}
                                                                        <td> {user.name} </td>
                                                                        <td> {user.nic} </td>
                                                                        <td> {user.tel} </td>
                                                                        <td> {user.district} </td>
                                                                        <td>  {user.gndivision} </td>
                                                                        <td> {user.gender == "MALE" ? (
                                                                            "Male"
                                                                        ) : (
                                                                                user.gender == "FEMALE" ? (
                                                                                    "Female"
                                                                                ) : (
                                                                                        null
                                                                                    )
                                                                            )} </td>
                                                                        <td>  {moment(user.createdAt).format('YYYY-MM-DD')} </td>
                                                                        <td>
                                                                            <button type="button" onClick={() => this.viewUser(user.id)} className="btn btn-info" > Edit </button>
                                                                        </td>
                                                                        <td>
                                                                            <button type="button" onClick={() => this.onDelete(user.id)} className="btn btn-warning" > Delete </button>
                                                                        </td>

                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </ScrollContainer>
                                                {
                                                    this.state.count > 50 ? (
                                                        <div style={{ textAlign: "center" }}>
                                                            <div aria-label="Pagination Navigation" role="navigation" class="ui pagination menu">

                                                                <a
                                                                    onClick={() => this.handlePagination(0)}
                                                                    aria-current="false"
                                                                    aria-disabled="false"
                                                                    tabindex="0"
                                                                    value="1"
                                                                    type="firstItem"
                                                                    class="icon item"
                                                                >
                                                                    <i aria-hidden="true" class="angle double left icon"></i>
                                                                </a>
                                                                <a
                                                                    onClick={() => this.handlePagination(this.state.currPage - 1)}
                                                                    aria-current="false"
                                                                    aria-disabled="false"
                                                                    tabindex="0"
                                                                    value="1"
                                                                    aria-label="Previous item"
                                                                    type="prevItem"
                                                                    class="item">
                                                                    <i aria-hidden="true" class="angle left icon"></i>
                                                                </a>

                                                                {
                                                                    this.state.items.map((item, i) =>
                                                                        <a
                                                                            onClick={() => this.handlePagination(item + 1)}
                                                                            aria-current="true"
                                                                            aria-disabled="false"
                                                                            tabindex="0"
                                                                            value="1"
                                                                            type="pageItem"
                                                                            class="active item"
                                                                        >{i + 1}</a>
                                                                    )
                                                                }
                                                                <a onClick={() => this.handlePagination(this.state.currPage + 1)} aria-current="false" aria-disabled="false" tabindex="0" value="2" type="pageItem" class="item"> <i aria-hidden="true" class="angle right icon"></i> </a>


                                                                <a
                                                                    onClick={() => this.handlePagination(this.state.totalPages - 1)}
                                                                    aria-current="false"
                                                                    aria-disabled="false"
                                                                    tabindex="0"
                                                                    value="10"
                                                                    type="lastItem"
                                                                    class="icon item"
                                                                >
                                                                    <i aria-hidden="true" class="angle double right icon"></i>
                                                                </a>

                                                            </div>
                                                            <br />
                                                        </div>
                                                    ) : (
                                                            null
                                                        )
                                                }

                                            </div>

                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ManageUsers)));

