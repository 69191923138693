import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus } from '../../actions/user-actions';

//sementic ui
import { Button } from 'semantic-ui-react';

//query
import { LOGIN } from '../../queries/UserQueries';
//Spinner
import Loader from 'react-loader-spinner'


const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        }
    }
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: {
                form_state: 'default',
                title: '',
                message: '',
            },
            nic: "",
            password: "",
            loading: false
        };

        props.setFormStatus({ status: false, title: '', message: '' });

        this.onHandleClick = this.onHandleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSubmit(e) {
        console.log(this.state.nic);
        e.preventDefault();
        this.setState({ loading: true });
        const { nic, password } = this.state;

        if (!nic == '' || !password == '') {
            console.log(this.state);
            this.login().then(result => {

                localStorage.unfpagisauthtoken = result.data.login.token;
                localStorage.USER_ID = result.data.login.user.id;
                localStorage.NIC = result.data.login.user.nic;
                localStorage.NAME = result.data.login.user.name;
                localStorage.USER_TYPE = result.data.login.user.userType;
                localStorage.NOTIFICATIONS = result.data.login.user.notifications;


                if (result.data.login.user.userType == "CONSULTANT") {
                    localStorage.CONSULTANT_SETTING = result.data.login.user.settingType;
                }

                this.setState({ loading: false });

                this.props.history.push("/home")


            })
                .catch(error => {
                    if (error) {
                        console.log(error);
                        console.log('There is an error');
                        this.props.setFormStatus({ status: true, title: 'Oops!', message: 'Your NIC or password appeared to be wrong!' });
                    }
                    this.setState({ loading: false });

                });
        }

        else {
            this.props.setFormStatus({ status: true, title: 'Oops!', message: 'Required All Fields!' });
            this.setState({ nic: "", password: "", loading: false });
            return;
        }


    }

    login = async () => {
        const { nic, password } = this.state;
        console.log(this.props.client)
        const result = this.props.client.mutate(
            {
                mutation: LOGIN,
                variables: { nic, password },
            })
        return result;
    }

    onHandleClick = () => {
        this.props.history.push('/registration');
    }

    onForgetPasswordClick = () => {
        this.props.history.push('/forget-password');
    }

    render() {
        const { loading } = this.state;
        const { formErrorStatus } = this.props;
        return (
            <div class="login">
                <div className="login_wrapper">
                    <div className="animate form login_form">
                        <section className="login_content">
                            <form onSubmit={this.handleSubmit} >
                                <div style={{ textAlign: "center" }}>
                                    <img src={require('../../images/un_icon.png')} width="100" />
                                </div>
                                <h1>Login Form</h1>
                                <div>
                                    <input type="text" name="nic" id="nic" className="form-control" placeholder="NIC" onChange={this.handleChange} required />
                                </div>
                                <div>
                                    <input type="password" name="password" id="password" className="form-control" placeholder="Password" onChange={this.handleChange} required />
                                </div>
                                <div>
                                    <Button className="btn btn-default submit" disabled={loading ? (true) : (false)}>
                                        {loading ? (
                                            <Loader
                                                type="Oval"
                                                color="#2A3F54"
                                                height={15}
                                                width={40}
                                            />
                                        ) : ("Log in")}
                                    </Button>
                                    <a className="reset_pass"> <p className="change_link"> New to site ? &nbsp;&nbsp;
                                    <a style={{ color: "#0077cc" }} onClick={this.onHandleClick} className="to_register link"> Create Account </a>
                                    </p></a>
                                </div>
                                <div className="clearfix" />
                                <div className="separator">
                                    <a style={{ color: "#0077cc" }} onClick={this.onForgetPasswordClick} className="to_register link"> Forget Password? </a>
                                    <br />
                                </div>

                                {(formErrorStatus.status) ? (
                                    <div class="ui negative message">

                                        <div class="header">
                                            Login Problem.
                                                </div>
                                        <p>{formErrorStatus.message}</p>
                                    </div>

                                ) : (null)}
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Login))); 
