import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions';


//ui
import { Button, Grid, Dropdown, TextArea, Input, Popup, Message, Icon } from 'semantic-ui-react';

//Google map
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

//Spinner
import Loader from 'react-loader-spinner'

//firebase
import { uploadFile, deleteFile } from "../../firebase/FileServices";

//Image Uploader
import Images from '../../components/Image/Images'


import {
    ADD_NEWS, GET_SINGLE_NEWS, EDIT_NEWS
} from '../../queries/NewsQueries'


//DatePicker
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

//middleware
import { formatDate } from '../../middleware/index';

//ImageZoomer
import ImageZoom from 'react-medium-image-zoom'

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class AddNews extends Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        var currentLocation = window.location.href;
        var url = currentLocation.split("?")
        url = url[0];
        url = url.split("/");
        var page = url[url.length - 1];

        var pageFormat = "";
        var newsId = "";

        if (page == "editnews") {
            pageFormat = "EDIT"
            newsId = id
        } else {
            pageFormat = "ADD"
        }

        this.state = {
            title: "",
            expireAt: "",
            description: "",
            status: true,
            imageUrl: null,
            tempFile: null,
            isUploading: false,
            fileRef: null,
            uploads: [],

            images: [],
            loading: false,
            formData: [],
            readFiles: [],
            initialize: false,
            pageFormat: pageFormat,
            newsId: newsId,

        };

        props.setFormStatus({ status: false, title: '', message: '' });

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });


    }

    componentDidMount() {
        if (this.state.pageFormat == "EDIT") {
            this.loadSingleNews();
        }

    }

    loadSingleNews() {
        this.setState({ initialize: true });
        this.getSingleNews().then(result => {
            this.setState({
                title: result.news.title,
                description: result.news.description,
                expireAt: result.news.expireAt,
                images: result.news.images,
                initialize: false
            });
        })
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    handleTimeChange = (event, { name, value }) => {
        this.setState({ "expireAt": value });
    }


    handleSubmit() {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        const { title, description, pageFormat } = this.state;

        if (title == "") {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Title Required!"
            });
            return;
        } else if (description == "") {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Description Required!"
            });
            return;
        } else {
            this.setState({ loading: true });
            this.props.setFormStatus({ status: false, title: '', message: '' });
            this.props.setSuccessStatus({ status: false, title: '', message: '' });

            if (pageFormat == "ADD") {
                console.log("-----------------")
                this.addNews().then(result => {
                    console.log(result);
                    this.setState({
                        description: "",
                        expireAt: "",
                        loading: false,
                        form_state: 'default',
                        title: "",
                        message: "",
                    });

                    this.props.setSuccessStatus({
                        status: true,
                        title: "",
                        message:
                            "News added Successfully!.."
                    });
                }).catch(error => {
                    console.log(error);
                    if (error) {
                        this.setState({ loading: false });
                        this.props.setFormStatus({
                            status: true,
                            title: "Oops!",
                            message:
                                "There was an error while trying to add news."

                        });
                    }
                });

            } else if (pageFormat == "EDIT") {
                this.editNews().then(result => {
                    console.log(result);
                    this.setState({ loading: false });
                    this.loadSingleNews();

                    this.props.setSuccessStatus({
                        status: true,
                        title: "",
                        message:
                            "News edited Successfully!.."
                    });

                }).catch(error => {
                    console.log(error);
                    if (error) {
                        this.setState({ loading: false });
                        this.props.setFormStatus({
                            status: true,
                            title: "Oops!",
                            message:
                                "There was an error while trying to edit news."

                        });
                    }
                });
            }

        }



    }

    addNews = async () => {
        const {
            title,
            description,
            expireAt,
            images,
            status
        } = this.state;

        const result = await this.props.client.mutate({
            mutation: ADD_NEWS,
            variables: { title, description, expireAt, images, status }
        });
        console.log(result)
        return result.data.addNews;
    };

    editNews = async () => {

        const {
            title,
            description,
            expireAt,
            images
        } = this.state;

        var id = this.state.newsId;

        const result = await this.props.client.mutate({
            mutation: EDIT_NEWS,
            variables: { id, title, description, expireAt, images }
        });

        console.log(result)
        return result.data.editNews;
    };

    getSingleNews = async () => {
        const id = this.state.newsId;

        const result = await this.props.client.query({
            query: GET_SINGLE_NEWS,
            variables: { id },
            fetchPolicy: 'network-only'
        });
        return result.data.getSingleNews;
    }

    handleFileAddChange = event => {
        var formData = this.state.formData;
        var readFiles = this.state.readFiles;

        formData.push(event.target.files[0]);
        readFiles.push(URL.createObjectURL(event.target.files[0]));

        this.setState({
            formData: formData,
            readFiles: readFiles
        });

    }

    handleFileRemoveChange = (i) => {
        var formData = this.state.formData;
        var readFiles = this.state.readFiles;

        formData.splice(i, 1);
        readFiles.splice(i, 1);

        this.setState({
            formData: formData,
            readFiles: readFiles
        });

    }

    fileUploadOnClick = event => {
        this.setState({
            isUploading: true,
        });

        if (this.state.formData.length > 0) {
            this.state.formData.map((file, key) => {
                this._startUpload(file);
            })
        }

    };

    _startUpload = async file => {
        var intDate = Number(new Date());
        var images = this.state.images;

        const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
        if (result.status) {
            images.push(result.url);
            this.setState({
                isUploading: false,
                fileRef: 'uploads/' + localStorage.FIRST_NAME + intDate + '/' + file.name,
                images: images,
                readFiles: []
            });
        }
    };

    _deleteFile = async (path, i) => {
        var images = this.state.images;
        const deleteRef = await deleteFile(path);
        images.splice(i, 1);
        this.setState({
            images: images
        });
        return deleteRef;
    }

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        const { loading, description, title, initialize } = this.state;
        const { formErrorStatus, formSuccessState } = this.props;

        console.log(this.state.formData)
        console.log(this.state.readFiles)
        console.log(this.state.images)

        return (
            <div className="right_col" role="main">
                <div >
                    <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                </div>
                <div class="page-title">
                    <div class="title_left">
                        <h3>
                            {this.state.pageFormat == "EDIT" ? (
                                "Edit News"
                            ) : (
                                    "Add News"
                                )}
                        </h3>
                    </div>
                </div>
                <div class="clearfix"></div>

                <div className="row">
                    <div className="col-md-12 ">
                        <div className="x_panel">
                            <div className="x_title">
                                <h2> News </h2>

                                <div className="clearfix" />
                            </div>
                            <div className="x_content">
                                <br />

                                {this.state.initialize ? (
                                    null
                                ) : (
                                        <div className="row">
                                            <div className="col-md-8 ">
                                                <form className="form-horizontal form-label-left">

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Title </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input type="text" id="title" name="title" value={this.state.title} placeholder="Title" onChange={this.handleChange} className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div class="form-group row ">
                                                        <label class="control-label col-md-3 col-sm-3 " for="number" > Description </label>
                                                        <div class="col-md-9 col-sm-9">
                                                            <TextArea style={{ width: "100%" }} id="description" name="description" value={this.state.description} onChange={this.handleChange} placeholder='Description' rows={6} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Expire Date : </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <SemanticDatepicker
                                                                minDate={new Date()}
                                                                style={{ width: "100%" }}
                                                                label=""
                                                                id="expireAt"
                                                                name="expireAt"
                                                                placeholder="Expire Date"
                                                                dateFormat="YYYY-MM-DD"
                                                                value={
                                                                    this.state.expireDate != undefined && this.state.expireDate != null && this.state.expireDate != "" ? (
                                                                        new Date(this.state.expireAt)
                                                                    ) : ('')

                                                                }
                                                                iconPosition="left"
                                                                onChange={this.handleTimeChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div class="form-group row ">
                                                        <label class="control-label col-md-3 col-sm-3 " for="number" >Upload Images </label>
                                                        <div class="col-md-9 col-sm-9">
                                                            <div>
                                                                <Grid columns={2}>
                                                                    <Grid.Column textAlign="left">
                                                                        <Input
                                                                            onChange={this.handleFileAddChange}
                                                                            name="upload"
                                                                            id="upload"
                                                                            style={{ width: "250px" }}
                                                                            value={this.state.uploads}
                                                                            placeholder="Product Image"
                                                                            type="file"
                                                                            required
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column textAlign="right">
                                                                        <button onClick={() => this.fileUploadOnClick()} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                                            {loading ? (
                                                                                <Loader
                                                                                    type="Oval"
                                                                                    color="#2A3F54"
                                                                                    height={15}
                                                                                    width={40}
                                                                                />
                                                                            ) : ("Click here to Upload")}
                                                                        </button>

                                                                    </Grid.Column>
                                                                </Grid>
                                                            </div>

                                                            <div>
                                                                {this.state.isUploading ? (
                                                                    <Message icon>
                                                                        <Icon name="circle notched" loading />
                                                                        <Message.Content>
                                                                            <Message.Header>Just one second</Message.Header>
                                                                            We are uploading your document
                                                                </Message.Content>
                                                                    </Message>
                                                                ) : (
                                                                        ""
                                                                    )}
                                                            </div>

                                                            <div>
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column>
                                                                            {this.state.images.map((image, key) => {
                                                                                return (
                                                                                    <div class="img_wrp">
                                                                                        <ImageZoom
                                                                                            image={{
                                                                                                src: image,
                                                                                                className: 'img',
                                                                                                style: { width: "150px", height: "150px", "padding": "15px" }
                                                                                            }}
                                                                                            zoomImage={{
                                                                                                src: image,
                                                                                            }}
                                                                                        />

                                                                                        <span onClick={() => this._deleteFile(image, key)} class="close">&times;</span>
                                                                                    </div>

                                                                                );
                                                                            })}

                                                                            {this.state.readFiles.map((file, key) => {
                                                                                return (
                                                                                    <div class="img_wrp">
                                                                                        <ImageZoom
                                                                                            image={{
                                                                                                src: file,
                                                                                                className: 'img',
                                                                                                style: { width: "150px", height: "150px", "padding": "15px" }
                                                                                            }}
                                                                                            zoomImage={{
                                                                                                src: file,
                                                                                            }}
                                                                                        />

                                                                                        <span onClick={() => this.handleFileRemoveChange(key)} class="close">&times;</span>
                                                                                    </div>

                                                                                );
                                                                            })}

                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {(formErrorStatus.status) ? (
                                                        <div class="ui negative message">

                                                            <div class="header">
                                                                Not Submitted.
                                                </div>
                                                            <p>{formErrorStatus.message}</p>
                                                        </div>

                                                    ) : ((formSuccessState.status) ? (
                                                        <div class="ui success message">
                                                            <div class="header">
                                                                Submitted successfully.
                                                            </div>
                                                            <p>{formSuccessState.message}</p>
                                                        </div>
                                                    ) : (''))}

                                                    <div className="ln_solid" />
                                                    <div className="form-group">
                                                        <div className="col-md-9 col-sm-9  offset-md-3">

                                                            <button onClick={() => this.handleSubmit()} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                                {loading ? (
                                                                    <Loader
                                                                        type="Oval"
                                                                        color="#2A3F54"
                                                                        height={15}
                                                                        width={40}
                                                                    />
                                                                ) : ("Submit")}
                                                            </button>

                                                        </div>
                                                    </div>

                                                </form>
                                            </div>

                                        </div>

                                    )}


                            </div>
                        </div>

                    </div>


                </div>
            </div>

        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(AddNews)));
