import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions';

import { GET_SINGLE_NEWS } from '../../queries/NewsQueries'

//sementic ui
import { Grid, Button, Modal, Icon } from 'semantic-ui-react';

import Loader from 'react-loader-spinner'
import { formatDate } from '../../middleware/index';

//ImageZoomer
import ImageZoom from 'react-medium-image-zoom'

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}


class ViewNews extends Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        this.state = {
            id: id,
            title: "",
            description: "",
            expireDate: "",
            images: [],
            addedUserName: "",
            addedUserType: "",
            loading: true,
            submissionStatus: "",
            deleteId: "",
            openDelete: false
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.loadSingleNews();
    }

    loadSingleNews() {
        this.setState({ initialize: true });
        this.getSingleNews().then(result => {

            console.log(result)
            this.setState({
                title: result.news.title,
                description: result.news.description,
                expireDate: result.news.expireAt,
                images: result.news.images,
                addedUserName: result.news.userName,
                addedUserType: result.news.userType,
                loading: false
            });
        })
    }

    getSingleNews = async () => {
        const id = this.state.id;
        const result = await this.props.client.query({
            query: GET_SINGLE_NEWS,
            variables: { id },
            fetchPolicy: 'network-only'
        });
        return result.data.getSingleNews;
    }

    goBack = () => {
        this.props.history.goBack();
    };

    render() {

        const { loading } = this.state;

        return (

            <div className="right_col" role="main">

                <div className="page-title">

                </div>
                <div className="clearfix"></div>
                <div className="col-md-12 col-sm-12">
                    <div >
                        <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                    </div><br /><br />
                    <div className="x_panel">
                        <div className="x_title">
                            <h2> News </h2>
                            <div className="clearfix"></div>
                        </div>
                        <div class="x_content">



                            {loading ? (
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div className="ListLoader">
                                                <Loader
                                                    type="ThreeDots"
                                                    color="#5A738E"
                                                    height={100}
                                                    width={100}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            ) : (
                                    <Grid>
                                        <Grid.Row columns={2} className="viewSubRow">
                                            <Grid.Column width={4}>
                                                <p className="flat"> <strong> Title </strong> </p>
                                            </Grid.Column>
                                            <Grid.Column width={9}>
                                                <p className="flat"> {this.state.title}  </p>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={2} className="viewSubRow">
                                            <Grid.Column width={4}>
                                                <p className="flat"> <strong> Description </strong> </p>
                                            </Grid.Column>
                                            <Grid.Column width={9}>
                                                <p className="flat"> {this.state.description}  </p>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={2} className="viewSubRow">
                                            <Grid.Column width={4}>
                                                <p className="flat"> <strong> Expire Date </strong> </p>
                                            </Grid.Column>
                                            <Grid.Column width={9}>
                                                <p className="flat"> {
                                                    this.state.expireDate != undefined && this.state.expireDate != null && this.state.expireDate != "" ? (
                                                        formatDate(new Date(this.state.expireDate))
                                                    ) : ('')}  </p>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={2} className="viewSubRow">
                                            <Grid.Column width={4}>
                                                <p className="flat"> <strong> Added User </strong> </p>
                                            </Grid.Column>
                                            <Grid.Column width={9}>
                                                <p className="flat"> {this.state.addedUserName}  </p>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={2} className="viewSubRow">
                                            <Grid.Column width={4}>
                                                <p className="flat"> <strong> Added User Type </strong> </p>
                                            </Grid.Column>
                                            <Grid.Column width={9}>
                                                <p className="flat"> {this.state.addedUserType}  </p>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column>
                                                {
                                                    this.state.images != undefined ? (
                                                        this.state.images.map((image, key) => {
                                                            return (
                                                                <div class="img_wrp">
                                                                    <ImageZoom
                                                                        image={{
                                                                            src: image,
                                                                            className: 'img',
                                                                            style: { width: "150px", height: "150px", "padding": "15px" }
                                                                        }}
                                                                        zoomImage={{
                                                                            src: image,
                                                                        }}
                                                                    />

                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                            null
                                                        )

                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>

                                )}



                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ViewNews))); 
