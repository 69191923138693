import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

//query
import { LOGIN } from '../../../queries/UserQueries';

//ui
import { Button, Grid, Dropdown, TextArea, Input, Message, Icon, Segment } from 'semantic-ui-react';

//Google map
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

//Spinner
import Loader from 'react-loader-spinner'

//firebase
import { uploadFile, deleteFile } from "../../../firebase/FileServices";

//queries
import {
    GET_ALL_PROVINCES, GET_DISTRICTS_FOR_PROVINCES, GET_DIVISIONS_FOR_MOHAREA, GET_ALL_DISTRICTS, GET_AREA_DETAILS, GET_MOHAREAS_FOR_DISTRICT
} from '../../../queries/CommonQueries'
import {
    GET_ALL_SETTINGS, ADD_PRESCHOOL_SETTING_SUBMISSION, GET_SINGLE_PRESCHOOL_SETTING_SUBMISSION, EDIT_PRESCHOOL_SETTING_SUBMISSION
} from '../../../queries/SettingQueries'


//DatePicker
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

//middleware
import { formatDate } from '../../../middleware/index';

//location
import { geolocated } from "react-geolocated";

//ImageZoomer
import ImageZoom from 'react-medium-image-zoom'

const apiKey = "AIzaSyAa4iktQ8XNg1TJDk_CPptIgzZWmBQm7bM"

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class AddNewPreSchoolSetting extends Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        var currentLocation = window.location.href;
        var url = currentLocation.split("?")
        url = url[0];
        url = url.split("/");
        var page = url[url.length - 1];

        var pageFormat = "";
        var settingSubmissionId = "";

        if (page == "editpreschoolsubmission") {
            pageFormat = "EDIT"
            settingSubmissionId = id
        } else {
            pageFormat = "ADD"
        }

        this.state = {
            marker:
            {
                title: "The marker`s title will appear as a tooltip.",
                name: "COLOMBO",
                position: { lat: 6.93194, lng: 79.84778 }
            },
            Location: {},
            loading: false,
            pageFormat: pageFormat,
            settingSubmissionId: settingSubmissionId,
            form_state: 'default',
            title: "",
            message: "",
            province: "",
            district: "",
            gndivision: "",
            mohArea: "",
            provinceId: "",
            districtId: "",
            gndivisionId: "",
            mohAreaId: "",
            phmAreaId: "",
            lat: "",
            lon: "",
            provinceMachineName: "",
            districtMachineName: "",
            gndivisionMachineName: "",
            provinces: [],
            districts: [],
            divisions: [],
            mohareas: [],
            phmArea: "",
            registrationNumber: "",
            nameOfPreschool: "",
            address: "",
            placeOfRegistration: "",
            nameOfFacilitator: "",
            designationOfFacilitator: "",
            nameOfECCDOfficer: "",
            numOfPreschoolChildren: 0,
            numOfTeachers: 0,
            startingDate: "",
            marksAtInitialAssessment: [],
            marksAtSubsequentAssessments: [],
            conductedActivities: [],
            involvementActivities: [],
            comments: "",
            status: 'PENDING',
            settingId: "",
            allowClick: true,
            imageUrl: null,
            tempFile: null,
            isUploading: false,
            fileRef: null,
            uploads: [],
            images: [],
            loading: false,
            formData: [],
            readFiles: [],
            initialize: false,
            typeOfActivities: [
                { key: 1, text: 'Awareness Programme', value: 'Awareness Programme' },
                { key: 2, text: 'Activities related to mental and spiritual wellbeing', value: 'Activities related to mental and spiritual wellbeing' },
                { key: 3, text: 'Celebration of special days', value: 'Celebration of special days' },
                { key: 4, text: 'Events that promote healthy foods', value: 'Events that promote healthy foods' },
                { key: 5, text: 'Environment friendly activities', value: 'Environment friendly activities' },
                { key: 6, text: 'Other activities involving preschool community', value: 'Other activities involving preschool community' },
            ],
            typeOneActivities: [
                { text: 'Safe and healthy environment', value: 'Safe and healthy environment' },
                { text: 'Food and Nutrition', value: 'Food and Nutrition' },
                { text: 'Early childhood development stimulation', value: 'Early childhood development stimulation' },
                { text: 'Oral health', value: 'Oral health' },
                { text: 'Safe road use – following road rules and minimizing accidents', value: 'Safe road use – following road rules and minimizing accidents' },
                { text: 'Basic hygiene', value: 'Basic hygiene' },
                { text: 'Child protection and prevention of child abuse', value: 'Child protection and prevention of child abuse' },
                { text: 'Prevention of domestic violence its  Impact to the child', value: 'Prevention of domestic violence its  Impact to the child' },
                { text: 'Prevention of alcoholism / drug abuse and their impact on the child.', value: 'Prevention of alcoholism / drug abuse and their impact on the child.' },
                { text: 'Skill based participatory health education programme ', value: 'Skill based participatory health education programme ' },
                { text: 'Other', value: 'Other' }
            ],
            typeTwoActivities: [
                { text: 'Religious activities', value: 'Religious activities ' },
                { text: 'Participation in charity work and social welfare activities.', value: 'Participation in charity work and social welfare activities.' },
                { text: 'Trips', value: 'Trips' }
            ],
            typeThreeActivities: [],
            typeFourActivities: [
                { text: 'Birthday celebrations', value: 'Birthday celebrations' },
                { text: 'Concert', value: 'Concert' },
                { text: 'Sports meet', value: 'Sports meet' },
                { text: 'Healthy food demonstrations', value: 'Healthy food demonstrations' },
                { text: 'Other', value: 'Other' }
            ],
            typeFiveActivities: [
                { text: 'Tree planting activities', value: 'Tree planting activities' },
                { text: 'Home gardening', value: 'Home gardening' }
            ],
            typeSixActivities: [],
            acivities: [],
            typeOfActivity: '',
            typeOfActivityNum: '',
            activity: '',
            health: [],
            nonHealthformalSectors: [],
            promotionPlatforms: [],
            healthList: [
                { key: 'PHM', text: 'PHM', value: 'PHM' },
                { key: 'PHI', text: 'PHI', value: 'PHI' },
                { key: 'SDT', text: 'SDT', value: 'SDT' },
                { key: 'MOH', text: 'MOH', value: 'MOH' },
                { key: 'HEO', text: 'HEO', value: 'HEO' },
                { key: 'Other', text: 'Other', value: 'Other' }
            ],
            nonHealthformalSectorsList: [
                { key: 'Water supply board', text: 'Water supply board', value: 'Water supply board' },
                { key: 'Divisional Secretariat', text: 'Divisional Secretariat', value: 'Divisional Secretariat' },
                { key: 'Grama Niladhari', text: 'Grama Niladhari', value: 'Grama Niladhari' },
                { key: 'Other', text: 'Other', value: 'Other' }
            ],
            promotionPlatformsList: [
                { key: 'Happy village committee', text: 'Happy village committee', value: 'Happy village committee' },
                { key: 'Mother Suppoert Group', text: 'Mother Suppoert Group', value: 'Mother Suppoert Group' },
            ]

        };

        props.setFormStatus({ status: false, title: '', message: '' });

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMapClick = this.onMapClick.bind(this);

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });

        if (this.state.pageFormat == "ADD") {
            this.getLocation();
        }

    }

    getLocation() {
        var msg;

        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(success);

            var self = this;

            function success(pos) {
                var lng = pos.coords.longitude;
                var lat = pos.coords.latitude;

                console.log(lng)
                console.log(lat)

                self.setState({
                    marker:
                    {
                        title: "The marker`s title will appear as a tooltip.",
                        name: "COLOMBO",
                        position: { lat: lat, lng: lng }
                    },
                    lat: lat,
                    lon: lng
                });

            }

        } else {
            msg = "Sorry, looks like your browser doesn't support geolocation";
            alert(msg)
        }

    }

    componentDidMount() {

        

        // read provinces
        this.loadProvinces().then(result => {
            var provinces = result.data.getAllProvinces.map(province => {
                return { key: province.id, text: province.province, value: province.id, id: 'provinceId' }
            });
            provinces.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            provinces.unshift({ key: null, id: "provinceId", text: "Select-Province", value: "Select-Province", disabled: true });

            this.setState({ provinces: provinces });
        })

        this.loadAllDistricts().then(result => {
            var districts = result.data.getAllDistricts.map(district => {
                return { key: district.id, text: district.district, value: district.id, id: 'districtId' }
            });
            districts.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            districts.unshift({ key: null, id: "districtId", text: "Select-District", value: "Select-District", disabled: true });

            this.setState({ districts: districts });
        })

        this.loadSettings().then(result => {
            var settingId = "";
            result.data.getAllSettings.map(setting => {
                if (setting.type == "PRESCHOOL") {
                    settingId = setting.id;
                }
            });

            this.setState({ settingId: settingId });
        })

        if (this.state.pageFormat == "EDIT") {
            this.getSingleSubmission();
        }
    }

    getSingleSubmission() {
        this.setState({ initialize: true });
        this.getSinglePreschoolSettingSubmission().then(result => {
            var images = [];
            if (result.images == undefined || result.images == "" || result.images == null) {
                images = [];
            } else {
                images = result.images;
            }

            if (result.districtId != undefined && result.districtId != "" && result.districtId != null) {
                this.loadMOHAreas(result.districtId).then(result => {
                    console.log(result.data.getDivisionsForMOHArea)
                    var mohareas = result.data.getMOHAreasForDistrict.map(moharea => {
                        return { key: moharea.id, text: moharea.mohArea, value: moharea.id, id: 'mohAreaId' }
                    });
                    mohareas.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
                    mohareas.unshift({ key: null, id: "mohAreaId", text: "Select-MOHArea", value: "Select-MOHArea", disabled: true });
                    this.setState({ mohareas: mohareas });
                })
            }

            if (result.mohAreaId != undefined && result.mohAreaId != "" && result.mohAreaId != null) {
                this.getDivisions(result.mohAreaId);
            }
            this.setState({
                location: result.location,
                province: result.province,
                district: result.district,
                gndivision: result.gndivision,
                mohArea: result.mohArea,
                provinceId: result.provinceId,
                districtId: result.districtId,
                gndivisionId: result.gndivisionId,
                mohAreaId: result.mohAreaId,
                phmAreaId: result.phmAreaId,
                phmArea: result.phmArea,
                lat: result.location.latitude,
                lon: result.location.longitude,
                registrationNumber: result.registrationNumber,
                nameOfPreschool: result.nameOfPreschool,
                address: result.address,
                placeOfRegistration: result.placeOfRegistration,
                nameOfFacilitator: result.nameOfFacilitator,
                designationOfFacilitator: result.designationOfFacilitator,
                nameOfECCDOfficer: result.nameOfECCDOfficer,
                numOfPreschoolChildren: result.numOfPreschoolChildren,
                numOfTeachers: result.numOfTeachers,
                startingDate: new Date(result.startingDate),
                marksAtInitialAssessment: result.marksAtInitialAssessment,
                marksAtSubsequentAssessments: result.marksAtSubsequentAssessments,
                conductedActivities: result.conductedActivities,
                involvementActivities: result.involvementActivities,
                comments: result.comments,
                initialize: false,
                marker:
                {
                    title: "The marker`s title will appear as a tooltip.",
                    name: "COLOMBO",
                    position: { lat: result.location.latitude, lng: result.location.longitude }
                }
            });


        })
    }

    getSinglePreschoolSettingSubmission = async () => {
        const { settingSubmissionId } = this.state;

        console.log(settingSubmissionId)
        const result = await this.props.client.query({
            query: GET_SINGLE_PRESCHOOL_SETTING_SUBMISSION,
            variables: { settingSubmissionId },
            fetchPolicy: 'network-only'
        });
        return result.data.getSinglePreschoolSettingSubmission;
    }


    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    handleHealthOnChange = (e, data) => {
        var involvementActivities = this.state.involvementActivities;

        this.setState({
            involvementActivities: {
                ...this.state.involvementActivities,
                [data.id]: data.value
            }
        });
    }

    handleNonHealthFormalChange = (e, data) => {
        var involvementActivities = this.state.involvementActivities;

        this.setState({
            involvementActivities: {
                ...this.state.involvementActivities,
                nonhealth: {
                    ...this.state.involvementActivities.nonhealth,
                    [data.id]: data.value
                }
            }
        });
    };

    handleNonHealthInformalChange = (event) => {
        var involvementActivities = this.state.involvementActivities;

        this.setState({
            involvementActivities: {
                ...this.state.involvementActivities,
                nonhealth: {
                    ...this.state.involvementActivities.nonhealth,
                    [event.target.id]: event.target.value
                }
            }
        });
    };


    handleInformalChange = (event) => {
        var involvementActivities = this.state.involvementActivities;

        this.setState({
            involvementActivities: {
                ...this.state.involvementActivities,
                [event.target.id]: event.target.value
            }
        });

    };

    // handleHealthOnChange = (e, data) => {
    //     var involvementActivities =this.state.involvementActivities;

    //     this.setState({
    //         involvementActivities: {
    //             ...this.state.involvementActivities,
    //             health: {
    //                 ...this.state.involvementActivities.health,
    //                 [data.id]: data.value
    //             }
    //         }
    //     });
    // }

    handleChangeTypeOfActivity = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);

        var typeOneActivities = this.state.typeOneActivities;
        var typeTwoActivities = this.state.typeTwoActivities;
        var typeThreeActivities = this.state.typeThreeActivities;
        var typeFourActivities = this.state.typeFourActivities;
        var typeFiveActivities = this.state.typeFiveActivities;
        var typeSixActivities = this.state.typeSixActivities;

        this.setState({ activity: '' });

        switch (key) {
            case 1:
                this.setState({ acivities: typeOneActivities, typeOfActivityNum: key, typeOfActivity: value });
                break;
            case 2:
                this.setState({ acivities: typeTwoActivities, typeOfActivityNum: key, typeOfActivity: value });
                break;
            case 3:
                this.setState({ acivities: typeThreeActivities, typeOfActivityNum: key, typeOfActivity: value });
                break;
            case 4:
                this.setState({ acivities: typeFourActivities, typeOfActivityNum: key, typeOfActivity: value });
                break;
            case 5:
                this.setState({ acivities: typeFiveActivities, typeOfActivityNum: key, typeOfActivity: value });
                break;
            case 6:
                this.setState({ acivities: typeSixActivities, typeOfActivityNum: key, typeOfActivity: value });
                break;
            default:
                break;
        }

    }

    handleChangeActivity = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);

        this.setState({ activity: value });
    }

    handleChangeInitialAssessmentMark = (event) => {
        this.setState({
            marksAtInitialAssessment: {
                ...this.state.marksAtInitialAssessment,
                [event.target.id]: event.target.value
            }
        });
    };

    handleChangeInitialAssessmentDate = (event, { id, name, value }) => {
        let formatedDate = formatDate(value);
        this.setState({
            marksAtInitialAssessment: {
                ...this.state.marksAtInitialAssessment,
                [id]: formatedDate
            }
        });
    }

    handleChangeSubsequentAssessmentMark = (event) => {

        switch (event.target.name) {
            case "subsequentSecondAssessment":
                this.setState({
                    marksAtSubsequentAssessments: {
                        ...this.state.marksAtSubsequentAssessments,
                        subsequentSecondAssessment: {
                            ...this.state.marksAtSubsequentAssessments.subsequentSecondAssessment,
                            [event.target.id]: event.target.value
                        }
                    }
                });
                break;
            case "subsequentThirdAssessment":
                this.setState({
                    marksAtSubsequentAssessments: {
                        ...this.state.marksAtSubsequentAssessments,
                        subsequentThirdAssessment: {
                            ...this.state.marksAtSubsequentAssessments.subsequentThirdAssessment,
                            [event.target.id]: event.target.value
                        }
                    }
                });
                break;
            case "subsequentFourthAssessment":
                this.setState({
                    marksAtSubsequentAssessments: {
                        ...this.state.marksAtSubsequentAssessments,
                        subsequentFourthAssessment: {
                            ...this.state.marksAtSubsequentAssessments.subsequentFourthAssessment,
                            [event.target.id]: event.target.value
                        }
                    }
                });
                break;
            case "subsequentFifthAssessment":
                this.setState({
                    marksAtSubsequentAssessments: {
                        ...this.state.marksAtSubsequentAssessments,
                        subsequentFifthAssessment: {
                            ...this.state.marksAtSubsequentAssessments.subsequentFifthAssessment,
                            [event.target.id]: event.target.value
                        }
                    }
                });
                break;
            case "subsequentSixthAssessment":
                this.setState({
                    marksAtSubsequentAssessments: {
                        ...this.state.marksAtSubsequentAssessments,
                        subsequentSixthAssessment: {
                            ...this.state.marksAtSubsequentAssessments.subsequentSixthAssessment,
                            [event.target.id]: event.target.value
                        }
                    }
                });
                break;
            default:
                break;
        }
    };

    handleChangeSubsequentAssessmentDate = (event, { id, name, value }) => {
        let formatedDate = formatDate(value);

        switch (name) {
            case "subsequentSecondAssessment":
                this.setState({
                    marksAtSubsequentAssessments: {
                        ...this.state.marksAtSubsequentAssessments,
                        subsequentSecondAssessment: {
                            ...this.state.marksAtSubsequentAssessments.subsequentSecondAssessment,
                            [id]: formatedDate
                        }
                    }
                });
                break;
            case "subsequentThirdAssessment":
                this.setState({
                    marksAtSubsequentAssessments: {
                        ...this.state.marksAtSubsequentAssessments,
                        subsequentThirdAssessment: {
                            ...this.state.marksAtSubsequentAssessments.subsequentThirdAssessment,
                            [id]: formatedDate
                        }
                    }
                });
                break;
            case "subsequentFourthAssessment":
                this.setState({
                    marksAtSubsequentAssessments: {
                        ...this.state.marksAtSubsequentAssessments,
                        subsequentFourthAssessment: {
                            ...this.state.marksAtSubsequentAssessments.subsequentFourthAssessment,
                            [id]: formatedDate
                        }
                    }
                });
                break;
            case "subsequentFifthAssessment":
                this.setState({
                    marksAtSubsequentAssessments: {
                        ...this.state.marksAtSubsequentAssessments,
                        subsequentFifthAssessment: {
                            ...this.state.marksAtSubsequentAssessments.subsequentFifthAssessment,
                            [id]: formatedDate
                        }
                    }
                });
                break;
            case "subsequentSixthAssessment":
                this.setState({
                    marksAtSubsequentAssessments: {
                        ...this.state.marksAtSubsequentAssessments,
                        subsequentSixthAssessment: {
                            ...this.state.marksAtSubsequentAssessments.subsequentSixthAssessment,
                            [id]: formatedDate
                        }
                    }
                });
                break;
            default:
                break;
        }

    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }


    handleSubmit(event) {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        event.preventDefault();
        const { provinceId, districtId,mohAreaId, lat, lon, pageFormat } = this.state;

        if (provinceId == "") {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Province Required!"
            });
            return;
        } else if (districtId == "") {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "District Required!"
            });
            return;
        } else {

            if (mohAreaId == "") {
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message: "MOH Area Required!"
                });
                return;
            } else {
                
            this.setState({ loading: true });
            this.props.setFormStatus({ status: false, title: '', message: '' });
            this.props.setSuccessStatus({ status: false, title: '', message: '' });

            if (pageFormat == "ADD") {
                this.addPreSchoolSettingSubmission().then(result => {
                    console.log(result);
                    this.setState({
                        Location: {},
                        loading: false,
                        pageFormat: pageFormat,
                        form_state: 'default',
                        title: "",
                        message: "",
                        province: "",
                        district: "",
                        gndivision: "",
                        mohArea: "",
                        provinceId: "",
                        districtId: "",
                        gndivisionId: "",
                        mohAreaId: "",
                        phmAreaId: "",
                        lat: "",
                        lon: "",
                        provinceMachineName: "",
                        districtMachineName: "",
                        gndivisionMachineName: "",
                        provinces: [],
                        districts: [],
                        divisions: [],
                        mohareas: [],
                        phmArea: "",
                        registrationNumber: "",
                        nameOfPreschool: "",
                        address: "",
                        placeOfRegistration: "",
                        nameOfFacilitator: "",
                        designationOfFacilitator: "",
                        nameOfECCDOfficer: "",
                        numOfPreschoolChildren: 0,
                        numOfTeachers: 0,
                        startingDate: "",
                        marksAtInitialAssessment: [],
                        marksAtSubsequentAssessments: [],
                        conductedActivities: [],
                        involvementActivities: [],
                        comments: "",
                        status: 'PENDING',
                        settingId: "",
                        allowClick: true,
                        imageUrl: null,
                        tempFile: null,
                        isUploading: false,
                        fileRef: null,
                        uploads: [],
                        images: [],
                        loading: false,
                        formData: [],
                        readFiles: [],
                        initialize: false,
                        typeSixActivities: [],
                        acivities: [],
                        typeOfActivity: '',
                        typeOfActivityNum: '',
                        activity: '',
                        health: [],
                        nonHealthformalSectors: [],
                        promotionPlatforms: [],
                    });

                    this.props.setSuccessStatus({
                        status: true,
                        title: "",
                        message:
                            "Setting Submitted Successfully!. Please wait for till get admin approve."
                    });
                }).catch(error => {
                    console.log(error);
                    if (error) {
                        this.setState({ loading: false });
                        this.props.setFormStatus({
                            status: true,
                            title: "Oops!",
                            message:
                                "There was an error while trying to submit setting."

                        });
                    }
                });
            } else if (pageFormat == "EDIT") {
                this.editPreSchoolSettingSubmission().then(result => {
                    console.log(result);
                    this.setState({ loading: false });
                    this.getSingleSubmission();

                    this.props.setSuccessStatus({
                        status: true,
                        title: "",
                        message:
                            "Setting edited Successfully!.."
                    });

                }).catch(error => {
                    console.log(error);
                    if (error) {
                        this.setState({ loading: false });
                        this.props.setFormStatus({
                            status: true,
                            title: "Oops!",
                            message:
                                "There was an error while trying to edit setting."

                        });
                    }
                });
            }

            }

        }

    }

    addPreSchoolSettingSubmission = async () => {
        const {
            provinceId,
            province,
            districtId,
            district,
            mohArea,
            mohAreaId,
            phmArea,
            gndivision,
            gndivisionId,
            registrationNumber,
            nameOfPreschool,
            address,
            placeOfRegistration,
            nameOfFacilitator,
            nameOfECCDOfficer,
            designationOfFacilitator,
            startingDate,
            marksAtInitialAssessment,
            marksAtSubsequentAssessments,
            conductedActivities,
            involvementActivities,
            comments,
            status,
            settingId,
        } = this.state;

        var lat = "";   
        var lon = "";

        if(this.state.lat == "" || this.state.lon == "" || this.state.lat == undefined || this.state.lon == undefined){
           lat = "";   
           lon = "";
        }else{
           lat = this.state.lat.toString();    
           lon = this.state.lon.toString(); 
        }

        var numOfPreschoolChildren = parseInt(this.state.numOfPreschoolChildren);
        var numOfTeachers = parseInt(this.state.numOfTeachers);

        const result = await this.props.client.mutate({
            mutation: ADD_PRESCHOOL_SETTING_SUBMISSION,
            variables: {
                provinceId,
                province,
                districtId,
                district,
                mohArea,
                mohAreaId,
                phmArea,
                gndivision, gndivisionId, lat, lon,
                registrationNumber,
                nameOfPreschool,
                address,
                placeOfRegistration,
                nameOfFacilitator,
                designationOfFacilitator,
                nameOfECCDOfficer,
                numOfPreschoolChildren,
                numOfTeachers,
                startingDate,
                marksAtInitialAssessment,
                marksAtSubsequentAssessments,
                conductedActivities,
                involvementActivities, comments, status, settingId
            }
        });
        return result.data.addPreschoolSettingSubmission;
    };

    editPreSchoolSettingSubmission = async () => {
        const {
            settingSubmissionId,
            provinceId,
            province,
            districtId,
            district,
            mohArea,
            mohAreaId,
            phmArea,
            gndivision,
            gndivisionId,
            registrationNumber,
            nameOfPreschool,
            address,
            placeOfRegistration,
            nameOfFacilitator,
            designationOfFacilitator,
            nameOfECCDOfficer,
            startingDate,
            marksAtInitialAssessment,
            marksAtSubsequentAssessments,
            conductedActivities,
            involvementActivities,
            comments,
            status,
            settingId,
        } = this.state;

        var lat = "";   
        var lon = "";

        if(this.state.lat == "" || this.state.lon == "" || this.state.lat == undefined || this.state.lon == undefined){
           lat = "";   
           lon = "";
        }else{
           lat = this.state.lat.toString();    
           lon = this.state.lon.toString(); 
        }


        var numOfPreschoolChildren = parseInt(this.state.numOfPreschoolChildren);
        var numOfTeachers = parseInt(this.state.numOfTeachers);

        const result = await this.props.client.mutate({
            mutation: EDIT_PRESCHOOL_SETTING_SUBMISSION,
            variables: {
                settingSubmissionId,
                provinceId,
                province,
                districtId,
                district,
                mohArea,
                mohAreaId,
                phmArea, gndivision, gndivisionId, lat, lon,
                registrationNumber,
                nameOfPreschool,
                address,
                placeOfRegistration,
                nameOfFacilitator,
                designationOfFacilitator,
                nameOfECCDOfficer,
                numOfPreschoolChildren,
                numOfTeachers,
                startingDate,
                marksAtInitialAssessment,
                marksAtSubsequentAssessments,
                conductedActivities,
                involvementActivities,
                comments, status, settingId
            }
        });
        return result.data.editPreschoolSettingSubmission;
    };

    // load provinces from api
    loadProvinces = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_PROVINCES,
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load all districts from api
    loadAllDistricts = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_DISTRICTS,
            fetchPolicy: "network-only"
        });
        return result;
    };


    // load districts from api
    loadDistricts = async (provinceId) => {
        const result = await this.props.client.query({
            query: GET_DISTRICTS_FOR_PROVINCES,
            variables: { provinceId },
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load divisions from api
    loadDivisions = async (mohId) => {
        const result = await this.props.client.query({
            query: GET_DIVISIONS_FOR_MOHAREA,
            variables: { mohId },
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load mohareas from api
    loadMOHAreas = async (districtId) => {
        const result = await this.props.client.query({
            query: GET_MOHAREAS_FOR_DISTRICT,
            variables: { districtId },
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load settings from api
    loadSettings = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_SETTINGS,
            fetchPolicy: "network-only"
        });
        return result;
    };

    handleProvinceChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "province": text });

        this.loadDistricts(key).then(result => {
            var districts = result.data.getDistrictsForProvince.map(district => {
                return { key: district.id, text: district.district, value: district.id, id: 'districtId' }
            });
            districts.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            districts.unshift({ key: null, id: "districtId", text: "Select-District", value: "Select-District", disabled: true });
            this.setState({ districts: districts });
        })
    }

    handleDistrictChange = (e, data) => {

        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "district": text });
        this.loadMOHAreas(key).then(result => {
            console.log(result.data.getDivisionsForMOHArea)
            var mohareas = result.data.getMOHAreasForDistrict.map(moharea => {
                return { key: moharea.id, text: moharea.mohArea, value: moharea.id, id: 'mohAreaId' }
            });
            mohareas.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            mohareas.unshift({ key: null, id: "mohAreaId", text: "Select-MOHArea", value: "Select-MOHArea", disabled: true });
            this.setState({ mohareas: mohareas });
        })

    }

    handleMOHChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "mohArea": text });
        this.getDivisions(key);
    }

    getDivisions(key) {
        this.loadDivisions(key).then(result => {
            var divisions = result.data.getDivisionsForMOHArea.map(division => {
                return { key: division.id, text: division.gndivision, value: division.id, id: 'gndivisionId' }
            });
            divisions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            divisions.unshift({ key: null, id: "gndivisionId", text: "Select-Division", value: "Select-Division", disabled: true });
            this.setState({ divisions: divisions });
        })
    }

    handleDivisionChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        this.setState({ [data.id]: key, "gndivision": text });

    }

    handleMeetingChange = (e, data) => {
        this.setState({ [data.id]: data.value });
    }

    handleOnCheck = (e, data) => {
        this.setState({ ['gender']: data.value });
    }

    handleTimeChange = (event, { name, value }) => {
        this.setState({ startingDate: value });
    }

    onMapClick(t, map, coord) {
        const { latLng } = coord;
        const lat = latLng.lat().toString();
        const lng = latLng.lng().toString();
        const location = { latitude: lat, longitude: lng };
        this.setState({ lat: lat, lon: lng, allowClick: false });

        this.setState({ marker: { title: "", name: "", position: { lat, lng } } });

        this.getAreaDetails(lat, lng).then(result => {
            var mohareas = result.mohareas.map(moharea => {
                return { key: moharea.id, text: moharea.mohArea, value: moharea.id, id: 'mohAreaId' }
            });
            mohareas.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            mohareas.unshift({ key: null, id: "mohAreaId", text: "Select-MOHArea", value: "Select-MOHArea", disabled: true });

            var divisions = result.divisions.map(division => {
                return { key: division.id, text: division.gndivision, value: division.id, id: 'gndivisionId' }
            });
            divisions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            divisions.unshift({ key: null, id: "gndivisionId", text: "Select-Division", value: "Select-Division", disabled: true });

            this.setState({
                provinceId: result.province.provinceId,
                province: result.province.province,
                districtId: result.district.districtId,
                district: result.district.district,
                divisions: divisions,
                mohareas: mohareas,
                allowClick: true
            });
        }).catch(error => {
            if (error) {
                console.log(error);
                console.log('There is an error');
                this.props.setFormStatus({ status: true, title: 'Oops!', message: 'Error in ' });
            }
            this.setState({ loading: false, allowClick: true });

        });
    }

    getAreaDetails = async (lat, lng) => {
        const lon = lng;

        const result = await this.props.client.query({
            query: GET_AREA_DETAILS,
            variables: { lat, lon },
            fetchPolicy: "network-only"
        });
        return result.data.getAreaDetails;
    };

    handleFileAddChange = event => {
        var formData = this.state.formData;
        var readFiles = this.state.readFiles;

        formData.push(event.target.files[0]);
        readFiles.push(URL.createObjectURL(event.target.files[0]));

        this.setState({
            formData: formData,
            readFiles: readFiles
        });

    }

    handleFileRemoveChange = (i) => {
        var formData = this.state.formData;
        var readFiles = this.state.readFiles;

        formData.splice(i, 1);
        readFiles.splice(i, 1);

        this.setState({
            formData: formData,
            readFiles: readFiles
        });

    }

    fileUploadOnClick = event => {
        this.setState({
            isUploading: true,
        });

        var images = this.state.images;
        var typeOfActivityNum = this.state.typeOfActivityNum;
        var typeOfActivity = this.state.typeOfActivity;
        var activity = this.state.activity;
        var conductedActivities = this.state.conductedActivities;
        var imageList = [];

        if (this.state.formData.length > 0) {
            this.state.formData.map((file, key) => {
                this._startUpload(file).then(result => {
                    imageList.push(result)

                    console.log(this.state.formData.length)
                    console.log(imageList.length)
                    console.log(imageList)

                    if (this.state.formData.length == imageList.length) {
                        conductedActivities.push(
                            {
                                "typeOfActivity": typeOfActivity,
                                "activity": activity,
                                "images": imageList,
                            }
                        );

                        this.setState({
                            conductedActivities: conductedActivities,
                            isUploading: false,
                            images: [],
                            readFiles: [],
                            formData: []
                        });
                    }
                });


            })

        } else {
            this.props.setFormStatus({ status: true, title: 'Oops!', message: 'Please select the images to upload' });
        }

    };

    _startUpload = async file => {
        var intDate = Number(new Date());

        const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
        if (result.status) {
            return result.url;
        }

    };

    _deleteActivity = async (i) => {
        var conductedActivities = this.state.conductedActivities;

        conductedActivities[i].images.map(async (path, key) => {
            await deleteFile(path);
        })

        conductedActivities.splice(i, 1)
        this.setState({
            conductedActivities: conductedActivities
        })
    }

    goBack = () => {
        this.props.history.goBack();
      };

    render() {
        const { loading, marker, provinces, districts, divisions, mohareas, marksAtSubsequentAssessments, involvementActivities, marksAtInitialAssessment } = this.state;
        const { formErrorStatus, formSuccessState } = this.props;


        console.log(this.state.marksAtInitialAssessment);

        return (
            <div className="right_col" role="main">
                <div >
                    <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                </div>
                <div class="page-title">
                    <div class="title_left">
                        <h3> Add Setting </h3>
                    </div>
                </div>
                <div class="clearfix"></div>

                <div className="row">
                    <div className="col-md-12 ">
                        <div className="x_panel">
                            <div className="x_title">
                                <h2> Pre School Submission </h2>

                                <div className="clearfix" />
                            </div>
                            <div className="x_content">
                                <br />

                                <div className="row">
                                    <div className="col-md-6 ">
                                        <form className="form-horizontal form-label-left">
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 ">Select Province </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <Dropdown
                                                        placeholder='Select Province'
                                                        fluid
                                                        selection
                                                        id='provinceId'
                                                        name='provinceId'
                                                        options={provinces}
                                                        value={this.state.provinceId}
                                                        onChange={this.handleProvinceChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 ">District </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <Dropdown
                                                        placeholder='Select District'
                                                        fluid
                                                        selection
                                                        id='districtId'
                                                        name='districtId'
                                                        options={districts}
                                                        value={this.state.districtId}
                                                        onChange={this.handleDistrictChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> MOHArea </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <Dropdown
                                                        placeholder='Select MOHArea'
                                                        fluid
                                                        selection
                                                        id='mohAreaId'
                                                        name='mohAreaId'
                                                        options={mohareas}
                                                        value={this.state.mohAreaId}
                                                        onChange={this.handleMOHChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Gndivision </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <Dropdown
                                                        placeholder='Select Division'
                                                        fluid
                                                        search
                                                        selection
                                                        id='gndivisionId'
                                                        name='gndivisionId'
                                                        options={divisions}
                                                        value={this.state.gndivisionId}
                                                        onChange={this.handleDivisionChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> PHMArea </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <input type="text" id="phmArea" name="phmArea" value={this.state.phmArea} onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </div>

                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Reg. Number </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <input type="text" id="registrationNumber" name="registrationNumber" value={this.state.registrationNumber} onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </div>

                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Name of Pre School </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <input type="text" id="nameOfPreschool" name="nameOfPreschool" value={this.state.nameOfPreschool} onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Address </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <input type="text" id="address" name="address" value={this.state.address} onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Place Of Registration </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <input type="text" id="placeOfRegistration" name="placeOfRegistration" value={this.state.placeOfRegistration} onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Name Of Facilitator </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <input type="text" id="nameOfFacilitator" name="nameOfFacilitator" value={this.state.nameOfFacilitator} onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Designation Of Facilitator </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <input type="text" id="designationOfFacilitator" name="designationOfFacilitator" value={this.state.designationOfFacilitator} onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Name Of ECCD Officer </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <input type="text" id="nameOfECCDOfficer" name="nameOfECCDOfficer" value={this.state.nameOfECCDOfficer} onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Num Of Preschool Children </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <input type="number" id="numOfPreschoolChildren" name="numOfPreschoolChildren" value={this.state.numOfPreschoolChildren} onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Num Of Teachers </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <input type="number" id="numOfTeachers" name="numOfTeachers" value={this.state.numOfTeachers} onChange={this.handleChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Starting Year and Month </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <SemanticDatepicker
                                                        maxDate={new Date()}
                                                        style={{ width: "100%" }}
                                                        label=""
                                                        id="startingDate"
                                                        name="startingDate"
                                                        placeholder="Starting Year and Month"
                                                        value={this.state.startingDate}
                                                        iconPosition="left"
                                                        onChange={this.handleTimeChange}
                                                    />
                                                </div>
                                            </div>

                                            <hr />

                                            <div className="form-group row ">

                                                <label className="control-label col-md-12 col-sm-12 ">
                                                    Marks at initial assessment using observation checklist for "PHPS development"
                                                    by the preschool teacher  </label>

                                                <div className="col-md-6 col-sm-6 ">
                                                    <input type="text" placeholder="Initial Assessment Marks" id="initialAssessmentMarks" name="initialAssessmentMarks"
                                                        value={
                                                            marksAtInitialAssessment != null || marksAtInitialAssessment != undefined ? (
                                                                marksAtInitialAssessment.initialAssessmentMarks != undefined || marksAtInitialAssessment.initialAssessmentMarks != null ?
                                                                    marksAtInitialAssessment.initialAssessmentMarks : ''
                                                            ) : ('')
                                                        }
                                                        onChange={this.handleChangeInitialAssessmentMark} className="form-control" />
                                                </div>

                                                <div className="col-md-6 col-sm-6 ">
                                                    <SemanticDatepicker
                                                        maxDate={new Date()}
                                                        style={{ width: "100%" }}
                                                        label=""
                                                        id="initialAssessmentDate"
                                                        name="initialAssessmentDate"
                                                        placeholder="Initial Assessment Date"
                                                        dateFormat="YYYY-MM-DD"
                                                        value={
                                                            marksAtInitialAssessment != null || marksAtInitialAssessment != undefined ? (
                                                                marksAtInitialAssessment.initialAssessmentDate != undefined || marksAtInitialAssessment.initialAssessmentDate != null ?
                                                                    marksAtInitialAssessment.initialAssessmentDate : ''
                                                            ) : ('')
                                                        }
                                                        iconPosition="left"
                                                        onChange={this.handleChangeInitialAssessmentDate}
                                                    />
                                                </div>

                                            </div>

                                            <div className="form-group row ">

                                                <label className="control-label col-md-12 col-sm-12 ">
                                                    Marks at subsequent assessments (at 6 months intervals) </label>

                                                {/* Start 2nd Assessment */}
                                                <div className="col-md-6 col-sm-6 ">
                                                    <input type="text" placeholder="Subsequent 2nd Assessment Marks" id="subsequentSecondAssessmentMarks" name="subsequentSecondAssessment"
                                                        value={
                                                            marksAtSubsequentAssessments != null || marksAtSubsequentAssessments != undefined ? (
                                                                marksAtSubsequentAssessments.subsequentSecondAssessment == undefined || marksAtSubsequentAssessments.subsequentSecondAssessment.subsequentSecondAssessmentMarks == undefined ?
                                                                '' : marksAtSubsequentAssessments.subsequentSecondAssessment.subsequentSecondAssessmentMarks
                                                            ) : ('')
                                                        }
                                                        onChange={this.handleChangeSubsequentAssessmentMark} className="form-control" />
                                                </div>

                                                <div className="col-md-6 col-sm-6 ">
                                                    <SemanticDatepicker
                                                        maxDate={new Date()}
                                                        style={{ width: "100%" }}
                                                        label=""
                                                        id="subsequentSecondAssessmentDate"
                                                        name="subsequentSecondAssessment"
                                                        placeholder="Subsequent 2nd Assessment Date"
                                                        dateFormat="YYYY-MM-DD"
                                                       
                                                        value={
                                                            marksAtSubsequentAssessments != null || marksAtSubsequentAssessments != undefined ? (
                                                                marksAtSubsequentAssessments.subsequentSecondAssessment == undefined || marksAtSubsequentAssessments.subsequentSecondAssessment.subsequentSecondAssessmentDate == undefined ?
                                                                '' : marksAtSubsequentAssessments.subsequentSecondAssessment.subsequentSecondAssessmentDate
                                                            ) : ('')
                                                        }
                                                        iconPosition="left"
                                                        onChange={this.handleChangeSubsequentAssessmentDate}
                                                    />
                                                </div>
                                                {/* End 2nd Assessment */}

                                                {/* Start 3nd Assessment */}
                                                <div className="col-md-6 col-sm-6 ">
                                                    <input type="text" placeholder="Subsequent 3nd Assessment Marks" id="subsequentThirdAssessmentMarks" name="subsequentThirdAssessment"
                                                    
                                                        value={
                                                            marksAtSubsequentAssessments != null || marksAtSubsequentAssessments != undefined ? (
                                                                marksAtSubsequentAssessments.subsequentThirdAssessment == undefined || marksAtSubsequentAssessments.subsequentThirdAssessment.subsequentThirdAssessmentMarks == undefined ?
                                                                '' : marksAtSubsequentAssessments.subsequentThirdAssessment.subsequentThirdAssessmentMarks
                                                            ) : ('')
                                                        }
                                                        onChange={this.handleChangeSubsequentAssessmentMark} className="form-control" />
                                                </div>

                                                <div className="col-md-6 col-sm-6 ">
                                                    <SemanticDatepicker
                                                        maxDate={new Date()}
                                                        style={{ width: "100%" }}
                                                        label=""
                                                        id="subsequentThirdAssessmentDate"
                                                        name="subsequentThirdAssessment"
                                                        placeholder="Subsequent 3nd Assessment Date"
                                                        dateFormat="YYYY-MM-DD"
                                                        
                                                        value={
                                                            marksAtSubsequentAssessments != null || marksAtSubsequentAssessments != undefined ? (
                                                                marksAtSubsequentAssessments.subsequentThirdAssessment == undefined || marksAtSubsequentAssessments.subsequentThirdAssessment.subsequentThirdAssessmentDate == undefined ?
                                                                '' : marksAtSubsequentAssessments.subsequentThirdAssessment.subsequentThirdAssessmentDate
                                                            ) : ('')
                                                        }
                                                        iconPosition="left"
                                                        onChange={this.handleChangeSubsequentAssessmentDate}
                                                    />
                                                </div>
                                                {/* End 3nd Assessment */}

                                                {/* Start 4nd Assessment */}
                                                <div className="col-md-6 col-sm-6 ">
                                                    <input type="text" placeholder="Subsequent 4nd Assessment Marks" id="subsequentFourthAssessmentMarks" name="subsequentFourthAssessment"
                                                        
                                                        value={
                                                            marksAtSubsequentAssessments != null || marksAtSubsequentAssessments != undefined ? (
                                                                marksAtSubsequentAssessments.subsequentFourthAssessment == undefined || marksAtSubsequentAssessments.subsequentFourthAssessment.subsequentFourthAssessmentMarks == undefined ?
                                                                '' : marksAtSubsequentAssessments.subsequentFourthAssessment.subsequentFourthAssessmentMarks
                                                            ) : ('')
                                                        }
                                                        onChange={this.handleChangeSubsequentAssessmentMark} className="form-control" />
                                                </div>

                                                <div className="col-md-6 col-sm-6 ">
                                                    <SemanticDatepicker
                                                        maxDate={new Date()}
                                                        style={{ width: "100%" }}
                                                        label=""
                                                        id="subsequentFourthAssessmentDate"
                                                        name="subsequentFourthAssessment"
                                                        placeholder="Subsequent 4nd Assessment Date"
                                                        dateFormat="YYYY-MM-DD"
                                                        
                                                        value={
                                                            marksAtSubsequentAssessments != null || marksAtSubsequentAssessments != undefined ? (
                                                                marksAtSubsequentAssessments.subsequentFourthAssessment == undefined || marksAtSubsequentAssessments.subsequentFourthAssessment.subsequentFourthAssessmentDate == undefined ?
                                                                '' : marksAtSubsequentAssessments.subsequentFourthAssessment.subsequentFourthAssessmentDate
                                                            ) : ('')
                                                        }
                                                        iconPosition="left"
                                                        onChange={this.handleChangeSubsequentAssessmentDate}
                                                    />
                                                </div>
                                                {/* End 4nd Assessment */}

                                                {/* Start 5nd Assessment */}
                                                <div className="col-md-6 col-sm-6 ">
                                                    <input type="text" placeholder="Subsequent 5nd Assessment Marks" id="subsequentFifthAssessmentMarks" name="subsequentFifthAssessment"

                                                        value={
                                                            marksAtSubsequentAssessments != null || marksAtSubsequentAssessments != undefined ? (
                                                                marksAtSubsequentAssessments.subsequentFifthAssessment == undefined || marksAtSubsequentAssessments.subsequentFifthAssessment.subsequentFifthAssessmentMarks == undefined ?
                                                                '' : marksAtSubsequentAssessments.subsequentFifthAssessment.subsequentFifthAssessmentMarks
                                                            ) : ('')
                                                        }
                                                        onChange={this.handleChangeSubsequentAssessmentMark} className="form-control" />
                                                </div>

                                                <div className="col-md-6 col-sm-6 ">
                                                    <SemanticDatepicker
                                                        maxDate={new Date()}
                                                        style={{ width: "100%" }}
                                                        label=""
                                                        id="subsequentFifthAssessmentDate"
                                                        name="subsequentFifthAssessment"
                                                        placeholder="Subsequent 5nd Assessment Date"
                                                        dateFormat="YYYY-MM-DD"
                                                        
                                                        value={
                                                            marksAtSubsequentAssessments != null || marksAtSubsequentAssessments != undefined ? (
                                                                marksAtSubsequentAssessments.subsequentFifthAssessment == undefined || marksAtSubsequentAssessments.subsequentFifthAssessment.subsequentFifthAssessmentDate == undefined ?
                                                                '' : marksAtSubsequentAssessments.subsequentFifthAssessment.subsequentFifthAssessmentDate
                                                            ) : ('')
                                                        }
                                                        iconPosition="left"
                                                        onChange={this.handleChangeSubsequentAssessmentDate}
                                                    />
                                                </div>
                                                {/* End 5nd Assessment */}

                                                {/* Start 6th Assessment */}
                                                <div className="col-md-6 col-sm-6 ">
                                                    <input type="text" placeholder="Subsequent 6th Assessment Marks" id="subsequentSixthAssessmentMarks" name="subsequentSixthAssessment"

                                                        value={
                                                            marksAtSubsequentAssessments != null || marksAtSubsequentAssessments != undefined ? (
                                                                marksAtSubsequentAssessments.subsequentSixthAssessment == undefined || marksAtSubsequentAssessments.subsequentSixthAssessment.subsequentSixthAssessmentMarks == undefined ?
                                                                '' : marksAtSubsequentAssessments.subsequentSixthAssessment.subsequentSixthAssessmentMarks
                                                            ) : ('')
                                                        }
                                                        onChange={this.handleChangeSubsequentAssessmentMark} className="form-control" />
                                                </div>

                                                <div className="col-md-6 col-sm-6 ">
                                                    <SemanticDatepicker
                                                        maxDate={new Date()}
                                                        style={{ width: "100%" }}
                                                        label=""
                                                        id="subsequentSixthAssessmentDate"
                                                        name="subsequentSixthAssessment"
                                                        placeholder="Subsequent 6nd Assessment Date"
                                                        dateFormat="YYYY-MM-DD"
                                                        
                                                        value={
                                                            marksAtSubsequentAssessments != null || marksAtSubsequentAssessments != undefined ? (
                                                                marksAtSubsequentAssessments.subsequentSixthAssessment == undefined || marksAtSubsequentAssessments.subsequentSixthAssessment.subsequentSixthAssessmentDate == undefined ?
                                                                '' : marksAtSubsequentAssessments.subsequentSixthAssessment.subsequentSixthAssessmentDate
                                                            ) : ('')
                                                        }
                                                        iconPosition="left"
                                                        onChange={this.handleChangeSubsequentAssessmentDate}
                                                    />
                                                </div>
                                                {/* End 6th Assessment */}

                                            </div>

                                            <hr />
                                            <div className="form-group row ">
                                                <label className="control-label col-md-12 col-sm-12 ">
                                                    Activities conducted (Photo upload of the activity)   </label>
                                            </div>

                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3"> Select Type of activity  </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <Dropdown
                                                        placeholder='Select Type of activity'
                                                        fluid
                                                        selection
                                                        id='typeOfActivity'
                                                        name='typeOfActivity'
                                                        options={this.state.typeOfActivities}
                                                        value={this.state.typeOfActivity}
                                                        onChange={this.handleChangeTypeOfActivity}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row ">
                                                <label className="control-label col-md-3 col-sm-3 "> Select activity  </label>
                                                <div className="col-md-9 col-sm-9 ">
                                                    <Dropdown
                                                        placeholder='Select activity'
                                                        fluid
                                                        selection
                                                        id='activity'
                                                        name='activity'
                                                        options={this.state.acivities}
                                                        value={this.state.activity}
                                                        onChange={this.handleChangeActivity}
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row ">
                                                <label class="control-label col-md-3 col-sm-3 " for="number" > Upload Activity Images </label>
                                                <div class="col-md-9 col-sm-9">
                                                    <div>
                                                        <Grid columns={2}>
                                                            <Grid.Column textAlign="left">
                                                                <Input
                                                                    onChange={this.handleFileAddChange}
                                                                    name="upload"
                                                                    id="upload"
                                                                    style={{ width: "230px" }}
                                                                    value={this.state.uploads}
                                                                    placeholder="Product Image"
                                                                    type="file"
                                                                    required
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column textAlign="right">
                                                                {localStorage.USER_TYPE == "USER" ? (
                                                                    <button onClick={() => this.fileUploadOnClick()} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                                        {loading ? (
                                                                            <Loader
                                                                                type="Oval"
                                                                                color="#2A3F54"
                                                                                height={15}
                                                                                width={40}
                                                                            />
                                                                        ) : ("Click Here To Add Activity")}
                                                                    </button>
                                                                ) : (
                                                                        null
                                                                    )}

                                                            </Grid.Column>
                                                        </Grid>
                                                    </div>

                                                    <div>
                                                        {this.state.isUploading ? (
                                                            <Message icon>
                                                                <Icon name="circle notched" loading />
                                                                <Message.Content>
                                                                    <Message.Header>Just one second</Message.Header>
                                                                    We are uploading your document
                                                                </Message.Content>
                                                            </Message>
                                                        ) : (
                                                                ""
                                                            )}
                                                    </div>

                                                    <div>
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column>

                                                                    {this.state.images.map((image, key) => {
                                                                        return (
                                                                            <div class="img_wrp">

                                                                                <ImageZoom
                                                                                    image={{
                                                                                        src: image,
                                                                                        className: 'img',
                                                                                        style: { width: "150px", height: "150px", "padding": "15px" }
                                                                                    }}
                                                                                    zoomImage={{
                                                                                        src: image,
                                                                                    }}
                                                                                />
                                                                                <span onClick={() => this._deleteFile(image, key)} class="close">&times;</span>
                                                                            </div>

                                                                        );
                                                                    })}

                                                                    {this.state.readFiles.map((file, key) => {
                                                                        return (
                                                                            <div class="img_wrp">
                                                                                <ImageZoom
                                                                                    image={{
                                                                                        src: file,
                                                                                        className: 'img',
                                                                                        style: { width: "150px", height: "150px", "padding": "15px" }
                                                                                    }}
                                                                                    zoomImage={{
                                                                                        src: file,
                                                                                    }}
                                                                                />
                                                                                <span onClick={() => this.handleFileRemoveChange(key)} class="close">&times;</span>
                                                                            </div>

                                                                        );
                                                                    })}

                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </div>

                                                </div>
                                            </div>
                                            <div >
                                                {this.state.conductedActivities.map((conductedActivity, key) => {
                                                    return (
                                                        <div>
                                                            <Segment.Group>
                                                                <Segment></Segment>
                                                                <Segment.Group>
                                                                    <Segment>
                                                                        <strong> {key + 1} ) </strong>
                                                                        <Button.Content onClick={() => this._deleteActivity(key)} hidden style={{ float: "right" }}>
                                                                            <Icon color='red' style={{ margin: "20px" }} name='close' />
                                                                        </Button.Content>
                                                                    </Segment>
                                                                    <Segment>
                                                                        <strong> Type Of Activity : </strong> &nbsp;&nbsp;&nbsp; {conductedActivity.typeOfActivity} <br />
                                                                    </Segment>
                                                                    <Segment>
                                                                        <strong> Activity : </strong> &nbsp;&nbsp;&nbsp; {conductedActivity.activity} <br />
                                                                    </Segment>
                                                                    <Segment>
                                                                        {conductedActivity.images.map((image, key) => {
                                                                            return (
                                                                                <div class="img_wrp">
                                                                                    <img style={{ width: "150px", height: "150px", "padding": "15px" }} src={image} />

                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </Segment>
                                                                </Segment.Group>
                                                            </Segment.Group>

                                                        </div>
                                                    );
                                                })
                                                }

                                            </div>

                                            <br /><strong >Multisector involvement for the activity  </strong> <br /><br />

                                            <div class="form-group row ">
                                                <label class="control-label col-md-3 col-sm-3 " for="number" > Health  </label>
                                                <div class="col-md-9 col-sm-9">
                                                    <Dropdown
                                                        placeholder='Health'
                                                        fluid
                                                        multiple
                                                        selection
                                                        id='health'
                                                        name='health'
                                                        options={this.state.healthList}
                                                        value={
                                                            involvementActivities.health == undefined ?
                                                                [] : involvementActivities.health
                                                        }
                                                        onChange={this.handleHealthOnChange}
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row ">
                                                <strong><label class="control-label col-md-12 col-sm-12 " for="number" > Non – health </label></strong>
                                            </div>

                                            <div class="form-group row ">
                                                <div class="col-md-12 col-sm-12">
                                                    <label class="control-label col-md-3 col-sm-3 " for="number" > Formal sector </label>
                                                    <div class="col-md-9 col-sm-9">
                                                        <Dropdown
                                                            placeholder='Non – health (Formal sector) '
                                                            fluid
                                                            multiple
                                                            selection
                                                            id='nonHealthFormalSector'
                                                            name='nonHealthFormalSector'
                                                            options={this.state.nonHealthformalSectorsList}
                                                            value={
                                                                involvementActivities.nonhealth == undefined ?
                                                                    [] : involvementActivities.nonhealth.nonHealthFormalSector
                                                            }
                                                            onChange={this.handleNonHealthFormalChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row ">
                                                <div class="col-md-12 col-sm-12">
                                                    <label class="control-label col-md-3 col-sm-3 " for="number" >  Informal sector </label>
                                                    <div class="col-md-9 col-sm-9">
                                                        <TextArea style={{ width: "100%" }} placeholder="Non – health (Informal sector)" id="nonHealthInformalSector" name="nonHealthInformalSector"
                                                            value={
                                                                involvementActivities.nonhealth == undefined ?
                                                                    '' : involvementActivities.nonhealth.nonHealthInformalSector
                                                            }
                                                            onChange={this.handleNonHealthInformalChange} rows={3} /><br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row ">

                                                <label className="control-label col-md-3 col-sm-3 " for="number" > Community Leaders </label>
                                                <div className=" col-md-9 col-sm-9 ">
                                                    <TextArea style={{ width: "100%" }} id="communityLeaders" name="communityLeaders"
                                                        value={
                                                            involvementActivities.communityLeaders == undefined ?
                                                                '' : involvementActivities.communityLeaders
                                                        }
                                                        onChange={this.handleInformalChange} placeholder='Community Leaders' rows={3} /><br /><br />
                                                </div>

                                                <label className="control-label col-md-3 col-sm-3 " for="number" > Religious leaders </label>
                                                <div className=" col-md-9 col-sm-9 ">

                                                    <TextArea style={{ width: "100%" }} id="religiousLeaders" name="religiousLeaders"
                                                        value={
                                                            involvementActivities.religiousLeaders == undefined ?
                                                                '' : involvementActivities.religiousLeaders
                                                        }
                                                        onChange={this.handleInformalChange} placeholder='Religious leaders' rows={3} /><br /><br />
                                                </div>

                                                <label className="control-label col-md-3 col-sm-3 " for="number" > Community based organizations  </label>
                                                <div className=" col-md-9 col-sm-9 ">

                                                    <TextArea style={{ width: "100%" }} id="communityOrganizations " name="communityOrganizations"
                                                        value={
                                                            involvementActivities.communityOrganizations == undefined ?
                                                                '' : involvementActivities.communityOrganizations
                                                        }
                                                        onChange={this.handleInformalChange} placeholder='Community based organizations' rows={3} /><br /><br />
                                                </div>

                                                <label className="control-label col-md-3 col-sm-3 " for="number" > Health promotion platforms </label>
                                                <div className="col-md-9 col-sm-9">
                                                    <div>
                                                        <Dropdown
                                                            placeholder='Health promotion platforms'
                                                            fluid
                                                            multiple
                                                            selection
                                                            id='healthPromotionPlatform'
                                                            name='healthPromotionPlatform'
                                                            options={this.state.promotionPlatformsList}
                                                            value={
                                                                involvementActivities.healthPromotionPlatform == undefined ?
                                                                    [] : involvementActivities.healthPromotionPlatform
                                                            }
                                                            onChange={this.handleHealthOnChange}
                                                        />
                                                    </div>
                                                </div><br />
                                            </div>

                                            <div class="form-group row ">
                                                <label class="control-label col-md-3 col-sm-3 " for="number" >Comments </label>
                                                <div class="col-md-9 col-sm-9">
                                                    <TextArea style={{ width: "100%" }} id="comments" name="comments" value={this.state.comments} onChange={this.handleChange} placeholder='Comments' rows={6} />
                                                </div>
                                            </div>

                                            {(formErrorStatus.status) ? (
                                                <div class="ui negative message">

                                                    <div class="header">
                                                        Not Submitted.
                                                </div>
                                                    <p>{formErrorStatus.message}</p>
                                                </div>

                                            ) : ((formSuccessState.status) ? (
                                                <div class="ui success message">
                                                    <div class="header">
                                                        Submitted successfully.
                                                            </div>
                                                    <p>{formSuccessState.message}</p>
                                                </div>
                                            ) : (''))}

                                            <div className="ln_solid" />
                                            <div className="form-group">
                                                <div className="col-md-9 col-sm-9  offset-md-3">
                                                    <button onClick={this.handleSubmit} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                        {loading ? (
                                                            <Loader
                                                                type="Oval"
                                                                color="#2A3F54"
                                                                height={15}
                                                                width={40}
                                                            />
                                                        ) : ("Submit")}
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="map_container">
                                            <Map
                                                google={this.props.google}
                                                style={{ width: "80%", margin: "auto", height: "78%" }}
                                                initialCenter={{ lat: 7.2906, lng: 80.6337 }}
                                                zoom={8.7}
                                                onClick={
                                                    this.state.allowClick ? this.onMapClick : false
                                                }
                                            >
                                                <Marker
                                                    title={marker.title}
                                                    name={marker.name}
                                                    position={marker.position}
                                                />
                                            </Map>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: apiKey
})(AddNewPreSchoolSetting))));
