import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions'


//Queries
import { CREATE_USER } from '../../queries/UserQueries'
import {
    GET_ALL_PROVINCES, GET_DISTRICTS_FOR_PROVINCES, GET_DIVISIONS_FOR_DISTRICT, GET_ALL_DISTRICTS,
    GET_AREA_DETAILS
} from '../../queries/CommonQueries'

//utilities
// import { validateEmail } from "../../utilities/utils"

//Google map
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

//sementic ui
import { Dropdown, Grid, Button, Radio, Form, Icon } from 'semantic-ui-react';

//Spinner
import Loader from 'react-loader-spinner'

const apiKey = "AIzaSyAa4iktQ8XNg1TJDk_CPptIgzZWmBQm7bM"

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class addNewHighLevelUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form_state: 'default',
            title: "",
            message: "",
            loading: false,
            name: '',
            tel: "",
            province: "",
            district: "",
            gndivision: "",
            provinceId: "",
            districtId: "",
            gndivisionId: "",
            provinceMachineName: "",
            districtMachineName: "",
            gndivisionMachineName: "",
            email: "",
            gender: "",
            nic: "",
            userType: '',
            settingType: '',
            password: "",
            provinces: [],
            districts: [],
            divisions: [],
            confirmpassword: "",
            isActive: true,
            userTypes: [
                { key: "ADMIN", text: "Admin", value: "ADMIN", id: 'userType' },
                { key: "DIRECTOR", text: "Director", value: "DIRECTOR", id: 'userType' },
                { key: "CONSULTANT", text: "Consultant", value: "CONSULTANT", id: 'userType' },
                { key: "HEO", text: "HEO", value: "HEO", id: 'userType' },
                { key: "RDHS", text: "RDHS", value: "RDHS", id: 'userType' },
                { key: "CCP", text: "CCP", value: "CCP", id: 'userType' }
            ],
            settingTypes: [
                { key: "MOTHERSUPPORTGROUP", text: "Mother Support Group", value: "MOTHERSUPPORTGROUP", id: 'settingType' },
                { key: "PRESCHOOL", text: "Pre School", value: "PRESCHOOL", id: 'settingType' },
                { key: "SCHOOL", text: "School", value: "SCHOOL", id: 'settingType' },
                { key: "WORKPLACE", text: "Work Place", value: "WORKPLACE", id: 'settingType' },
                { key: "HOSPITAL", text: "Hospital", value: "HOSPITAL", id: 'settingType' },
                { key: "VILLAGE", text: "Village", value: "VILLAGE", id: 'settingType' }
            ],
            marker:
            {
                title: "The marker`s title will appear as a tooltip.",
                name: "COLOMBO",
                position: { lat: 6.93194, lng: 79.84778 }
            },
            location: {}
        };

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
    }

    componentDidMount() {
        // read provinces
        this.loadProvinces().then(result => {
            var provinces = result.data.getAllProvinces.map(province => {
                return { key: province.id, text: province.province, value: province.id, id: 'provinceId' }
            });
            provinces.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            provinces.unshift({ key: null, id: "provinceId", text: "Select-Province", value: "Select-Province", disabled: true });

            this.setState({ provinces: provinces });
        })

        this.loadAllDistricts().then(result => {
            var districts = result.data.getAllDistricts.map(district => {
                return { key: district.id, text: district.district, value: district.id, id: 'districtId' }
            });
            districts.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            districts.unshift({ key: null, id: "districtId", text: "Select-District", value: "Select-District", disabled: true });

            this.setState({ districts: districts });
        })

    }

    handleOnChange = (e, data) => {
        this.setState({ [data.id]: data.value });
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    handleSubmit(event) {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        console.log(this.state.name);
        event.preventDefault();
        const { name, email, nic, password, confirmpassword, provinceId, districtId, gndivisionId, location, userType, settingType } = this.state;
        if (nic === '' || name === '' || email === '' || password === '' || userType === '') {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "All Fields Required!"
            });
            return;
        } else {

            if (password != confirmpassword) {
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message: "Confirm password not matching!"
                });
                return;
            } else {

                if (userType == "CONSULTANT" && settingType == "") {
                    this.props.setFormStatus({
                        status: true,
                        title: "Oops!",
                        message: "Setting Type Required!"
                    });
                    return;
                } else {

                    this.setState({ loading: true });
                    this.props.setFormStatus({ status: false, title: '', message: '' });
                    this.props.setSuccessStatus({ status: false, title: '', message: '' });

                    this.creatUser().then(result => {
                        console.log(result);
                        this.setState({
                            name: '',
                            tel: '',
                            province: null,
                            district: null,
                            gndivision: null,
                            provinceId: null,
                            districtId: null,
                            gndivisionId: null,
                            provinceMachineName: null,
                            districtMachineName: null,
                            gndivisionMachineName: null,
                            email: '',
                            gender: '',
                            nic: '',
                            password: '',
                            confirmpassword: '',
                            isActive: true,
                            loading: false
                        });

                        this.props.setSuccessStatus({
                            status: true,
                            title: "",
                            message:
                                " Added Successfully!. "
                        });
                    }).catch(error => {
                        console.log(error);
                        if (error) {
                            this.setState({ loading: false });
                            this.props.setFormStatus({
                                status: true,
                                title: "Oops!",
                                message:
                                    "There was an error while trying to create user, User already exist."

                            });
                        }
                    });

                }
            }

        }
    }


    creatUser = async () => {
        const { name, tel, provinceId, province, districtId, district, gndivisionId, gndivision, location, email, gender, nic, password,
            userType, isActive, settingType } = this.state;

        var data = {};

        if (userType == "CONSULTANT") {
            data = {
                name, tel, provinceId, province, districtId, district, gndivisionId, gndivision, location, email, gender, nic, password,
                userType, settingType, isActive
            }
        } else {
            data = {
                name, tel, provinceId, province, districtId, district, gndivisionId, gndivision, location, email, gender, nic, password,
                userType, isActive
            };
        }


        const result = await this.props.client.mutate({
            mutation: CREATE_USER,
            variables: data
        });
        return result.data.signup;
    };

    RedirectToLogin = () => {
        this.props.history.push('/login')
    }

    // load provinces from api
    loadProvinces = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_PROVINCES,
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load all districts from api
    loadAllDistricts = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_DISTRICTS,
            fetchPolicy: "network-only"
        });
        return result;
    };


    // load districts from api
    loadDistricts = async (provinceId) => {
        const result = await this.props.client.query({
            query: GET_DISTRICTS_FOR_PROVINCES,
            variables: { provinceId },
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load divisions from api
    loadDivisions = async (districtId) => {
        const result = await this.props.client.query({
            query: GET_DIVISIONS_FOR_DISTRICT,
            variables: { districtId },
            fetchPolicy: "network-only"
        });
        return result;
    };


    handleProvinceChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "province": text });

        this.loadDistricts(key).then(result => {
            var districts = result.data.getDistrictsForProvince.map(district => {
                return { key: district.id, text: district.district, value: district.id, id: 'districtId' }
            });
            districts.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            districts.unshift({ key: null, id: "districtId", text: "Select-District", value: "Select-District", disabled: true });
            this.setState({ districts: districts });
        })
    }

    handleDistrictChange = (e, data) => {

        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "district": text });

    }



    handleDivisionChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        this.setState({ [data.id]: key, "gndivision": text });

    }

    handleOnCheck = (e, data) => {
        this.setState({ ['gender']: data.value });
    }

    onMapClick(t, map, coord) {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        const location = { latitude: lat, longitude: lng };
        this.setState({ location: location });

        this.setState({ marker: { title: "", name: "", position: { lat, lng } } });

        this.getAreaDetails(location).then(result => {
            var divisions = result.divisions.map(division => {
                return { key: division.id, text: division.gndivision, value: division.id, id: 'gndivisionId' }
            });
            divisions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            divisions.unshift({ key: null, id: "gndivisionId", text: "Select-Division", value: "Select-Division", disabled: true });
            this.setState({
                provinceId: result.province.provinceId,
                province: result.province.province,
                districtId: result.district.districtId,
                district: result.district.district,
                divisions: divisions
            });
        }).catch(error => {
            if (error) {
                console.log(error);
                console.log('There is an error');
                this.props.setFormStatus({ status: true, title: 'Oops!', message: 'Error in ' });
            }
            this.setState({ loading: false });

        });


    }

    getAreaDetails = async (location) => {
        console.log(location)
        const result = await this.props.client.query({
            query: GET_AREA_DETAILS,
            variables: { location },
            fetchPolicy: "network-only"
        });
        return result.data.getAreaDetails;
    };

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        const { settingTypes, userTypes, marker, name, loading, tel, gndivision, email, nic, password, confirmpassword, userType, provinces, districts, divisions } = this.state;
        const { formErrorStatus, formSuccessState } = this.props;

        console.log(this.state.districts)


        return (


            <div class="right_col" role="main">
                <div >
                    <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                </div>
                <div class="">

                    <div class="page-title">
                        <div class="title_left">
                            <h3>Personal Info</h3>
                        </div>
                    </div>

                    <div class="clearfix"></div>

                    <div class="row">
                        <div class="col-md-12 ">
                            <div class="x_panel">
                                <div class="x_title">
                                    <h2> Create Account</h2>

                                    <div class="clearfix"></div>
                                </div>
                                <div class="x_content">
                                    <br />
                                    <form >
                                        <Grid columns='two'>
                                            <Grid.Row>
                                                <Grid.Column className="login_leftcontent" width={7}>
                                                    <div>
                                                        <Dropdown
                                                            placeholder='Select User Type'
                                                            fluid
                                                            selection
                                                            id='userType'
                                                            name='userType'
                                                            options={userTypes}
                                                            value={this.state.userType}
                                                            onChange={this.handleOnChange}
                                                        />
                                                    </div><br />

                                                    {this.state.userType == "CONSULTANT" ? (
                                                        <div>
                                                            <Dropdown
                                                                placeholder='Select Setting Type'
                                                                fluid
                                                                selection
                                                                id='settingType'
                                                                name='settingType'
                                                                options={settingTypes}
                                                                value={this.state.settingType}
                                                                onChange={this.handleOnChange}
                                                            />
                                                            <br />
                                                        </div>
                                                    ) : (
                                                            null
                                                        )}

                                                    <div>
                                                        <input value={name || ''} type="text" id="name" name="name" class="form-control" placeholder="Full Name" required="" onChange={this.handleChange} /><br />
                                                    </div>

                                                    <div>
                                                        <input type="text" id="tel" value={tel || ''} name='tel' class="form-control" placeholder="Telephone" onChange={this.handleChange} /><br />
                                                    </div>
                                                    <div style={{ float: "left", textAlign: "left" }}> <br />
                                                        <label>Gender</label><br />

                                                        <Form.Group>
                                                            <Form.Radio
                                                                id="male"
                                                                label="Male"
                                                                name="gender"
                                                                value="MALE"
                                                                onChange={this.handleOnCheck}
                                                            />
                                                            <Form.Radio
                                                                id="female"
                                                                label="Female"
                                                                name="gender"
                                                                value="FEMALE"
                                                                onChange={this.handleOnCheck}
                                                            />
                                                        </Form.Group>
                                                        <br />
                                                    </div>
                                                    <div>
                                                        <input type="text" id="nic" name='nic' value={nic || ''} class="form-control" placeholder="NIC" required="" onChange={this.handleChange} /><br />
                                                    </div>

                                                    <div>
                                                        <input type="email" id="email" name='email' value={email || ''} class="form-control" placeholder="Email" required="" onChange={this.handleChange} /><br />
                                                    </div>

                                                    <div>
                                                        <input type="password" id="password" name='password' value={password || ''} class="form-control" placeholder="Password" required="" onChange={this.handleChange} /><br />
                                                    </div>

                                                    <div>
                                                        <input type="password" id="confirmpassword" name='confirmpassword' value={confirmpassword || ''} class="form-control" placeholder="Confirm Password" required="" onChange={this.handleChange} /><br />
                                                    </div>

                                                    {this.state.userType == "CONSULTANT" || this.state.userType == "DIRECTOR" || this.state.userType == "ADMIN" ? (
                                                        null
                                                    ) : (
                                                            <div>
                                                                <Dropdown
                                                                    placeholder='Select Province'
                                                                    fluid
                                                                    selection
                                                                    id='provinceId'
                                                                    name='provinceId'
                                                                    options={provinces}
                                                                    value={this.state.provinceId}
                                                                    onChange={this.handleProvinceChange}
                                                                /><br />

                                                                <Dropdown
                                                                    placeholder='Select District'
                                                                    fluid
                                                                    selection
                                                                    id='districtId'
                                                                    name='districtId'
                                                                    options={districts}
                                                                    value={this.state.districtId}
                                                                    onChange={this.handleDistrictChange}
                                                                /><br />
                                                            </div>


                                                        )}

                                                    <div >
                                                        <Button type="button" onClick={this.handleSubmit} className="btn btn-primary registration_submit" disabled={loading ? (true) : (false)}>
                                                            {loading ? (
                                                                <Loader
                                                                    type="Oval"
                                                                    color="#2A3F54"
                                                                    height={15}
                                                                    width={40}
                                                                />
                                                            ) : ("Submit")}
                                                        </Button>
                                                    </div>


                                                    {(formErrorStatus.status) ? (
                                                        <div class="ui negative message">

                                                            <div class="header">
                                                                Not Registered.
                                                </div>
                                                            <p>{formErrorStatus.message}</p>
                                                        </div>

                                                    ) : ((formSuccessState.status) ? (
                                                        <div class="ui success message">
                                                            <div class="header">
                                                                Registered successfully.
                                                            </div>
                                                            <p>{formSuccessState.message}</p>
                                                        </div>
                                                    ) : (''))}

                                                </Grid.Column>
                                                <Grid.Column>
                                                    {/* 
                                                    <div className="map_container">
                                                        <Map
                                                            google={this.props.google}

                                                            style={{ width: "80%", margin: "auto", height: "78%" }}
                                                            initialCenter={{ lat: 7.2906, lng: 80.6337 }}
                                                            zoom={8}
                                                            onClick={this.onMapClick}
                                                        >
                                                            <Marker
                                                                title={marker.title}
                                                                name={marker.name}
                                                                position={marker.position}
                                                            />

                                                        </Map>
                                                    </div> */}

                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: apiKey
})(addNewHighLevelUser))));
