import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus } from '../../../actions/user-actions';
import Chart from "chart.js";
// Map Box
import { randomPoint } from '@turf/random';
import MapGL, { Marker } from '@urbica/react-map-gl';
import Cluster from '@urbica/react-map-gl-cluster';
import 'mapbox-gl/dist/mapbox-gl.css';

//Spinner
import Loader from 'react-loader-spinner'

//ui
import { Icon, Grid, Dimmer, Image, Segment, Button } from 'semantic-ui-react';

//ticker
import Ticker from 'react-ticker'

//queries
import {
    GET_EVENT_COUNT_BY_MONTH, GET_ALL_SUBMISSION_LOCATIONS, GET_ALL_SUBMISSION_COUNTS, GET_ALL_EVENT_COUNTS,
    GET_SUBMISSION_BY_DISTRICT, GET_RECENT_EVENTS, GET_COUNTERS_FOR_DASHBOARD
} from '../../../queries/ReportQueries'

//middleware
import { formatDate } from '../../../middleware/index';

const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoibGFrc2hpdGhhMjEyIiwiYSI6ImNpbGl3dzh1cTNvdDF1aGtwZzdrNG13ZnEifQ.1T1IJGIOO1KbZ-lCN4nDtw";


const bbox = [79.639893, 5.916581, 81.886597, 9.849804];
// const points = randomPoint(20, { bbox }).features;
// points.forEach((point, index) => (point.id = index));
// console.log(points);
//Google map
// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
const apiKey = "AIzaSyAa4iktQ8XNg1TJDk_CPptIgzZWmBQm7bM"

const style = {
    width: '20px',
    height: '20px',
    color: '#fff',
    background: '#20c997',
    borderRadius: '20px',
    textAlign: 'center'
};

const styleApproved = {
    width: '20px',
    height: '20px',
    color: '#fff',
    background: '#20c997',
    borderRadius: '20px',
    textAlign: 'center'
};

const stylePending = {
    width: '20px',
    height: '20px',
    color: '#fff',
    background: '#c93f20',
    borderRadius: '20px',
    textAlign: 'center'
};

const ClusterMarker = ({ longitude, latitude, pointCount }) => (
    <Marker longitude={longitude} latitude={latitude}>
        <div style={{ ...style, background: '#fd7e14' }}>{pointCount}</div>
    </Marker>
);

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        }
    }
}
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: {
                form_state: 'default',
                title: '',
                message: '',
            },
            loading: false,
            marker: {
                title: "The marker`s title will appear as a tooltip.",
                name: "COLOMBO",
                position: { lat: 6.93194, lng: 79.84778 }
            },
            viewport: {
                latitude: 7.708270,
                longitude: 80.620422,
                zoom: 7
            },
            points: [],
            pagination: {
                skip: 0,
                first: 5
            },
            previousPage: 0,
            nextPage: 1,
            currentPage: 0,
            activePage: '',
            ToDos: 5,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            isFirstBtnActive: 'disabled',
            isLastBtnActive: '',
            count: 5,
            events: [],
            counters: [],
            counterLoader: true,
            registrationLoader: true,
            settingLoader: true,
            eventLoader: true,
            districtLoader: true,
            activityLoader: true,
            locationLoader: true,
            pieChartRegistrationCount: 0,
            pieChartSubmissionCount: 0,
            pieChartEventCount: 0,
        };
    }

    setPieData = async (id, data) => {
        new Chart(id, {
            type: 'pie',
            data: data,
            options: {
                cutoutPercentage: 40,
                responsive: false,
            }

        });
    }

    displayChartData = async (userCountPer, settingCountPer, eventCountPer) => {

        if (document.getElementById("pieChartRegistration") != null
            && document.getElementById("pieChartSubmission") != null
            && document.getElementById("pieChartEvent") != null) {


            var registrationPieChart = document.getElementById("pieChartRegistration").getContext("2d");
            var submissionPieChart = document.getElementById("pieChartSubmission").getContext("2d");
            var eventPieChart = document.getElementById("pieChartEvent").getContext("2d");

            var registrationPieChartData = {};
            var submissionPieChartData = {};
            var eventPieChartData = {};

            var userCountPerSpace = userCountPer > 99 ? 0 : 100 - userCountPer;
            var settingCountPerSpace = settingCountPer > 99 ? 0 : 100 - settingCountPer;
            var eventCountPerSpace = eventCountPer > 99 ? 0 : 100 - eventCountPer;


            registrationPieChartData = {
                datasets: [{
                    label: '',
                    data: [userCountPer, userCountPerSpace],
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255,255,255, 0.2)',
                    ],
                    borderColor: [
                        'rgba(54, 162, 235, 1)',
                        'rgba(54, 162, 235, 1)',
                    ],
                    borderWidth: 1
                }]
            };

            submissionPieChartData = {
                datasets: [{
                    label: '',
                    data: [settingCountPer, settingCountPerSpace],
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255,255,255, 0.2)',
                    ],
                    borderColor: [
                        'rgba(54, 162, 235, 1)',
                        'rgba(54, 162, 235, 1)',
                    ],
                    borderWidth: 1
                }]
            };

            eventPieChartData = {
                datasets: [{
                    label: '',
                    data: [eventCountPer, eventCountPerSpace],
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255,255,255, 0.2)',
                    ],
                    borderColor: [
                        'rgba(54, 162, 235, 1)',
                        'rgba(54, 162, 235, 1)',
                    ],
                    borderWidth: 1
                }]
            };

            this.setPieData(registrationPieChart, registrationPieChartData);
            this.setPieData(submissionPieChart, submissionPieChartData);
            this.setPieData(eventPieChart, eventPieChartData);
        }

    };

    componentDidMount() {

        this.loadCounters().then(result => {
            this.setState({
                counters: result.counters,
                counterLoader: false,
                pieChartRegistrationCount: result.counters[0].userCountPer,
                pieChartSubmissionCount: result.counters[1].settingCount,
                pieChartEventCount: result.counters[2].eventCount,
            });

            this.displayChartData(result.counters[0].userCountPer, result.counters[1].settingCountPer, result.counters[2].eventCountPer).then(result => {
            });
        })

        // Registration Summary Chart
        const registrationSummaryChart = document.getElementById('registrationSummaryChart').getContext("2d");
        var registrationSummaryData = {};
        this.setRegistartionData(registrationSummaryChart, {});
        this.getEventCountByMonth().then(result => {
            registrationSummaryData = {
                labels: result.lastSixMonths,
                datasets: [{
                    label: '# of events',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                    data: result.collaborateEventCount
                }]
            };

            this.setState({ registrationLoader: false });

            this.setRegistartionData(registrationSummaryChart, registrationSummaryData);
        })

        this.loadSubmissionCoordinates().then(result => {
            //console.log(result.geometry)
            this.setState({ points: result.geometry });
        })

        // Submissions by Settings Chart


        const submissionsBySettingsChart = document.getElementById('submissionsBySettingsChart').getContext("2d");
        var submissionsBySettingsData = {};
        this.setSettingsData(submissionsBySettingsChart, {});
        this.loadSubmissionCounts().then(result => {
            submissionsBySettingsData = {
                labels: result.settings,
                datasets: [{
                    label: '# of submissions',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    data: result.counts
                }]
            };

            this.setSettingsData(submissionsBySettingsChart, submissionsBySettingsData);
        })


        const eventsBySettingsChart = document.getElementById('eventsBySettingsChart').getContext("2d");
        var eventsBySettingsData = {};
        this.setEventsData(eventsBySettingsChart, {});
        this.loadEventCounts().then(result => {
            eventsBySettingsData = {
                labels: result.settings,
                datasets: [{
                    label: '# of events',
                    backgroundColor: 'rgba(153, 102, 255, 0.2)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                    data: result.counts
                }]
            };

            this.setEventsData(eventsBySettingsChart, eventsBySettingsData);
        })

        this.setdistrictSubmissionActivities(this.state.pagination);

        this.loadRecentEvents().then(result => {
            console.log(result)
            this.setState({ events: result.events, activityLoader: false });
        })

        console.log('loaded DOM');
    }

    setdistrictSubmissionActivities(pagination) {

        this.setState({ districtLoader: true });

        const districtActivityChart = document.getElementById('districtActivityChart').getContext("2d");
        var districtActivityData = {};
        // this.setdistrictActivity(districtActivityChart, {});
        this.loadSubmissionsByDistrict(pagination).then(result => {
console.log(result)
            districtActivityData = {
                labels: result.counts.labels,
                datasets: [{
                    label: 'Mother Support Group',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                    data: result.counts.datasets[0].data
                }, {
                    label: 'Preschool',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                    data: result.counts.datasets[1].data
                }, {
                    label: 'School',
                    backgroundColor: 'rgba(255, 206, 86, 0.2)',
                    borderColor: 'rgba(255, 206, 86, 1)',
                    borderWidth: 1,
                    data: result.counts.datasets[2].data
                }, {
                    label: 'Work Place',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    data: result.counts.datasets[3].data
                }, {
                    label: 'Village',
                    backgroundColor: 'rgba(153, 102, 255, 0.2)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                    data: result.counts.datasets[4].data
                }, {
                    label: 'Hospital',
                    backgroundColor: 'rgba(255, 159, 64, 0.2)',
                    borderColor: 'rgba(255, 159, 64, 1)',
                    borderWidth: 1,
                    data: result.counts.datasets[5].data
                }]
            };

            this.setdistrictActivity(districtActivityChart, districtActivityData);

            this.setState({ districtLoader: false });

            // }).catch(error => {
            //     console.log(error);

            // });

        })
    }

    handlePagination = async (pageNo, status) => {
        console.log(pageNo);
        this.setState({
            currentPage: pageNo,
            nextPage: pageNo + 1,
            previousPage: pageNo - 1

        })

        var pagination = {}

        if (status == "NEXT") {
            pagination = {
                skip: pageNo * this.state.ToDos,
                first: 5
            };
        } else if (status == "BACK") {
            pagination = {
                skip: pageNo * this.state.ToDos,
                first: 5
            };
        }

        this.setdistrictSubmissionActivities(pagination);
    }

    setRegistartionData(registrationSummaryChart, data) {
        new Chart(registrationSummaryChart, {
            type: "bar",
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            drawOnChartArea: false
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            drawOnChartArea: true
                        }
                    }]
                }
            }
        });
    }

    setSettingsData(submissionsBySettingsChart, data) {
        new Chart(submissionsBySettingsChart, {
            type: "horizontalBar",
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: 'top',
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    setEventsData(eventsBySettingsChart, data) {
        new Chart(eventsBySettingsChart, {
            type: "horizontalBar",
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: 'top',
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    setdistrictActivity(districtActivityChart, data) {
        new Chart(districtActivityChart, {
            type: "bar",
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: 'top',
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    }]
                }
            }
        });
    }

    // load registrations by week
    getEventCountByMonth = async () => {
        const result = await this.props.client.query({
            query: GET_EVENT_COUNT_BY_MONTH,
            //fetchPolicy: "cache-first"
            fetchPolicy: "network-only"
        });
        return result.data.getEventCountByMonth;
    };

    // load Coordinates
    loadSubmissionCoordinates = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_SUBMISSION_LOCATIONS,
            //fetchPolicy: "cache-first"
            fetchPolicy: "network-only"
        });
        return result.data.getAllSubmissionLocations;
    };

    // load submission count
    loadSubmissionCounts = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_SUBMISSION_COUNTS,
            //fetchPolicy: "cache-first"
            fetchPolicy: "network-only"
        });
        return result.data.getAllSubmissionCounts;
    };

    // load event count
    loadEventCounts = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_EVENT_COUNTS,
            //fetchPolicy: "cache-first"
            fetchPolicy: "network-only"
        });
        return result.data.getAllEventCounts;
    };

    // load submission by district count
    loadSubmissionsByDistrict = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_SUBMISSION_BY_DISTRICT,
            variables: pagination,
            //fetchPolicy: "cache-only"
            fetchPolicy: "network-only"
        });
        console.log(result.data.getSubmissionsByDistrict)
        return result.data.getSubmissionsByDistrict;
    };

    // load recent events
    loadRecentEvents = async () => {
        const result = await this.props.client.query({
            query: GET_RECENT_EVENTS,
            //fetchPolicy: "cache-first"
            fetchPolicy: "network-only"
        });
        return result.data.getRecentEvents;
    };

    // load counters
    loadCounters = async () => {
        const result = await this.props.client.query({
            query: GET_COUNTERS_FOR_DASHBOARD,
            //fetchPolicy: "cache-only"
            fetchPolicy: "network-only"
        });
        return result.data.getCountersForDashboard;
    };

    stop() {
        document.getElementById('ticker').stop();
    }

    start() {
        document.getElementById('ticker').start();
    }


    render() {
        const { marker, points, count, ToDos, nextPage, previousPage, events, counters, activityLoader } = this.state;

        console.log(points)
        return (
            <div >

                <div className="right_col" role="main">

                    <div className="row tile_count">

                        <div className="col-md-3 col-sm-4 col-xs-6 tile_stats_count" style={{ "textAlign": "center" }}>
                            <span className="count_top"><i className="fa fa-user"></i> Total Users</span>
                            <div className="count" >
                                {this.state.counterLoader ? (

                                    <Loader
                                        type="Oval"
                                        color="#2A3F54"
                                        height={30}
                                        width={30}
                                    />

                                ) : (
                                        counters.length > 0 ? (
                                            counters[0].userCount
                                        ) : (
                                                null
                                            )
                                    )}
                            </div>

                            <div> <canvas className="noHover" style={{ "display": "inline" }} id="pieChartRegistration" width="40" height="40"></canvas> </div>
                            <span className="count_bottom">

                                <i className="green">
                                    {
                                        counters.length > 0 ? (
                                            counters[0].userCountPer + "%"
                                        ) : (
                                                null
                                            )
                                    }

                                </i>  From last Week</span>
                        </div>


                        <div className="col-md-3 col-sm-4 col-xs-6 tile_stats_count" style={{ "textAlign": "center" }}>
                            <span className="count_top"><i className="fa fa-desktop"></i> Total Submissions</span>
                            <div className="count" >
                                {this.state.counterLoader ? (

                                    <Loader
                                        type="Oval"
                                        color="#2A3F54"
                                        height={30}
                                        width={30}
                                    />

                                ) : (
                                        counters.length > 0 ? (
                                            counters[1].settingCount
                                        ) : (
                                                null
                                            )
                                    )}
                            </div>
                            <div>
                                <canvas className="noHover" style={{ "display": "inline" }} id="pieChartSubmission" width="40" height="40"></canvas>
                            </div>
                            <span className="count_bottom"><i className="green">

                                {
                                    counters.length > 0 ? (
                                        counters[1].settingCountPer + "%"
                                    ) : (
                                            null
                                        )
                                }
                            </i> From last Week</span>
                        </div>
                        <div className="col-md-3 col-sm-4 col-xs-6 tile_stats_count" style={{ "textAlign": "center" }}>
                            <span className="count_top"><i className="fa fa-desktop"></i> Total Events</span>
                            <div className="count" >

                                {this.state.counterLoader ? (

                                    <Loader
                                        type="Oval"
                                        color="#2A3F54"
                                        height={30}
                                        width={30}
                                    />

                                ) : (
                                        counters.length > 0 ? (
                                            counters[2].eventCount
                                        ) : (
                                                null
                                            )
                                    )}
                            </div>
                            <div>
                                <canvas className="noHover" style={{ "display": "inline" }} id="pieChartEvent" width="40" height="40"></canvas>
                            </div>
                            <span className="count_bottom"><i className="green">

                                {
                                    counters.length > 0 ? (
                                        counters[2].eventCountPer + "%"
                                    ) : (
                                            null
                                        )
                                }

                            </i>  From last Week</span>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="x_panel">
                                <div className="x_title">
                                    <h2>Recent Activities <small> Events </small></h2>
                                    <div className="clearfix"></div>

                                </div>
                                <div className="x_content">
                                    <div className="dashboard-widget-content">
                                        <ul className="list-unstyled timeline widget">

                                            {

                                                activityLoader ? (
                                                    <div style={{ "textAlign": "center" }}>
                                                        <Loader
                                                            type="Oval"
                                                            color="#2A3F54"
                                                            height={30}
                                                            width={30}
                                                        />Loading<br /><br />

                                                        <Loader
                                                            type="Oval"
                                                            color="#2A3F54"
                                                            height={30}
                                                            width={30}
                                                        />Loading<br /><br />

                                                        <Loader
                                                            type="Oval"
                                                            color="#2A3F54"
                                                            height={30}
                                                            width={30}
                                                        />Loading<br /><br />

                                                        <Loader
                                                            type="Oval"
                                                            color="#2A3F54"
                                                            height={30}
                                                            width={30}
                                                        />Loading<br /><br />
                                                    </div>
                                                ) : (
                                                        <div style={{
                                                            height: "490px",
                                                             "overflow-y": "auto"
                                                        }} >

                                                            {
                                                                events.map((event, index) => (
                                                                    <li>
                                                                        <div className="block">
                                                                            <div className="block_content">
                                                                                <h2 className="title">
                                                                                    <a>{event.title}</a>
                                                                                </h2>
                                                                                <p className="excerpt">
                                                                                    {event.description}
                                                                                </p>
                                                                                <div className="byline">
                                                                                    <Grid>
                                                                                        <Grid.Row>
                                                                                            <Grid.Column width={8}>
                                                                                                <span > Added Date :  <a>{formatDate(event.createdAt)}</a> </span>
                                                                                            </Grid.Column>
                                                                                            <Grid.Column width={8}>
                                                                                                <span > Event Date :  <a>{formatDate(event.date)}</a> </span>
                                                                                            </Grid.Column>
                                                                                        </Grid.Row>
                                                                                        <Grid.Row className="recentEventsRow">
                                                                                             <Grid.Column width={8}>
                                                                                                <span> Setting :  
                                                                                                    <a className="recentEventsEventType">
                                                                                                        {
                                                                                                            event.type === 'MOTHERSUPPORTGROUP' ? " Mother Support Group"
                                                                                                            : event.type === 'PRESCHOOL' ? " Preschool"
                                                                                                            : event.type === 'SCHOOL' ? " School"
                                                                                                            : event.type === 'WORKPLACE' ? " Workplace"
                                                                                                            : event.type === 'HOSPITAL' ? " Hospital"
                                                                                                            : event.type === 'VILLAGE' ? " Village"
                                                                                                            : null
                                                                                                        }
                                                                                                    </a>
                                                                                                </span>
                                                                                            </Grid.Column>
                                                                                            <Grid.Column width={8}>
                                                                                                <span > GN Division :  <a>{event.gndivision}</a> </span>
                                                                                            </Grid.Column>
                                                                                        </Grid.Row>
                                                                                        <Grid.Row className="recentEventsRow">
                                                                                            <Grid.Column width={16}>
                                                                                                <span > Added by :  <a>{event.addedBy ? event.addedBy : null}</a> </span>
                                                                                            </Grid.Column>
                                                                                        </Grid.Row>
                                                                                    </Grid>
                                                                                    
                                                                                </div>

                                                                                <div>
                                                                                    <Grid>
                                                                                        <Grid.Row>
                                                                                            <Grid.Column>
                                                                                                {
                                                                                                    event.images != null && event.images != undefined ? (
                                                                                                        event.images.length > 0 ? (
                                                                                                            event.images.map((image, key) => {
                                                                                                                return (
                                                                                                                    <img style={{ width: "80px", height: "80px", "margin": "10px" }} src={image} />
                                                                                                                );
                                                                                                            })
                                                                                                        ) : (null)
                                                                                                    ) : (null)
                                                                                                }
                                                                                            </Grid.Column>
                                                                                        </Grid.Row>
                                                                                    </Grid>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )

                                                                )
                                                            }
                                                        </div>


                                                    )
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-8 col-xs-12">
                            <div className="row">

                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="x_panel">
                                        <div className="x_title">
                                            <h2> Setting Submissions <small>geo-locations</small></h2>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="x_content">
                                            <div style={{ minHeight: "510px" }}>

                                                <MapGL
                                                    style={{ width: '100%', height: '510px' }}
                                                    mapStyle='mapbox://styles/mapbox/light-v9'
                                                    accessToken={MAPBOX_ACCESS_TOKEN}
                                                    onViewportChange={viewport => this.setState({ viewport })}
                                                    {...this.state.viewport}
                                                >
                                                    <Cluster radius={40} extent={512} nodeSize={64} component={ClusterMarker}>
                                                        {points.map(point => (
                                                            <Marker
                                                                key={point.id}
                                                                longitude={point.location.longitude}
                                                                latitude={point.location.latitude}
                                                            >

                                                                {
                                                                    point.location.latitude >= 6.0 ? 

                                                                        point.status == "APPROVED" ? (
                                                                            <div style={styleApproved} />
                                                                        ) : (
                                                                            <div style={stylePending} />
                                                                        )

                                                                    : null
                                                                
                                                                }


                                                            </Marker>
                                                        ))}
                                                    </Cluster>
                                                </MapGL>


                                                {/* <Map
                                                        google={this.props.google}
                                                        initialCenter={{ lat: 7.2906, lng: 80.6337 }}
                                                        zoom={7}
                                                        onClick={
                                                            this.state.allowClick ? this.onMapClick : false
                                                        }
                                                    >
                                                        <Marker
                                                            title={marker.title}
                                                            name={marker.name}
                                                            position={marker.position}
                                                        />

                                                    </Map> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>


                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="dashboard_graph">

                                <div className="row x_title">
                                    <div className="col-md-6">
                                        <h3>Events Summary <small>Monthly</small></h3>
                                    </div>
                                </div>

                                <div className="col-md-9 col-sm-9 col-xs-12">

                                    <canvas id="registrationSummaryChart" style={{ height: "470px" }} />

                                </div>

                                <div className="col-md-3 col-sm-3 col-xs-12 bg-white">
                                    <div className="x_title">
                                        <h2>Submissions by Settings</h2>
                                        <div className="clearfix"></div>
                                    </div>

                                    <div className="col-md-12 col-sm-12 col-xs-6">
                                        <canvas id="submissionsBySettingsChart" style={{ height: "180px" }} />
                                    </div>
                                    <div className="x_title">
                                        <h2>Events by Settings</h2>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-6">
                                        <canvas id="eventsBySettingsChart" style={{ height: "180px" }} />
                                    </div>

                                </div>

                                <div className="clearfix"></div>
                            </div>
                        </div>

                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="x_panel tile fixed_height_320">
                                <div className="x_title">
                                    <h2>District Activities</h2>
                                    <div style={{ float: "right" }}>
                                        <button disabled={this.state.districtLoader ? (true) : (false)} type="button" className="btn btn-info" onClick={() => this.handlePagination(previousPage, "BACK")}>
                                            {this.state.districtLoader ? (
                                                <Loader type="Oval" color="#2A3F54" height={15} width={40} />
                                            ) : (<Icon name="fa-angle-double-left" />)}
                                        </button>
                                        <button disabled={this.state.districtLoader ? (true) : (false)} type="button" className="btn btn-info" onClick={() => this.handlePagination(nextPage, "NEXT")}>
                                            {this.state.districtLoader ? (
                                                <Loader type="Oval" color="#2A3F54" height={15} width={40} />
                                            ) : (<Icon name="fa-angle-double-right" />)}

                                        </button>
                                    </div>

                                    <div className="clearfix"></div>
                                </div>
                                <div className="x_content">
                                    <canvas className="noHover" id="districtActivityChart" style={{ height: "250px" }} />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));

// export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
//     apiKey: apiKey
// })(Dashboard))));
