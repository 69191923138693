import gql from 'graphql-tag';




export const GET_MOTHER_SUPPORT_SUBMISSIONS = gql`
query GetMotherSupportSubmissions($status:settingStatus,$skip:Int,$first:Int){
    getMotherSupportSubmissions( status:$status,skip:$skip,first:$first){
        motherSupportSettingSubmissions{
            id
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            msg
            facilitatingPHM
            msgPresident
            msgPresidentTel
            establishmentAt
            numOfMembers
            numOfMaleMembers
            meetingFrequency
            numOfMeetingInYear
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
        count
    }
}
`

export const SEARCH_MOTHER_SUPPORT_SUBMISSIONS = gql`
  query SearchMotherSupportSubmissions($searchTerm: String){
    searchMotherSupportSubmissions(searchTerm:$searchTerm){
        motherSupportSettingSubmissions{
            id
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            msg
            facilitatingPHM
            msgPresident
            msgPresidentTel
            establishmentAt
            numOfMembers
            numOfMaleMembers
            meetingFrequency
            numOfMeetingInYear
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
        count
        }
    }
`;

export const GET_PRESCHOOL_SUBMISSIONS = gql`
query GetPreschoolSubmissions($status:settingStatus,$skip:Int,$first:Int){
    getPreschoolSubmissions( status:$status,skip:$skip,first:$first){
        preschoolSettingSubmissions{
            id
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfPreschool
            address
            placeOfRegistration
            nameOfFacilitator
            designationOfFacilitator
            nameOfECCDOfficer
            numOfPreschoolChildren
            numOfTeachers 
            startingDate
            startingYearAndMonth
            marksAtInitialAssessment
            marksAtSubsequentAssessments
            conductedActivities
            involvementActivities
            comments
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
        count
    }
}
`

export const SEARCH_PRE_SCHOOL_SUBMISSIONS = gql`
  query SearchPreSchoolSubmissions($searchTerm: String){
    searchPreSchoolSubmissions(searchTerm:$searchTerm){
        preschoolSettingSubmissions{
            id
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfPreschool
            address
            placeOfRegistration
            nameOfFacilitator
            designationOfFacilitator
            nameOfECCDOfficer
            numOfPreschoolChildren
            numOfTeachers 
            startingDate
            startingYearAndMonth
            marksAtInitialAssessment
            marksAtSubsequentAssessments
            conductedActivities
            involvementActivities
            comments
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
        count
        }
    }
`;

export const GET_SCHOOL_SUBMISSIONS = gql`
query GetSchoolSubmissions($status:settingStatus,$skip: Int ,$first: Int){
    getSchoolSubmissions(status:$status,skip:$skip, first: $first){
        schoolSettingSubmissions{
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfSchool
            nameOfFacilitatingPHM
            nameOfFacilitatingPHI
            schoolPrincipalName
            schoolPrincipalTel
            dateOfEstablishmentOfSchool
            numOfStudents
            meetingFrequency
            numOfMeetingsInyear
            numOfProgramsHealthAuthority
            conductedActivities
            supervisionVisit
            comments
            status
            images
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
        count
    }
}
`


export const SEARCH_SCHOOL_SUBMISSIONS = gql`
  query SearchschoolSubmissions($searchTerm: String){
    searchschoolSubmissions(searchTerm:$searchTerm){
        schoolSettingSubmissions{
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfSchool
            nameOfFacilitatingPHM
            nameOfFacilitatingPHI
            schoolPrincipalName
            schoolPrincipalTel
            dateOfEstablishmentOfSchool
            numOfStudents
            meetingFrequency
            numOfMeetingsInyear
            numOfProgramsHealthAuthority
            conductedActivities
            supervisionVisit
            comments
            status
            images
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
        count
        }
    }
`;

export const GET_WORKPLACE_SUBMISSIONS = gql`
query GetWorkPlaceSubmissions($status:settingStatus,$skip:Int,$first:Int){
    getWorkPlaceSubmissions(status:$status,skip:$skip,first:$first ){
        workPlaceSettingSubmissions{
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            workSettingName
            typeOfWorkSetting
            facilitatingPHI
            facilitatingHEO
            numOfMaleEmployees
            numOfFemaleEmployees
            numOfDifferentlyAbleEmployees
            numOfPregnantEmployees
            numOfPostpartumEmployees
            dateOfConductingHealthPromotion
            numOfVisits
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
        count
    }
}
`

export const SEARCH_WORK_PLACE_SUBMISSIONS = gql`
  query SearchWorkPlaceSubmissions($searchTerm: String){
    searchWorkPlaceSubmissions(searchTerm:$searchTerm){
        workPlaceSettingSubmissions{
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            workSettingName
            typeOfWorkSetting
            facilitatingPHI
            facilitatingHEO
            numOfMaleEmployees
            numOfFemaleEmployees
            numOfDifferentlyAbleEmployees
            numOfPregnantEmployees
            numOfPostpartumEmployees
            dateOfConductingHealthPromotion
            numOfVisits
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
        count
        }
    }
`;

export const GET_VILLAGE_SUBMISSIONS = gql`
query GetVillageSubmissions($status:settingStatus,$skip: Int ,$first: Int){
    getVillageSubmissions(status:$status,skip:$skip, first:$first){
        villageSettingSubmissions{
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfVillageSetting
            nameOfFacilitatingPHM
            nameOfFacilitatingPHI
            nameOfVillagePresident
            numOfVillagePresident
            dateOfEstablishment
            numOfMembers
            numOfMembersOverSixty
            meetingFrequency
            numOfMeetingsInyear
            numOfProgramsOtherStackholders
            conductedActivities
            supervisionVisit
            comments
            status
            images
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
        count
    }
}
`

export const SEARCH_VILLAGE_SUBMISSIONS = gql`
  query SearchVillageSubmissions($searchTerm: String){
    searchVillageSubmissions(searchTerm:$searchTerm){
        villageSettingSubmissions{
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfVillageSetting
            nameOfFacilitatingPHM
            nameOfFacilitatingPHI
            nameOfVillagePresident
            numOfVillagePresident
            dateOfEstablishment
            numOfMembers
            numOfMembersOverSixty
            meetingFrequency
            numOfMeetingsInyear
            numOfProgramsOtherStackholders
            conductedActivities
            supervisionVisit
            comments
            status
            images
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
        count
        }
    }
`;


export const GET_HOSPITAL_SUBMISSIONS = gql`
   query GetHospitalSubmissions($status:settingStatus,$skip:Int,$first: Int){
    getHospitalSubmissions(status:$status,skip:$skip,first:$first){
        hospitalSettingSubmissions{
                    id
                    provinceId
                    province
                    districtId
                    district
                    mohArea
                    mohAreaId
                    phmArea
                    phmAreaId
                    gndivision
                    gndivisionId
                    location
                    nameOfFacilitatingCommittee 
                    availabilityOfHealthEducationUnit 
                    nameOfFacilitatingOfficer 
                    nameOfFacilitatingCommitteePresident
                    telNumberOfHealthPromotionCommitteePresident 
                    dateOfEstablishmentOfCommittee
                    nameOfRepresentativeFromCommunity 
                    contactNumberOfRepresentativeFromCommunity 
                    numOfCommitteeMembers 
                    meetingFrequency 
                    numOfMeetingsInyear 
                    numOfMeetingsConductedWithOther 
                    conductedActivities
                    comments
                    images
                    status
                    settingId
                    createdAt
                    updatedAt
                    createdBy
                    approvedBy
        }
        count
    }
   }
`

export const SEARCH_HOSPITAL_SUBMISSIONS = gql`
  query SearchHospitalSubmissions($searchTerm: String){
    searchHospitalSubmissions(searchTerm:$searchTerm){
        hospitalSettingSubmissions{
                    id
                    provinceId
                    province
                    districtId
                    district
                    mohArea
                    mohAreaId
                    phmArea
                    phmAreaId
                    gndivision
                    gndivisionId
                    location
                    nameOfFacilitatingCommittee 
                    availabilityOfHealthEducationUnit 
                    nameOfFacilitatingOfficer 
                    nameOfFacilitatingCommitteePresident
                    telNumberOfHealthPromotionCommitteePresident 
                    dateOfEstablishmentOfCommittee
                    nameOfRepresentativeFromCommunity 
                    contactNumberOfRepresentativeFromCommunity 
                    numOfCommitteeMembers 
                    meetingFrequency 
                    numOfMeetingsInyear 
                    numOfMeetingsConductedWithOther 
                    conductedActivities
                    comments
                    images
                    status
                    settingId
                    createdAt
                    updatedAt
                    createdBy
                    approvedBy
        }
        count
        }
    }
`;

export const GET_ALL_SETTINGS = gql`
 query {
    getAllSettings{
        id
        name
        type
    }
 }
`;

export const ADD_MOTHERSUPPORT_SETTING_SUBMISSION = gql`
mutation AddMotherSupportSettingSubmission($registrationNumber:String,$provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,$msg: String,$facilitatingPHM: String,$msgPresident: String,$msgPresidentTel: String,$establishmentAt: String,$numOfMembers: Int,$numOfMaleMembers: Int,$meetingFrequency: MeetingFrequency,$numOfMeetingInYear: Int,$conductedActivities: JSON,$supervisionVisit: String,$comments: String,$status: settingStatus,$settingId: String!,$images: JSON){
    addMotherSupportSettingSubmission( registrationNumber:$registrationNumber,provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon: $lon,msg: $msg,facilitatingPHM: $facilitatingPHM,msgPresident: $msgPresident,msgPresidentTel: $msgPresidentTel,establishmentAt: $establishmentAt,numOfMembers: $numOfMembers,numOfMaleMembers: $numOfMaleMembers,meetingFrequency: $meetingFrequency,numOfMeetingInYear: $numOfMeetingInYear,conductedActivities: $conductedActivities,supervisionVisit:$supervisionVisit,comments: $comments,status: $status,settingId: $settingId,images: $images )
        {
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            msg
            facilitatingPHM
            msgPresident
            msgPresidentTel
            establishmentAt
            numOfMembers
            numOfMaleMembers
            meetingFrequency
            numOfMeetingInYear
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const ADD_PRESCHOOL_SETTING_SUBMISSION = gql`
mutation AddPreschoolSettingSubmission($registrationNumber:String,$provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,
    $nameOfPreschool: String ,
    $address: String,
    $placeOfRegistration: String,
    $nameOfFacilitator: String,
    $designationOfFacilitator: String,
    $nameOfECCDOfficer: String,
    $numOfPreschoolChildren: Int,
    $numOfTeachers: Int,
    $startingDate: String,
    $marksAtInitialAssessment: JSON
    $marksAtSubsequentAssessments: JSON,
    $conductedActivities: JSON,
    $involvementActivities: JSON,
    $comments: String,$status: settingStatus,$settingId: String!){
    addPreschoolSettingSubmission( registrationNumber:$registrationNumber,provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon: $lon,
        nameOfPreschool: $nameOfPreschool,
        address: $address,
        placeOfRegistration: $placeOfRegistration,
        nameOfFacilitator: $nameOfFacilitator,
        designationOfFacilitator: $designationOfFacilitator,
        nameOfECCDOfficer: $nameOfECCDOfficer,
        numOfPreschoolChildren: $numOfPreschoolChildren,
        numOfTeachers: $numOfTeachers,
        startingDate: $startingDate,
        marksAtInitialAssessment: $marksAtInitialAssessment,
        marksAtSubsequentAssessments: $marksAtSubsequentAssessments,
        conductedActivities: $conductedActivities,
        involvementActivities: $involvementActivities,
        comments: $comments,status: $status,settingId: $settingId )
        {
            id
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfPreschool
            address
            placeOfRegistration
            nameOfFacilitator
            designationOfFacilitator
            nameOfECCDOfficer
            numOfPreschoolChildren
            numOfTeachers 
            startingDate
            startingYearAndMonth
            marksAtInitialAssessment
            marksAtSubsequentAssessments
            conductedActivities
            involvementActivities
            comments
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const ADD_WORKPLACE_SETTING_SUBMISSION = gql`
mutation AddWorkPlaceSettingSubmission($provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,$workSettingName: String,$typeOfWorkSetting:String,$facilitatingPHI:String,$facilitatingHEO: String,$numOfMaleEmployees: Int,$numOfFemaleEmployees: Int,$numOfDifferentlyAbleEmployees: Int,$numOfPregnantEmployees: Int,$numOfPostpartumEmployees: Int,$dateOfConductingHealthPromotion: String,$numOfVisits: Int,$conductedActivities: JSON,$supervisionVisit: String,$comments: String,$status: settingStatus,$settingId: String!,$images: JSON){
    addWorkPlaceSettingSubmission(provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon: $lon,workSettingName:$workSettingName,typeOfWorkSetting:$typeOfWorkSetting,facilitatingPHI:$facilitatingPHI,facilitatingHEO: $facilitatingHEO,numOfMaleEmployees: $numOfMaleEmployees,numOfFemaleEmployees: $numOfFemaleEmployees,numOfDifferentlyAbleEmployees: $numOfDifferentlyAbleEmployees,numOfPregnantEmployees: $numOfPregnantEmployees,numOfPostpartumEmployees: $numOfPostpartumEmployees,dateOfConductingHealthPromotion: $dateOfConductingHealthPromotion,numOfVisits: $numOfVisits,conductedActivities: $conductedActivities,supervisionVisit:$supervisionVisit,comments: $comments,status: $status,settingId: $settingId,images: $images )
        {
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            workSettingName
            typeOfWorkSetting
            facilitatingPHI
            facilitatingHEO
            numOfMaleEmployees
            numOfFemaleEmployees
            numOfDifferentlyAbleEmployees
            numOfPregnantEmployees
            numOfPostpartumEmployees
            dateOfConductingHealthPromotion
            numOfVisits
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const ADD_HOSPITAL_SETTING_SUBMISSION = gql`
mutation AddHospitalSettingSubmission($provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,
$nameOfFacilitatingCommittee: String,
$availabilityOfHealthEducationUnit:String,
$nameOfFacilitatingOfficer:String,
$nameOfFacilitatingCommitteePresident: String,
$telNumberOfHealthPromotionCommitteePresident: String,
$dateOfEstablishmentOfCommittee: String,
$nameOfRepresentativeFromCommunity: String,
$contactNumberOfRepresentativeFromCommunity: String,
$numOfCommitteeMembers: Int,
$meetingFrequency: MeetingFrequency,
$numOfMeetingsInyear: Int,
$numOfMeetingsConductedWithOther: Int,
$conductedActivities: JSON,
$comments: String,$status: settingStatus,$settingId: String!,$images: JSON){
    addHospitalSettingSubmission(provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon: $lon,
    nameOfFacilitatingCommittee:$nameOfFacilitatingCommittee,
    availabilityOfHealthEducationUnit:$availabilityOfHealthEducationUnit,
    nameOfFacilitatingOfficer:$nameOfFacilitatingOfficer,
    nameOfFacilitatingCommitteePresident: $nameOfFacilitatingCommitteePresident,
    telNumberOfHealthPromotionCommitteePresident: $telNumberOfHealthPromotionCommitteePresident,
    dateOfEstablishmentOfCommittee: $dateOfEstablishmentOfCommittee,
    nameOfRepresentativeFromCommunity: $nameOfRepresentativeFromCommunity,
    contactNumberOfRepresentativeFromCommunity: $contactNumberOfRepresentativeFromCommunity,
    numOfCommitteeMembers: $numOfCommitteeMembers,
    meetingFrequency: $meetingFrequency,
    numOfMeetingsInyear: $numOfMeetingsInyear,
    numOfMeetingsConductedWithOther: $numOfMeetingsConductedWithOther,
    conductedActivities:$conductedActivities,
    comments: $comments,status: $status,settingId: $settingId,images: $images )
        {
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfFacilitatingCommittee 
            availabilityOfHealthEducationUnit 
            nameOfFacilitatingOfficer 
            nameOfFacilitatingCommitteePresident
            telNumberOfHealthPromotionCommitteePresident 
            dateOfEstablishmentOfCommittee
            nameOfRepresentativeFromCommunity 
            contactNumberOfRepresentativeFromCommunity 
            numOfCommitteeMembers 
            meetingFrequency 
            numOfMeetingsInyear 
            numOfMeetingsConductedWithOther 
            conductedActivities
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const ADD_SCHOOL_SETTING_SUBMISSION = gql`
mutation AddSchoolSettingSubmission($provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,
    $nameOfSchool: String
    $nameOfFacilitatingPHM: String
    $nameOfFacilitatingPHI: String
    $schoolPrincipalName: String
    $schoolPrincipalTel: String
    $dateOfEstablishmentOfSchool: String
    $numOfStudents: Int
    $meetingFrequency: MeetingFrequency
    $numOfMeetingsInyear: Int
    $numOfProgramsHealthAuthority: Int
    $conductedActivities: JSON
    $supervisionVisit: String
    $comments: String,$status: settingStatus,$settingId: String!,$images: JSON){
        addSchoolSettingSubmission(provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon: $lon,
            nameOfSchool:$nameOfSchool,
            nameOfFacilitatingPHM:$nameOfFacilitatingPHM,
            nameOfFacilitatingPHI:$nameOfFacilitatingPHI,
            schoolPrincipalName: $schoolPrincipalName,
            schoolPrincipalTel: $schoolPrincipalTel,
            dateOfEstablishmentOfSchool: $dateOfEstablishmentOfSchool,
            numOfStudents: $numOfStudents,
            meetingFrequency: $meetingFrequency
            numOfMeetingsInyear: $numOfMeetingsInyear,
            numOfProgramsHealthAuthority: $numOfProgramsHealthAuthority,
            conductedActivities: $conductedActivities,
            supervisionVisit: $supervisionVisit,
            comments: $comments,status: $status,settingId: $settingId,images: $images )
            {
                id
                provinceId
                province
                districtId
                district
                mohArea
                mohAreaId
                phmArea
                phmAreaId
                gndivision
                gndivisionId
                location
                nameOfSchool
                nameOfFacilitatingPHM
                nameOfFacilitatingPHI
                schoolPrincipalName
                schoolPrincipalTel
                dateOfEstablishmentOfSchool
                numOfStudents
                meetingFrequency
                numOfMeetingsInyear
                numOfProgramsHealthAuthority
                conductedActivities
                supervisionVisit
                comments
                status
                images
                settingId
                createdAt
                updatedAt
                createdBy
                approvedBy
            }
}
`

export const ADD_VILLAGE_SETTING_SUBMISSION = gql`
mutation AddVillageSettingSubmission($provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,
    $nameOfVillageSetting: String
    $nameOfFacilitatingPHM: String
    $nameOfFacilitatingPHI: String
    $nameOfVillagePresident: String
    $numOfVillagePresident: String
    $dateOfEstablishment: String
    $numOfMembers: Int
    $numOfMembersOverSixty: Int
    $meetingFrequency: MeetingFrequency
    $numOfMeetingsInyear: Int
    $numOfProgramsOtherStackholders: Int
    $conductedActivities: JSON
    $supervisionVisit: String
    $comments: String,$status: settingStatus,$settingId: String!,$images: JSON){
        addVillageSettingSubmission(provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon: $lon,
            nameOfVillageSetting:$nameOfVillageSetting,
            nameOfFacilitatingPHM:$nameOfFacilitatingPHM,
            nameOfFacilitatingPHI:$nameOfFacilitatingPHI,
            nameOfVillagePresident: $nameOfVillagePresident,
            numOfVillagePresident: $numOfVillagePresident,
            dateOfEstablishment: $dateOfEstablishment,
            numOfMembers: $numOfMembers,
            numOfMembersOverSixty: $numOfMembersOverSixty,
            meetingFrequency: $meetingFrequency,
            numOfMeetingsInyear: $numOfMeetingsInyear,
            numOfProgramsOtherStackholders: $numOfProgramsOtherStackholders,
            conductedActivities: $conductedActivities,
            supervisionVisit: $supervisionVisit,
            comments: $comments,status: $status,settingId: $settingId,images: $images )
            {
                id
                provinceId
                province
                districtId
                district
                mohArea
                mohAreaId
                phmArea
                phmAreaId
                gndivision
                gndivisionId
                location
                nameOfVillageSetting
                nameOfFacilitatingPHM
                nameOfFacilitatingPHI
                nameOfVillagePresident
                numOfVillagePresident
                dateOfEstablishment
                numOfMembers
                numOfMembersOverSixty
                meetingFrequency
                numOfMeetingsInyear
                numOfProgramsOtherStackholders
                conductedActivities
                supervisionVisit
                comments
                status
                images
                settingId
                createdAt
                updatedAt
                createdBy
                approvedBy
            }
}
`


export const APPROVE_MOTHERSUPPORT_SETTING_SUBMISSION = gql`
mutation ApproveMotherSupportSettingSubmission($settingSubmissionId:String!,$status: settingStatus){
    approveMotherSupportSettingSubmission( settingSubmissionId:$settingSubmissionId,status: $status)
        {
            id
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            msg
            facilitatingPHM
            msgPresident
            msgPresidentTel
            establishmentAt
            numOfMembers
            numOfMaleMembers
            meetingFrequency
            numOfMeetingInYear
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const APPROVE_PRESCHOOL_SETTING_SUBMISSION = gql`
mutation ApprovePreschoolSettingSubmission($settingSubmissionId:String!,$status: settingStatus){
    approvePreschoolSettingSubmission( settingSubmissionId:$settingSubmissionId,status: $status)
        {
            id
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfPreschool
            address
            placeOfRegistration
            nameOfFacilitator
            designationOfFacilitator
            nameOfECCDOfficer
            numOfPreschoolChildren
            numOfTeachers 
            startingYearAndMonth
            marksAtInitialAssessment
            marksAtSubsequentAssessments
            conductedActivities
            involvementActivities
            comments
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const APPROVE_WORKPLACE_SETTING_SUBMISSION = gql`
mutation ApproveWorkPlaceSettingSubmission($settingSubmissionId:String!,$status: settingStatus){
    approveWorkPlaceSettingSubmission( settingSubmissionId:$settingSubmissionId,status: $status)
        {
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            workSettingName
            typeOfWorkSetting
            facilitatingPHI
            facilitatingHEO
            numOfMaleEmployees
            numOfFemaleEmployees
            numOfDifferentlyAbleEmployees
            numOfPregnantEmployees
            numOfPostpartumEmployees
            dateOfConductingHealthPromotion
            numOfVisits
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const APPROVE_HOSPITAL_SETTING_SUBMISSION = gql`
mutation ApproveHospitalSettingSubmission($settingSubmissionId:String!,$status: settingStatus){
    approveHospitalSettingSubmission( settingSubmissionId:$settingSubmissionId,status: $status)
        {
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfFacilitatingCommittee 
            availabilityOfHealthEducationUnit 
            nameOfFacilitatingOfficer 
            nameOfFacilitatingCommitteePresident
            telNumberOfHealthPromotionCommitteePresident 
            dateOfEstablishmentOfCommittee
            nameOfRepresentativeFromCommunity 
            contactNumberOfRepresentativeFromCommunity 
            numOfCommitteeMembers 
            meetingFrequency 
            numOfMeetingsInyear 
            numOfMeetingsConductedWithOther 
            conductedActivities
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const APPROVE_SCHOOL_SETTING_SUBMISSION = gql`
mutation ApproveSchoolSettingSubmission($settingSubmissionId:String!,$status: settingStatus){
    approveSchoolSettingSubmission( settingSubmissionId:$settingSubmissionId,status: $status)
        {
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfSchool
            nameOfFacilitatingPHM
            nameOfFacilitatingPHI
            schoolPrincipalName
            schoolPrincipalTel
            dateOfEstablishmentOfSchool
            numOfStudents
            meetingFrequency
            numOfMeetingsInyear
            numOfProgramsHealthAuthority
            conductedActivities
            supervisionVisit
            comments
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const APPROVE_VILLAGE_SETTING_SUBMISSION = gql`
mutation ApproveVillageSettingSubmission($settingSubmissionId:String!,$status: settingStatus){
    approveVillageSettingSubmission( settingSubmissionId:$settingSubmissionId,status: $status)
        {
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfVillageSetting
            nameOfFacilitatingPHM
            nameOfFacilitatingPHI
            nameOfVillagePresident
            numOfVillagePresident
            dateOfEstablishment
            numOfMembers
            numOfMembersOverSixty
            meetingFrequency
            numOfMeetingsInyear
            numOfProgramsOtherStackholders
            conductedActivities
            supervisionVisit
            comments
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const EDIT_MOTHERSUPPORT_SETTING_SUBMISSION = gql`
mutation EditMotherSupportSettingSubmission($registrationNumber:String,$settingSubmissionId:String!,$provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,$msg: String,$facilitatingPHM: String,$msgPresident: String,$msgPresidentTel: String,$establishmentAt: String,$numOfMembers: Int,$numOfMaleMembers: Int,$meetingFrequency: MeetingFrequency,$numOfMeetingInYear: Int,$conductedActivities: JSON,$supervisionVisit: String,$comments: String,$status: settingStatus,$images: JSON){
    editMotherSupportSettingSubmission(registrationNumber:$registrationNumber,settingSubmissionId:$settingSubmissionId,provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon:$lon,msg: $msg,facilitatingPHM: $facilitatingPHM,msgPresident: $msgPresident,msgPresidentTel: $msgPresidentTel,establishmentAt: $establishmentAt,numOfMembers: $numOfMembers,numOfMaleMembers: $numOfMaleMembers,meetingFrequency: $meetingFrequency,numOfMeetingInYear: $numOfMeetingInYear,conductedActivities: $conductedActivities,supervisionVisit:$supervisionVisit,comments: $comments,status: $status ,images:$images)
        {
            id
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            msg
            facilitatingPHM
            msgPresident
            msgPresidentTel
            establishmentAt
            numOfMembers
            numOfMaleMembers
            meetingFrequency
            numOfMeetingInYear
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const EDIT_PRESCHOOL_SETTING_SUBMISSION = gql`
mutation EditPreschoolSettingSubmission($registrationNumber:String,$settingSubmissionId:String!,$provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,
    $nameOfPreschool: String ,
    $address: String,
    $placeOfRegistration: String,
    $nameOfFacilitator: String,
    $designationOfFacilitator: String,
    $nameOfECCDOfficer: String,
    $numOfPreschoolChildren: Int,
    $numOfTeachers: Int,
    $startingDate: String,
    $marksAtInitialAssessment: JSON
    $marksAtSubsequentAssessments: JSON,
    $conductedActivities: JSON,
    $involvementActivities: JSON,
    $comments: String,$status: settingStatus){
        editPreschoolSettingSubmission(registrationNumber:$registrationNumber,settingSubmissionId:$settingSubmissionId,provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon:$lon,
            nameOfPreschool: $nameOfPreschool,
            address: $address,
            placeOfRegistration: $placeOfRegistration,
            nameOfFacilitator: $nameOfFacilitator,
            designationOfFacilitator: $designationOfFacilitator,
            nameOfECCDOfficer: $nameOfECCDOfficer,
            numOfPreschoolChildren: $numOfPreschoolChildren,
            numOfTeachers: $numOfTeachers,
            startingDate: $startingDate,
            marksAtInitialAssessment: $marksAtInitialAssessment,
            marksAtSubsequentAssessments: $marksAtSubsequentAssessments,
            conductedActivities: $conductedActivities,
            involvementActivities: $involvementActivities,
            comments: $comments,status: $status)
        {
            id
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfPreschool
            address
            placeOfRegistration
            nameOfFacilitator
            designationOfFacilitator
            nameOfECCDOfficer
            numOfPreschoolChildren
            numOfTeachers 
            startingDate
            startingYearAndMonth
            marksAtInitialAssessment
            marksAtSubsequentAssessments
            conductedActivities
            involvementActivities
            comments
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const EDIT_WORKPLACE_SETTING_SUBMISSION = gql`
mutation EditWorkPlaceSettingSubmission($settingSubmissionId:String!,$provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,$workSettingName:String,$typeOfWorkSetting:String,$facilitatingPHI:String,$facilitatingHEO: String,$numOfMaleEmployees: Int,$numOfFemaleEmployees: Int,$numOfDifferentlyAbleEmployees: Int,$numOfPregnantEmployees: Int,$numOfPostpartumEmployees: Int,$dateOfConductingHealthPromotion: String,$numOfVisits: Int,$conductedActivities: JSON,$supervisionVisit: String,$comments: String,$status: settingStatus,$images: JSON){
    editWorkPlaceSettingSubmission( settingSubmissionId:$settingSubmissionId,provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon:$lon,workSettingName:$workSettingName,typeOfWorkSetting:$typeOfWorkSetting,facilitatingPHI:$facilitatingPHI,facilitatingHEO: $facilitatingHEO,numOfMaleEmployees: $numOfMaleEmployees,numOfFemaleEmployees: $numOfFemaleEmployees,numOfDifferentlyAbleEmployees: $numOfDifferentlyAbleEmployees,numOfPregnantEmployees: $numOfPregnantEmployees,numOfPostpartumEmployees: $numOfPostpartumEmployees,dateOfConductingHealthPromotion: $dateOfConductingHealthPromotion,numOfVisits: $numOfVisits,conductedActivities: $conductedActivities,supervisionVisit:$supervisionVisit,comments: $comments,status: $status,images:$images )
        {
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            workSettingName
            typeOfWorkSetting
            facilitatingPHI
            facilitatingHEO
            numOfMaleEmployees
            numOfFemaleEmployees
            numOfDifferentlyAbleEmployees
            numOfPregnantEmployees
            numOfPostpartumEmployees
            dateOfConductingHealthPromotion
            numOfVisits
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
        }
}
`

export const EDIT_HOSPITAL_SETTING_SUBMISSION = gql`
mutation EditHospitalSettingSubmission($settingSubmissionId:String!,$provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,
    $nameOfFacilitatingCommittee: String,
    $availabilityOfHealthEducationUnit:String,
    $nameOfFacilitatingOfficer:String,
    $nameOfFacilitatingCommitteePresident: String,
    $telNumberOfHealthPromotionCommitteePresident: String,
    $dateOfEstablishmentOfCommittee: String,
    $nameOfRepresentativeFromCommunity: String,
    $contactNumberOfRepresentativeFromCommunity: String,
    $numOfCommitteeMembers: Int,
    $meetingFrequency: MeetingFrequency,
    $numOfMeetingsInyear: Int,
    $numOfMeetingsConductedWithOther: Int,
    $conductedActivities: JSON,
    $comments: String,$status: settingStatus,$images: JSON){
        editHospitalSettingSubmission( settingSubmissionId:$settingSubmissionId,provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon:$lon,
            nameOfFacilitatingCommittee:$nameOfFacilitatingCommittee,
            availabilityOfHealthEducationUnit:$availabilityOfHealthEducationUnit,
            nameOfFacilitatingOfficer:$nameOfFacilitatingOfficer,
            nameOfFacilitatingCommitteePresident: $nameOfFacilitatingCommitteePresident,
            telNumberOfHealthPromotionCommitteePresident: $telNumberOfHealthPromotionCommitteePresident,
            dateOfEstablishmentOfCommittee: $dateOfEstablishmentOfCommittee,
            nameOfRepresentativeFromCommunity: $nameOfRepresentativeFromCommunity,
            contactNumberOfRepresentativeFromCommunity: $contactNumberOfRepresentativeFromCommunity,
            numOfCommitteeMembers: $numOfCommitteeMembers,
            meetingFrequency: $meetingFrequency,
            numOfMeetingsInyear: $numOfMeetingsInyear,
            numOfMeetingsConductedWithOther: $numOfMeetingsConductedWithOther,
            conductedActivities:$conductedActivities,
            comments: $comments,status: $status,images:$images )
                {
                    id
                    provinceId
                    province
                    districtId
                    district
                    mohArea
                    mohAreaId
                    phmArea
                    phmAreaId
                    gndivision
                    gndivisionId
                    location
                    nameOfFacilitatingCommittee 
                    availabilityOfHealthEducationUnit 
                    nameOfFacilitatingOfficer 
                    nameOfFacilitatingCommitteePresident
                    telNumberOfHealthPromotionCommitteePresident 
                    dateOfEstablishmentOfCommittee
                    nameOfRepresentativeFromCommunity 
                    contactNumberOfRepresentativeFromCommunity 
                    numOfCommitteeMembers 
                    meetingFrequency 
                    numOfMeetingsInyear 
                    numOfMeetingsConductedWithOther 
                    conductedActivities
                    comments
                    images
                    status
                    settingId
                    createdAt
                    updatedAt
                    createdBy
                    approvedBy
                }
}
`

export const EDIT_SCHOOL_SETTING_SUBMISSION = gql`
mutation EditSchoolSettingSubmission($settingSubmissionId:String!,$provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,
    $nameOfSchool: String
    $nameOfFacilitatingPHM: String
    $nameOfFacilitatingPHI: String
    $schoolPrincipalName: String
    $schoolPrincipalTel: String
    $dateOfEstablishmentOfSchool: String
    $numOfStudents: Int
    $meetingFrequency: MeetingFrequency
    $numOfMeetingsInyear: Int
    $numOfProgramsHealthAuthority: Int
    $conductedActivities: JSON
    $supervisionVisit: String
    $comments: String,$status: settingStatus,$images: JSON){
        editSchoolSettingSubmission( settingSubmissionId:$settingSubmissionId,provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon:$lon,
            nameOfSchool:$nameOfSchool,
            nameOfFacilitatingPHM:$nameOfFacilitatingPHM,
            nameOfFacilitatingPHI:$nameOfFacilitatingPHI,
            schoolPrincipalName: $schoolPrincipalName,
            schoolPrincipalTel: $schoolPrincipalTel,
            dateOfEstablishmentOfSchool: $dateOfEstablishmentOfSchool,
            numOfStudents: $numOfStudents,
            meetingFrequency: $meetingFrequency
            numOfMeetingsInyear: $numOfMeetingsInyear,
            numOfProgramsHealthAuthority: $numOfProgramsHealthAuthority,
            conductedActivities: $conductedActivities,
            supervisionVisit: $supervisionVisit,
            comments: $comments,status: $status,images:$images )
                {
                    id
                    provinceId
                    province
                    districtId
                    district
                    mohArea
                    mohAreaId
                    phmArea
                    phmAreaId
                    gndivision
                    gndivisionId
                    location
                    nameOfSchool
                    nameOfFacilitatingPHM
                    nameOfFacilitatingPHI
                    schoolPrincipalName
                    schoolPrincipalTel
                    dateOfEstablishmentOfSchool
                    numOfStudents
                    meetingFrequency
                    numOfMeetingsInyear
                    numOfProgramsHealthAuthority
                    conductedActivities
                    supervisionVisit
                    comments
                    status
                    images
                    settingId
                    createdAt
                    updatedAt
                    createdBy
                    approvedBy
                }
}
`

export const EDIT_VILLAGE_SETTING_SUBMISSION = gql`
mutation EditVillageSettingSubmission($settingSubmissionId:String!,$provinceId: String,$province: String,$districtId: String,$district: String,$mohArea: String,$mohAreaId: String,$phmArea: String,$gndivision: String,$gndivisionId: String,$lat: String,$lon: String,
    $nameOfVillageSetting: String
    $nameOfFacilitatingPHM: String
    $nameOfFacilitatingPHI: String
    $nameOfVillagePresident: String
    $numOfVillagePresident: String
    $dateOfEstablishment: String
    $numOfMembers: Int
    $numOfMembersOverSixty: Int
    $meetingFrequency: MeetingFrequency
    $numOfMeetingsInyear: Int
    $numOfProgramsOtherStackholders: Int
    $conductedActivities: JSON
    $supervisionVisit: String
    $comments: String,$status: settingStatus,$images: JSON){
        editVillageSettingSubmission( settingSubmissionId:$settingSubmissionId,provinceId: $provinceId,province: $province,districtId: $districtId,district: $district,mohArea: $mohArea,mohAreaId: $mohAreaId,phmArea: $phmArea,gndivision: $gndivision,gndivisionId: $gndivisionId,lat: $lat,lon:$lon,
            nameOfVillageSetting:$nameOfVillageSetting,
            nameOfFacilitatingPHM:$nameOfFacilitatingPHM,
            nameOfFacilitatingPHI:$nameOfFacilitatingPHI,
            nameOfVillagePresident: $nameOfVillagePresident,
            numOfVillagePresident: $numOfVillagePresident,
            dateOfEstablishment: $dateOfEstablishment,
            numOfMembers: $numOfMembers,
            numOfMembersOverSixty: $numOfMembersOverSixty,
            meetingFrequency: $meetingFrequency,
            numOfMeetingsInyear: $numOfMeetingsInyear,
            numOfProgramsOtherStackholders: $numOfProgramsOtherStackholders,
            conductedActivities: $conductedActivities,
            supervisionVisit: $supervisionVisit,
            comments: $comments,status: $status,images:$images )
                {
                    id
                    provinceId
                    province
                    districtId
                    district
                    mohArea
                    mohAreaId
                    phmArea
                    phmAreaId
                    gndivision
                    gndivisionId
                    location
                    nameOfVillageSetting
                    nameOfFacilitatingPHM
                    nameOfFacilitatingPHI
                    nameOfVillagePresident
                    numOfVillagePresident
                    dateOfEstablishment
                    numOfMembers
                    numOfMembersOverSixty
                    meetingFrequency
                    numOfMeetingsInyear
                    numOfProgramsOtherStackholders
                    conductedActivities
                    supervisionVisit
                    comments
                    status
                    images
                    settingId
                    createdAt
                    updatedAt
                    createdBy
                    approvedBy
                }
}
`

export const GET_SINGLE_MOTHERSUPPORT_SETTING_SUBMISSION = gql`
query GetSingleMotherSupportSettingSubmission($settingSubmissionId:String!){
    getSingleMotherSupportSettingSubmission( settingSubmissionId:$settingSubmissionId){
            id
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            msg
            facilitatingPHM
            msgPresident
            msgPresidentTel
            establishmentAt
            numOfMembers
            numOfMaleMembers
            meetingFrequency
            numOfMeetingInYear
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
    }
}
`

export const GET_SINGLE_PRESCHOOL_SETTING_SUBMISSION = gql`
query GetSinglePreschoolSettingSubmission($settingSubmissionId:String!){
    getSinglePreschoolSettingSubmission(settingSubmissionId:$settingSubmissionId){
            id
            registrationNumber
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfPreschool
            address
            placeOfRegistration
            nameOfFacilitator
            designationOfFacilitator
            nameOfECCDOfficer
            numOfPreschoolChildren
            numOfTeachers 
            startingDate
            startingYearAndMonth
            marksAtInitialAssessment
            marksAtSubsequentAssessments
            conductedActivities
            involvementActivities
            comments
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
    }
}
`
export const GET_SINGLE_WORKPLACE_SETTING_SUBMISSION = gql`
query GetSingleWorkPlaceSettingSubmission($settingSubmissionId:String!){
    getSingleWorkPlaceSettingSubmission( settingSubmissionId:$settingSubmissionId){
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            workSettingName
            typeOfWorkSetting
            facilitatingPHI
            facilitatingHEO
            numOfMaleEmployees
            numOfFemaleEmployees
            numOfDifferentlyAbleEmployees
            numOfPregnantEmployees
            numOfPostpartumEmployees
            dateOfConductingHealthPromotion
            numOfVisits
            conductedActivities
            supervisionVisit
            comments
            images
            status
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
    }
}
`

export const GET_SINGLE_HOSPITAL_SETTING_SUBMISSION = gql`
query GetSingleHospitalSettingSubmission($settingSubmissionId:String!){
    getSingleHospitalSettingSubmission( settingSubmissionId:$settingSubmissionId){
                    id
                    provinceId
                    province
                    districtId
                    district
                    mohArea
                    mohAreaId
                    phmArea
                    phmAreaId
                    gndivision
                    gndivisionId
                    location
                    nameOfFacilitatingCommittee 
                    availabilityOfHealthEducationUnit 
                    nameOfFacilitatingOfficer 
                    nameOfFacilitatingCommitteePresident
                    telNumberOfHealthPromotionCommitteePresident 
                    dateOfEstablishmentOfCommittee
                    nameOfRepresentativeFromCommunity 
                    contactNumberOfRepresentativeFromCommunity 
                    numOfCommitteeMembers 
                    meetingFrequency 
                    numOfMeetingsInyear 
                    numOfMeetingsConductedWithOther 
                    conductedActivities
                    comments
                    images
                    status
                    settingId
                    createdAt
                    updatedAt
                    createdBy
                    approvedBy
    }
}
`

export const GET_SINGLE_SCHOOL_SETTING_SUBMISSION = gql`
query GetSingleSchoolSettingSubmission($settingSubmissionId:String!){
    getSingleSchoolSettingSubmission( settingSubmissionId:$settingSubmissionId){
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfSchool
            nameOfFacilitatingPHM
            nameOfFacilitatingPHI
            schoolPrincipalName
            schoolPrincipalTel
            dateOfEstablishmentOfSchool
            numOfStudents
            meetingFrequency
            numOfMeetingsInyear
            numOfProgramsHealthAuthority
            conductedActivities
            supervisionVisit
            comments
            status
            images
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
    }
}
`

export const GET_SINGLE_VILLAGE_SETTING_SUBMISSION = gql`
query GetSingleVillageSettingSubmission($settingSubmissionId:String!){
    getSingleVillageSettingSubmission( settingSubmissionId:$settingSubmissionId){
            id
            provinceId
            province
            districtId
            district
            mohArea
            mohAreaId
            phmArea
            phmAreaId
            gndivision
            gndivisionId
            location
            nameOfVillageSetting
            nameOfFacilitatingPHM
            nameOfFacilitatingPHI
            nameOfVillagePresident
            numOfVillagePresident
            dateOfEstablishment
            numOfMembers
            numOfMembersOverSixty
            meetingFrequency
            numOfMeetingsInyear
            numOfProgramsOtherStackholders
            conductedActivities
            supervisionVisit
            comments
            status
            images
            settingId
            createdAt
            updatedAt
            createdBy
            approvedBy
    }
}
`



export const DELETE_MOTHERSUPPORT_SETTING_SUBMISSION = gql`
mutation DeleteMotherSupportSettingSubmission($id:String!){
    deleteMotherSupportSettingSubmission(id:$id)
        {
            id
        }
}
`

export const DELETE_PRESCHOOL_SETTING_SUBMISSION = gql`
mutation DeletePreschoolSettingSubmission($id:String!){
    deletePreschoolSettingSubmission(id:$id)
        {
            id
        }
}
`

export const DELETE_SCHOOL_SETTING_SUBMISSION = gql`
mutation DeleteSchoolSettingSubmission($id:String!){
    deleteSchoolSettingSubmission(id:$id)
        {
            id
        }
}
`

export const DELETE_WORKPLACE_SETTING_SUBMISSION = gql`
mutation DeleteWorkPlaceSettingSubmission($id:String!){
    deleteWorkPlaceSettingSubmission(id:$id)
        {
            id
        }
}
`

export const DELETE_HOSPITAL_SETTING_SUBMISSION = gql`
mutation DeleteHospitalSettingSubmission($id:String!){
    deleteHospitalSettingSubmission(id:$id)
        {
            id
        }
}
`

export const DELETE_VILLAGE_SETTING_SUBMISSION = gql`
mutation DeleteVillageSettingSubmission($id:String!){
    deleteVillageSettingSubmission(id:$id)
        {
            id
        }
}
`

export const GET_PENDING_SUBMISSIONS = gql`
query GetPendingSubmissions($skip:Int,$first:Int){
    getPendingSubmissions( skip:$skip,first:$first)
}
`



