import React, { Component } from 'react'
import { GET_ALL_SETTINGS } from '../../queries/SettingQueries'
import { GET_ALL_DISTRICTS } from '../../queries/CommonQueries'
import { GET_SUBMISSION_BY_TYPEAND_DISTRICT } from '../../queries/ReportQueries'
import { GET_SINGLE_USER } from '../../queries/UserQueries'
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions'
import moment from 'moment';
import CountUp from 'react-countup';

//ui
import { Button, Grid, Modal, Dropdown, Icon } from 'semantic-ui-react';
import ScrollContainer from 'react-indiana-drag-scroll';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import Loader from 'react-loader-spinner'
import './Table.css';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}


class SubmissionReportByDistrict extends Component {
    constructor(props) {
        super(props);
        if (localStorage.USER_ID == undefined) {
            this.props.history.push('/');
        }
        this.state = {
            loading: false,
            districtId: "ALL-ISLAND",
            districts: [],
            district: "All Island",
            settingType: "ALL",
            settingName: "All",
            settings: [
                { key: "ALL", text: "All", value: "ALL", id: 'settingId' },
                { key: "MOTHERSUPPORTGROUP", text: "Mother Support Group", value: "MOTHERSUPPORTGROUP", id: 'settingId' },
                { key: "PRESCHOOL", text: "Pre School", value: "PRESCHOOL", id: 'settingId' },
                { key: "SCHOOL", text: "School", value: "SCHOOL", id: 'settingId' },
                { key: "WORKPLACE", text: "Work Place", value: "WORKPLACE", id: 'settingId' },
                { key: "HOSPITAL", text: "Hospital", value: "HOSPITAL", id: 'settingId' },
                { key: "VILLAGE", text: "Village", value: "VILLAGE", id: 'settingId' },

            ],
            statTypes: [
                { key: "MOTHERSUPPORTGROUP", text: "Mother Support Group", count: 0, color: '#1ABB9C' },
                { key: "PRESCHOOL", text: "Pre School", count: 0, color: '#FFC107' },
                { key: "SCHOOL", text: "School", count: 0, color: '#17A2B8' },
                { key: "WORKPLACE", text: "Work Place", count: 0, color: '#DC3545' },
                { key: "HOSPITAL", text: "Hospital", count: 0, color: '#2c87f0' },
                { key: "VILLAGE", text: "Village", count: 0, color: '#28a745' },
            ],
            summaryStat: [],
            submissions: [],
            start: "",
            end: "",
            minDate: new Date().setDate(new Date().getDate() - 1),
            summaryStatColor: '',
        }
    }

    componentDidMount() {

        this.setState({ loading: true });

        this.getAllDistricts().then(result => {
            var districts = result.map(district => {
                return { key: district.id, text: district.district, value: district.id, id: 'districtId' }
            });
            districts.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            districts.unshift({ key: "ALL-ISLAND", id: "districtId", text: "All Island", value: "ALL-ISLAND" });


            if(localStorage.USER_TYPE == "HEO" || localStorage.USER_TYPE == "RDHS"){
                this._getSingleUser(localStorage.USER_ID).then(resultUser => {
                    console.log(resultUser)

                    this.setState({ districts: districts, loading: true, districtId: resultUser.districtId, district: resultUser.district });

                this.getSubmissionsByTypeAndDistrict("ALL", resultUser.districtId).then(result => {
                    console.log(result)
                    this.generateInitialStats(result, "all");
                });
                })
            }
            else if(localStorage.USER_TYPE == "CONSULTANT"){
                this._getSingleUser(localStorage.USER_ID).then(resultUser => {

                    this.setState({ districts: districts, loading: true, districtId: resultUser.districtId, district: resultUser.district, settingType: localStorage.CONSULTANT_SETTING });

                this.getSubmissionsByTypeAndDistrict(localStorage.CONSULTANT_SETTING, "ALL-ISLAND").then(result => {
                    console.log(result)
                    this.generateInitialStats(result, "all");
                });
                })
            }
            else{
                this.setState({ districts: districts, loading: true });

                this.getSubmissionsByTypeAndDistrict("ALL", "ALL-ISLAND").then(result => {

                this.generateInitialStats(result, "all");
                    
                });
            }
           
        })

    }

    generateInitialStats = (result, setting) => {
        let statsArr = [];
        let count = 0;
        console.log(result)

            this.state.statTypes.map((setting, i) => (
                count = result.submissions.filter(obj => obj.type === setting.text).length,
                console.log(setting.key , count),
                statsArr.push({key: setting.key, text: setting.text, color: setting.color, count: count })
            ))
    
            console.log(result)
            this.setState({
                loading: false,
                submissions: result.submissions,
                statTypes: statsArr,
                summaryStat: result.stats
            })
    };


    generateStats = (result, category) => {

        if (category === "ALL") {

            this.setState({
                loading: false,
                submissions: result.submissions,
                summaryStat: result.stats,
                summaryStatColor: 'white'
            })

        }
        else {

            let categoryInfoIndex = this.state.statTypes.findIndex(obj => obj.key === category);
            let color = this.state.statTypes[categoryInfoIndex].color;
            console.log(color)

                this.setState({
                    loading: false,
                    submissions: result.submissions,
                    summaryStat: result.stats,
                    summaryStatColor: color
                })

        }

    };


    getAllDistricts = async () => {
        const result = await this.props.client.mutate({
            mutation: GET_ALL_DISTRICTS,
        });

        return result.data.getAllDistricts;
    };

    getAllSettings = async () => {
        const result = await this.props.client.mutate({
            mutation: GET_ALL_SETTINGS,
        });

        return result.data.getAllSettings;
    };


    goBack = () => {
        this.props.history.goBack();
    };

    getSubmissionsByTypeAndDistrict = async (settingType, districtId) => {

        const {start,end} = this.state;

        const result = await this.props.client.query({
            query: GET_SUBMISSION_BY_TYPEAND_DISTRICT,
            variables: { "settingType": settingType, "districtId": districtId, "start": start, "end": end },
            fetchPolicy: 'network-only'
        });
        return result.data.getSubmissionsByTypeAndDistrict;
    };

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    getSubmissionsSubmit = () => {
        if (this.state.settingType != "") {
            this.setState({
                loading: true
            })
            this.getSubmissionsByTypeAndDistrict(this.state.settingType, this.state.districtId).then(result => {
                console.log(result)

                this.generateStats(result, this.state.settingType);
            });
        }

    }

    handleDistrictChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "district": text, loading: true });

        this.getSubmissionsByTypeAndDistrict(this.state.settingType, key).then(result => {
            console.log(result)
            this.setState({
                loading: false,
                submissions: result.submissions
            })
        });
    }

    handleSettingChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "setting": text, settingName: text });
    }

    _export_to_pdf = () => {
        var page = '<html>' + document.getElementById("submissionsTableDiv").innerHTML + '</html>';

        var is_chrome = function () { return Boolean(window.chrome); }
        if (is_chrome) {
            window.document.write(page);
            window.print();
            setTimeout(function () { window.close();; window.location.reload() }, 1000);
            //give them 10 seconds to print, then close
        }
        else {
            window.print();
            window.close();
        }

    }

    _export_to_excel = () => {

        var uri = '';
        if (navigator.appVersion.indexOf("Win") != -1) {
            uri = 'data:application/vnd.ms-excel;base64,';
        }
        if (navigator.appVersion.indexOf("Linux") != -1) {
            uri = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
        }
        var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Worksheet</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>' + document.getElementById("submissionsTable").innerHTML + '</table></body></html>',
            a = document.createElement('a');
        a.href = uri + window.btoa(unescape(encodeURIComponent(template)));
        a.setAttribute('download', 'submissionReport.xls');
        a.click();
    }

    handleDateChange = (event, { name, value }) => {
        if (value != null) {
            let formatedDate = moment(value).format('YYYY-MM-DD');
            this.setState({
                [name]: formatedDate,
            });
        }
    };

    _getSingleUser = async (userId) => {
        const result = await this.props.client.query({
            query: GET_SINGLE_USER,
            variables: { userId },
            //consider above
            fetchPolicy: 'network-only'
        });
     
        return result.data.getSingleUser;
    };

    render() {
        const { loading, districts, settings, submissions, districtId, district } = this.state;

        console.log(districtId)
        return (
            <div>
                <div className="right_col" role="main">
                    <div className="page-title">
                        <div >
                            <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                        </div>

                    </div>
                    <div className="clearfix"></div>
                    <br />
                    <div className="row">
                        <div className="col-md-12 col-sm-12  ">
                            <div className="x_panel">
                                <div className="">
                                    <h2>Settings Reports</h2><br />
                                    <div className="clearfix"></div>
                                </div>


                                <div className="settingReportStatMainDiv">
                                    {
                                        localStorage.USER_TYPE === "CONSULTANT" ?
                                            <Grid className="settingReportStatGrid" stackable>
                                                {
                                                    this.state.statTypes.map((stat, i) => (
                                                        localStorage.CONSULTANT_SETTING === stat.key ?
                                                            <Grid.Column width={6} className="settingReportStatCol">
                                                                <div className="settingReportStatCard" style={{backgroundColor: stat.color}}>
                                                                    <table className="settingReportStatTable">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{verticalAlign: 'top'}}>
                                                                                    <div className="settingReportStatCardRow1">
                                                                                        {stat.text}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{verticalAlign: 'bottom'}}>
                                                                                    <div className="settingReportStatCardRow2">
                                                                                        <CountUp
                                                                                            start={0}
                                                                                            end={stat.count}
                                                                                            duration={2.75}>
                                                                                        </CountUp>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Grid.Column>
                                                        : null
                                                    ))
                                                }
                                            </Grid>
                                            :
                                            <Grid columns={6} className="settingReportStatGrid" stackable>
                                                {
                                                    this.state.statTypes.map((stat, i) => (
                                                        <Grid.Column className="settingReportStatCol">
                                                            <div className="settingReportStatCard" style={{backgroundColor: stat.color}}>
                                                                <table className="settingReportStatTable">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{verticalAlign: 'top'}}>
                                                                                <div className="settingReportStatCardRow1">
                                                                                    {stat.text}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{verticalAlign: 'bottom'}}>
                                                                                <div className="settingReportStatCardRow2">
                                                                                    <CountUp
                                                                                        start={0}
                                                                                        end={stat.count}
                                                                                        duration={2.75}>
                                                                                    </CountUp>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Grid.Column>
                                                    ))
                                                }
                                            </Grid>
                                    }
                                    
                                </div>
                                
                                <br />


                                <div className="">
                                    <div className="col-md-3 col-sm-3  ">
                                        Setting Type
                                            <Dropdown
                                            placeholder='Select Setting Type'
                                            fluid
                                            selection
                                            id='settingType'
                                            name='settingType'
                                            options={settings}
                                            value={this.state.settingType}
                                            onChange={this.handleSettingChange}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-3  ">
                                        District
                                            <Dropdown
                                            placeholder='Select District'
                                            fluid
                                            selection
                                            id='districtId'
                                            name='districtId'
                                            options={districts}
                                            value={this.state.districtId}
                                            onChange={this.handleDistrictChange}
                                            disabled={localStorage.USER_TYPE == "HEO" ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-3">
                                        Start <br/>
                                        <SemanticDatepicker
                                            id="start"
                                            name="start"
                                            placeholder="start"
                                            dateFormat="YYYY-MM-DD"
                                            value={this.state.start != '' ? new Date(this.state.start) : ''}
                                            iconPosition="left"
                                            className=""
                                            onChange={this.handleDateChange}
                                            
                                            // minDate={this.state.minDate}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-3">
                                        End <br/>
                                        <SemanticDatepicker
                                            id="end"
                                            name="end"
                                            placeholder="end"
                                            dateFormat="YYYY-MM-DD"
                                            value={this.state.end != '' ? new Date(this.state.end) : ''}
                                            iconPosition="left"
                                            className=""
                                            onChange={this.handleDateChange}
                                            // minDate={this.state.minDate}
                                        />
                                    </div>

                                    <div className="col-md-1 col-sm-1  ">
                                        <Button style={{ marginTop: "20px" }} type="button" onClick={() => this.getSubmissionsSubmit()} className="btn btn-primary">
                                            SEARCH
                                    </Button>
                                    </div>



                                </div>



                                <div className="x_content">

                                    {loading ? (
                                        <div className="ListLoader">
                                            <Loader
                                                type="ThreeDots"
                                                color="#5A738E"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                    ) : (


                                        <div>
                                            <br />
                                            <div >
                                                <a style={{ cursor: "pointer", color: "#4183c4" }} onClick={() => this._export_to_pdf()}><b>Print</b></a> |
                                            <a style={{ cursor: "pointer", color: "#4183c4" }} onClick={() => this._export_to_excel()}><b> Export to Excel</b></a>

                                            </div>



                                            <br />
                                            <br />

                                            {/* Stat Section Start */}

        
                                            <Grid className="settingReportStatGrid" stackable>
                                                        {
                                                            localStorage.USER_TYPE != "CONSULTANT" ?
                                                                <Grid.Column width={4} className="settingReportSummaryStatCol" floated="left">
                                                                <div className="settingReportStatSummaryCard" style={{backgroundColor: '#2A3F54'}}>
                                                                    <table className="settingReportStatTable">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="settingReportStatCardRow3 rightBorderedTd">
                                                                                    <div>
                                                                                        Total
                                                                                    </div>
                                                                                </td>
                                                                                <td className="settingReportStatCardRow4" style={{color: this.state.summaryStatColor}}>
                                                                                    <div>
                                                                                        
                                                                                        <CountUp
                                                                                            start={0}
                                                                                            end={this.state.summaryStat.total}
                                                                                            duration={2.75}>
                                                                                        </CountUp>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Grid.Column>
                                                            :
                                                            null
                                                        }
                                                        
                                                        <Grid.Column width={7} className="" floated={localStorage.USER_TYPE != "CONSULTANT" ? "right" : "left"}>
                                                            <div className="settingReportStatSummaryCard" style={{backgroundColor: '#2A3F54'}}>
                                                                <table className="settingReportStatTable">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="rightBorderedTd">
                                                                                <div className="settingReportStatCardRow6" style={{color: this.state.summaryStatColor}}>
                                                                                    
                                                                                    <CountUp
                                                                                        start={0}
                                                                                        end={this.state.summaryStat.week | 0}
                                                                                        duration={2.75}>
                                                                                    </CountUp>
                                                                                </div>
                                                                                <div className="settingReportStatCardRow5">
                                                                                    This week
                                                                                </div>
                                                                            </td>
                                                                            <td className="rightBorderedTd">
                                                                                <div className="settingReportStatCardRow6" style={{color: this.state.summaryStatColor}}>
                                                                                    
                                                                                    <CountUp
                                                                                        start={0}
                                                                                        end={this.state.summaryStat.month | 0}
                                                                                        duration={2.75}>
                                                                                    </CountUp>
                                                                                </div>
                                                                                <div className="settingReportStatCardRow5">
                                                                                    This month
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="settingReportStatCardRow6" style={{color: this.state.summaryStatColor}}>
                                                                                    
                                                                                    <CountUp
                                                                                        start={0}
                                                                                        end={this.state.summaryStat.year | 0}
                                                                                        duration={2.75}>
                                                                                    </CountUp>
                                                                                </div>
                                                                                <div className="settingReportStatCardRow5">
                                                                                    This year
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Grid.Column>
                                                    
                                            </Grid>

                                        <br/>
                                        <br/>

                                        {/* Stat Section End */}

                                            <div id="submissionsTableDiv">
                                                <Grid>
                                                    <Grid.Row>

                                                        <Grid.Column width={2}>
                                                            <h3><b>Settings Report</b></h3>
                                                        </Grid.Column>
                                                        <Grid.Column width={6}>
                                                            {this.state.settingType == "ALL" ? "All Settings" : this.state.settingName
                                                            }
                                                            {" - "}
                                                            {
                                                                districtId == "ALL-ISLAND" ? "National Level" : district + " District"
                                                            }
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>
                                                        {
                                                                this.state.start != "" && this.state.end != "" ?
                                                                "From : " + moment(this.state.start).format("YYYY-MM-DD") + " | To :" + moment(this.state.end).format("YYYY-MM-DD")
                                                                : "Date : "+ moment().format("YYYY-MM-DD")
                                                            }
                                                        </Grid.Column>
                                                        <Grid.Column width={3}>
                                                            Submissions Count : &nbsp;
                                                                {
                                                                submissions != undefined ?
                                                                    submissions.length > 0 ?
                                                                        submissions.length
                                                                        : 0 : 0
                                                            }
                                                        </Grid.Column>

                                                    </Grid.Row>
                                                </Grid>
                                                <br />
                                                <ScrollContainer>
                                                    <table style={{ "width": "100%", borderSpacing: "0" }} border="1" className="progressTable table table-striped" id="submissionsTable">
                                                        <thead>
                                                            <tr>
                                                                <th style={{textAlign: 'center'}}> # </th>
                                                                <th > Submitted User Name </th>
                                                                {
                                                                    this.state.settingType === "ALL" ?
                                                                        <th > Setting Type </th>
                                                                    : null
                                                                }
                                                                {districtId == "ALL-ISLAND" ? <th> District </th> : null}
                                                                <th> MOH Area </th>
                                                                <th> PHM Area </th>
                                                                <th> GN Division </th>
                                                                <th> Submitted Date </th>
                                                                <th> Status </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                submissions != undefined ?
                                                                    submissions.length > 0 ?
                                                                        submissions.map((submission, index) => (
                                                                            <tr key={index}>
                                                                                {/* <th scope="row"> {index + 1}</th> */}
                                                                                <td style={{textAlign: 'center'}}> {index + 1} </td>
                                                                                <td> {submission.createdByName} </td>
                                                                                {
                                                                                    this.state.settingType === "ALL" ?
                                                                                        <td> {submission.type} </td>
                                                                                    : null
                                                                                }
                                                                                {districtId == "ALL-ISLAND" ? <td> {submission.district} </td> : null}

                                                                                <td> {submission.mohArea} </td>
                                                                                <td> {submission.phmArea} </td>
                                                                                <td> {submission.gndivision} </td>
                                                                                <td>  {moment(submission.createdAt).format('YYYY-MM-DD')} </td>
                                                                                <td> {submission.status} </td>
                                                                            </tr>
                                                                        ))
                                                                        : null : null
                                                            }
                                                        </tbody>
                                                    </table>
                                                </ScrollContainer>
                                            </div>


                                        </div>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const exportLink = {
    color: "cadetblue !important",
    "fontSize": "15px",
    margin: "5px",
    cursor: "pointer"
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(SubmissionReportByDistrict)));

