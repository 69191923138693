import React, { Component } from 'react';
import { EDIT_BASIC_PROFILE, GET_SINGLE_USER } from '../../queries/UserQueries';

import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions'

import { Dropdown, Grid, Button, Form } from 'semantic-ui-react';


//Spinner
import Loader from 'react-loader-spinner'


import {
    EDIT_PASSWORD
} from '../../queries/CommonQueries'

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class ChangePassword extends Component {
    constructor(props) {
        super(props);


        this.state = {
            loading: false,
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });

        this.setState({ loading: true });

        const { currentPassword, newPassword, confirmPassword } = this.state;

        if (currentPassword == "" || newPassword == "" || confirmPassword == "") {
            this.setState({ loading: false });
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "All Fields Required!"
            });
            return;
        } else {
            if (newPassword == confirmPassword) {
                this.editPassword().then(result => {

                    if (result.status == true) {
                        this.props.setSuccessStatus({
                            status: true,
                            title: "Oops!",
                            message:
                                "Password Updated Successfully!"
                        });
                        this.setState({ loading: false });
                        localStorage.unfpagisauthtoken = result.token;

                        var that = this;
                        setInterval(function () {
                            that.props.history.push('/login')
                        }, 2000);


                    } else {
                        this.props.setFormStatus({
                            status: true,
                            title: "Oops!",
                            message:
                                "Your current password is incorrent, Try Again."
                        });
                        this.setState({ loading: false });
                    }

                }).catch(error => {

                    console.log(error);
                    this.setState({ loading: false });
                    this.props.setFormStatus({
                        status: true,
                        title: "Oops!",
                        message:
                            "There was an error while trying to Update, Please try again later!"
                    });

                });
            } else {
                this.setState({ loading: false });
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message: "Confirm password not matching!"
                });
                return;
            }

        }


    };

    editPassword = async () => {
        const { currentPassword, newPassword } = this.state;

        const result = await this.props.client.mutate({
            mutation: EDIT_PASSWORD,
            variables: { currentPassword, newPassword }
        });

        return result.data.editPassword;
    }


    render() {
        const { loading } = this.state;

        const { formErrorStatus, formSuccessState } = this.props;

        return (
            <div className="right_col" role="main">
                <div className="row">
                    <div id="" class="col-md-12 col-sm-12 ">
                        <section class="login_content">
                            <form>
                                <Grid columns='two'>
                                    <Grid.Row>
                                        <Grid.Column className="login_leftcontent" width={6}>
                                            <h1>Change Password</h1>
                                            <div>
                                                <label className="floatLeft"> Current Password </label><br />
                                                <input value={this.state.currentPassword} type="text" id="currentPassword" name="currentPassword" className="form-control" placeholder="Current Password" onChange={this.handleChange} />
                                            </div>
                                            <div>
                                                <label className="floatLeft"> New Password </label><br />
                                                <input value={this.state.newPassword} type="text" id="newPassword" name='newPassword' className="form-control" placeholder="New Password" onChange={this.handleChange} />
                                            </div>

                                            <div>
                                                <label className="floatLeft"> Confirm Password </label><br />
                                                <input value={this.state.confirmPassword} type="text" id="confirmPassword" name='confirmPassword' className="form-control" placeholder="Confirm Password" onChange={this.handleChange} />
                                            </div>

                                            <br />

                                            {(formErrorStatus.status) ? (
                                                <div class="ui negative message">

                                                    <div class="header">
                                                        Not Submitted.
                                                </div>
                                                    <p>{formErrorStatus.message}</p>
                                                </div>

                                            ) : ((formSuccessState.status) ? (
                                                <div class="ui success message">
                                                    <div class="header">
                                                        Submitted successfully.
                                                            </div>
                                                    <p>{formSuccessState.message}</p>
                                                </div>
                                            ) : (''))}

                                            <div className="ln_solid" />
                                            <div className="form-group">
                                                <div className="col-md-12 col-sm-12" style={{ textAlign: "right" }}>
                                                    <button onClick={this.handleSubmit} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                        {loading ? (
                                                            <Loader
                                                                type="Oval"
                                                                color="#2A3F54"
                                                                height={15}
                                                                width={40}
                                                            />
                                                        ) : ("Submit")}
                                                    </button>
                                                </div>
                                            </div>

                                        </Grid.Column>


                                    </Grid.Row>
                                </Grid>


                            </form>
                        </section>
                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ChangePassword)));