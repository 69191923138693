import gql from 'graphql-tag';

export const CREATE_USER = gql`
mutation Signup($name: String!,$tel: String,$provinceId: String,$province: String,$districtId: String,$district: String,$mohareaId: String,$moharea: String,$gndivisionId: String,$gndivision: String,$lat: String,$lon: String,$email: String!,$gender: Gender,$nic: String!,$userType: UserType!,$settingType:SettingType,$isActive: Boolean,$password: String!){
    signup( name: $name,tel: $tel,provinceId: $provinceId,province: $province,districtId: $districtId,mohareaId:$mohareaId,moharea:$moharea,district: $district,gndivisionId: $gndivisionId,gndivision: $gndivision,lat: $lat,lon: $lon,email: $email,gender: $gender,nic: $nic,userType: $userType,settingType:$settingType,isActive: $isActive,password: $password  )
        {
        token
        user{
            id
            name
            tel
            provinceId
            province
            districtId
            district
            mohareaId
            moharea
            gndivisionId
            gndivision
            location
            email
            gender
            nic
            password
            userType
            settingType
            isActive
            notifications
        }
    }
}
`

export const LOGIN = gql`
mutation Login( $nic: String!, $password: String!){
        login( nic:$nic, password: $password ){
            token
            user{
                id
                name
                tel
                provinceId
                province
                districtId
                district
                mohareaId
                moharea
                gndivisionId
                gndivision
                location
                email
                gender
                nic
                password
                userType
                isActive
                settingType
                notifications
                regUserNotifications
            }
        }
}
`

export const EDIT_BASIC_PROFILE =gql`
mutation EditBasicProfile($userId: String!,$name: String,$tel: String,$email: String,$provinceId: String, $province: String,$districtId: String,$district: String,$mohareaId: String,$moharea: String,$gndivisionId: String,$gndivision: String,$lat: String,$lon: String,$isActive: Boolean){
    editBasicProfile(userId:$userId,name:$name,tel:$tel,email:$email,province:$province,provinceId:$provinceId,district:$district,districtId:$districtId,moharea:$moharea,mohareaId:$mohareaId,gndivision:$gndivision,gndivisionId:$gndivisionId,lat:$lat,lon:$lon,isActive:$isActive){
        id
        name
        tel
        provinceId
        province
        districtId
        district
        mohareaId
        moharea
        gndivisionId
        gndivision
        location
        email
        gender
        nic
        password
        userType
        isActive
    }
}
`

export const DELETE_USER =gql`
mutation DeleteUser($id: String!){
    deleteUser(id:$id){
        id
        name
        tel
        provinceId
        province
        districtId
        district
        mohareaId
        moharea
        gndivisionId
        gndivision
        location
        email
        gender
        nic
        password
        userType
        isActive
    }
}
`


export const GET_All_USERS =gql`
  query GetAllUsers($userType: UserType,$skip: Int,$first: Int){
        getAllUsers(userType:$userType,skip:$skip,first:$first){
            users{
                id
                name
                tel
                provinceId
                province
                districtId
                district
                mohareaId
                moharea
                gndivisionId
                gndivision
                location
                email
                gender
                nic
                password
                userType
                settingType
                isActive
                createdAt
            }
            count
        }
    }
`;

export const GET_SINGLE_USER = gql`
query GetSingleUser($userId: String!){
    getSingleUser( userId: $userId)
        {
            id
            name
            tel
            provinceId
            province
            districtId
            district
            mohareaId
            moharea
            gndivisionId
            gndivision
            location
            email
            gender
            nic
            password
            userType
            isActive
    }
}
`

export const UPDATE_USER_VIEW_REG_NOTIFICATIONS = gql`
mutation {
    updateUserViewRegNotifications
        {
            id
            regUserNotifications
        }
    
}
`

export const GET_USER_REG_NOTIFICATIONS = gql`
query {
    getUserRegNotifications
}`

export const GET_USER_REG_LATEST_NOTIFICATIONS = gql`
query {
    getUserRegLatestNotifications
}`

export const UPDATE_REG_NOTIFICATIONS =gql`
mutation UpdateRegNotifications($id: String!){
    updateRegNotifications(id:$id)
}
`

export const UPDATE_PASSWORD =gql`
mutation UpdatePassword($userId: String!,$password: String!){
    updatePassword(userId:$userId,password:$password){
        token
        user{
            id
            name
            email
        }
    }
}
`

export const SEARCH_USERS =gql`
  query SearchUsers($searchTerm: String){
    searchUsers(searchTerm:$searchTerm){
            users{
                id
                name
                tel
                provinceId
                province
                districtId
                district
                mohareaId
                moharea
                gndivisionId
                gndivision
                location
                email
                gender
                nic
                password
                userType
                settingType
                isActive
                createdAt
            }
            count
        }
    }
`;


export const SEARCH_HIGHLEVEL_USERS =gql`
  query SearchHighLevelUsers($searchTerm: String){
    searchHighLevelUsers(searchTerm:$searchTerm){
            users{
                id
                name
                tel
                provinceId
                province
                districtId
                district
                mohareaId
                moharea
                gndivisionId
                gndivision
                location
                email
                gender
                nic
                password
                userType
                settingType
                isActive
                createdAt
            }
            count
        }
    }
`;
