import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

import { GET_SINGLE_PRESCHOOL_SETTING_SUBMISSION } from '../../../queries/SettingQueries'

//sementic ui
import { Grid, Button, List, Segment, Icon } from 'semantic-ui-react';

//Google map
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

//ImageZoomer
import ImageZoom from 'react-medium-image-zoom'

const apiKey = "AIzaSyAa4iktQ8XNg1TJDk_CPptIgzZWmBQm7bM"


const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.formErrorStatus,
    formSuccessState: state.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}


class ViewPreSchoolSubmission extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('id');

    this.state = {
      settingSubmissionId: id,
      preschoolSubmission: {},
      marker:
      {
        title: "The marker`s title will appear as a tooltip.",
        name: "COLOMBO",
        position: { lat: 6.93194, lng: 79.84778 }
      },
      loading: false
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    this._getSinglePreSchoolSettingSubmission().then(result => {
      console.log(result.location)
      var marker = this.state.marker;
      if (result.location != undefined || result.location != null) {
        // marker['lat'] = result.location.latitude;
        // marker['lon'] = result.location.longitude;
        marker = {
          title: "The marker`s title will appear as a tooltip.",
          name: "COLOMBO",
          position: { lat: result.location.latitude, lng: result.location.longitude }
        }
      }
      this.setState({ preschoolSubmission: result, marker: marker, loading: false });
    })
  }

  _getSinglePreSchoolSettingSubmission = async () => {
    const { settingSubmissionId } = this.state;
    const result = await this.props.client.query({
      query: GET_SINGLE_PRESCHOOL_SETTING_SUBMISSION,
      variables: { settingSubmissionId },
      fetchPolicy: 'network-only'
    });
    return result.data.getSinglePreschoolSettingSubmission;
  }

  newEvent = () => {
    let id = this.state.settingSubmissionId;
    this.props.history.push("/addpreschoolevent?id=" + id);
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {

    const { preschoolSubmission, loading, marker } = this.state;

    console.log(preschoolSubmission)


    return (
      <div className="right_col" role="main">
        <div class="row">
          <div class="col-md-6 col-sm-6 ">
            <div >
              <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
            </div> <br /><br />
            <div class="x_panel">
              <div class="x_title">
                <h2> Pre School Submission <small>View</small></h2>

                <div class="clearfix"></div>
              </div>
              <div class="x_content">

                {
                  loading ? (
                    null
                  ) : (
                      <Grid>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Province : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.province}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> District : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.district}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> MOHArea : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.mohArea}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Gndivision : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.gndivision}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> PHM Area : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.phmArea}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Registration Number : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.registrationNumber}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Name of the preschool : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.nameOfPreschool}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Address  : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.address}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Place of registration : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.placeOfRegistration}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Name of the facilitator : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.nameOfFacilitator}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Designation of the facilitator  : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.designationOfFacilitator}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Name of the ECCD officer  : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.nameOfECCDOfficer}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Number  of preschool children : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.numOfPreschoolChildren}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Number of teachers / assistant teachers : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.numOfTeachers}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Starting year and month of the health promotion setting : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {preschoolSubmission.startingYearAndMonth}  </p>
                          </Grid.Column>
                        </Grid.Row>


                        <Grid.Row className="viewSubRow">
                          <Grid.Column width={12}>
                            <p className="PreSchoolMultipleLabel"> Marks at initial assessment  </p> <br />
                          </Grid.Column>

                          {
                            preschoolSubmission.marksAtInitialAssessment != undefined ? (

                              [<Grid.Column width={8}>
                                <p className="flat"> <strong> Marks : {
                                  preschoolSubmission.marksAtInitialAssessment != undefined ? (
                                    preschoolSubmission.marksAtInitialAssessment.initialAssessmentMarks != undefined ? (
                                      preschoolSubmission.marksAtInitialAssessment.initialAssessmentMarks
                                    ) : ('')
                                  ) : ('')
                                } </strong> </p>
                              </Grid.Column>,
                              <Grid.Column width={4}>
                                <p className="flat"> <strong> Date : {
                                  preschoolSubmission.marksAtInitialAssessment != undefined ? (
                                    preschoolSubmission.marksAtInitialAssessment.initialAssessmentDate != undefined ? (
                                      preschoolSubmission.marksAtInitialAssessment.initialAssessmentDate
                                    ) : ('')
                                  ) : ('')
                                } </strong> </p>
                              </Grid.Column>]

                            ) : (
                                null
                              )
                          }

                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          <Grid.Column width={12}>
                            <p className="PreSchoolMultipleLabel"> Marks at subsequent assessments </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          {
                            preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                              [
                                <Grid.Column width={12}>
                                  <label>2nd Subsequent Assessment</label>
                                </Grid.Column>,
                                <Grid.Column width={8}>
                                  <p className="flat"> <strong> Marks : {
                                    preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                                      preschoolSubmission.marksAtSubsequentAssessments.subsequentSecondAssessment != undefined ? (
                                        preschoolSubmission.marksAtSubsequentAssessments.subsequentSecondAssessment.subsequentSecondAssessmentMarks != undefined ? (
                                          preschoolSubmission.marksAtSubsequentAssessments.subsequentSecondAssessment.subsequentSecondAssessmentMarks
                                        ) : ('')
                                      ) : ('')
                                    ) : ('')
                                  } </strong> </p>
                                </Grid.Column>,
                                <Grid.Column width={4}>
                                  <p className="flat"> <strong> Date : {
                                    preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                                      preschoolSubmission.marksAtSubsequentAssessments.subsequentSecondAssessment != undefined ? (
                                        preschoolSubmission.marksAtSubsequentAssessments.subsequentSecondAssessment.subsequentSecondAssessmentDate != undefined ? (
                                          preschoolSubmission.marksAtSubsequentAssessments.subsequentSecondAssessment.subsequentSecondAssessmentDate
                                        ) : ('')
                                      ) : ('')
                                    ) : ('')
                                  } </strong> </p>
                                </Grid.Column>]
                            ) : (
                                null
                              )
                          }
                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          {
                            preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                              [
                                <Grid.Column width={12}>
                                  <label>3rd Subsequent Assessment</label>
                                </Grid.Column>,
                                <Grid.Column width={8}>
                                  <p className="flat"> <strong> Marks : {

                                    preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                                      preschoolSubmission.marksAtSubsequentAssessments.subsequentThirdAssessment != undefined ? (
                                        preschoolSubmission.marksAtSubsequentAssessments.subsequentThirdAssessment.subsequentThirdAssessmentMarks != undefined ? (
                                          preschoolSubmission.marksAtSubsequentAssessments.subsequentThirdAssessment.subsequentThirdAssessmentMarks
                                        ) : ('')
                                      ) : ('')
                                    ) : ('')

                                  } </strong> </p>
                                </Grid.Column>,
                                <Grid.Column width={4}>
                                  <p className="flat"> <strong> Date : {
                                    preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                                      preschoolSubmission.marksAtSubsequentAssessments.subsequentThirdAssessment != undefined ? (
                                        preschoolSubmission.marksAtSubsequentAssessments.subsequentThirdAssessment.subsequentThirdAssessmentDate != undefined ? (
                                          preschoolSubmission.marksAtSubsequentAssessments.subsequentThirdAssessment.subsequentThirdAssessmentDate
                                        ) : ('')
                                      ) : ('')
                                    ) : ('')
                                  } </strong> </p>
                                </Grid.Column>]
                            ) : (
                                null
                              )
                          }
                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          {
                            preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                              [
                                <Grid.Column width={12}>
                                  <label>4th Subsequent Assessment</label>
                                </Grid.Column>,
                                <Grid.Column width={8}>
                                  <p className="flat"> <strong> Marks : {
                                    preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                                      preschoolSubmission.marksAtSubsequentAssessments.subsequentFourthAssessment != undefined ? (
                                        preschoolSubmission.marksAtSubsequentAssessments.subsequentFourthAssessment.subsequentFourthAssessmentMarks != undefined ? (
                                          preschoolSubmission.marksAtSubsequentAssessments.subsequentFourthAssessment.subsequentFourthAssessmentMarks
                                        ) : ('')
                                      ) : ('')
                                    ) : ('')
                                  } </strong> </p>
                                </Grid.Column>,
                                <Grid.Column width={4}>
                                  <p className="flat"> <strong> Date : {
                                    preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                                      preschoolSubmission.marksAtSubsequentAssessments.subsequentFourthAssessment != undefined ? (
                                        preschoolSubmission.marksAtSubsequentAssessments.subsequentFourthAssessment.subsequentFourthAssessmentDate != undefined ? (
                                          preschoolSubmission.marksAtSubsequentAssessments.subsequentFourthAssessment.subsequentFourthAssessmentDate
                                        ) : ('')
                                      ) : ('')
                                    ) : ('')
                                  } </strong> </p>
                                </Grid.Column>]
                            ) : (
                                null
                              )
                          }
                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          {
                            preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                              [
                                <Grid.Column width={12}>
                                  <label>5th Subsequent Assessment</label>
                                </Grid.Column>,
                                <Grid.Column width={8}>
                                  <p className="flat"> <strong> Marks : {
                                    preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                                      preschoolSubmission.marksAtSubsequentAssessments.subsequentFifthAssessment != undefined ? (
                                        preschoolSubmission.marksAtSubsequentAssessments.subsequentFifthAssessment.subsequentFifthAssessmentMarks != undefined ? (
                                          preschoolSubmission.marksAtSubsequentAssessments.subsequentFifthAssessment.subsequentFifthAssessmentMarks
                                        ) : ('')
                                      ) : ('')
                                    ) : ('')
                                  } </strong> </p>
                                </Grid.Column>,
                                <Grid.Column width={4}>
                                  <p className="flat"> <strong> Date : {
                                    preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                                      preschoolSubmission.marksAtSubsequentAssessments.subsequentFifthAssessment != undefined ? (
                                        preschoolSubmission.marksAtSubsequentAssessments.subsequentFifthAssessment.subsequentFifthAssessmentDate != undefined ? (
                                          preschoolSubmission.marksAtSubsequentAssessments.subsequentFifthAssessment.subsequentFifthAssessmentDate
                                        ) : ('')
                                      ) : ('')
                                    ) : ('')
                                  } </strong> </p>
                                </Grid.Column>]
                            ) : (
                                null
                              )
                          }
                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          {
                            preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                              [
                                <Grid.Column width={12}>
                                  <label>6th Subsequent Assessment</label>
                                </Grid.Column>,
                                <Grid.Column width={8}>
                                  <p className="flat"> <strong> Marks : {
                                    preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                                      preschoolSubmission.marksAtSubsequentAssessments.subsequentSixthAssessment != undefined ? (
                                        preschoolSubmission.marksAtSubsequentAssessments.subsequentSixthAssessment.subsequentSixthAssessmentMarks != undefined ? (
                                          preschoolSubmission.marksAtSubsequentAssessments.subsequentSixthAssessment.subsequentSixthAssessmentMarks
                                        ) : ('')
                                      ) : ('')
                                    ) : ('')
                                  } </strong> </p>
                                </Grid.Column>,
                                <Grid.Column width={4}>
                                  <p className="flat"> <strong> Date : {
                                    preschoolSubmission.marksAtSubsequentAssessments != undefined ? (
                                      preschoolSubmission.marksAtSubsequentAssessments.subsequentSixthAssessment != undefined ? (
                                        preschoolSubmission.marksAtSubsequentAssessments.subsequentSixthAssessment.subsequentSixthAssessmentDate != undefined ? (
                                          preschoolSubmission.marksAtSubsequentAssessments.subsequentSixthAssessment.subsequentSixthAssessmentDate
                                        ) : ('')
                                      ) : ('')
                                    ) : ('')
                                  } </strong> </p>
                                </Grid.Column>]
                            ) : (
                                null
                              )
                          }
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={12}>
                            <p className="flat"> <strong> Conducted Activities </strong> </p> <br />
                          </Grid.Column>
                          <Grid.Column width={12}>

                            {preschoolSubmission.conductedActivities != undefined ? (
                              preschoolSubmission.conductedActivities.length > 0 ? (
                                preschoolSubmission.conductedActivities.map((conductedActivity, key) => {
                                  return (
                                    <Segment.Group>

                                      <Segment.Group>
                                        <Segment>
                                          <strong> {key + 1} ) </strong>
                                        </Segment>
                                        <Segment>
                                          <strong> Type Of Activity : </strong> &nbsp;&nbsp;&nbsp; {conductedActivity.typeOfActivity} <br />
                                        </Segment>
                                        <Segment>
                                          <strong> Activity : </strong> &nbsp;&nbsp;&nbsp; {conductedActivity.activity} <br />
                                        </Segment>
                                        <Segment>
                                          {conductedActivity.images.map((image, key) => {
                                            return (
                                              <div class="img_wrp">
                                                <ImageZoom
                                                  image={{
                                                    src: image,
                                                    className: 'img',
                                                    style: { width: "150px", height: "150px", "padding": "15px" }
                                                  }}
                                                  zoomImage={{
                                                    src: image,
                                                  }}
                                                />
                                              </div>
                                            );
                                          })}
                                        </Segment>
                                      </Segment.Group>
                                    </Segment.Group>
                                  );
                                })
                              ) : (
                                  null
                                )
                            ) : (
                                null
                              )}

                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          <Grid.Column width={12}>
                            <p className="flat"> <strong> Multisector involvement for the activity </strong> </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          <Grid.Column width={6}>
                            <label>Health</label>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <List divided relaxed>
                              {preschoolSubmission.involvementActivities != undefined ? (
                                preschoolSubmission.involvementActivities.health.length > 0 ? (
                                  preschoolSubmission.involvementActivities.health.map((activity, key) => {
                                    return (
                                      <List.Item>
                                        <List.Icon name='marker' />
                                        <List.Content>
                                          <List.Description>
                                            {activity}
                                          </List.Description>
                                        </List.Content>
                                      </List.Item>
                                    );
                                  })
                                ) : (
                                    null
                                  )
                              ) : (
                                  null
                                )}
                            </List>
                          </Grid.Column>
                        </Grid.Row>


                        <Grid.Row className="viewSubRow">
                          <Grid.Column width={12}>
                            <strong><label> Non Health</label></strong><br />
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <label> Formal sector </label>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <List divided relaxed>
                              {preschoolSubmission.involvementActivities != undefined ? (
                                preschoolSubmission.involvementActivities.nonhealth.nonHealthFormalSector.length > 0 ? (
                                  preschoolSubmission.involvementActivities.nonhealth.nonHealthFormalSector.map((activity, key) => {
                                    return (
                                      <List.Item>
                                        <List.Icon name='marker' />
                                        <List.Content>
                                          <List.Description>
                                            {activity}
                                          </List.Description>
                                        </List.Content>
                                      </List.Item>
                                    );
                                  })
                                ) : (
                                    null
                                  )
                              ) : (
                                  null
                                )}
                            </List>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <label> Informal sector </label>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            {preschoolSubmission.involvementActivities != undefined ? (
                              preschoolSubmission.involvementActivities.nonhealth.nonHealthInformalSector
                            ) : (
                                null
                              )}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          <Grid.Column width={6}>
                            <label>Community Leaders</label>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            {preschoolSubmission.involvementActivities != undefined ? (
                              preschoolSubmission.involvementActivities.communityLeaders
                            ) : (
                                null
                              )}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          <Grid.Column width={6}>
                            <label>Religious Leaders</label>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            {preschoolSubmission.involvementActivities != undefined ? (
                              preschoolSubmission.involvementActivities.religiousLeaders
                            ) : (
                                null
                              )}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          <Grid.Column width={6}>
                            <label>Community based organizations</label>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            {preschoolSubmission.involvementActivities != undefined ? (
                              preschoolSubmission.involvementActivities.communityOrganizations
                            ) : (
                                null
                              )}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="viewSubRow">
                          <Grid.Column width={6}>
                            <label>Health promotion platforms</label>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <List divided relaxed>
                              {preschoolSubmission.involvementActivities != undefined ? (
                                preschoolSubmission.involvementActivities.healthPromotionPlatform.length > 0 ? (
                                  preschoolSubmission.involvementActivities.healthPromotionPlatform.map((activity, key) => {
                                    return (
                                      <List.Item>
                                        <List.Icon name='marker' />
                                        <List.Content>
                                          <List.Description>
                                            {activity}
                                          </List.Description>
                                        </List.Content>
                                      </List.Item>
                                    );
                                  })
                                ) : (
                                    null
                                  )
                              ) : (
                                  null
                                )}
                            </List>
                          </Grid.Column>
                        </Grid.Row>


                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={6}>
                            <p className="flat"> <strong> Comments : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            {preschoolSubmission.comments}
                          </Grid.Column>
                        </Grid.Row>

                        {/* <Grid.Row>
                          <Grid.Column>
                            {
                              workPlaceSubmission.images != undefined ? (
                                workPlaceSubmission.images.map((image, key) => {
                                  return (
                                    <div class="img_wrp">
                                      <img style={{ width: "150px", height: "150px", "padding": "15px" }} src={image} />
                                    </div>
                                  );
                                })
                              ) : (
                                  null
                                )

                            }
                          </Grid.Column>
                        </Grid.Row> */}
                      </Grid>
                    )
                }


              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 ">



            <div className="" style={{ "margin-right": "50px" }}>
              <br />
              {localStorage.USER_TYPE == "USER" ? (
                preschoolSubmission.status == "APPROVED" ? (
                  <Button basic color='blue' style={{ float: "right" }} type="button" onClick={() => this.newEvent()}>  Add New Event </Button>
                ) : (
                    <Button disabled basic color='blue' style={{ float: "right" }} type="button" onClick={() => this.newEvent()}>  Add New Event </Button>
                  )
              ) : (
                  null
                )}

              <br /><br />
            </div>

            <div className="">
              <Map
                google={this.props.google}
                style={{ width: "80%", "margin-top": "20px", "margin-left": "50px", height: "78%" }}
                initialCenter={{ lat: 7.2906, lng: 80.6337 }}
                zoom={8.7}
              >
                <Marker
                  title={marker.title}
                  name={marker.name}
                  position={marker.position}
                />

              </Map>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
  apiKey: apiKey
})(ViewPreSchoolSubmission))));