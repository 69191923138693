import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

import { GET_SCHOOL_EVENTS, DELETE_SCHOOL_EVENT, SEARCH_SCHOOL_EVENTS } from '../../../queries/EventQueries'
import { GET_SINGLE_SCHOOL_SETTING_SUBMISSION } from '../../../queries/SettingQueries'

//sementic ui
import { Grid, Button, Modal, Icon } from 'semantic-ui-react';
import Loader from 'react-loader-spinner'
import moment from 'moment';

//middleware
import { formatDate } from '../../../middleware';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}


class ViewSchoolEvents extends Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        this.state = {
            schoolSettingSubmissionId: id,
            schoolEvents: [],
            loading: true,
            submissionStatus: "",
            deleteId: "",
            openDelete: false,
            searchTerm: "",
            searchLoading: false,
            currPage: 0,
            totalPages: 0,
            count: 0,
            items: [],
            pagination: {
                skip: 0,
                first: 10,
                schoolSettingSubmissionId: id
            },

        }
    }

    componentDidMount() {

        this._getSingleSchoolSettingSubmission().then(result => {
            this.setState({ submissionStatus: result.status });

            this.getEvents(this.state.pagination);
        })


    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    searchEvent() {
        var searchTerm = this.state.searchTerm;
        if (searchTerm == "" || searchTerm == null) {
            return false;
        } else {
            this.setState({ searchLoading: true });
            this.findEvents(searchTerm).then(result => {
                this.setState({ schoolEvents: result.events, searchLoading: false });
            })
        }
    }

    findEvents = async (searchTerm) => {
        const result = await this.props.client.query({
            query: SEARCH_SCHOOL_EVENTS,
            variables: { searchTerm },
            fetchPolicy: 'network-only'
        });
        return result.data.searchSchoolEvents;
    }

    getEvents(pagination) {
        this.setState({ loading: true });

        this._getSchoolEvents(pagination).then(result => {

            if (result.schoolEvents.length == 0) {
                this.setState({ loading: false });
            } else {

                const pages = Math.ceil(result.count / 10);

                if (pages === 0) return null;

                var items = [];

                for (var index = 0; index < pages; index++) {
                    items[index] = index - 1;
                }

                this.setState({
                    schoolEvents: result.schoolEvents,
                    count: result.count,
                    totalPages: parseInt(result.count / 10) + 1,
                    items: items,
                    loading: false
                })

            }

        })
    }

    handlePagination = async (pageNo) => {

        if (pageNo < 0) {
            return false;
        } else if (pageNo == 0) {

            this.setState({ currPage: pageNo })

            this.getEvents(this.state.pagination);

        } else if (pageNo > 0) {

            if (this.state.totalPages < pageNo + 1) {
                return false;
            } else {
                this.setState({ currPage: pageNo })

                var pagination = {
                    skip: pageNo * 10,
                    first: 10,
                    schoolSettingSubmissionId: this.state.schoolSettingSubmissionId
                };

                this.getEvents(pagination);
            }
        }

    }

    _getSingleSchoolSettingSubmission = async () => {
        const settingSubmissionId = this.state.schoolSettingSubmissionId;
        const result = await this.props.client.query({
            query: GET_SINGLE_SCHOOL_SETTING_SUBMISSION,
            variables: { settingSubmissionId },
            fetchPolicy: 'network-only'
        });
        return result.data.getSingleSchoolSettingSubmission;
    }

    _getSchoolEvents = async (pagination) => {

        const result = await this.props.client.query({
            query: GET_SCHOOL_EVENTS,
            variables: pagination,
            fetchPolicy: 'network-only'
        });
        return result.data.getSchoolEvents;
    }

    viewEvent = (id) => {
        this.props.history.push("/viewschoolevent?id=" + id);
    }

    newEvent = () => {
        let id = this.state.schoolSettingSubmissionId;
        this.props.history.push("/addschoolevent?id=" + id);
    }

    onDelete = (id) => {
        this.setState({ deleteId: id, openDelete: true });
    }

    deleteSubmission = () => {
        this.deleteSchoolEvent().then(result => {
            this.closeDelete();
            this.getEvents();
        })
    }

    deleteSchoolEvent = async () => {
        const id = this.state.deleteId;
        const result = await this.props.client.mutate({
            mutation: DELETE_SCHOOL_EVENT,
            variables: { id }
        });
        return result.data.deleteSchoolEvent;
    };

    closeDelete = () => this.setState({ openDelete: false })

    goBack = () => {
        this.props.history.goBack();
    };

    render() {

        const { schoolEvents, loading, submissionStatus, searchLoading } = this.state;

        return (

            <div className="right_col" role="main">
                <div className="page-title">

                </div>
                <div className="clearfix"></div>
                <div className="col-md-12 col-sm-12">
                    <div >
                        <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                    </div><br /><br />
                    <div className="x_panel">
                        <div className="x_title">
                            <Grid>
                                <Grid.Row style={{ float: "right" }}>
                                    <Grid.Column width={11}>
                                        <h2> School Events </h2>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <div class="form-group row ">
                                            <input style={{ float: "right" }} type="text" id="searchTerm" name="searchTerm" value={this.state.searchTerm} placeholder="Search" onChange={this.handleChange} className="form-control" />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <button style={{ float: "left" }} onClick={() => this.searchEvent()} type="button" className="btn btn-info" disabled={searchLoading ? (true) : (false)}>
                                            {searchLoading ? (
                                                <Loader
                                                    type="Oval"
                                                    color="#2A3F54"
                                                    height={15}
                                                    width={40}
                                                />
                                            ) : ("Search")}
                                        </button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                        <div class="x_content">

                            <Grid>
                                <Grid.Row>
                                    <Grid.Column > <br />
                                        {localStorage.USER_TYPE == "USER" ? (
                                            submissionStatus == "APPROVED" ? (
                                                <Button basic color='blue' style={{ float: "right" }} type="button" onClick={() => this.newEvent()}>  Add New Event </Button>
                                            ) : (
                                                    <Button disabled basic color='blue' style={{ float: "right" }} type="button" onClick={() => this.newEvent()}>  Add New Event </Button>
                                                )
                                        ) : (
                                                null
                                            )}
                                        <br />
                                    </Grid.Column>

                                </Grid.Row>

                                <Modal className="middleModal" size="mini" open={this.state.openDelete} onClose={this.closeDelete}>
                                    <Modal.Header> Delete Submission</Modal.Header>
                                    <Modal.Content>
                                        <p>Do you want to delete this event ?</p>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button negative onClick={() => this.closeDelete()}>No</Button>
                                        <Button
                                            positive
                                            onClick={() => this.deleteSubmission()}
                                        >Yes</Button>
                                    </Modal.Actions>
                                </Modal>


                                <Grid.Row>
                                    <Grid.Column>
                                        {loading ? (
                                            <div className="ListLoader">
                                                <Loader
                                                    type="ThreeDots"
                                                    color="#5A738E"
                                                    height={100}
                                                    width={100}
                                                />
                                            </div>
                                        ) : (

                                                <div>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th> Event Title </th>
                                                                <th> Description </th>
                                                                <th> Event Date </th>
                                                                <th> Added Date </th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                schoolEvents.map((schoolEvent, index) => (
                                                                    <tr key={index} >
                                                                        <td> {schoolEvent.title} </td>
                                                                        <td> {schoolEvent.description} </td>
                                                                        <td> {
                                                                            schoolEvent.date != undefined ? (
                                                                                moment(schoolEvent.date).format('YYYY-MM-DD')
                                                                            ) : (null)

                                                                        } </td>
                                                                        <td>  {moment(schoolEvent.createdAt).format('YYYY-MM-DD')} </td>
                                                                        <th>
                                                                            <button type="button" className="btn btn-success" onClick={() => this.viewEvent(schoolEvent.id)} >
                                                                                {localStorage.USER_TYPE == "USER" ? (
                                                                                    "View"
                                                                                ) : (
                                                                                        "View"
                                                                                    )}
                                                                            </button>
                                                                        </th>
                                                                        <th> <button type="button" className="btn btn-warning" onClick={() => this.onDelete(schoolEvent.id)} > Delete </button></th>

                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>

                                                    {
                                                        this.state.count > 10 ? (
                                                            <div style={{ textAlign: "center" }}>
                                                                <div aria-label="Pagination Navigation" role="navigation" class="ui pagination menu">

                                                                    <a
                                                                        onClick={() => this.handlePagination(0)}
                                                                        aria-current="false"
                                                                        aria-disabled="false"
                                                                        tabindex="0"
                                                                        value="1"
                                                                        type="firstItem"
                                                                        class="icon item"
                                                                    >
                                                                        <i aria-hidden="true" class="angle double left icon"></i>
                                                                    </a>
                                                                    <a
                                                                        onClick={() => this.handlePagination(this.state.currPage - 1)}
                                                                        aria-current="false"
                                                                        aria-disabled="false"
                                                                        tabindex="0"
                                                                        value="1"
                                                                        aria-label="Previous item"
                                                                        type="prevItem"
                                                                        class="item">
                                                                        <i aria-hidden="true" class="angle left icon"></i>
                                                                    </a>

                                                                    {
                                                                        this.state.items != undefined ? (
                                                                            this.state.items.length > 0 ? (
                                                                                this.state.items.map((item, i) =>
                                                                                    <a
                                                                                        onClick={() => this.handlePagination(item + 1)}
                                                                                        aria-current="true"
                                                                                        aria-disabled="false"
                                                                                        tabindex="0"
                                                                                        value="1"
                                                                                        type="pageItem"
                                                                                        class="active item"
                                                                                    >{i + 1}</a>
                                                                                )
                                                                            ) : (null)
                                                                        ) : (null)
                                                                    }
                                                                    <a onClick={() => this.handlePagination(this.state.currPage + 1)} aria-current="false" aria-disabled="false" tabindex="0" value="2" type="pageItem" class="item"> <i aria-hidden="true" class="angle right icon"></i> </a>


                                                                    <a
                                                                        onClick={() => this.handlePagination(this.state.totalPages - 1)}
                                                                        aria-current="false"
                                                                        aria-disabled="false"
                                                                        tabindex="0"
                                                                        value="10"
                                                                        type="lastItem"
                                                                        class="icon item"
                                                                    >
                                                                        <i aria-hidden="true" class="angle double right icon"></i>
                                                                    </a>

                                                                </div>
                                                                <br />
                                                            </div>
                                                        ) : (
                                                                null
                                                            )
                                                    }

                                                </div>
                                            )}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ViewSchoolEvents))); 
