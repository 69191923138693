import gql from 'graphql-tag';


export const GET_NEWS = gql`
query GetNews($skip:Int,$first:Int){
    getNews( skip:$skip,first:$first){
        news{
            id
            title
            description
            images
            status
            expireAt
            createdAt
        }
        count
    }
}
`


export const GET_ALL_NEWS = gql`
  query getAllNews{
    getAllNews
  }
`

export const ADD_NEWS = gql`
mutation AddNews($title: String,$description: String,$images: JSON,$status: Boolean,$expireAt: String){
    addNews( title: $title,description: $description,images: $images,status: $status,expireAt: $expireAt)
        {
            id
            title
            description
            images
            status
            expireAt
        }
}
`

export const UPDATE_USER_VIEW_NOTIFICATIONS = gql`
mutation {
    updateUserViewNotifications
        {
            id
            notifications
        }
    
}
`

export const EDIT_NEWS = gql`
mutation EditNews($id: String!,$title: String,$description: String,$images: JSON,$status: Boolean,$expireAt: String){
    editNews( id:$id,title: $title,description: $description,images: $images,status: $status,expireAt: $expireAt)
        {
            id
            title
            description
            images
            status
            expireAt
        }
}
`

export const DELETE_NEWS = gql`
mutation DeleteNews($id:String!){
    deleteNews(id:$id)
        {
            id
        }
}
`

export const GET_SINGLE_NEWS =gql`
  query GetSingleNews($id: String!){
        getSingleNews(id:$id)
    }
`;