import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions'
import { GET_VILLAGE_SUBMISSIONS, APPROVE_VILLAGE_SETTING_SUBMISSION, DELETE_VILLAGE_SETTING_SUBMISSION,
    SEARCH_VILLAGE_SUBMISSIONS } from '../../../queries/SettingQueries'


//ui
import { Button, Grid, Modal, Dropdown } from 'semantic-ui-react';

import Loader from 'react-loader-spinner'
import moment from 'moment';
import ScrollContainer from 'react-indiana-drag-scroll';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

 class Village extends Component {
     constructor(props){
         super(props);
         this.state={
            loading: true,
            villageSettingSubmissions: [],
            open: false,
            approvestatus: [
                { key: "PENDING", text: "Pending", value: "PENDING", id: 'status' },
                { key: "APPROVED", text: "Approved", value: "APPROVED", id: 'status' },
                { key: "NOTAPPROVED", text: "Not Approved", value: "NOTAPPROVED", id: 'status' }
            ],
            status: "PENDING",
            settingSubmissionId: "",
            deleteId: "",
            openDelete: false,
            currPage: 0,
            totalPages: 0,
            count: 0,
            pagination: {
                skip: 0,
                first: 10,
            },
            items: [],
            statusList: [
                { key: "EM", text: "Select-Status", value: "EM", id: 'status', disabled: true },
                { key: "ALL", text: "ALL", value: "ALL", id: 'status' },
                { key: "PENDING", text: "PENDING", value: "PENDING", id: 'status' },
                { key: "APPROVED", text: "APPROVED", value: "APPROVED", id: 'status' },
                { key: "NOTAPPROVED", text: "NOT APPROVED", value: "NOTAPPROVED", id: 'status' },
            ],
            status: "",
            searchTerm: "",
            searchLoading: false,
         }
     }

     componentDidMount() {
        this.getSubmissions(this.state.pagination);
    }

    getSubmissions(pagination) {
        this.setState({ loading: true })
        this.getVillageSubmissions(pagination).then(result => {

            const pages = Math.ceil(result.count / 10);

            if (pages === 0) return null;

            var items = [];

            for (var index = 0; index < pages; index++) {
                items[index] = index - 1;
            }

            this.setState({
                villageSettingSubmissions: result.villageSettingSubmissions,
                count: result.count,
                totalPages: parseInt(result.count / 10) + 1,
                items: items,
                loading: false
            })

        })
    }

    handlePagination = async (pageNo) => {

        if (pageNo < 0) {
            return false;
        } else if (pageNo == 0) {

            this.setState({ currPage: pageNo })

            this.getSubmissions(this.state.pagination);

        } else if (pageNo > 0) {

            if (this.state.totalPages < pageNo + 1) {
                return false;
            } else {
                this.setState({ currPage: pageNo })

                var pagination = {
                    skip: pageNo * 10,
                    first: 10,
                };

                this.getSubmissions(pagination);
            }
        }

    }

    getVillageSubmissions = async (pagination) => {
        const result = await this.props.client.query({
            query: GET_VILLAGE_SUBMISSIONS,
            variables: pagination,
            fetchPolicy: 'network-only'
        });
        if(result.data.getVillageSubmissions.villageSettingSubmissions != undefined){
            if(result.data.getVillageSubmissions.villageSettingSubmissions.length == 0){
                this.setState({ loading: false })
            }
        }
        return result.data.getVillageSubmissions
    }

    newSubmission() {
        this.props.history.push("/addvillagesubmission");
    }

    changeApproveSettingStatus = (settingSubmissionId) => {
        this.setState({ open: true, settingSubmissionId: settingSubmissionId });
    }

    updateStatus = () => {
        this.approveVillageSettingSubmission().then(result => {
            window.location.reload();
            this.close();
            this.getSubmissions();
        })
    }

    approveVillageSettingSubmission = async () => {
        const { status, settingSubmissionId } = this.state;
        const result = await this.props.client.mutate({
            mutation: APPROVE_VILLAGE_SETTING_SUBMISSION,
            variables: { settingSubmissionId, status }
        });
        return result.data.approveVillageSettingSubmission;
    };

    onDelete = (id) => {
        this.setState({ deleteId: id, openDelete: true });
    }

    deleteSubmission = () => {
        this.deleteVillageSettingSubmission().then(result => {
            this.closeDelete();
            this.getSubmissions();
        })
    }

    deleteVillageSettingSubmission = async () => {
        const id = this.state.deleteId;
        const result = await this.props.client.mutate({
            mutation: DELETE_VILLAGE_SETTING_SUBMISSION,
            variables: { id }
        });
        return result.data.deleteVillageSettingSubmission;
    };

    closeDelete = () => this.setState({ openDelete: false })

    show = () => () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    handleOnActionChange = (e, data) => {
        this.setState({ status: data.value, settingSubmissionId: data.id, open: true });
    }

    viewSubmission = (id) => {
        this.props.history.push("/viewvillagesubmission?id=" + id);
    }

    editSubmission = (id) => {
        this.props.history.push("/editvillagesubmission?id=" + id);
    }

    viewEvents = (id) => {
        this.props.history.push("/viewvillageevents?id=" + id);
    }

    handleStatusChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({
            [data.id]: key, villageSettingSubmissions: [], currPage: 0,
            totalPages: 0,
            count: 0
        });
        var pagination = {};
        if (key == "ALL") {
            pagination = {
                skip: 0,
                first: 1000,
            };
        } else {
            pagination = {
                skip: 0,
                first: 1000,
                status: key
            };


        }
        this.getSubmissions(pagination);


    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    search() {
        var searchTerm = this.state.searchTerm;
        if (searchTerm == "" || searchTerm == null) {
            return false;
        } else {
            this.setState({ searchLoading: true });
            this.findSetting(searchTerm).then(result => {

                if (result.count > 0) {
                    const pages = Math.ceil(result.count / 10);

                    if (pages === 0) return null;

                    var items = [];

                    for (var index = 0; index < pages; index++) {
                        items[index] = index - 1;
                    }


                    console.log(result)

                    this.setState({
                        villageSettingSubmissions: result.villageSettingSubmissions,
                        count: result.count,
                        totalPages: parseInt(result.count / 10) + 1,
                        items: items,
                        loading: false,
                        searchLoading: false
                    })
                } else {
                    this.setState({
                        villageSettingSubmissions: [],
                        count: 0,
                        totalPages: 0,
                        items: [],
                        loading: false,
                        searchLoading: false
                    })
                }


            })
        }
    }

    findSetting = async (searchTerm) => {
        const result = await this.props.client.query({
            query: SEARCH_VILLAGE_SUBMISSIONS,
            variables: { searchTerm },
            fetchPolicy: 'network-only'
        });
        return result.data.searchVillageSubmissions;
    }
     
    render() {
        const {  open, villageSettingSubmissions, approvestatus, loading } = this.state;
        return (
            <div>
                <Grid>
                    <Grid.Row>

                    <Grid.Column width={4}>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {localStorage.USER_TYPE == "USER" ? (
                                <Grid.Column >
                                    <br /><Button style={{ float: "right" }} type="button" className="btn btn-success" onClick={() => this.newSubmission()}>  Add New Submission </Button> <br />
                                </Grid.Column>
                            ) : (
                                null
                            )}
                        </Grid.Column>
                        <Grid.Column width={4}>

                            <Dropdown
                                placeholder='Filter by Status'
                                fluid
                                selection
                                id='status'
                                name='status'
                                options={this.state.statusList}
                                value={this.state.status}
                                onChange={this.handleStatusChange}
                            />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <div class="form-group row ">
                                <input style={{ float: "right" }} type="text" id="searchTerm" name="searchTerm" value={this.state.searchTerm} placeholder="Search" onChange={this.handleChange} className="form-control" />
                            </div>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <button style={{ float: "left" }} onClick={() => this.search()} type="button" className="btn btn-info" disabled={this.state.searchLoading ? (true) : (false)}>
                                {this.state.searchLoading ? (
                                    <Loader
                                        type="Oval"
                                        color="#2A3F54"
                                        height={15}
                                        width={40}
                                    />
                                ) : ("Search")}
                            </button>
                        </Grid.Column>

                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Modal className="middleModal" size="mini" open={open} onClose={this.close}>
                                <Modal.Header> Setting Submission</Modal.Header>
                                <Modal.Content>
                                    <p>Do you want to confirm this action ?</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button negative onClick={() => this.close()}>No</Button>
                                    <Button
                                        positive
                                        onClick={() => this.updateStatus()}
                                    >Yes</Button>
                                </Modal.Actions>
                            </Modal>

                            <Modal className="middleModal" size="mini" open={this.state.openDelete} onClose={this.closeDelete}>
                                <Modal.Header> Delete Submission</Modal.Header>
                                <Modal.Content>
                                    <p>Do you want to delete this submission ?</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button negative onClick={() => this.closeDelete()}>No</Button>
                                    <Button
                                        positive
                                        onClick={() => this.deleteSubmission()}
                                    >Yes</Button>
                                </Modal.Actions>
                            </Modal>

                            {loading ? (
                                <div className="ListLoader">
                                    <Loader
                                        type="ThreeDots"
                                        color="#5A738E"
                                        height={100}
                                        width={100}
                                    />
                                </div>
                            ) : (

                                    <div>
       <ScrollContainer>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th> Province </th>
                                                    <th> District </th>
                                                    <th> MOHArea </th>
                                                    <th> Name Of Village Setting </th>
                                                    <th> Name Of Village President </th>
                                                    <th> Added Date </th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    villageSettingSubmissions.map((villageSettingSubmission, index) => (
                                                        <tr key={index} >
                                                            <td> {villageSettingSubmission.province} </td>
                                                            <td> {villageSettingSubmission.district} </td>
                                                            <td> {villageSettingSubmission.mohArea} </td>
                                                            <td> {villageSettingSubmission.nameOfVillageSetting} </td>
                                                            <td> {villageSettingSubmission.nameOfVillagePresident} </td>
                                                            <td>  { moment(villageSettingSubmission.createdAt).format('YYYY-MM-DD')} </td>
                                                            <th> <button type="button" className="btn btn-success" onClick={() => this.viewSubmission(villageSettingSubmission.id)} > View </button></th>
                                                            <th> <button type="button" className="btn btn-success" onClick={() => this.viewEvents(villageSettingSubmission.id)} > Events </button></th>

                                                            {
                                                                villageSettingSubmission.status == "APPROVED" || villageSettingSubmission.status == "NOTAPPROVED" ? (
                                                                    <td>
                                                                        <button type="button" className="btn btn-info" disabled > Edit </button>
                                                                    </td>
                                                                ) : (
                                                                        <td>
                                                                            <button type="button" onClick={() => this.editSubmission(villageSettingSubmission.id)} className="btn btn-info" > Edit </button>
                                                                        </td>
                                                                    )
                                                            }

                                                            <td>

                                                                {
                                                                    villageSettingSubmission.status == "PENDING" ? (
                                                                        localStorage.USER_TYPE != "USER" ? (
                                                                            <Dropdown
                                                                                placeholder='Select Status'
                                                                                style={{ width: "150px" }}
                                                                                fluid
                                                                                selection
                                                                                id={villageSettingSubmission.id}
                                                                                name='status'
                                                                                options={approvestatus}
                                                                                value={this.state.status}
                                                                                onChange={this.handleOnActionChange}
                                                                            />
                                                                        ) : (
                                                                                <p style={{ color: "#FFBF00" }}>Pending</p>

                                                                            )

                                                                    ) : (
                                                                        villageSettingSubmission.status == "APPROVED" ? (
                                                                                <p style={{ color: "green" }}>Approved</p>
                                                                            ) : (
                                                                                villageSettingSubmission.status == "NOTAPPROVED" ? (
                                                                                        <p style={{ color: "red" }}>Not Approved</p>
                                                                                    ) : (
                                                                                            null
                                                                                        )
                                                                                )
                                                                        )
                                                                }

                                                            </td>
                                                            <th> <button type="button" className="btn btn-warning" onClick={() => this.onDelete(villageSettingSubmission.id)} > Delete </button></th>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>

                                        {
                                            this.state.count > 10 ? (
                                                <div style={{ textAlign: "center" }}>
                                                    <div aria-label="Pagination Navigation" role="navigation" class="ui pagination menu">

                                                        <a
                                                            onClick={() => this.handlePagination(0)}
                                                            aria-current="false"
                                                            aria-disabled="false"
                                                            tabindex="0"
                                                            value="1"
                                                            type="firstItem"
                                                            class="icon item"
                                                        >
                                                            <i aria-hidden="true" class="angle double left icon"></i>
                                                        </a>
                                                        <a
                                                            onClick={() => this.handlePagination(this.state.currPage - 1)}
                                                            aria-current="false"
                                                            aria-disabled="false"
                                                            tabindex="0"
                                                            value="1"
                                                            aria-label="Previous item"
                                                            type="prevItem"
                                                            class="item">
                                                            <i aria-hidden="true" class="angle left icon"></i>
                                                        </a>

                                                        {
                                                            this.state.items.map((item, i) =>
                                                                <a
                                                                    onClick={() => this.handlePagination(item + 1)}
                                                                    aria-current="true"
                                                                    aria-disabled="false"
                                                                    tabindex="0"
                                                                    value="1"
                                                                    type="pageItem"
                                                                    class="active item"
                                                                >{i + 1}</a>
                                                            )
                                                        }
                                                        <a onClick={() => this.handlePagination(this.state.currPage + 1)} aria-current="false" aria-disabled="false" tabindex="0" value="2" type="pageItem" class="item"> <i aria-hidden="true" class="angle right icon"></i> </a>


                                                        <a
                                                            onClick={() => this.handlePagination(this.state.totalPages - 1)}
                                                            aria-current="false"
                                                            aria-disabled="false"
                                                            tabindex="0"
                                                            value="10"
                                                            type="lastItem"
                                                            class="icon item"
                                                        >
                                                            <i aria-hidden="true" class="angle double right icon"></i>
                                                        </a>

                                                    </div>
                                                    <br />
                                                </div>
                                            ) : (
                                                    null
                                                )
                                        }
       </ScrollContainer>
                                    </div>
                                )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}
export default withRouter(withApollo(connect(mapStateToProps,mapDispatchToProps)(Village)));
