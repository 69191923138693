import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/index';
import {loginFormMiddleware } from '../middleware/index';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = (window.devToolsExtension
  ? window.devToolsExtension()(createStore)
  : createStore)(rootReducer);

export default store;




