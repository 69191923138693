import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus } from '../../actions/user-actions';

//query
import { LOGIN } from '../../queries/UserQueries';

//components
import Sidemenu from "../../components/sidemenu/sidemenu";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

//screens
import ManageUsers from "../../screens/users/manageUsers";
import ManageSettings from "../../screens/settings/manageSettings";
import AddNewPreSchoolSetting from "../../screens/settings/preSchool/addNewPreSchoolSetting";
import UserProfile from "../../screens/users/userProfile";
import HighlevelUsers from "../../screens/highlevelusers/highlevelUsers";
import AddNewHighLevelUser from "../../screens/highlevelusers/addNewHighLevelUser";
import Dashboard from "../../screens/reports/dashboard/dashboard";

//routes
import { userRoutes, adminRoutes, consultantRoutes, hgoRoutes, reportRoutes } from "../../routes/userRoutes";
import { Router, Route, Switch, Redirect } from "react-router-dom";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            sideRoutes: []
        }

    }

    componentDidMount() {
        localStorage.USER_TYPE == "USER" ? (
            this.setState({ loading: false, sideRoutes: userRoutes })
        ) : (
            localStorage.USER_TYPE == "CONSULTANT" ? (
                this.setState({ loading: false, sideRoutes: consultantRoutes })
            ) : (
                localStorage.USER_TYPE == "ADMIN" ? (
                    this.setState({ loading: false, sideRoutes: adminRoutes })
                ) : (
                    localStorage.USER_TYPE == "HGO" ? (
                        this.setState({ loading: false, sideRoutes: hgoRoutes })
                    ) : (
                        this.setState({ loading: false, sideRoutes: consultantRoutes })
                    )
                )
            )
        )

    }

    setRouteFrame(url) {
        this.props.history.push(url)
    }

    render() {
        const { sideRoutes, loading } = this.state;

        console.log(sideRoutes)
        return (

            <div class="nav-md">
                <div class="container body">
                    <div class="main_container">

                        <Header />

                        {
                            loading ? (
                                null
                            ) : (
                                <div>
                                    <Sidemenu sideRoutes={sideRoutes} reportRoutes={reportRoutes} getClickedUrl={(url) => this.setRouteFrame(url)} />

                                    <Switch>
                                        {sideRoutes.map((prop, key) => {
                                            return <Route path={prop.path} component={prop.component} key={key} />;
                                        })}

                                        {reportRoutes.map((prop, key) => {
                                            return <Route path={prop.path} component={prop.component} key={key} />;
                                        })}

                                        <Redirect to="/dashboard" />
                                    </Switch>

                                </div>
                            )
                        }

                        {/* <Footer /> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(Home));
