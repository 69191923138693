import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions'


//Queries
import { CREATE_USER,LOGIN } from '../../queries/UserQueries'
import {
    GET_ALL_PROVINCES, GET_DISTRICTS_FOR_PROVINCES, GET_DIVISIONS_FOR_MOHAREA, GET_MOHAREAS_FOR_DISTRICT, GET_ALL_DISTRICTS,
    GET_AREA_DETAILS
} from '../../queries/CommonQueries'

//utilities
// import { validateEmail } from "../../utilities/utils"

//Google map
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

//sementic ui
import { Dropdown, Grid, Button, Radio, Form } from 'semantic-ui-react';

//Spinner
import Loader from 'react-loader-spinner'

const apiKey = "AIzaSyAa4iktQ8XNg1TJDk_CPptIgzZWmBQm7bM"

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form_state: 'default',
            title: "",
            message: "",
            loading: false,
            name: "",
            tel: "",
            province: "",
            district: "",
            moharea: "",
            gndivision: "",
            provinceId: "",
            districtId: "",
            mohareaId: "",
            gndivisionId: "",
            provinceMachineName: "",
            districtMachineName: "",
            gndivisionMachineName: "",
            email: "",
            gender: "",
            nic: "",
            lat:"",
            lon:"",
            userType: 'USER',
            password: "",
            provinces: [],
            districts: [],
            mohareas: [],
            divisions: [],
            confirmpassword: "",
            isActive: true,
            marker:
            {
                title: "The marker`s title will appear as a tooltip.",
                name: "COLOMBO",
                position: { lat: 6.93194, lng: 79.84778 }
            },
            location: {},
            allowClick:true
        };

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
    }

    componentDidMount() {
        // read provinces
        this.loadProvinces().then(result => {
            var provinces = result.data.getAllProvinces.map(province => {
                return { key: province.id, text: province.province, value: province.id, id: 'provinceId' }
            });
            provinces.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            provinces.unshift({ key: null, id: "provinceId", text: "Select-Province", value: "Select-Province", disabled: true });

            this.setState({ provinces: provinces });
        })

        this.loadAllDistricts().then(result => {
            var districts = result.data.getAllDistricts.map(district => {
                return { key: district.id, text: district.district, value: district.id, id: 'districtId' }
            });
            districts.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            districts.unshift({ key: null, id: "districtId", text: "Select-District", value: "Select-District", disabled: true });

            this.setState({ districts: districts });
        })

    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    handleSubmit(event) {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        event.preventDefault();
        const { name, email, nic,tel, password, confirmpassword, provinceId, districtId, lat,lon } = this.state;
        if (nic === '' || name === '' || tel === '' || email === '' || password === '') {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "All Fields Required!"
            });
            return;
        } else {

            if (password != confirmpassword) {
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message: "Confirm password not matching!"
                });
                return;
            } else {
                if (provinceId == null) {
                    this.props.setFormStatus({
                        status: true,
                        title: "Oops!",
                        message: "Province Required!"
                    });
                    return;
                } else if (districtId == null) {
                    this.props.setFormStatus({
                        status: true,
                        title: "Oops!",
                        message: "District Required!"
                    });
                    return;
                } else {

                    // if (lat=="" || lon=="") {
                    //     this.props.setFormStatus({
                    //         status: true,
                    //         title: "Oops!",
                    //         message: "Location Required!"
                    //     });
                    //     return;
                    // } else {
                        this.setState({ loading: true });
                        this.props.setFormStatus({ status: false, title: '', message: '' });
                        this.props.setSuccessStatus({ status: false, title: '', message: '' });

                        this.creatUser().then(result => {

                            this.login().then(result => {

                                this.setState({
                                    name: "",
                                    tel: "",
                                    province: "",
                                    district: "",
                                    moharea:"",
                                    gndivision: "",
                                    provinceId: "",
                                    districtId: "",
                                    mohareaId:"",
                                    gndivisionId: "",
                                    lat:"",
                                    lon:"",
                                    provinceMachineName: "",
                                    districtMachineName: "",
                                    gndivisionMachineName: "",
                                    email: "",
                                    gender: "",
                                    nic: "",
                                    password: "",
                                    confirmpassword: "",
                                    isActive: false,
                                    loading: false
                                });

                                localStorage.unfpagisauthtoken = result.data.login.token;
                                localStorage.USER_ID = result.data.login.user.id;
                                localStorage.NIC = result.data.login.user.nic;
                                localStorage.NAME = result.data.login.user.name;
                                localStorage.USER_TYPE = result.data.login.user.userType;
                                localStorage.NOTIFICATIONS = result.data.login.user.notifications;
                
                                this.props.history.push("/settings");
                
                            })

                        }).catch(error => {
                            console.log(error);
                            if (error) {
                                this.setState({ loading: false });
                                this.props.setFormStatus({
                                    status: true,
                                    title: "Oops!",
                                    message:
                                        "There was an error while trying to create user, User already exist."

                                });
                            }
                        });
                    // }
                }
            }

        }
    }

    login = async () => {
        const { nic, password } = this.state;
        console.log(this.props.client)
        const result = this.props.client.mutate(
            {
                mutation: LOGIN,
                variables: { nic, password },
            })
        return result;
    }


    creatUser = async () => {
        const { name, tel, provinceId, province, districtId, district,moharea,mohareaId, gndivisionId, gndivision, lat,lon, email, gender, nic, password,
            userType, isActive } = this.state;

        const result = await this.props.client.mutate({
            mutation: CREATE_USER,
            variables: {
                name, tel, provinceId, province, districtId, district,moharea,mohareaId, gndivisionId, gndivision, lat,lon, email, gender, nic, password,
                userType, isActive
            }
        });
        return result;
    };

    RedirectToLogin = () => {
        this.props.history.push('/login')
    }

    // load provinces from api
    loadProvinces = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_PROVINCES,
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load all districts from api
    loadAllDistricts = async () => {
        const result = await this.props.client.query({
            query: GET_ALL_DISTRICTS,
            fetchPolicy: "network-only"
        });
        return result;
    };


    // load districts from api
    loadDistricts = async (provinceId) => {
        const result = await this.props.client.query({
            query: GET_DISTRICTS_FOR_PROVINCES,
            variables: { provinceId },
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load mohareas from api
    loadMOHAreas = async (districtId) => {
        const result = await this.props.client.query({
            query: GET_MOHAREAS_FOR_DISTRICT,
            variables: { districtId },
            fetchPolicy: "network-only"
        });
        return result;
    };

    // load divisions from api
    loadDivisions = async (mohId) => {
        const result = await this.props.client.query({
            query: GET_DIVISIONS_FOR_MOHAREA,
            variables: { mohId },
            fetchPolicy: "network-only"
        });
        return result;
    };


    handleProvinceChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "province": text });

        this.loadDistricts(key).then(result => {
            var districts = result.data.getDistrictsForProvince.map(district => {
                return { key: district.id, text: district.district, value: district.id, id: 'districtId' }
            });
            districts.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            districts.unshift({ key: null, id: "districtId", text: "Select-District", value: "Select-District", disabled: true });
            this.setState({ districts: districts });
        })
    }

    handleDistrictChange = (e, data) => {

        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "district": text });

        this.loadMOHAreas(key).then(result => {
            console.log(result.data.getDivisionsForMOHArea)
            var mohareas = result.data.getMOHAreasForDistrict.map(moharea => {
                return { key: moharea.id, text: moharea.mohArea, value: moharea.id, id: 'mohareaId' }
            });
            mohareas.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            mohareas.unshift({ key: null, id: "mohareaId", text: "Select-MOHArea", value: "Select-MOHArea", disabled: true });
            this.setState({ mohareas: mohareas });
        })
    }

    handleMOHChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        console.log(text)
        this.setState({ [data.id]: key, "moharea": text });
        this.getDivisions(key);
    }

    getDivisions(key) {
        this.loadDivisions(key).then(result => {
            var divisions = result.data.getDivisionsForMOHArea.map(division => {
                return { key: division.id, text: division.gndivision, value: division.id, id: 'gndivisionId' }
            });
            divisions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            divisions.unshift({ key: null, id: "gndivisionId", text: "Select-Division", value: "Select-Division", disabled: true });
            this.setState({ divisions: divisions });
        })
    }

    handleDivisionChange = (e, data) => {
        const { value } = data;
        const { key, text } = data.options.find(o => o.value === value);
        this.setState({ [data.id]: key, "gndivision": text });

    }

    handleOnCheck = (e, data) => {
        this.setState({ ['gender']: data.value });
    }

    onMapClick(t, map, coord) {
        const { latLng } = coord;
        const lat = latLng.lat().toString();
        const lng = latLng.lng().toString();
        const location = { latitude: lat, longitude: lng };
        this.setState({ lat: lat,lon:lng,allowClick:false });

        this.setState({ marker: { title: "", name: "", position: { lat, lng } } });

        this.getAreaDetails(lat, lng).then(result => {
            var mohareas = result.mohareas.map(moharea => {
                return { key: moharea.id, text: moharea.mohArea, value: moharea.id, id: 'mohareaId' }
            });
            mohareas.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            mohareas.unshift({ key: null, id: "mohareaId", text: "Select-MOHArea", value: "Select-MOHArea", disabled: true });

            var divisions = result.divisions.map(division => {
                return { key: division.id, text: division.gndivision, value: division.id, id: 'gndivisionId' }
            });
            divisions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
            divisions.unshift({ key: null, id: "gndivisionId", text: "Select-Division", value: "Select-Division", disabled: true });

            this.setState({
                provinceId: result.province.provinceId,
                province: result.province.province,
                districtId: result.district.districtId,
                district: result.district.district,
                divisions: divisions,
                mohareas: mohareas,
                allowClick:true
            });
        }).catch(error => {
            if (error) {
                console.log(error);
                console.log('There is an error');
                this.props.setFormStatus({ status: true, title: 'Oops!', message: 'Error in ' });
            }
            this.setState({ loading: false, allowClick:true });

        });


    }

    getAreaDetails = async (lat, lng) => {
        const lon = lng;

        const result = await this.props.client.query({
            query: GET_AREA_DETAILS,
            variables: { lat, lon },
            fetchPolicy: "network-only"
        });
        return result.data.getAreaDetails;
    };


    render() {
        const { marker, name, loading, tel, gndivision, email, nic, password, confirmpassword, userType, provinces, districts, mohareas, divisions } = this.state;
        const { formErrorStatus, formSuccessState } = this.props;

        console.log(this.state.districts)


        return (
            <div class="login">
                <div class="login_container">
                    <div id="register" >
                        <section class="login_content">
                            <form>
                                <Grid columns='two'>
                                    <Grid.Row>
                                        <Grid.Column className="login_leftcontent" width={5}>

                                            <h1>Create Account</h1>

                                            <div>
                                                <input value={name || ''} type="text" id="name" name="name" class="form-control" placeholder="Full Name" required="" onChange={this.handleChange} />
                                            </div>
                                            <div>
                                                <input type="text" id="tel" value={tel || ''} name='tel' class="form-control" placeholder="Telephone" onChange={this.handleChange} />
                                            </div>
                                            <div style={{ float: "left", textAlign: "left" }}>
                                                <label>Gender</label><br />

                                                <Form.Group>
                                                    <Form.Radio
                                                        id="male"
                                                        label="Male"
                                                        name="gender"
                                                        value="MALE"
                                                        onChange={this.handleOnCheck}
                                                    />
                                                    <Form.Radio
                                                        id="female"
                                                        label="Female"
                                                        name="gender"
                                                        value="FEMALE"
                                                        onChange={this.handleOnCheck}
                                                    />
                                                </Form.Group>
                                                <br />
                                            </div>
                                            <div>
                                                <input type="text" id="nic" name='nic' value={nic || ''} class="form-control" placeholder="NIC" required="" onChange={this.handleChange} />
                                            </div>

                                            <div>
                                                <input type="email" id="email" name='email' value={email || ''} class="form-control" placeholder="Email" required="" onChange={this.handleChange} />
                                            </div>

                                            <div>
                                                <input type="password" id="password" name='password' value={password || ''} class="form-control" placeholder="Password" required="" onChange={this.handleChange} />
                                            </div>

                                            <div>
                                                <input type="password" id="confirmpassword" name='confirmpassword' value={confirmpassword || ''} class="form-control" placeholder="Confirm Password" required="" onChange={this.handleChange} />
                                            </div>

                                            <div>
                                                <Dropdown
                                                    placeholder='Select Province'
                                                    fluid
                                                    selection
                                                    id='provinceId'
                                                    name='provinceId'
                                                    options={provinces}
                                                    value={this.state.provinceId}
                                                    onChange={this.handleProvinceChange}
                                                />
                                            </div><br />

                                            <div>
                                                <Dropdown
                                                    placeholder='Select District'
                                                    fluid
                                                    selection
                                                    id='districtId'
                                                    name='districtId'
                                                    options={districts}
                                                    value={this.state.districtId}
                                                    onChange={this.handleDistrictChange}
                                                />
                                            </div><br />

                                            <div>
                                                <Dropdown
                                                    placeholder='Select MOH Area'
                                                    fluid
                                                    selection
                                                    search
                                                    id='mohareaId'
                                                    name='mohareaId'
                                                    options={mohareas}
                                                    value={this.state.mohareaId}
                                                    onChange={this.handleMOHChange}
                                                />
                                            </div><br />

                                            <div>
                                                <Dropdown
                                                    placeholder='Select Division'
                                                    fluid
                                                    selection
                                                    search
                                                    id='gndivisionId'
                                                    name='gndivisionId'
                                                    options={divisions}
                                                    value={this.state.divisionId}
                                                    onChange={this.handleDivisionChange}
                                                />
                                            </div><br />

                                            <div >
                                                <Button type="button" onClick={this.handleSubmit} className="btn btn-primary registration_submit" disabled={loading ? (true) : (false)}>
                                                    {loading ? (
                                                        <Loader
                                                            type="Oval"
                                                            color="#2A3F54"
                                                            height={15}
                                                            width={40}
                                                        />
                                                    ) : ("Submit")}
                                                </Button>
                                            </div>

                                            <div class="clearfix"></div>

                                            <div class="separator">
                                                <p class="change_link"> Already a member ?  &nbsp;&nbsp;

                                                    <a className="link" onClick={() => this.RedirectToLogin()}>Log in </a>
                                                </p>

                                                <div class="clearfix"></div>
                                                <br />
                                            </div>

                                            {(formErrorStatus.status) ? (
                                                <div class="ui negative message">

                                                    <div class="header">
                                                        Not Registered.
                                                </div>
                                                    <p>{formErrorStatus.message}</p>
                                                </div>

                                            ) : ((formSuccessState.status) ? (
                                                <div class="ui success message">
                                                    <div class="header">
                                                        Registered successfully.
                                                            </div>
                                                    <p>{formSuccessState.message}</p>
                                                </div>
                                            ) : (''))}

                                        </Grid.Column>
                                        <Grid.Column>

                                            <div className="map_container">
                                                <Map
                                                    google={this.props.google}

                                                    style={{ width: "80%", margin: "auto", height: "78%" }}
                                                    initialCenter={{ lat: 7.2906, lng: 80.6337 }}
                                                    zoom={8.7}
                                                    onClick={
                                                        this.state.allowClick ? this.onMapClick : false
                                                    }
                                                >
                                                    <Marker
                                                        title={marker.title}
                                                        name={marker.name}
                                                        position={marker.position}
                                                    />

                                                </Map>
                                            </div>

                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </form>
                        </section>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: apiKey
})(Registration))));
