import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

import { GET_SINGLE_SCHOOL_SETTING_SUBMISSION } from '../../../queries/SettingQueries'

//sementic ui
import { Grid, Button, List, Icon } from 'semantic-ui-react';

//Google map
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

//middleware
import { formatDate } from '../../../middleware';

//ImageZoomer
import ImageZoom from 'react-medium-image-zoom'

const apiKey = "AIzaSyAa4iktQ8XNg1TJDk_CPptIgzZWmBQm7bM"


const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.formErrorStatus,
    formSuccessState: state.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}


class viewSchoolSubmission extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('id');

    this.state = {
      settingSubmissionId: id,
      schoolSubmission: {},
      marker:
      {
        title: "The marker`s title will appear as a tooltip.",
        name: "COLOMBO",
        position: { lat: 6.93194, lng: 79.84778 }
      },
      loading: false
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    this._getSingleSchoolSettingSubmission().then(result => {
      console.log(result)
      var marker = this.state.marker;
      if (result.location != undefined || result.location != null) {
        // marker['lat'] = result.location.latitude;
        // marker['lon'] = result.location.longitude;
        marker = {
          title: "The marker`s title will appear as a tooltip.",
          name: "COLOMBO",
          position: { lat: result.location.latitude, lng: result.location.longitude }
        }
      }
      this.setState({ schoolSubmission: result, marker: marker, loading: false });
    })
  }

  _getSingleSchoolSettingSubmission = async () => {
    const { settingSubmissionId } = this.state;
    const result = await this.props.client.query({
      query: GET_SINGLE_SCHOOL_SETTING_SUBMISSION,
      variables: { settingSubmissionId },
      fetchPolicy: 'network-only'
    });
    return result.data.getSingleSchoolSettingSubmission;
  }

  newEvent = () => {
    let id = this.state.settingSubmissionId;
    this.props.history.push("/addschoolevent?id=" + id);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {

    const { schoolSubmission, loading, marker } = this.state;

    return (
      <div className="right_col" role="main">
        <div class="row">
          <div class="col-md-6 col-sm-6 ">
            <div >
              <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
            </div> <br /><br />
            <div class="x_panel">
              <div class="x_title">
                <h2> School Submission <small>View</small></h2>

                <div class="clearfix"></div>
              </div>
              <div class="x_content">

                {
                  loading ? (
                    null
                  ) : (
                      <Grid>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Province : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.province}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> District : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.district}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> MOHArea : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.mohArea}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Gndivision : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.gndivision}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> PHM Area : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.phmArea}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Name of School : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.nameOfSchool}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Name of Facilitating PHM: </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.nameOfFacilitatingPHM}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Name of Facilitating PHI </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.nameOfFacilitatingPHI}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Name Of Principal </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.schoolPrincipalName}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Phone No Of Principal : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.schoolPrincipalTel}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Date of establishment of the School : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {
                              schoolSubmission.dateOfEstablishmentOfSchool != undefined ? (
                                formatDate(schoolSubmission.dateOfEstablishmentOfSchool)
                              ) : (null)
                            }  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Number Of Students : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.numOfStudents}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Meeting Frequency : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.meetingFrequency}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Number of Meetings In year : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.numOfMeetingsInyear}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Number of meetings/programs conducted for school health authorities: </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.numOfProgramsHealthAuthority}  </p>
                          </Grid.Column>
                        </Grid.Row>


                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Conducted Activities </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>

                            <List divided relaxed>
                              {schoolSubmission.conductedActivities != undefined ? (
                                schoolSubmission.conductedActivities.length > 0 ? (
                                  schoolSubmission.conductedActivities.map((activity, key) => {
                                    return (
                                      <List.Item>
                                        <List.Icon name='marker' />
                                        <List.Content>
                                          <List.Description>
                                            {activity}
                                          </List.Description>
                                        </List.Content>
                                      </List.Item>
                                    );
                                  })
                                ) : (
                                    null
                                  )
                              ) : (
                                  null
                                )}
                            </List>

                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Last Supervision Visit </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.supervisionVisit}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2} className="viewSubRow">
                          <Grid.Column width={7}>
                            <p className="flat"> <strong> Comments : </strong> </p>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <p className="flat"> {schoolSubmission.comments}  </p>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column>
                            {
                              schoolSubmission.images != undefined ? (
                                schoolSubmission.images.map((image, key) => {
                                  return (
                                    <div class="img_wrp">
                                      <ImageZoom
                                        image={{
                                          src: image,
                                          className: 'img',
                                          style: { width: "150px", height: "150px", "padding": "15px" }
                                        }}
                                        zoomImage={{
                                          src: image,
                                        }}
                                      />
                                    </div>
                                  );
                                })
                              ) : (
                                  null
                                )

                            }
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )
                }


              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 ">



            <div className="" style={{ "margin-right": "50px" }}>
              <br />
              {localStorage.USER_TYPE == "USER" ? (
                schoolSubmission.status == "APPROVED" ? (
                  <Button basic color='blue' style={{ float: "right" }} type="button" onClick={() => this.newEvent()}>  Add New Event </Button>
                ) : (
                    <Button disabled basic color='blue' style={{ float: "right" }} type="button" onClick={() => this.newEvent()}>  Add New Event </Button>
                  )
              ) : (
                  null
                )}

              <br /><br />
            </div>

            <div className="">
              <Map
                google={this.props.google}
                style={{ width: "80%", "margin-top": "20px", "margin-left": "50px", height: "78%" }}
                initialCenter={{ lat: 7.2906, lng: 80.6337 }}
                zoom={8.7}
              >
                <Marker
                  title={marker.title}
                  name={marker.name}
                  position={marker.position}
                />

              </Map>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
  apiKey: apiKey
})(viewSchoolSubmission))));