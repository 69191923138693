import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';

//query
import { LOGIN } from '../../../queries/UserQueries';

//ui
import { Button, Grid, Dropdown, TextArea, Input, Popup, Message, Icon } from 'semantic-ui-react';

//Google map
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

//Spinner
import Loader from 'react-loader-spinner'

//firebase
import { uploadFile, deleteFile } from "../../../firebase/FileServices";

//Image Uploader
import Images from '../../../components/Image/Images'

//ImageZoomer
import ImageZoom from 'react-medium-image-zoom'

import {
    ADD_SCHOOL_EVENT, GET_SINGLE_SCHOOL_EVENT, EDIT_SCHOOL_EVENT
} from '../../../queries/EventQueries'


//DatePicker
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

//middleware
import { formatDate } from '../../../middleware/index';

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.formErrorStatus,
        formSuccessState: state.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class AddNewSchoolEvent extends Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');

        var currentLocation = window.location.href;
        var url = currentLocation.split("?")
        url = url[0];
        url = url.split("/");
        var page = url[url.length - 1];

        var pageFormat = "";
        var eventId = "";

        if (page == "viewschoolevent") {
            pageFormat = "EDIT"
            eventId = id
        } else {
            pageFormat = "ADD"
        }

        this.state = {
            schoolSettingSubmissionId: id,
            title: "",
            description: "",
            date: "",
            imageUrl: null,
            tempFile: null,
            isUploading: false,
            fileRef: null,
            uploads: [],

            images: [],
            loading: false,
            formData: [],
            readFiles: [],
            initialize: false,
            pageFormat: pageFormat,
            eventId: eventId,

        };

        props.setFormStatus({ status: false, title: '', message: '' });

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        props.setFormStatus({ status: false, title: '', message: '' });
        props.setSuccessStatus({ status: false, title: '', message: '' });


    }

    componentDidMount() {
        if (this.state.pageFormat == "EDIT") {
            this.getSingleEvent();
        }

    }

    getSingleEvent() {
        this.setState({ initialize: true });
        this.getSingleSchoolEvent().then(result => {
            this.setState({
                title: result.title,
                description: result.description,
                date: new Date(result.date),
                images: result.images,
                initialize: false
            });
        })
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    handleTimeChange = (event, { name, value }) => {
        this.setState({ date: value });
    }


    handleSubmit() {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        const { title, description, date, images, pageFormat } = this.state;

        if (title == "") {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Title Required!"
            });
            return;
        } else if (description == "") {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Description Required!"
            });
            return;
        } else if (date == "") {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "Event Date Required!"
            });
            return;
        } else if (images.length <= 0) {
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message: "At least one image Required!"
            });
            return;
        } else {
            this.setState({ loading: true });
            this.props.setFormStatus({ status: false, title: '', message: '' });
            this.props.setSuccessStatus({ status: false, title: '', message: '' });

            if (pageFormat == "ADD") {
                this.addSchoolEvent().then(result => {
                    console.log(result);
                    this.setState({
                        description: "",
                        date: "",
                        loading: false,
                        form_state: 'default',
                        title: "",
                        message: "",
                    });

                    this.props.setSuccessStatus({
                        status: true,
                        title: "",
                        message:
                            "Event added Successfully!.."
                    });
                }).catch(error => {
                    console.log(error);
                    if (error) {
                        this.setState({ loading: false });
                        this.props.setFormStatus({
                            status: true,
                            title: "Oops!",
                            message:
                                "There was an error while trying to add event."

                        });
                    }
                });

            } else if (pageFormat == "EDIT") {
                this.editSchoolEvent().then(result => {
                    console.log(result);
                    this.setState({ loading: false });
                    this.getSingleEvent();

                    this.props.setSuccessStatus({
                        status: true,
                        title: "",
                        message:
                            "Event edited Successfully!.."
                    });

                }).catch(error => {
                    console.log(error);
                    if (error) {
                        this.setState({ loading: false });
                        this.props.setFormStatus({
                            status: true,
                            title: "Oops!",
                            message:
                                "There was an error while trying to edit event."

                        });
                    }
                });
            }

        }



    }

    addSchoolEvent = async () => {
        const {
            title,
            description,
            date,
            images,
            schoolSettingSubmissionId
        } = this.state;

        const result = await this.props.client.mutate({
            mutation: ADD_SCHOOL_EVENT,
            variables: { title, description, date, images, schoolSettingSubmissionId }
        });
        return result.data.addSchoolEvent;
    };

    editSchoolEvent = async () => {
        const {
            eventId,
            title,
            description,
            date,
            images
        } = this.state;

        const result = await this.props.client.mutate({
            mutation: EDIT_SCHOOL_EVENT,
            variables: { eventId, title, description, date, images }
        });

        console.log(result)
        return result.data.editSchoolEvent;
    };

    getSingleSchoolEvent = async () => {
        const { eventId } = this.state;

        console.log(eventId)
        const result = await this.props.client.query({
            query: GET_SINGLE_SCHOOL_EVENT,
            variables: { eventId },
            fetchPolicy: 'network-only'
        });
        return result.data.getSingleSchoolEvent;
    }

    handleFileAddChange = event => {
        var formData = this.state.formData;
        var readFiles = this.state.readFiles;

        formData.push(event.target.files[0]);
        readFiles.push(URL.createObjectURL(event.target.files[0]));

        console.log(readFiles)

        this.setState({
            formData: formData,
            readFiles: readFiles
        });

    }

    handleFileRemoveChange = (i) => {
        var formData = this.state.formData;
        var readFiles = this.state.readFiles;

        formData.splice(i, 1);
        readFiles.splice(i, 1);

        this.setState({
            formData: formData,
            readFiles: readFiles
        });

    }

    fileUploadOnClick = event => {
        this.setState({
            isUploading: true,
        });

        if (this.state.formData.length > 0) {
            this.state.formData.map((file, key) => {
                this._startUpload(file);
            })
        }

    };

    _startUpload = async file => {
        var intDate = Number(new Date());
        var images = [];

        if (this.state.images != undefined) {
            images = this.state.images;
        }

        const result = await uploadFile(file, localStorage.FIRST_NAME + intDate);
        if (result.status) {
            images.push(result.url);
            this.setState({
                isUploading: false,
                fileRef: 'uploads/' + localStorage.FIRST_NAME + intDate + '/' + file.name,
                images: images,
                readFiles: []
            });

            console.log(this.state.images)
        }
    };

    _deleteFile = async (path, i) => {
        var images = this.state.images;
        const deleteRef = await deleteFile(path);
        images.splice(i, 1);
        this.setState({
            images: images
        });
        return deleteRef;
    }

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        const { loading, description, title, initialize } = this.state;
        const { formErrorStatus, formSuccessState } = this.props;

        console.log(this.state.formData)
        console.log(this.state.readFiles)
        console.log(this.state.images)

        return (
            <div className="right_col" role="main">
                <div >
                    <Icon style={{ "cursor": "pointer" }} onClick={() => this.goBack()} name="arrow circle left" size="big" />
                </div>
                <div class="page-title">
                    <div class="title_left">
                        <h3>
                            {this.state.pageFormat == "EDIT" ? (
                                "Edit Event"
                            ) : (
                                    "Add Event"
                                )}
                        </h3>
                    </div>
                </div>
                <div class="clearfix"></div>

                <div className="row">
                    <div className="col-md-12 ">
                        <div className="x_panel">
                            <div className="x_title">
                                <h2> School Event </h2>

                                <div className="clearfix" />
                            </div>
                            <div className="x_content">
                                <br />

                                {this.state.initialize ? (
                                    null
                                ) : (
                                        <div className="row">
                                            <div className="col-md-8 ">
                                                <form className="form-horizontal form-label-left">

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Title </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input type="text" id="title" name="title" value={this.state.title} placeholder="Title" onChange={this.handleChange} className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div class="form-group row ">
                                                        <label class="control-label col-md-3 col-sm-3 " for="number" > Description </label>
                                                        <div class="col-md-9 col-sm-9">
                                                            <TextArea style={{ width: "100%" }} id="description" name="description" value={this.state.description} onChange={this.handleChange} placeholder='Description' rows={6} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="control-label col-md-3 col-sm-3 "> Event Date : </label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <SemanticDatepicker
                                                                style={{ width: "100%" }}
                                                                label=""
                                                                id="date"
                                                                name="date"
                                                                placeholder="Event Date"
                                                                dateFormat="YYYY-MM-DD"
                                                                value={this.state.date}
                                                                iconPosition="left"
                                                                onChange={this.handleTimeChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div class="form-group row ">
                                                        <label class="control-label col-md-3 col-sm-3 " for="number" >Upload Images </label>
                                                        <div class="col-md-9 col-sm-9">
                                                            <div>
                                                                <Grid columns={2}>
                                                                    <Grid.Column textAlign="left">
                                                                        <Input
                                                                            onChange={this.handleFileAddChange}
                                                                            name="upload"
                                                                            id="upload"
                                                                            style={{ width: "250px" }}
                                                                            value={this.state.uploads}
                                                                            placeholder="Product Image"
                                                                            type="file"
                                                                            required
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column textAlign="right">
                                                                        {localStorage.USER_TYPE == "USER" ? (
                                                                            <button onClick={() => this.fileUploadOnClick()} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                                                {loading ? (
                                                                                    <Loader
                                                                                        type="Oval"
                                                                                        color="#2A3F54"
                                                                                        height={15}
                                                                                        width={40}
                                                                                    />
                                                                                ) : ("Click here to Upload")}
                                                                            </button>
                                                                        ) : (
                                                                                null
                                                                            )}

                                                                    </Grid.Column>
                                                                </Grid>
                                                            </div>

                                                            <div>
                                                                {this.state.isUploading ? (
                                                                    <Message icon>
                                                                        <Icon name="circle notched" loading />
                                                                        <Message.Content>
                                                                            <Message.Header>Just one second</Message.Header>
                                                                            We are uploading your document
                                                                </Message.Content>
                                                                    </Message>
                                                                ) : (
                                                                        ""
                                                                    )}
                                                            </div>

                                                            <div>
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column>

                                                                            {
                                                                                this.state.images != undefined ? (
                                                                                    this.state.images.length > 0 ? (
                                                                                        this.state.images.map((image, key) => {
                                                                                            return (
                                                                                                <div class="img_wrp">
                                                                                                    <ImageZoom
                                                                                                        image={{
                                                                                                            src: image,
                                                                                                            className: 'img',
                                                                                                            style: { width: "150px", height: "150px", "padding": "15px" }
                                                                                                        }}
                                                                                                        zoomImage={{
                                                                                                            src: image,
                                                                                                        }}
                                                                                                    />

                                                                                                    <span onClick={() => this._deleteFile(image, key)} class="close">&times;</span>
                                                                                                </div>

                                                                                            );
                                                                                        })
                                                                                    ) : (null)
                                                                                ) : (null)
                                                                            }

                                                                            {this.state.readFiles.map((file, key) => {
                                                                                return (
                                                                                    <div class="img_wrp">
                                                                                        <ImageZoom
                                                                                            image={{
                                                                                                src: file,
                                                                                                className: 'img',
                                                                                                style: { width: "150px", height: "150px", "padding": "15px" }
                                                                                            }}
                                                                                            zoomImage={{
                                                                                                src: file,
                                                                                            }}
                                                                                        />

                                                                                        <span onClick={() => this.handleFileRemoveChange(key)} class="close">&times;</span>
                                                                                    </div>

                                                                                );
                                                                            })}

                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {(formErrorStatus.status) ? (
                                                        <div class="ui negative message">

                                                            <div class="header">
                                                                Not Submitted.
                                                </div>
                                                            <p>{formErrorStatus.message}</p>
                                                        </div>

                                                    ) : ((formSuccessState.status) ? (
                                                        <div class="ui success message">
                                                            <div class="header">
                                                                Submitted successfully.
                                                            </div>
                                                            <p>{formSuccessState.message}</p>
                                                        </div>
                                                    ) : (''))}

                                                    <div className="ln_solid" />
                                                    <div className="form-group">
                                                        <div className="col-md-9 col-sm-9  offset-md-3">

                                                            {localStorage.USER_TYPE == "USER" ? (
                                                                <button onClick={() => this.handleSubmit()} type="button" className="btn btn-success" disabled={loading ? (true) : (false)}>
                                                                    {loading ? (
                                                                        <Loader
                                                                            type="Oval"
                                                                            color="#2A3F54"
                                                                            height={15}
                                                                            width={40}
                                                                        />
                                                                    ) : ("Submit")}
                                                                </button>
                                                            ) : (
                                                                    null
                                                                )}

                                                        </div>
                                                    </div>

                                                </form>
                                            </div>

                                        </div>

                                    )}


                            </div>
                        </div>

                    </div>


                </div>
            </div>

        )
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(AddNewSchoolEvent)));
