import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./store/store";
import { Provider } from "react-redux";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { persistCache } from "apollo-cache-persist";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter,Route } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "semantic-ui-css/semantic.min.css";


const httpLink = createHttpLink({
    uri: "https://msgwatcher.encyte.tech:4003/"
    // uri: "http://localhost:4002"
});

const cache = new InMemoryCache();
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("unfpagisauthtoken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache
});

persistCache({
    cache:client.cache,
    storage: window.localStorage,
    debug:true,
});

ReactDOM.render(
    <BrowserRouter basename="">
        {/* basename="/production/healthpromotionweb" */}
        <ApolloProvider client={client}>
            <Provider store={store}>
                <App/>
            </Provider>
        </ApolloProvider>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
